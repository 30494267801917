import React, {useEffect, useState} from 'react';
import {Select, Stepper} from '@mantine/core';
import {DatePickerProps, DateValue, TimeInput} from "@mantine/dates";
import '@mantine/core/styles.css';
import '../../css/stepper.css'
import "dayjs/locale/it"
import calendar_icon from "../../assets/reservation/stepper/calendar-icon.svg"
import clock_icon from "../../assets/reservation/stepper/clock.svg"
import person from "../../assets/reservation/stepper/person.svg"
import check from "../../assets/reservation/stepper/check.svg"
import check_prenotazione from "../../assets/reservation/stepper/check-prenotazione.svg"
import CustomButton from "../CustomButton";
import CustomTextInput from "../generic/CustomTextInput";
import {LoginResponseType} from "../../types/LoginResponseType";
import Calendar from "./Calendar";
import ButtonGroup from "./ButtonGroup";
import {GetRestaurantResponseType} from "../../types/GetRestaurantResponseType";
import {makeRequest} from "../../network/RestAdapter";
import {HttpMethodsEnum} from "../../network/HttpMethodsEnum";
import {ReservationSendType} from "../../types/ReservationSendType";
import {useDebounce} from "use-debounce";
import {searchUserReservation} from "../../network/controllers/admin/ReservationController";
import {GetUserReservationAdmin} from "../../types/GetUsersReservationAdmin";
import {isMobile} from "react-device-detect";
import {getTimeBandsByRestaurant, TimeBandType} from "../../network/controllers/TableBandController";


export default function ReservationStepper(props: { isAdmin: boolean ,isSmall?:boolean}) {
    const [active, setActive] = useState(0);
    const [loadingButton, setLoadingButton] = useState(false);
    const nextStep = () => setActive((current) => (current < 4 ? current + 1 : current));
    const [value, setValue] = useState<Date | null>(new Date());
    const [ore, setOre] = useState('undefined')
    const [persons, setPersons] = useState('undefined')
    const userData = props.isAdmin ? undefined : JSON.parse(localStorage.getItem('userData')!) as LoginResponseType;
    const [messageError, setMessageError] = useState('')
    const [buttonHourEnabled, setButtonHourEnable] = useState(false)
    const [buttonPrenottaEnabled, setButtonPrenottaEnable] = useState(false)
    const [user, setUser] = useState({
        name: !props.isAdmin && userData ? userData.name : '',
        surname: !props.isAdmin && userData ? userData.surname : '',
        phoneNumber: !props.isAdmin && userData ? userData.phone : '',
        email: !props.isAdmin && userData ? userData.email : '',
        note: ''
    })
    const [phoneUser,setPhoneUser] = useState('')

    const restaurantData = JSON.parse(localStorage.getItem(props.isAdmin ? 'selectedRestaurant' : 'selectedRestaurantClient') as string) as GetRestaurantResponseType
    let closedDays: number[] = []
    let openedDays: string[] = [];
    calculateOpenedDays()


    const [timeSlotsList, setTimeSlotsList] = useState<TimeBandType[]>([]);

// Funzione per caricare le fasce orarie
    useEffect(() => {
        getTimeBandsByRestaurant(restaurantData.id, (response) => {
            if (response) {
                // Prendo solo le date che sono nel futuro
                const now = new Date();
                const futureTimeBands = response.filter((item: { band: string }) => {
                    const [hours, minutes] = item.band.split(':').map(Number);
                    const bandDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
                    return bandDate > now; // Mantieni solo le fasce future
                });
                //Prendo solo le fasce orarie che sono comprese tra i range di apertura/chiusura del locale selzionato usando il metodo checkhour
                const validTimeBand=futureTimeBands.filter(it=>CheckHour(it.band))
                setTimeSlotsList(response);
            }
        });
    }, [restaurantData.id]);

    //gestione rubrica

    const [valueUser] = useDebounce(user.phoneNumber, 500);
    const [usersList, setUsersList] = useState<{ label: string, value: any }[]>([]);

    useEffect(() => {
        if (props.isAdmin && user.phoneNumber.length>4) {
            searchUserReservation(user.phoneNumber, (response) => {
                var tmpArr: { label: string, value: any }[] = [];
                if (response.length > 0) {
                    response.forEach((user) => {
                        tmpArr.push({value: JSON.stringify(user), label: `${user.phone}`});
                    })
                }
                setUsersList(tmpArr);
            })
        }else{
            setUsersList([])
        }
    }, [valueUser]);

    function calculateOpenedDays() {
        for (let day of Object.values(restaurantData.hours)) {
            if (day.split(':').at(1)!.toLowerCase().trim() === 'chiuso') {
                closedDays.push(italianWeekdayToNumber(day.split(':').at(0)!));
            } else {
                openedDays.push(day)
            }
        }
    }

    //Takes the information of the day and returns "HH:MM / HH:MM"
    function hoursLabel(day: string) {
        let hours = day.slice(day.indexOf(':') + 1)
        let ranges = hours.split(',')
        const formatedRanges = ranges.map(range => {
            const [startRange, endRange] = range.split('–');
            return `${startRange}-${endRange}`;
        })
        return formatedRanges.join(' / ');
    }

    /*Gets the day picked and gives the information of the day*/
    function dayNumberToHourInfo() {
        if ((value!.getDay() - 1) >= 0) {
            // @ts-ignore
            return restaurantData.hours[(value!.getDay() - 1).toString()]
        } else {
            // @ts-ignore
            return restaurantData.hours["6"]
        }
    }

    function handleChange(value: DateValue) {
        setValue(value)
        nextStep()
    }

    function HandlePersons(persons: string) {
        setPersons(persons)
        nextStep()
    }

    function HandleTimeChange(eventTarget: string) {
        if (props.isAdmin) {
            // Ignora il controllo e abilita direttamente il pulsante per gli admin
            setOre(eventTarget);
            setButtonHourEnable(true);
            setMessageError('');
            return;
        }

        // Verifica che il formato dell'orario sia valido
        const timePattern = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
        if (!timePattern.test(eventTarget)) {
            setMessageError("Formato orario non valido");
            setButtonHourEnable(false);
            console.log('orario inviato', eventTarget);
            return;
        }

        // Imposta direttamente l'orario senza alterare il fuso orario
        try {
            setOre(eventTarget);
            // Verifica se l'orario è valido
            if (CheckHour(eventTarget)) {
                setButtonHourEnable(true);
                setMessageError('');
            } else {
                setButtonHourEnable(false);
                setMessageError('Seleziona un orario compreso tra quelli elencati sopra');
            }
        } catch (error) {
            console.error("Errore durante l'elaborazione dell'orario:", error);
            setMessageError("Errore interno nell'elaborazione dell'orario");
            setButtonHourEnable(false);
        }
    }



    /*Checks if the hour is between the range of the restaurant schedule*/
    function CheckHour(ore: string) {
        const hours = hoursLabel(dayNumberToHourInfo()).split('/');
        let validation = false;

        hours.forEach(range => {
            const startRange = combineDateTimeCustom(value!.toISOString(), range.split('-').at(0)!);
            const endRange = combineDateTimeCustom(value!.toISOString(), range.split('-').at(1)!);
            const selectedRange = combineDateTimeCustom(value!.toISOString(), ore);

            if (new Date(selectedRange) >= new Date(startRange) && new Date(selectedRange) <= new Date(endRange)) {
                validation = true;
            }
        });

        return validation;
    }


    function HandlePrenota() {
        if (user.name === '') {
            setButtonPrenottaEnable(false)
            return
        }
        if (user.email === '') {
            setButtonPrenottaEnable(false)
            return
        }
        if (user.surname === '') {
            setButtonPrenottaEnable(false)
            return
        }
        if (user.phoneNumber === '' || !(parseInt(user.phoneNumber) > 0)) {
            setButtonPrenottaEnable(false)
            return;
        }
        setButtonPrenottaEnable(true)
    }

    //Takes the string day and converts it to a number to pass it to the datepicker
    function italianWeekdayToNumber(weekday: string) {
        switch (weekday.toLowerCase()) {
            case 'lunedì':
                return 1;
            case 'martedì':
                return 2;
            case 'mercoledì':
                return 3;
            case 'giovedì':
                return 4;
            case 'venerdì':
                return 5;
            case 'sabato':
                return 6;
            case 'domenica':
                return 0;
            default:
                return -1;
        }
    }



    //Checks the number of the closed days and disables them
    const getDayProps: DatePickerProps['getDayProps'] = (date) => {
        if (openedDays.length < 7) {
            for (let closedDay of closedDays) {
                if (date.getDay() === closedDay) {
                    return {disabled: true};
                }
            }
        }
        if (new Date(Date.now()).setHours(0, 0, 0, 0) > new Date(date).setHours(0, 0, 0, 0)) {
            return {disabled: true};
        }
        return {};
    };

    function combineDateTimeCustom(localDateString: string, timeString: string) {
        // Crea un oggetto Date dalla stringa della data esistente in locale
        let localDate = new Date(localDateString);

        // Prendi i componenti della data esistente
        let year = localDate.getFullYear();
        let month = localDate.getMonth(); // Mesi sono zero-indicizzati
        let day = localDate.getDate();
        // Parse l'orario specificato
        let hours = timeString.split(':')[0];
        let minutes = timeString.split(':')[1]
        // Crea un nuovo oggetto Date utilizzando i componenti della data e dell'orario in UTC
        // @ts-ignore
        let combinedDate = new Date(year, month, day, hours, minutes, 11, 11);
        // Restituisce la stringa ISO 8601
        let isoString = combinedDate.toISOString().slice(0, -2); // Rimuovi gli ultimi 5 caratteri "000Z"
        // Aggiungi "7Z" alla fine
        isoString += '7Z';
        let result = isoString.replace(/:(\d{3})Z$/, '.$1Z')
        return result;
    }

    function combineDateTime(localDateString: string, timeString: string) {
        // Crea un oggetto Date dalla stringa della data esistente in locale
        let localDate = new Date(localDateString);
        // Prendi i componenti della data esistente
        let year = localDate.getFullYear();
        let month = localDate.getMonth(); // Mesi sono zero-indicizzati
        let day = localDate.getDate();
        // Parse l'orario specificato
        let hours = timeString.split(':')[0];
        let minutes = timeString.split(':')[1]
        // Crea un nuovo oggetto Date utilizzando i componenti della data e dell'orario in UTC
        // @ts-ignore
        let combinedDate = new Date(Date.UTC(year, month, day, hours, minutes));

        // Restituisce la stringa ISO 8601
        let isoString = combinedDate.toISOString().slice(0, -5); // Rimuovi gli ultimi 5 caratteri "000Z"

        // Aggiungi "7Z" alla fine
        isoString += '7Z';
        return isoString.replace(/:(\d{3})Z$/, '.$1Z');
    }


    return (
        <>
            <Stepper classNames={
                {
                    root: `bg-black rounded-[16px] px-1 py-8 ${props.isSmall?'w-[340px] h-[572px] ':'w-[425px] h-[572px]'}`,
                    steps: `flex flex-row mx-auto rounded-[6px] bg-white ${props.isSmall?'w-[298px] h-[52px]':'w-[373px] h-[52px]'}`,
                    step: `${props.isSmall?'h-[52px]':'h-[52px]'} ${isMobile? 'flex justify-evenly stepCustom0':'flex justify-center stepCustom0'}`,
                    stepLabel: `text-black ${props.isSmall?'text-[12px]':'text-[16px]'} ml-2 font-poppins-regular`,
                    stepWrapper: 'mx-6 customWrapper0',
                    separator: 'separatorCustom0',
                    content: 'flex flex-col h-[92%]',
                    stepIcon: 'iconCustom0 max-w-[30px] max-h-[30px] min-w-[30px] min-h-[30px] border-none bg-transparent',
                    stepCompletedIcon: '!animate-none !transition-none ',
                    stepBody: 'ml-0 mr-2'
                }} allowNextStepsSelect={false} active={active} onStepClick={loadingButton ? setActive : () => {
            }}>
                <Stepper.Step
                    className={`!rounded-l-[5px]`}

                    label={value === null ? 'Data' : value!.getDate().toString() + " " + Intl.DateTimeFormat("it-IT", {month: "short"}).format(value!).charAt(0).toUpperCase() + Intl.DateTimeFormat("it-IT", {month: "short"}).format(value!).substring(1)}
                    completedIcon={<img src={calendar_icon} alt={''}/>}
                    icon={<img src={calendar_icon} alt={''}/>}>
                    <Calendar dayProps={getDayProps} value={value} onChange={(value) => {

                        handleChange(value)

                    }}/>
                </Stepper.Step>

                <Stepper.Step
                    label={ore === 'undefined' ? 'Orario' : ore.toString()}
                    completedIcon={<img src={clock_icon} alt={''} />}
                    icon={<img src={clock_icon} alt={''} />}
                >
                    {props.isAdmin ? (
                        <TimeInput
                            onKeyDown={(event) => {
                                if (event.key === 'Delete' || event.key === 'Backspace') {
                                    event.preventDefault();
                                    console.log('Delete or Backspace key press prevented');
                                }
                            }}
                            className={'mt-4 mx-4'}
                            classNames={{ input: 'my-4', label: 'text-white font-poppins-regular text-base' }}
                            label={`Scegli un orario : ${hoursLabel(dayNumberToHourInfo())}`}
                            error={messageError}
                            onChange={(event) => HandleTimeChange(event.target.value)}
                        />

                    ) : (
                        <Select
                            className="mt-4 mx-4"
                            classNames={{ input: "my-4", label: "text-white font-poppins-regular text-base" }}
                            label="Seleziona una fascia oraria"
                            placeholder="Scegli un orario"
                            data={
                                timeSlotsList
                                    .filter((slot) => {
                                        const [slotHours, slotMinutes] = slot.band.split(":").map(Number);

                                        if (!value) return false; // Nessuna data selezionata => nessuna fascia


                                        const now = new Date();
                                        const isToday =
                                            now.getFullYear() === value.getFullYear() &&
                                            now.getMonth() === value.getMonth() &&
                                            now.getDate() === value.getDate();

                                        if (isToday) {

                                            const currentHour = now.getHours();
                                            const currentMinute = now.getMinutes();


                                            return (
                                                slotHours > currentHour ||
                                                (slotHours === currentHour && slotMinutes > currentMinute)
                                            );
                                        } else {

                                            return true;
                                        }
                                    })
                                    .map((slot) => ({
                                        value: slot.band,
                                        label: slot.band,
                                    }))
                            }
                            error={messageError}
                            value={ore}
                            onChange={(value) => {
                                if (value) {
                                    HandleTimeChange(value);
                                }
                            }}
                        />
                    )}


                    <CustomButton customStyles={'mt-auto mx-auto w-[300px]'} title={'Conferma'} onClick={() => {
                        nextStep()
                        console.log('pausa')
                    }} enable={buttonHourEnabled} isLoading={false}/>

                </Stepper.Step>

                <Stepper.Step label={persons === 'undefined' ? 'Persone' : persons.toString()}
                              completedIcon={<img src={person} alt={''}/>} icon={<img src={person} alt={''}/>}>
                    {props.isAdmin ?
                        <>
                            <CustomTextInput defaultValue={persons!=='undefined'?persons:''} customDimensions={'h-[47px] mx-3 mt-4'}
                                             placeholder={'Inserisci il numero di clienti'} onChange={(persons) => {
                                                 if (!isNaN(parseInt(persons))){
                                                     setPersons(persons)
                                                 }else {
                                                     if (persons===''){
                                                         setPersons('undefined')
                                                     }
                                                 }
                            }}></CustomTextInput>

                            <CustomButton customStyles={'mt-auto mx-auto w-[300px]'} title={'Conferma'} onClick={() => {
                                nextStep()
                            }} enable={buttonHourEnabled} isLoading={false}/>
                        </>
                        :
                        <>
                            <ButtonGroup label={'Seleziona numero di commensali'}
                                         content={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
                                         onClick={(persons) => {
                                             HandlePersons(persons)
                                         }} selected={persons}/>

                            <h1 className={'text-xs text-white text-center font-poppins-semi-bold mt-5 px-2'}>N.B. Per Prenotazioni di tavoli con
                                più di 10 commensali è necessaria la prenotazione telefonica</h1>

                        </>

                    }

                </Stepper.Step>
                <Stepper.Step className={`!rounded-l-[6px] mx-auto`}
                              label={''} completedIcon={<img src={check} alt={''}/>} icon={<img src={check} alt={''}/>}>
                    <div className={'mt-4 h-full'}>

                        <div className={'recap-content'}>
                            <div className={'text-center'}>
                                <h1 className={'text-xs my-4 px-5 font-poppins-regular text-white'}> La tua prenotazione
                                    per
                                    il {value!.getDate() + '/' + ("0" + (value!.getMonth() + 1)).slice(-2) + '/' + value!.getFullYear()} alle
                                    ore {ore} per {persons} persone</h1>
                            </div>
                            <div className={'mt-2 w-full px-4 flex flex-col'}>
                                {!userData && !props.isAdmin ? <div className={'w-full flex flex-col gap-2'}>

                                    <CustomTextInput customDimensions={'w-full h-[47px]'}
                                                     defaultValue={user.phoneNumber}
                                                     onChange={(phoneNumber) => {
                                                         setUser({...user, phoneNumber: phoneNumber})
                                                         HandlePrenota()
                                                     }} placeholder={'Numero di telefono'}/>

                                    <div className={'flex gap-2 flex-row'}>
                                        <CustomTextInput customDimensions={'h-[47px] w-full'} defaultValue={user.name}
                                                         placeholder={'Nome'}
                                                         onChange={(name) => {
                                                             setUser({...user, name: name})
                                                             HandlePrenota()
                                                         }}/>
                                        <CustomTextInput customDimensions={'w-full h-[47px]'}
                                                         defaultValue={user.surname}
                                                         onChange={(surname) => {
                                                             setUser({...user, surname: surname})
                                                             HandlePrenota()
                                                         }} placeholder={'Cognome'}/>
                                    </div>


                                    <div className={'w-full flex h-[47px]'}>
                                        <CustomTextInput customDimensions={'w-full h-[50px]'} defaultValue={user.email}
                                                         onChange={(email) => {
                                                             setUser({...user, email: email})
                                                             HandlePrenota()
                                                         }} placeholder={'Email'}/>
                                    </div>
                                </div> : props.isAdmin ? <div className={'w-full flex flex-col gap-2'}>

                                    <div className={'w-full h-[50px] flex mb-10 '}>
                                        <Select
                                            searchable={true}
                                            label={'Inserisci numero di telefono del cliente'}
                                            variant={'unstyled'}
                                            defaultValue={user.phoneNumber}
                                            data={usersList}
                                            classNames={{
                                                root: 'w-full mt-2 ml-1',
                                                wrapper: 'w-full h-full',
                                                input: 'w-full h-full px-5 bg-[#F2F2F2A8] rounded-lg',
                                                label: 'text-white font-poppins-regular',
                                            }}
                                            onInput={(v)=>{
                                                setUser({...user,phoneNumber:v.currentTarget.value})
                                            }}
                                            onChange={(value) => {
                                                if (value) {
                                                    var resValue=JSON.parse(value) as GetUserReservationAdmin
                                                    setUser({...user,name:resValue.name,surname:resValue.surname,phoneNumber:resValue.phone,email:resValue.email})
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={'flex gap-2 flex-row'}>
                                        <CustomTextInput customDimensions={'h-[47px] w-full'} defaultValue={user.name}
                                                         placeholder={'Nome'}
                                                         onChange={(name) => {
                                                             setUser({...user, name: name})
                                                             HandlePrenota()
                                                         }}/>
                                        <CustomTextInput customDimensions={'w-full h-[47px]'}
                                                         defaultValue={user.surname}
                                                         onChange={(surname) => {
                                                             setUser({...user, surname: surname})
                                                             HandlePrenota()
                                                         }} placeholder={'Cognome'}/>
                                    </div>


                                    <div className={'w-full flex h-[47px]'}>
                                        <CustomTextInput customDimensions={'w-full h-[50px]'} defaultValue={user.email}
                                                         onChange={(email) => {
                                                             setUser({...user, email: email})
                                                             HandlePrenota()
                                                         }} placeholder={'Email'}/>
                                    </div>
                                </div> : null
                                }
                                <CustomTextInput customDimensions={'w-full h-[50px] mt-3'} defaultValue={user.note}
                                                 onChange={(note) => {
                                                     setUser({...user, note: note})
                                                     HandlePrenota()
                                                 }} placeholder={'Insersici note per il ristorante'}/>

                            </div>
                        </div>

                    </div>
                    <CustomButton customStyles={'mx-auto mt-auto w-[300px]'} title={'Prenota'} onClick={() => {
                        setLoadingButton(true)
                        var newDate = value
                        newDate?.setHours(parseInt(ore.split(':')[0]) + 1, parseInt(ore.split(':')[1]))
                        var sendReservation: ReservationSendType = {
                            numberOfPeople: parseInt(persons),
                            reservationDateTime: newDate!.toISOString(),
                            reservationEmail: user.email,
                            reservationInfo: user.note,
                            reservationPhone: user.phoneNumber,
                            userName: user.name,
                            userSurname: user.surname,
                            restaurantId: restaurantData.id,
                            admin: props.isAdmin
                        }

                        makeRequest('/restaurants/reservations', HttpMethodsEnum.POST, sendReservation).then((res) => {
                            setLoadingButton(false)
                            console.log('calogero',sendReservation)
                            if (res.status === 201) {
                                nextStep()
                            }
                        })
                    }} enable={userData !== null || buttonPrenottaEnabled} isLoading={loadingButton}/>

                </Stepper.Step>
                <Stepper.Completed>
                    <div className={'mt-8 flex max-w-full flex-row text-white items-center h-full justify-center'}>
                        <div className={'flex justify-center items-center flex-col content-center'}>
                            <img className={'max-w-[50px] mb-2'} src={check_prenotazione} alt={''}></img>
                            <div className={'text-container'}>
                                <h1 className={' font-poppins-bold text-center'}>Richiesta di prenotazione
                                    inviata con
                                    successo!</h1>
                                {userData ?
                                    <h1 className={'text-xs text-center ps-12 pe-8 mt-4'}>Controlla nella tua sezione prenotazioni
                                        per visualizzare lo stato</h1> :
                                    <h1 className={'text-xs ps-12 pe-8 mt-4'}>Riceverai una email nel quando la
                                        prenotazione sarà accettata</h1>}
                                {userData ? <>
                                        <h1 className={'text-xs mt-14 text-center'}> {userData.name} {userData.surname}  </h1>
                                        <h1 className={'text-xs text-center'}>{userData.phone}</h1>
                                        <h1 className={'text-xs text-center'}> {userData.email} </h1>
                                        <h1 className={'text-xs mt-6 text-center'}>{user.note !== '' && user.note}</h1>
                                    </> :
                                    <>
                                        <h1 className={'text-xs mt-14 text-center'}> {user.name} {user.surname}  </h1>
                                        <h1 className={'text-xs text-center'}>{user.phoneNumber}</h1>
                                        <h1 className={'text-xs text-center'}> {user.email} </h1>
                                        <h1 className={'text-xs mt-6 text-center'}>{user.note !== '' && user.note}</h1>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <CustomButton customStyles={`mx-auto w-[300px]`} title={'Ritorna alla Home'} onClick={() => {
                        window.location.reload()
                    }} enable={true} isLoading={false}/>

                </Stepper.Completed>
            </Stepper>
        </>
    );
}
