import {Table} from "@mantine/core";
import GenericImg from "../../../components/generic/GenericImg";
import trashIcon from '../../../assets/delete-icon.svg'
import {showFailNotification, showSuccessNotification, showWarningNotification} from "../../../utils/NotificationUtils";
import {IngredientType} from "../../../types/IngredientType";
import {deleteIngredient} from "../../../network/controllers/admin/IngredientsController";
import {isMobile} from "react-device-detect";


export default function IngredientsAdminTable(props: {
    ingredientsList: IngredientType[],
    onClickItem: (ingredient: IngredientType) => void
}) {


    const rows = props.ingredientsList.length > 0 ? props.ingredientsList.map((element, index) => (
        <Table.Tr className={'w-auto cursor-pointer '} key={Math.random()}>
            <Table.Td onClick={() => {
                props.onClickItem(element)
            }}>
                <div className={`w-auto flex justify-start items-center ${isMobile ?'text-xs':''}`}>
                    <h1>{element.name}</h1>
                </div>
            </Table.Td>

            <Table.Td onClick={() => {
                props.onClickItem(element)
            }}>
                <div className={`w-auto flex justify-start items-center ${isMobile ?'text-sm':''} `}>
                    <h1>{element.price}</h1>
                </div>
            </Table.Td>
            <Table.Td onClick={() => {
                props.onClickItem(element)
            }}>
                <div className={`w-auto flex justify-start items-center `}>
                    <h1>{element.quantity}</h1>
                </div>
            </Table.Td>
            <Table.Td onClick={() => {
                props.onClickItem(element)
            }}>
                <div className={'w-auto flex justify-start items-center '}>
                    <h1>{element.quantityPerServing}</h1>
                </div>
            </Table.Td>
            <Table.Td>
                <div className={'w-auto flex items-end justify-end '}>
                    <GenericImg src={trashIcon} className={'w-5 h-5 flex'} onCLick={() => {
                        showWarningNotification(`Sicuro di voler eliminare  ${element.name}?`, () => {
                            deleteIngredient(element.id!, (response) => {
                                if (response.status === 200) {
                                    showSuccessNotification('Ingrediente', 'Eliminato con successo', 2000)
                                    window.location.reload()
                                } else {
                                    showFailNotification('Errore', 'Riprova più tardi', 2000)
                                }

                            })
                        })
                    }}/>
                </div>

            </Table.Td>
        </Table.Tr>

    )) : null;

    if (isMobile) {
    return (
        <div className={`w-auto h-auto`}>
            <Table.ScrollContainer minWidth={'fit-content'}>
                <Table striped highlightOnHover withTableBorder withRowBorders={true}>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th style={{ fontSize: '8px' }}>NOME</Table.Th>
                            <Table.Th style={{ fontSize: '8px' }}>PREZZO</Table.Th>
                            <Table.Th style={{ fontSize: '8px' }}>QUANTITA</Table.Th>
                            <Table.Th style={{ fontSize: '8px'  }}>QUANTITA PER PRODOTTO</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody key={Math.random()}>{rows}</Table.Tbody>
                </Table>
            </Table.ScrollContainer>
        </div>
    )

}else{
        return (
            <div className={' mt-10'}>
                <Table.ScrollContainer minWidth={'auto'}>
                    <Table striped highlightOnHover withTableBorder withRowBorders={true}>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>NOME
                                </Table.Th>
                                <Table.Th>PREZZO</Table.Th>
                                <Table.Th>QUANTITA'</Table.Th>
                                <Table.Th>QUANTITA' PER PRODOTTO</Table.Th>

                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody key={Math.random()}>{rows}</Table.Tbody>
                    </Table>
                </Table.ScrollContainer>
            </div>
        )

    }}
