import {Accordion,Drawer, Popover, Switch} from "@mantine/core";
import CustomButton from "../components/CustomButton";
import {DatePickerProps, TimeInput} from "@mantine/dates";
import React, {useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import MenuDrawerClient from "./deliveryDrawer/MenuDrawerClient";
import {GetProductResponseType} from "../types/GetProductResponseType";
import {addCommand} from "../network/controllers/CommandController";
import {Dish, Ingredient, PostCommand} from "../types/PostCommand";
import {LoginResponseType} from "../types/LoginResponseType";
import deleteIcon from '../assets/delete-icon.svg'
import addIcon from '../assets/add-icon.svg'
import GenericTooltip from "../components/generic/GenericTooltip";
import {GetRestaurantResponseType} from "../types/GetRestaurantResponseType";
import Calendar from "../components/reservation/Calendar";
import CustomTextInput from "../components/generic/CustomTextInput";
import GoogleAddressSearch from "../components/GoogleAddressSearch";
import {RestaurantStatusEnum} from "../enums/RestaurantStatusEnum";
import check_prenotazione from "../assets/reservation/stepper/check-prenotazione.svg";
import {useNavigate} from "react-router-dom";
import {showSuccessNotification} from "../utils/NotificationUtils";
import {getSystemLanguage} from "../utils/Utility";


export default function DeliveryClientPageMobile(props: { restaurantStatus: RestaurantStatusEnum }) {
    const [opened, {open, close}] = useDisclosure(false);
    const [messageError, setMessageError] = useState<string | undefined>(undefined)
    const [isDelivery, setIsDelivery] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const [reservationSuccess, setReservationSuccess] = useState<boolean>(false);
    const [listProduct, setListProduct] = useState<Map<string, {
        product: GetProductResponseType,
        quantity: number,
        newPrice: number,
        formattedData: string,
        note: string | null
    }>>();
    var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType
    const [user, setUser] = useState({
        name: userData ? userData.name : '',
        surname: userData ? userData.surname : '',
        phoneNumber: userData ? userData.phone : '',
        email: userData ? userData.email : '',
        userId: userData ? userData.id : '',
        address: null
    })
    const [dateTimeData, setDateTimeData] = useState<Date | null>(new Date());
    var navigate = useNavigate();
    const [finalPrice, setFinalPrice] = useState<number>(0)
    const [formattedIng, setFormattedIng] = useState<string[]>([])
    useEffect(() => {
        if (listProduct && Array.from((listProduct)).length > 0) {
            var count = 0
            Array.from((listProduct)).forEach((item) => {
                count += (item[1].product.priceOriginal + item[1].newPrice) * item[1].quantity
            })
            setFinalPrice(count)
        } else {
            setFinalPrice(0)
        }

    }, [listProduct]);


    const restaurantData = JSON.parse(localStorage.getItem('selectedRestaurantClient') as string) as GetRestaurantResponseType

    let closedDays: number[] = []
    let openedDays: string[] = [];
    calculateOpenedDays()

    function calculateOpenedDays() {
        for (let day of Object.values(restaurantData.hours)) {
            if (day.split(':').at(1)!.toLowerCase().trim() === 'chiuso') {
                closedDays.push(italianWeekdayToNumber(day.split(':').at()!));
            } else {
                openedDays.push(day)
            }
        }
    }

    const getDayProps: DatePickerProps['getDayProps'] = (date) => {
        if (openedDays.length < 7) {
            for (let closedDay of closedDays) {
                if (date.getDay() === closedDay) {
                    return {disabled: true};
                }
            }
        }
        if (new Date(Date.now()).setHours(0, 0, 0, 0) > new Date(date).setHours(0, 0, 0, 0)) {
            return {disabled: true};
        }
        return {};
    };

    function italianWeekdayToNumber(weekday: string) {
        switch (weekday.toLowerCase()) {
            case 'lunedì':
                return 1;
            case 'martedì':
                return 2;
            case 'mercoledì':
                return 3;
            case 'giovedì':
                return 4;
            case 'venerdì':
                return 5;
            case 'sabato':
                return 6;
            case 'domenica':
                return 0;
            default:
                return -1;
        }
    }

    function HandleTimeChange(eventTarget: string) {
        // Controlliamo prima se il locale è aperto per il giorno selezionato
        const dayHours = dayNumberToHourInfo();
        if (dayHours.split(':').at(1)?.toLowerCase().trim() === 'chiuso') {
            setMessageError('Il locale è chiuso in questo giorno');
            return;
        }

        var actualTime = new Date()
        const hours = String(actualTime.getHours()).padStart(2, '0');
        const minutes = String(actualTime.getMinutes()).padStart(2, '0');
        var splittedDate = eventTarget.split(':')

        if (dateTimeData && dateTimeData.getTime() > actualTime.getTime()) {
            if (CheckHour(eventTarget)) {
                setMessageError('')
            } else {
                setMessageError(`Seleziona un orario compreso tra quelli elencati sopra`)
            }
        } else {
            if (parseInt(hours) < parseInt(splittedDate[0])) {
                if (CheckHour(eventTarget)) {
                    setMessageError('')
                } else {
                    setMessageError(`Seleziona un orario compreso tra quelli elencati sopra`)
                }
            } else if (parseInt(hours) === parseInt(splittedDate[0])) {
                if (parseInt(minutes) < parseInt(splittedDate[1])) {
                    if (CheckHour(eventTarget)) {
                        setMessageError('')
                    } else {
                        setMessageError(`Seleziona un orario compreso tra quelli elencati sopra`)
                    }
                } else {
                    setMessageError(`Non è possibile effettuare un ordine nel passato!`)
                }
            } else {
                setMessageError(`Non è possibile effettuare un ordine nel passato!`)
            }
        }
    }
    function hoursLabel(day: string) {
        let hours = day.slice(day.indexOf(':') + 1)
        if (hours.toLowerCase().trim() === 'chiuso') {
            return 'Chiuso';
        }
        let ranges = hours.split(',')
        const formatedRanges = ranges.map(range => {
            const [startRange, endRange] = range.split('–');
            if (endRange) {
                return `${startRange}-${endRange}`;
            } else {
                return `${startRange}`;
            }
        })
        return formatedRanges.join(' / ');
    }

    function dayNumberToHourInfo() {
        if ((dateTimeData !== null && dateTimeData.getDay() - 1) >= 0) {
            // @ts-ignore
            return restaurantData.hours[(dateTimeData.getDay() - 1).toString()]
        } else {
            // @ts-ignore
            return restaurantData.hours["6"]
        }
    }

    function combineDateTimeCustom(localDateString: string, timeString: string) {
        let localDate = new Date(localDateString);
        let year = localDate.getFullYear();
        let month = localDate.getMonth();
        let day = localDate.getDate();
        let hours = timeString.split(':')[0];
        let minutes = timeString.split(':')[1]
        // @ts-ignore
        let combinedDate = new Date(year, month, day, hours, minutes, 11, 11);
        let isoString = combinedDate.toISOString().slice(0, -2);
        isoString += '7Z';
        let result = isoString.replace(/:(\d{3})Z$/, '.$1Z')
        return result;
    }

    function CheckHour(ore: string) {
        let hours = hoursLabel(dayNumberToHourInfo()).split('/')
        let validation = false
        hours.forEach(range => {
            let inizioData = new Date(combineDateTimeCustom(dateTimeData!.toISOString(), range.split('-').at(0)!))
            let inizioDataNumber = new Date(combineDateTimeCustom(inizioData.toISOString(), range.split('-').at(0)!)).getTime()
            let endData = new Date(combineDateTimeCustom(dateTimeData!.toISOString(), range.split('-').at(1)!))
            let selectedData = new Date(combineDateTimeCustom(dateTimeData!.toISOString(), ore))
            if (selectedData.getHours() <= 5) {
                selectedData.setDate(selectedData.getDate() + 1)
            }
            if (endData.getHours() <= 5) {
                endData.setDate(endData.getDate() + 1)
            }
            let selectedDataNumber = new Date(combineDateTimeCustom(selectedData!.toISOString(), ore)).getTime()
            let endDataNumber = new Date(combineDateTimeCustom(endData.toISOString(), range.split('-').at(1)!)).getTime()

            if (selectedDataNumber >= inizioDataNumber && selectedDataNumber <= endDataNumber) {
                validation = true
            }
        })
        return validation;
    }

    function checkEnableButton(): boolean {
        // Controlla se il locale è chiuso
        const dayHours = dayNumberToHourInfo();
        if (dayHours.split(':').at(1)?.toLowerCase().trim() === 'chiuso') {
            return false;
        }

        if (isDelivery) {
            if (user.address) {
                if (userData) {
                    // @ts-ignore
                    return finalPrice !== 0 && messageError === '' && user.address!.address !== '' && user.address!.civicNumber !== '' && user.address!.city !== ''
                } else {
                    // @ts-ignore
                    return finalPrice !== 0 && messageError === '' && user.address!.address !== '' && user.address!.civicNumber !== '' && user.address!.city !== '' && user.name !== '' && user.surname !== '' && user.email !== '' && user.phoneNumber.length === 10
                }
            } else {
                return false
            }
        } else {
            if (userData) {
                return finalPrice !== 0 && messageError === ''
            } else {
                return finalPrice !== 0 && messageError === '' && user.name !== '' && user.surname !== '' && user.email !== '' && user.phoneNumber.length === 10
            }
        }
    }

    if (!reservationSuccess) {
        // @ts-ignore
        return (
            <div className={'w-full h-full flex flex-col'}>
                <Drawer
                    position={"bottom"}
                    size={"100%"}
                    styles={{
                        content: {backgroundColor: 'black'},
                        header: {backgroundColor: 'black'}
                    }}

                    opened={opened}
                    onClose={() => {
                        close()
                    }}>
                    <MenuDrawerClient onProductAdd={(product, quantity, ingredientList, note) => {
                        var newRecipt = ''
                        var addPrice = 0
                        var originalIng = product.ingredients
                        originalIng.forEach((ingoriginal) => {
                            if (!ingredientList.some(item => item.id === ingoriginal.id)) {
                                setFormattedIng([...formattedIng, `-${ingoriginal.name},`])
                                newRecipt = newRecipt.concat(`-${ingoriginal.name},`);

                            }
                        });
                        ingredientList.forEach((newing) => {
                            if (!originalIng.some(ing => ing.id === newing.id)) {
                                setFormattedIng([...formattedIng, `+${newing.name},`])
                                newRecipt = newRecipt.concat(`+${newing.name},`);
                                addPrice += newing.price
                            }
                        })
                        setListProduct(prevListProduct => {
                            const updatedListProduct = new Map(prevListProduct);
                            if (updatedListProduct.has(product.id + newRecipt + ",note:"+note)) {
                                const existingProduct = updatedListProduct.get(product.id + newRecipt + ",note:" + note);
                                updatedListProduct.set(product.id + newRecipt, {
                                    product: product,
                                    quantity: existingProduct!.quantity + quantity,
                                    newPrice: addPrice,
                                    formattedData: newRecipt,
                                    note: existingProduct!.note
                                });
                            } else {
                                updatedListProduct.set(product.id + newRecipt + ",note:"+note, {
                                    product: product,
                                    quantity: quantity,
                                    newPrice: addPrice,
                                    formattedData: newRecipt,
                                    note: note
                                });
                            }
                            return updatedListProduct;
                        });
                        showSuccessNotification('Prodotto', `${product.nameProduct} aggiunto all'ordine`, 2000)

                    }}/>
                </Drawer>


                <p className={'w-full text-center text-black font-poppins-semi-bold text-3xl'}>Il mio ordine</p>
                <div className={'w-full h-auto flex items-center justify-center mt-5 px-3 '}>
                    <Popover
                        position={"bottom"}
                        withArrow>
                        <Popover.Target>
                            <div
                                className={`hover:scale-110 duration-700 pointer-events-auto cursor-pointer bg-yellow rounded-xl w-[150px] h-[3rem] flex flex-col justify-center items-center px-1 mt-12`}
                                onClick={() => {
                                }}>
                                <p className={`font-poppins-bold text-[14px] w-full text-center`}>{dateTimeData ? dateTimeData.toLocaleDateString() : new Date().toLocaleDateString()}</p>
                            </div>
                        </Popover.Target>
                        <Popover.Dropdown className={'bg-black'}>
                            <Calendar onChange={(value) => setDateTimeData(value!)} value={dateTimeData}
                                      dayProps={getDayProps}/>
                        </Popover.Dropdown>
                    </Popover>

                    <TimeInput
                        className={' mt-2 mx-4 cursor-pointer'}
                        classNames={{
                            input: 'my-4 cursor-pointer',
                            label: 'text-black font-poppins-regular text-xs'
                        }}
                        label={`Scegli  un orario : ${hoursLabel(dayNumberToHourInfo())}`} error={messageError}
                        defaultValue={dateTimeData ? dateTimeData.toTimeString() : new Date().toTimeString()}
                        onChange={(event) => {
                            if (event.target.value && event.target.value.split(":").length === 2 && event.target.value.split(":")[1].length === 2) {
                                HandleTimeChange(event.target.value)
                                if (messageError === '' || messageError === undefined) {
                                    setDateTimeData(prevState => {
                                        var date = new Date(prevState!)
                                        var value = event.target.value.split(":").map(Number);
                                        date.setHours(value[0]);
                                        date.setMinutes(value[1]);
                                        date.setSeconds(0);
                                        date.setMilliseconds(0);
                                        date.setFullYear(prevState?.getFullYear() as number, prevState?.getMonth(), prevState?.getDate())
                                        return date
                                    });
                                }
                            }
                        }}/>
                    {props.restaurantStatus === RestaurantStatusEnum.DELIVERY ?
                        <div className={'w-auto flex items-center justify-center mt-12 '}>
                            <Switch checked={isDelivery} onChange={(value) => {
                                setIsDelivery(value.currentTarget.checked)
                            }} color={'yellow'} size={'xl'} classNames={{label: 'font-poppins-bold'}}
                                    onLabel={'Domicilio'}
                                    offLabel={'Asporto'}/>
                        </div> : <p className={'w-auto text-center mt-16 text-black font-poppins-bold'}>Asporto</p>}
                </div>
                <div className={'w-full h-[300px] flex flex-col overflow-y-auto p-2'}>
                    {userData && !isDelivery ? null : <Accordion defaultValue="Apples">
                        <Accordion.Item value={'pippo'}>
                            <Accordion.Control>
                                <p className={'font-poppins-regular'}>{
                                    // @ts-ignore
                                    user.address ? user.address!.formatted_address : 'Inserisci dati aggiuntivi'
                                }</p>
                            </Accordion.Control>
                            <Accordion.Panel className={'h-full overflow-y-auto'} color={'white'}>
                                {!userData ?
                                    <div className={'w-full h-min flex flex-col gap-2  p-2 rounded-xl'}>

                                        <div className={'flex gap-2 flex-row'}>

                                            <CustomTextInput customDimensions={'h-[47px] w-full'}
                                                             defaultValue={user.name}
                                                             placeholder={'Nome'}
                                                             onChange={(name) => {
                                                                 setUser({...user, name: name})
                                                             }}/>
                                            <CustomTextInput customDimensions={'w-full h-[47px]'}
                                                             defaultValue={user.surname}
                                                             onChange={(surname) => {
                                                                 setUser({...user, surname: surname})
                                                             }} placeholder={'Cognome'}/>
                                        </div>
                                        <CustomTextInput customDimensions={'w-full h-[37px]'}
                                                         defaultValue={user.phoneNumber}
                                                         onChange={(phoneNumber) => {
                                                             setUser({...user, phoneNumber: phoneNumber})
                                                         }} placeholder={'Numero di telefono'}/>

                                        <div className={'w-full flex h-[55px]'}>
                                            <CustomTextInput label={'email'} isBlack={true}
                                                             customDimensions={'w-full h-[50px]'}
                                                             defaultValue={user.email}
                                                             onChange={(email) => {
                                                                 setUser({...user, email: email})
                                                             }} placeholder={'Email'}/>
                                        </div>
                                        {isDelivery ? <div className={'w-full mt-5'}>
                                            <GoogleAddressSearch isBlack={true}
                                                                 suggestionDimensions={'h-auto w-full  rounded-2xl'}
                                                                 inputDimensions={`w-full h-[60px] `}
                                                                 setNameTextInput={'formatted_address'}
                                                                 width={'100px'}
                                                                 label={'Indirizzo'}
                                                                 onPlaceSelect={(v) => {
                                                                     setUser({...user, address: v})
                                                                 }}/>

                                        </div>  : null}
                                    </div> : isDelivery ?
                                        <div
                                            className={'w-full h-min  flex flex-col gap-2 bg-white p-2 mt-5 rounded-xl'}>
                                            <GoogleAddressSearch isBlack={true}
                                                                 suggestionDimensions={'h-auto w-full rounded-2xl'}
                                                                 inputDimensions={`w-full h-[60px]`}
                                                                 setNameTextInput={'formatted_address'}
                                                                 width={'100px'}
                                                                 label={'Indirizzo'}
                                                                 onPlaceSelect={(v) => {
                                                                     setUser({
                                                                         ...user,
                                                                         address: v
                                                                     })
                                                                 }}/>
                                        </div> : null
                                }
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>}
                    <div className={'w-full flex mt-5 items-center justify-center'}>
                        <GenericTooltip txt={'Clicca qui per creare il tuo ordine!'} innerHtml={
                            <img alt={''} src={addIcon} className={'w-7 h-7 hover:scale-125 duration-500'}
                                 onClick={() => {
                                     open()
                                 }}/>
                        }/>
                    </div>
                    {listProduct && Array.from((listProduct)).length > 0 ? Array.from((listProduct)).map((item) => (
                        <div className={'w-full flex flex-col px-5'}>
                            <div
                                className={'w-full flex whitespace-nowrap  overflow-x-auto justify-between items-center   mt-5'}>
                                <div className={'flex flex-col'}>
                                <p className={'w-full whitespace-nowrap  font-poppins-regular '}>{`${item[1].quantity}X  ${item[1].product.nameProduct}`}</p>
                                <p className={'w-full whitespace-nowrap text-xs  font-poppins-regular ml-5'}>{`${item[1].product.productNameTranslations!!.get(getSystemLanguage(Array.from(item[1].product.productNameTranslations!!.keys())))}`}</p>
                                </div>
                                <div className={'w-full flex gap-x-2'}>
                                    <p className={'w-full whitespace-nowrap flex justify-end font-poppins-regular'}>{`€  ${item[1].product.priceOriginal + item[1].newPrice}`}</p>
                                    <img alt={''} className={'w-5 h-5'} src={deleteIcon} onClick={() => {
                                        setListProduct(prevListProduct => {
                                            const updatedListProduct = new Map(prevListProduct);
                                            updatedListProduct.delete(item[0]);
                                            return updatedListProduct;
                                        })
                                    }}/>
                                </div>

                            </div>
                            {item[0].substring(36).split(',').map((value) => (
                                <p className={' whitespace-nowrap font-poppins-regular'}>{value.includes("note")?null:value}</p>
                            ))}
                            <p className={' whitespace-nowrap font-poppins-regular'}>{` ${item[1].note}`}</p>

                        </div>

                    )) : null}
                </div>

                <p className={'w-full text-center text-4xl font-poppins-regular mt-2  mb-10'}>{`Totale:  € ${finalPrice}`}</p>

                <div className={'w-full h-auto flex  items-center justify-center mb-10'}>
                    <CustomButton title={'Conferma'} small={true} onClick={() => {
                        setLoading(true)
                        var dishes: Dish[] = []
                        var allergensId: string[] = []
                        var ingrList:Ingredient[] =[]
                        Array.from((listProduct!)).map((item) => {
                            item[1].product.allergensList.map((allergen)=>{
                                allergensId.push(allergen.id)
                            })
                            item[1].product.ingredients.map((ing)=>{
                                ingrList.push({
                                    addable: ing.addable,
                                    categories: ing.categories,
                                    id: ing.id?ing.id:"",
                                    idLocal: ing.idLocal,
                                    name: ing.name,
                                    price: ing.price,
                                    statusIngredient: ing.statusIngredient
                                })
                            })
                            dishes.push({
                                allergens: allergensId,
                                available: item[1].product.isAvailable,
                                description: item[1].product.description,
                                id: item[1].product.id,
                                idKitchen: item[1].product.idKitchen,
                                idLocal: item[1].product.idLocal,
                                ingredients: ingrList,
                                nameKitchen: item[1].product.nameKitchen,
                                nameProduct: item[1].product.nameProduct,
                                note: item[1].note?item[1].note:"",
                                price: item[1].product.priceOriginal+item[1].newPrice,
                                priceOriginal:item[1].product.priceOriginal,
                                quantity: item[1].quantity,
                                subNameProduct: item[1].product.subNameProduct
                            })

                        });
                        var date = dateTimeData ? new Date(dateTimeData) : new Date()
                        if (dateTimeData && dateTimeData?.getUTCHours() === 22) {
                            date.setDate(date.getDate() + 1)
                        }
                        date.setHours(date.getHours() + 1)

                        var request: PostCommand = {
                            // @ts-ignore
                            address: user.address ? user.address!.formatted_address : '',
                            delivery: date.toISOString(),
                            dishes: dishes,
                            email: user.email,
                            idLocal: JSON.parse(localStorage.getItem('selectedRestaurantClient')!).id,
                            idUser: userData ? userData.id : undefined,
                            name: user.name,
                            phoneNumber: user.phoneNumber,
                            surname: user.surname,
                            type: props.restaurantStatus === RestaurantStatusEnum.DELIVERY ? isDelivery ? 'DELIVERY' : "ASPORTO" : 'ASPORTO',
                        }

                        addCommand(request, (res) => {
                            setLoading(false)
                            if (res !== null) {
                                setReservationSuccess(true)
                            } else {
                                setReservationSuccess(false)
                            }
                        })
                    }} enable={checkEnableButton()} isLoading={loading}/>
                </div>
            </div>
        )
    } else {
        return (
            <div className={'w-full h-full flex flex-col pb-20 items-center justify-center'}>

                <div
                    className={'gap-y-10 flex max-w-full flex-col text-white items-center h-full justify-center p-20'}>
                    <div className={'flex justify-center items-center flex-col content-center'}>
                        <img className={'max-w-[50px] mb-2'} src={check_prenotazione} alt={''}></img>
                        <div className={'text-container'}>
                            <p className={'text-base text-center text-black font-poppins-regular'}>Ordine inviato
                                con successo!</p>
                            <p className={'text-base text-center text-black font-poppins-regular'}>Controlla la
                                sezione "I miei ordini" per rimanere aggiornato!</p>
                            <p></p>
                        </div>
                    </div>
                    <div>
                        <CustomButton title={'Vai ai miei ordini'}
                                      onClick={() => {
                                          navigate('/commandClient')
                                      }} enable={true} isLoading={false}/>
                    </div>
                </div>

            </div>
        )
    }

}
