import {Checkbox, Divider, Switch, Table} from "@mantine/core";
import GenericImg from "../../../components/generic/GenericImg";
import {StatusEnum} from "../../../enums/StatusEnum";
import placeholder from '../../../assets/placeolder-categoria.svg';
import {GetProductResponseType} from "../../../types/GetProductResponseType";
import {changeProductStatus, updateProductPosition} from "../../../network/controllers/admin/ProductController";
import React, {useEffect, useState} from "react";
import {getMenu} from "../../../network/controllers/admin/MenuController";
import {getLicenceFromLicenseId} from "../../../network/controllers/admin/AdminController";
import ReactDragListView from "react-drag-listview";
import arrowImage from '../../../assets/arrowSelection.svg'

import {CategoryType} from "../../../types/CategoryType";
import MenuProduct from "../../../components/MenuProduct";
import {isMobile} from "react-device-detect";
import {getDimension} from "../../../utils/Utility";

export default function ProductCategoryTable(props: {
    category: CategoryType
    productList: GetProductResponseType[],
    onClickItem: (product: GetProductResponseType) => void,
    onReload: () => void
}) {
    const [selectedRows, setSelectedRows] = useState<Map<string, GetProductResponseType>>(new Map());
    const [menuList, setMenuList] = useState<any[]>([]);
    const [isKitchenEnable, setIsKitchenEnable] = useState<boolean>(false);

    const [dimension, setDimension] = useState(window.innerWidth < 1280)
    useEffect(() => {
        getDimension(1280, (res)=>{
            console.log(res);
            setDimension(res)
        })
    },[])

    // Initialize items with sorted products based on position
    const [items, setItems] = useState(() => {
        const sortedItems = [...props.productList].sort((a, b) => {
            const menuCategoryKey = `${JSON.parse(localStorage.getItem('selectedMenu') as string).id}--${props.category.id}`;
            const posA = a.position[menuCategoryKey] || 0;
            const posB = b.position[menuCategoryKey] || 0;
            return posA - posB;
        });
        return sortedItems;
    });

    useEffect(() => {
        getMenu((menu) => {
            setMenuList(menu)
        });

        getLicenceFromLicenseId((license) => {
            if (license.namePackage !== 'BASE') {
                setIsKitchenEnable(true);
            }
        });
    }, []);



    function retrieveStatus(status: string): string {
        if (status === StatusEnum.ACCEPTED) return 'border-lime-400';
        if (status === StatusEnum.REJECTED) return 'border-red-600';
        if (status === StatusEnum.PENDING) return 'border-yellow';
        return '';
    }

    function addElement(element: GetProductResponseType, isChecked: boolean) {
        const tmpMap = new Map<string, GetProductResponseType>(selectedRows);
        const tmpElement = {...element};

        if (isChecked) {
            tmpElement.menusList!.push(localStorage.getItem('menuId') as string);
        } else {
            tmpElement.menusList = tmpElement.menusList!.filter(
                (value) => value !== localStorage.getItem('menuId') as string
            );
        }

        if (selectedRows.get(element.id) === undefined) {
            tmpMap.set(element.id, tmpElement);
        } else {
            tmpMap.delete(element.id);
        }
        setSelectedRows(tmpMap);
    }

    const updatePositions = (updatedItems: GetProductResponseType[]) => {
        const menuCategoryKey = `${JSON.parse(localStorage.getItem('selectedMenu') as string).id}--${props.category.id}`;

        const itemsWithUpdatedPositions = updatedItems.map((item, index) => ({
            ...item,
            position: {
                ...item.position,
                [menuCategoryKey]: index + 1
            }
        }));

        setItems(itemsWithUpdatedPositions);
        updateProductPosition(itemsWithUpdatedPositions, (response) => {
            if (response) {
                props.onReload();
            }
        });
    };

    const onDragEnd = (fromIndex: number, toIndex: number) => {
        const updatedItems = [...items];
        const [movedItem] = updatedItems.splice(fromIndex, 1);
        updatedItems.splice(toIndex, 0, movedItem);
        updatePositions(updatedItems);
    };

    const rows = items.length > 0 ? items.map((element, index) => (
        <Table.Tr className={`w-full cursor-pointer drag-handle`} key={element.id}>
            <Table.Td className={'w-10'}>
                <img alt={''} src={arrowImage}/>
            </Table.Td>
            <Table.Td onClick={() => {}}>
                <div className={'w-full flex justify-start p-2'}>
                    <img alt={''} src={element.photoPath}
                         className={`w-[100px] h-[100px] rounded-lg border-[3.5px] ${retrieveStatus(element.approvalStatus)} p-0.5`}/>
                </div>
            </Table.Td>
            <Table.Td><h1>{element.nameProduct}</h1></Table.Td>
            {isKitchenEnable && <Table.Td><h1>{element.nameKitchen}</h1></Table.Td>}
            <Table.Td>
                {element.ingredients.map((ingredient, i) => (
                    <h1 key={i}>{ingredient.name}</h1>
                ))}
            </Table.Td>
            <Table.Td>
                <h1>{element.priceOriginal + ' €  ' + element.priceType}</h1>
            </Table.Td>
            <Table.Td>
                <Switch
                    color={'yellow'}
                    defaultChecked={element.isAvailable}
                    onChange={(v) => {
                        changeProductStatus(element.id, v.currentTarget.checked, () => {});
                    }}
                />
            </Table.Td>
            <Table.Td>
                <MenuProduct
                    onClickElement={(product) => props.onClickItem(product)}
                    listSize={props.productList.length}
                    product={element}
                    category={props.category}
                    onListChange={props.onReload}
                />
            </Table.Td>
        </Table.Tr>
    )) : null;

    if (isMobile || dimension) {
        return (
            <div className="w-full h-auto flex-row">
                <ReactDragListView
                    nodeSelector=".mobile-row"
                    handleSelector=".mobile-drag-handle"
                    onDragEnd={onDragEnd}
                >
                    <Table.ScrollContainer minWidth="auto">
                        <Table striped highlightOnHover withTableBorder withRowBorders={false}>
                            <Table.Tbody>
                                {items.map((element, index) => (
                                    <Table.Tr key={element.id} className="mobile-row w-full h-auto flex flex-col p-5">
                                        <div className="mobile-drag-handle w-full flex items-center justify-center cursor-move mb-3">
                                            <img alt="" src={arrowImage} className="w-6 h-6"/>
                                        </div>
                                        <MenuProduct
                                            onClickElement={props.onClickItem}
                                            listSize={props.productList.length}
                                            product={element}
                                            category={props.category}
                                            onListChange={props.onReload}
                                        />
                                        <Table.Td className={'w-full h-auto flex justify-center items-center pb-3'} style={{fontSize: '10px'}}>
                                            <img
                                                alt={''}
                                                src={element.photoPath}
                                                className={`w-[80px] h-[80px] rounded-lg border-[3.5px] ${retrieveStatus(element.approvalStatus)} p-0.5`}
                                            />
                                        </Table.Td>
                                        <Table.Th style={{fontSize: '11px'}}><strong>NOME PRODOTTO</strong></Table.Th>
                                        <Table.Td style={{fontSize: '12px'}}>{element.nameProduct}</Table.Td>
                                        {isKitchenEnable && <Table.Td style={{fontSize: '11px'}}>{element.nameKitchen}</Table.Td>}
                                        <Table.Th style={{fontSize: '10px'}}>INGREDIENTI</Table.Th>
                                        <Table.Td style={{fontSize: '12px'}}>
                                            {element.ingredients.map((ingredient, i) => (
                                                <h1 key={i}>{ingredient.name}</h1>
                                            ))}
                                        </Table.Td>
                                        <Table.Th style={{fontSize: '11px'}}>PREZZO</Table.Th>
                                        <Table.Td style={{fontSize: '12px'}}>{element.priceOriginal + ' €  ' + element.priceType}</Table.Td>
                                        <Table.Th style={{fontSize: '12px'}}>ATTIVO</Table.Th>
                                        <Table.Td style={{fontSize: '11px'}}>
                                            <Switch
                                                color={'yellow'}
                                                defaultChecked={element.isAvailable}
                                                onChange={(v) => {
                                                    changeProductStatus(element.id, v.currentTarget.checked, () => {});
                                                }}
                                            />
                                        </Table.Td>
                                        <Divider className={'mx-5 my-5'} size={'xs'} color={'#FFCC00'}/>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                    </Table.ScrollContainer>
                </ReactDragListView>
            </div>
        );
    } else {
        return (
            <div className={`mt-2 min-w-[800px]`}>
                <ReactDragListView
                    nodeSelector="tr"
                    handleSelector=".drag-handle"
                    onDragEnd={onDragEnd}
                >
                    <Table.ScrollContainer minWidth={'auto'}>
                        <Table striped highlightOnHover withTableBorder withRowBorders={false}>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th></Table.Th>
                                    <Table.Th>IMMAGINE</Table.Th>
                                    <Table.Th>NOME PRODOTTO</Table.Th>
                                    {isKitchenEnable && <Table.Th>CUCINA</Table.Th>}
                                    <Table.Th>INGREDIENTI</Table.Th>
                                    <Table.Th>PREZZO</Table.Th>
                                    <Table.Th>ATTIVO</Table.Th>
                                    <Table.Th></Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>{rows}</Table.Tbody>
                        </Table>
                    </Table.ScrollContainer>
                </ReactDragListView>
            </div>
        );
    }
}
