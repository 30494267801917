import React from 'react';
import { Table } from "@mantine/core";
import { isMobile } from "react-device-detect";
import {OrderType} from "../../../types/OrderType";


type OrderTableProps = {
    ordersList: OrderType[];
    onClickItem: (order: OrderType) => void;
}

export default function OrderTable({ ordersList, onClickItem }: OrderTableProps) {
    const getStatusColor = (status: string) => {
        switch (status) {
            case "INLAVORAZIONE":
                return <div className={'w-5 h-5 bg-amber-400 rounded-full ml-2 border-[1px] border-white'}></div>;
            case "COMPLETATO":
                return <div className={'w-5 h-5 bg-blue-800 rounded-full ml-2 border-[1px] border-white'}></div>;
            case "SPEDITO":
                return <div className={'w-5 h-5 bg-lime-400 rounded-full ml-2 border-[1px] border-white'}></div>;
            default:
                return <div className={'w-5 h-5 bg-gray-500 rounded-full ml-2 border-[1px] border-white'}></div>;
        }
    }

    const getAddress = (order: OrderType) => {
        if (order.spedizione.indirizzo) {
            return (
                <div className="flex flex-col">
                    <p className="text-xs text-gray-500">Spedizione</p>
                    <p>{order.spedizione.indirizzo}</p>
                </div>
            );
        }

        return (
            <div className="flex flex-col">
                <p className="text-xs text-gray-500">Fatturazione</p>
                <p>{order.fatturazione.indirizzo}</p>
            </div>
        );
    }

    const rows = ordersList?.length > 0 ? ordersList.map((element) => (
        <Table.Tr className={'w-full cursor-pointer'} key={element.id}>
            <Table.Td onClick={() => onClickItem(element)}>
                <div className={`w-full flex justify-start items-center ${isMobile ? 'text-sm' : ''}`}>
                    <h1>{element.print.name}</h1>
                </div>
            </Table.Td>

            <Table.Td onClick={() => onClickItem(element)}>
                <div className={'w-auto flex justify-start items-center'}>
                    {getAddress(element)}
                </div>
            </Table.Td>

            <Table.Td>
                {getStatusColor(element.status)}
            </Table.Td>
        </Table.Tr>
    )) : null;

    if (isMobile) {
        return (
            <div className={`mt-10 w-auto h-auto`}>
                <Table.ScrollContainer minWidth={'auto'}>
                    <Table striped highlightOnHover withTableBorder withRowBorders={false}>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th style={{ fontSize: '10px' }}>NOME</Table.Th>
                                <Table.Th style={{ fontSize: '10px' }}>INDIRIZZO</Table.Th>
                                <Table.Th style={{ fontSize: '10px' }}>STATUS</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>{rows}</Table.Tbody>
                    </Table>
                </Table.ScrollContainer>
            </div>
        )
    }

    return (
        <div className={'mt-10'}>
            <Table.ScrollContainer minWidth={'auto'}>
                <Table striped highlightOnHover withTableBorder withRowBorders={false}>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>ORDINE</Table.Th>
                            <Table.Th>INDIRIZZO</Table.Th>
                            <Table.Th>STATUS</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Table.ScrollContainer>
        </div>
    )
}
