import { Popover, PopoverDropdown } from "@mantine/core";
import React, { useState } from "react";
import { DatePicker } from "@mantine/dates";
import { isMobile } from "react-device-detect";
import CustomButton from "./CustomButton";


export default function CustomDataPicker() {
    const [date, setDate] = useState<Date>(new Date());

    function isCurrentDate(checkDate: Date) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();
        const checkYear = checkDate.getFullYear();
        const checkMonth = checkDate.getMonth() + 1;
        const checkDay = checkDate.getDate();
        return currentYear === checkYear &&
            currentMonth === checkMonth &&
            currentDay === checkDay;
    }

    function setMidnight(date: Date): Date {
        var date = date
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date
    }

    if (isMobile) {
        return (
            <div className={'w-auto flex items-center justify-center pt-3'}>
                <div className={'w-auto h-full'}>
                    <Popover closeOnClickOutside={false}>
                        <Popover.Target>
                            <div className={'w-full flex items-center justify-center'}>
                                <CustomButton
                                    title={`Data: ${date.toLocaleString()}`}
                                    onClick={() => {}}
                                    enable={true}
                                    isLoading={false}
                                    small={true}
                                />
                            </div>
                        </Popover.Target>
                        <PopoverDropdown>
                            <div className={'bg-black'}>
                                <DatePicker
                                    className={'flex content-center'}
                                    classNames={{
                                        levelsGroup: 'flex w-full content-center',
                                        calendarHeader: 'text-white w-full flex-row content-center min-w-full mt-2',
                                        calendarHeaderLevel: 'customHeaderLevel0 text-xl hover:!bg-transparent !pointer-events-none',
                                        month: 'mt-4',
                                        monthRow: 'border-[12px] border-transparent',
                                        calendarHeaderControl: 'bg-trueGray-500 rounded-full mx-2 hover:!bg-yellow hover:!bg-opacity-50 hover:!rounded-full',
                                        day: 'text-white text-base font-poppins-bold customDatePicker0 hover:!bg-yellow hover:!bg-opacity-50 hover:!rounded-full',
                                        weekday: 'text-trueGray-200 uppercase text-xs font-poppins-bold',
                                    }}
                                    maxLevel="month"
                                    value={date}
                                    weekdayFormat='ddd'
                                    monthsListFormat="M"
                                    monthLabelFormat='MMMM'
                                    locale='it'
                                    onChange={(newDate) => {
                                        if (newDate) {
                                            const date = new Date()
                                            date.setDate(newDate.getDate())
                                            setDate(date)
                                        }
                                    }}
                                    hideOutsideDates
                                    firstDayOfWeek={0}
                                />
                            </div>
                        </PopoverDropdown>
                    </Popover>
                </div>
            </div>
        )
    } else {
        return (
            <div className={'w-auto flex items-center justify-center pt-5'}>
                <div className={'w-auto h-full'}>
                    <Popover closeOnClickOutside={false}>
                        <Popover.Target>
                            <div>
                                <CustomButton
                                    title={`Data: ${isCurrentDate(date) ? date.toLocaleString() : setMidnight(date).toLocaleString()}`}
                                    onClick={() => {}}
                                    enable={true}
                                    isLoading={false}

                                />
                            </div>
                        </Popover.Target>
                        <PopoverDropdown>
                            <div className={'bg-black'}>
                                <DatePicker
                                    className={'flex content-center'}
                                    classNames={{
                                        levelsGroup: 'flex w-full content-center',
                                        calendarHeader: 'text-white w-full flex-row content-center min-w-full mt-2',
                                        calendarHeaderLevel: 'customHeaderLevel0 text-xl hover:!bg-transparent !pointer-events-none',
                                        month: 'mt-4',
                                        monthRow: 'border-[12px] border-transparent',
                                        calendarHeaderControl: 'bg-trueGray-500 rounded-full mx-2 hover:!bg-yellow hover:!bg-opacity-50 hover:!rounded-full',
                                        day: 'text-white text-base font-poppins-bold customDatePicker0 hover:!bg-yellow hover:!bg-opacity-50 hover:!rounded-full',
                                        weekday: 'text-trueGray-200 uppercase text-xs font-poppins-bold',
                                    }}
                                    maxLevel="month"
                                    value={date}
                                    weekdayFormat='ddd'
                                    monthsListFormat="M"
                                    monthLabelFormat='MMMM'
                                    locale='it'
                                    onChange={(newDate) => {
                                        if (newDate) {
                                            const date = new Date()
                                            date.setDate(newDate.getDate())
                                            date.setMonth(newDate.getMonth())
                                            setDate(date)
                                        }
                                    }}
                                    hideOutsideDates
                                    firstDayOfWeek={0}
                                />
                            </div>
                        </PopoverDropdown>
                    </Popover>
                </div>
            </div>
        )
    }
}
