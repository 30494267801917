import ImageLogoMenus from "../../components/ImageLogoMenus";
import {useState} from "react";
import {Drawer} from "@mantine/core";
import menuicon from '../../assets/menu-icon-admin.svg';
import GenericImg from "../../components/generic/GenericImg";
import gestioneLocaleIcon from '../../assets/menu-icons/gestione-locale.svg';
import orderIcon from '../../assets/menu-icons/icona-ordini.svg';
import myAccountIcon from '../../assets/menu-icons/icona-account.svg';
import RestaurantSelect from "./RestaurantSelect";
import MenuGroupItem from "./MenuGroupItem";
import {SelectTypeFromPath} from "../../types/SelectTypeFromPath";
import SelectComponentFromPath from "../../components/generic/SelectComponentFromPath";
import {isMobile} from "react-device-detect";

export default function HeaderAdminComponent(props: { localeArray: any[], ordiniArray: any[], myAccountArray: any[] }) {
    const [statusMenu, setStatusMenu] = useState(false);

    var listSelect: SelectTypeFromPath[] = [];
    listSelect.push({path: '/admin/liste/categorie', title: 'CATEGORIE'});
    listSelect.push({path: '/admin/liste/prodotti', title: 'PRODOTTI'});
    listSelect.push({path: '/admin/liste/ingredienti', title: 'INGREDIENTI'});
    listSelect.push({path: '/admin/liste/cucine', title: 'CUCINE'});

    return (
        <div className={'w-full h-auto over '}>
            <Drawer
                size={''}
                styles={{
                    content: {backgroundColor: 'black'},
                    header: {backgroundColor: 'black'}
                }}
                zIndex={40}
                opened={statusMenu}
                onClose={() => setStatusMenu(false)}>
                <div className={`pt-[15px] pb-[15px] w-fit flex flex-col gap-y-16 ${isMobile ? 'pl-5 overflow-x-hidden ' : 'pl-16'}`}>
                    <MenuGroupItem title={{img: gestioneLocaleIcon, txt: 'gestione locale'}}
                                   subItems={props.localeArray}
                                   onMenuItemClick={() => setStatusMenu(false)}/>
                    {props.ordiniArray.length > 0 ?
                        <MenuGroupItem title={{img: orderIcon, txt: 'ordini'}}
                                       subItems={props.ordiniArray}
                                       onMenuItemClick={() => setStatusMenu(false)}/> : null}
                    <MenuGroupItem title={{img: myAccountIcon, txt: 'il mio account'}}
                                   subItems={props.myAccountArray}
                                   onMenuItemClick={() => setStatusMenu(false)}/>

                </div>
            </Drawer>
            <header className={`h-auto fixed z-40 bg-black w-full flex items-center shadow justify-center ${isMobile?'':'px-10'}`}>
                    <div className={`w-full flex ${isMobile?'':'gap-x-10 '} items-center justify-between`}>
                        <div className={`${isMobile? 'w-auto h-auto  flex  items-center gap-x-2 ':'flex items-center gap-x-3'}`}>
                            {localStorage.getItem('selectedRestaurant') ?
                                <GenericImg className={`${isMobile?'w-10 h-10 ml-4 mr-5':'w-10 h-10 cursor-pointer hover:scale-110'}`} src={menuicon} onCLick={() => {
                                    setStatusMenu(!statusMenu);
                                }}/> : null}
                            <div className={`${isMobile?'w-6 pt-2':'max-w-[300px]'} h-[100px] pb-1 `}>
                                <ImageLogoMenus/>
                            </div>
                        </div>
                        <RestaurantSelect/>
                    </div>
                </header>
                {window.location.pathname.includes('/admin/liste') ?
                    <div className={'mt-5 flex items-center justify-center '}>
                        <div className={'w-auto flex'}>
                            <SelectComponentFromPath listItem={listSelect}/>
                        </div>
                    </div> : null}

        </div>
    );
}
