import React, {useEffect, useState} from "react";
import {LocalType} from "../types/LocalType";
import {addFavourite, getDetailRestaurants} from "../network/controllers/RestaurantsControllers";
import GenericImg from "../components/generic/GenericImg";
import RatingComponent from "../components/RatingComponent";
import iconPrice from "../assets/icona_euro.svg";
import iconGroupDetail from '../assets/icon-group-detail.svg';
import iconPositionDetail from '../assets/position-icon-detail.svg';
import iconPriceDetail from '../assets/icon-price-detail.svg';
import iconInfoDetail from '../assets/info-detail-icon.svg';
import iconReservationDetail from '../assets/reservation-icon-detail.svg';
import iconDeliveryDetail from './../assets/deliveryIconDetail.svg';
import iconMenuDetail from '../assets/menu-icon-detail.svg';
import iconFavourite from '../assets/heart.svg';
import iconFavouriteFull from '../assets/heart_full.svg';
import InfoDetailPage from "./InfoDetailPage";
import ReservationDetailPage from "./ReservationDetailPage";
import MenuDetailPage from "./MenuDetailPage";
import {isMobile} from "react-device-detect";
import {isTablet} from "../utils/WindowDimensionUtils";
import {useNavigate} from "react-router-dom";
import {StatusEnum} from "../enums/StatusEnum";
import placeLocal from '../assets/placeolder-ristorante.svg';
import {showWarningNotification} from "../utils/NotificationUtils";
import {LoginResponseType} from "../types/LoginResponseType";
import DeliveryClientPage from "./DeliveryClientPage";
import {RestaurantStatusEnum} from "../enums/RestaurantStatusEnum";
import DeliveryClientPageMobile from "./DeliveryClientPageMobile";
import phoneIcon from "../assets/phoneIcon.svg"
import {getDimension} from "../utils/Utility";

var array: string[] = [];
export default function DetailRestaurantsPage() {
    const [dimension, setDimension] = useState(window.innerWidth < 1280)
    useEffect(() => {
        getDimension(1080, (res)=>{
            console.log(res);
            setDimension(res)
        })
    },[])

    var navigate = useNavigate();
    const [local, setLocal] = useState<LocalType | null>(null);
    var idLocal = '';
    var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType;
    var mapsURI = 'https://www.google.com/maps/search/?api=1&query=' + `${local?.address}`;
    try {
        idLocal = JSON.parse(localStorage.getItem('selectedRestaurantClient') as string).id;
    } catch (e) {
        navigate('/');
    }

    if (!idLocal) {
        navigate('/');
    }
    const [page, setPage] = useState<{ title: string, page: React.JSX.Element }>(
        {title: '', page: <div></div>},
    );



    useEffect(() => {
        getDetailRestaurants(idLocal!, (local) => {
            array = [];
            for (let i = 1; i <= local.priceLevel; i++) {
                array.push(iconPrice);
            }
            setLocal(local);

        });


    }, []);

    useEffect(() => {
        if (local) setPage({title: 'info', page: <InfoDetailPage adminpage={false} local={local}/>});
    }, [local]);


    if (local !== null) {
        if (isMobile) {
            return (
                <div className={"w-full h-full pt-[85px] flex flex-col"}>
                    <div className={'w-full h-auto'}>
                        <img alt={''} src={local.approvalStatus === StatusEnum.ACCEPTED ? local.photoPath : placeLocal}
                             className={'h-[300px] w-full object-cover'}/>
                        <div className={'w-full flex flex-col p-4'}>
                            <div className={'w-full h-full flex flex-col mt-8'}>
                                <div className={'w-full flex'}>
                                <h1 className={`w-full ${isTablet() ? 'text-[2rem]' : 'text-2xl '} font-poppins-bold whitespace-normal overflow-hidden truncate text-center`}>
                                    <strong>{local.nameRestaurant}</strong></h1>
                                <img alt={''} key={Math.random()} className={'w-8 h-8'}
                                     src={local.isFavourite ? iconFavouriteFull : iconFavourite}
                                     onClick={() => {
                                         if (userData) {
                                             addFavourite(local?.id, (response) => {
                                                 setLocal({...local, isFavourite: !local.isFavourite})
                                             })
                                         } else {
                                             showWarningNotification('Per aggiungere un prodotto ai preferiti effettua la login', () => {
                                                 navigate('/login')
                                             })
                                         }
                                     }}/>
                    </div>
                                <div className="w-full h-full flex flex-col items-center justify-center my-8">
                                    <div
                                        className={'w-full h-full flex flex-col gap-x-4 items-center justify-center gap-y-5'}>
                                        <RatingComponent key={Math.random()} size={isTablet() ? 'lg' : 'md'}
                                                         value={local.rating}
                                                         readonly={true}
                                                         onChangeValue={() => {
                                                         }}/>

                                        <h1 className={` ${local.openingInfo.isOpen ? 'text-green-600 border-green-600' : 'text-red-600 border-red-600'} font-poppins-bold  ml-5  px-2 py-1 `}>{local.openingInfo.isOpen ? `Aperto. ${local.openingInfo.closingAt}` : `Chiuso. ${local.openingInfo.nextOpen ? `Apre ${local.openingInfo.nextOpen}` : ''}`}</h1>
                                    </div>
                                    <div className={'w-full flex'}>
                                        <div className={'w-auto flex-col h-auto flex mx-5 mt-8 gap-y-3'}>

                                            <div className={'w-full flex'}>
                                                <img className={'w-8 h-8'} src={iconGroupDetail}/>
                                                <h1 className="text-sm ml-2 h-auto font-poppins-bold whitespace-normal">{local.typeLocal}</h1>
                                            </div>

                                            <div className={'w-full flex items-center'}>
                                                <img className={'w-8 h-8'} src={iconPositionDetail}/>

                                                <a
                                                    onClick={() => {
                                                        window.open(mapsURI, '_blank');
                                                    }}
                                                    href="#"
                                                    id="address-link"
                                                    className="underline text-sm font-poppins-semi-bold whitespace-normal truncate p-2"
                                                >
                                                    {local.address ? local.address : ''}
                                                </a>
                                            </div>

                                            <div className={'w-full flex items-center'}>
                                                <img className={'w-8 h-8'} src={phoneIcon}/>
                                                <a
                                                    href={`tel:${local.phoneNumber}`}
                                                    className="underline text-sm font-poppins-semi-bold whitespace-normal truncate p-2"
                                                >
                                                    {local.phoneNumber}
                                                </a>
                                            </div>


                                            <div className={'w-full flex items-center'}>
                                                <img className={'w-8 h-8'} src={iconPriceDetail}/>
                                                <div className="flex gap-x-1 ml-2">
                                                    {array.map((ite) => (
                                                        <GenericImg key={Math.random()} className="w-4 h-4 invert"
                                                                    src={ite}/>))}
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className={'w-full overflow-y-auto max-h-[300px] mt-5 px-5'}>
                                <h1 className={'h-auto overflow-y-auto font-poppins-semi-bold text-justify'}>
                                    {local.description}
                                </h1>
                            </div>
                        </div>

                    </div>
                    <div className={'w-full h-full'}>
                        <div
                            className={`w-full h-auto flex ${isTablet() ? 'justify-between px-10' : 'items-center justify-center'} gap-x-5 mt-10 p-2`}>
                            <div className={`flex flex-col items-center`} onClick={() => {
                                setPage({title: 'info', page: <InfoDetailPage adminpage={false} local={local}/>})
                            }}>
                                <img className={`${isTablet() ? 'w-32' : 'w-20'}`} src={iconInfoDetail}/>
                                <h1 className={`text-lg ml-2 ${page.title === 'info' ? 'text-amber-black' : 'text-black'} h-auto mt-2 font-poppins-bold`}>Info</h1>
                            </div>


                            {local.isReservation ? <div className={'flex flex-col items-center'} onClick={() => {
                                setPage({title: 'reservation', page: <ReservationDetailPage/>})
                            }}>
                                <img className={`${isTablet() ? 'w-32' : 'w-20'}`} src={iconReservationDetail}/>
                                <h1 className={`text-lg ml-2 h-auto ${page.title === 'reservation' ? 'text-amber-400' : 'text-black'} mt-2 font-poppins-bold`}>Prenota</h1>
                            </div> : null}


                            {local.isDelivery !== RestaurantStatusEnum.NESSUNO ?
                                <div className={'flex flex-col items-center'} onClick={() => {
                                    setPage({
                                        title: 'Delivery',
                                        page: <DeliveryClientPageMobile restaurantStatus={local.isDelivery}/>
                                    })
                                }}>
                                    <img className={`${isTablet() ? 'w-32' : 'w-20'}`} src={iconDeliveryDetail}/>
                                    <h1 className={`text-lg ml-2 h-auto ${page.title === 'Delivery' ? 'text-amber-400' : 'text-black'} mt-2 font-poppins-bold`}>Asporto</h1>
                                </div> : null}


                            <div className={'flex flex-col items-center'} onClick={() => {
                                setPage({title: 'menu', page: <MenuDetailPage/>})
                            }}>
                                <img className={`${isTablet() ? 'w-32 ' : 'w-20 '}`} src={iconMenuDetail}/>
                                <h1 className={`text-lg ml-2 ${page.title === 'menu' ? 'text-amber-400' : 'text-black'} h-auto mt-2 font-poppins-bold`}>Menu</h1>
                            </div>
                        </div>

                        <div className={'mt-5'}>
                            {page.page}
                        </div>
                    </div>

                </div>
            )
        }else {
            return (
                <div className={`w-full h-full pt-[85px]  flex overflow-x-hidden  ${dimension? "flex-col":""}`}>
                    <div className={`h-full  ${dimension? "w-full":"w-[60%]"}`}>
                        <img alt={''} src={local.approvalStatus === StatusEnum.ACCEPTED ? local.photoPath : placeLocal}
                             className={'h-[70%] w-full object-cover rounded-br-xl'}/>

                        <div className={'w-full h-[30%] flex flex-col p-4'}>
                            <div className={'w-full h-auto flex flex-col'}>
                                <div className={'flex flex-row justify-between items-center'}>
                                    <h1 className="max-w-full text-5xl font-poppins-bold whitespace-normal overflow-wrap break-word ml-5 my-5 ">
                                        {local.nameRestaurant}
                                    </h1>

                                    <img alt={''} key={Math.random()} className={'w-12 h-auto'}
                                         src={local.isFavourite ? iconFavouriteFull : iconFavourite}
                                         onClick={() => {
                                             if (userData) {
                                                 addFavourite(local?.id, (response) => {
                                                     setLocal({...local, isFavourite: !local.isFavourite})
                                                 })
                                             } else {
                                                 showWarningNotification('Attenzione Per aggiungere un prodotto ai preferiti effettua la login', () => {
                                                     navigate('/login')
                                                 })
                                             }
                                         }}/>
                                </div>

                                <div className="w-full h-full flex flex-col ml-5 gap-y-4">
                                    <div className={'flex'}>
                                        <RatingComponent size={'lg'} value={local.rating} readonly={true}
                                                         onChangeValue={() => {}}/>

                                        <h1 className={` ${local.openingInfo.isOpen ? 'text-green-600 border-green-600' : 'text-red-600 border-red-600'} font-poppins-bold  ml-5  px-2 py-1 `}>{local.openingInfo.isOpen ? `Aperto. ${local.openingInfo.closingAt}` : `Chiuso. ${local.openingInfo.nextOpen?`Apre ${local.openingInfo.nextOpen}`:''}`}</h1>
                                    </div>
                                    <div className={'w-full flex flex-col gap-y-3'}>

                                    <div className={'flex items-center'}>
                                                <img className={'w-8 h-8'} src={iconGroupDetail}/>
                                                <h1 className="text-sm ml-2 h-auto font-poppins-bold whitespace-normal overflow-wrap break-word">
                                                    {local.typeLocal}
                                                </h1>
                                            </div>

                                            <div className={'flex items-center'}>
                                                <img className={'w-8 h-8'} src={iconPositionDetail}/>
                                                <h1 className={`underline text-sm ml-2 font-poppins-semi-bold whitespace-normal overflow-wrap break-word`}>
                                                    <a onClick={() => {
                                                        window.open(mapsURI, '_blank')
                                                    }} href="#" id="address-link">
                                                        {`${local.address ? local.address : ''}`}
                                                    </a>
                                                </h1>

                                            </div>


                                        <div className={'flex items-center'}>
                                            <img className={'w-8 h-8'} src={phoneIcon}/>

                                            <h1 className="text-sm ml-2 font-poppins-semi-bold whitespace-normal overflow-wrap break-word">
                                                <a href={`tel:${local.phoneNumber}`}
                                                   className="underline">{local.phoneNumber}</a>
                                            </h1>
                                        </div>


                                        <div className={'flex items-center'}>
                                                <img alt={''} className={'w-8 h-8'} src={iconPriceDetail}/>
                                                <div className="flex gap-x-1 ml-2">
                                                    {array.map((ite) => (
                                                        <GenericImg key={Math.random()} className="w-4 h-4 invert" src={ite}/>
                                                    ))}
                                                </div>
                                            </div>

                                        </div>

                                </div>

                            </div>
                            <div className={'w-full h-full mt-5 px-5'}>
                                <h1 className={'h-full font-poppins-semi-bold text-justify'}>
                                    {local.description}
                                </h1>
                            </div>
                        </div>

                    </div>
                    <div className={` h-full p-8 ${dimension? "w-full":"w-[40%]"}`}>
                        <div className={'w-full h-[15%] flex items-center justify-center gap-x-6 mt-10'}>
                            <div className={`flex flex-col items-center cursor-pointer`} onClick={() => {
                                setPage({title: 'info', page: <InfoDetailPage adminpage={false} local={local}/>})
                            }}>
                                <img className={'w-32'} src={iconInfoDetail}/>
                                <h1 className={`text-lg ml-2 ${page.title === 'info' ? 'text-amber-400' : 'text-black'} h-auto mt-2 font-poppins-bold`}>Info</h1>
                            </div>

                            {local.isReservation ? (
                                <div className={'flex flex-col items-center cursor-pointer'} onClick={() => {
                                    setPage({title: 'reservation', page: <ReservationDetailPage/>})
                                }}>
                                    <img className={'w-32'} src={iconReservationDetail}/>
                                    <h1 className={`text-lg ml-2 h-auto ${page.title === 'reservation' ? 'text-amber-400' : 'text-black'} mt-2 font-poppins-bold`}>Prenota</h1>
                                </div>
                            ) : null}

                            {local.isDelivery !== RestaurantStatusEnum.NESSUNO ? (
                                <div className={'flex flex-col items-center cursor-pointer'} onClick={() => {
                                    setPage({title: 'Delivery', page: <DeliveryClientPage restaurantStatus={local.isDelivery}/>})
                                }}>
                                    <img alt={''} className={'w-32'} src={iconDeliveryDetail}/>
                                    <p className={`text-lg ml-2 h-auto ${page.title === 'Delivery' ? 'text-amber-400' : 'text-black'} mt-2 font-poppins-bold`}>Ordina</p>
                                </div>
                            ) : null}

                            <div className={'flex flex-col items-center cursor-pointer'} onClick={() => {
                                setPage({title: 'menu', page: <MenuDetailPage/>})
                            }}>
                                <img className={`w-32`} src={iconMenuDetail}/>
                                <h1 className={`text-lg ml-2 ${page.title === 'menu' ? 'text-amber-400' : 'text-black'} h-auto mt-2 font-poppins-bold`}>Menù</h1>
                            </div>
                        </div>

                        <div className={'mt-16'}>
                            {page.page}
                        </div>
                    </div>
                </div>
            )
        }


    } else {
        return null
    }
}
