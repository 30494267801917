import React, { useEffect, useState } from "react";
import { getClientCommad } from "../network/controllers/CommandController";
import { LoginResponseType } from "../types/LoginResponseType";
import CommandClientCardComponent from "../components/CommandClientCardComponent";
import { Grid } from "@mantine/core";
import { isMobile } from "react-device-detect";
import { ComandaOrder } from "../types/PostCommand";
import CustomDataPicker from "../components/CustomDataPicker";

export default function CommandPage() {
    const [orders, setOrders] = useState<ComandaOrder[]>([]);
    const userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType;
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<'current' | 'history'>('current');

    useEffect(() => {
        setIsLoading(true);
        dispatchEvent(new CustomEvent("loader", { detail: { isLoading: true } }));
        getClientCommad(userData.id, (response) => {
            if (response.length > 0) {
                setOrders(response);
            }
            dispatchEvent(new CustomEvent("loader", { detail: { isLoading: false } }));
            setIsLoading(false)
        });
    }, [userData.id, reload]);

    useEffect(() => {
        console.log('che cazzo mi sta arrivando',orders)
    }, [orders]);

    const tabStyle = (isActive: boolean) => `
        cursor-pointer 
        font-poppins-bold 
        text-black 
        text-2xl
        ${isActive ? 'text-black border-b-2 border-black' : 'text-gray-500'}
        hover:text-black
        px-4
        py-2
        transition-all
    `;

    const renderContent = () => {
        const filteredOrders = activeTab === 'current' ? orders : [];

        if (filteredOrders.length === 0 && !isLoading) {
            return (
                <div className="w-full h-full flex items-center justify-center ">
                    <p className="font-poppins-bold text-black text-center text-xl">
                        {activeTab === 'current'
                            ? "Non hai nessun ordine attivo da visualizzare"
                            : "Non hai nessun ordine nel tuo storico da visualizzare"}
                    </p>
                </div>
            );
        }

        return (
            <Grid gutter={'auto'}>
                {filteredOrders.map((item) => (
                    <Grid.Col
                        className="w-auto "
                        span={isMobile ? 12 : {base: 12, xs: 6, md: 4, xl: 3}}
                        key={item.id}
                    >
                        <div className="w-full mt-2 ">
                            <CommandClientCardComponent order={item}/>
                        </div>
                    </Grid.Col>
                ))}
            </Grid>
        );
    };

    const TabHeader = () => (


        <div className="w-full flex items-center justify-center gap-3">
            <span
                className={`{${isMobile?'text-sm':''} tabStyle(activeTab === 'current')}`}
                onClick={() => setActiveTab('current')}
            >
                I Miei Ordini
            </span>
            <span className="text-gray-400">|</span>
            <span
                className={`{${isMobile?'text-sm':''} tabStyle(activeTab === 'history')}`}
                onClick={() => setActiveTab('history')}
            >
                Storico Ordini
            </span>
        </div>
    );

    if (isMobile) {
        return (
            <div className="w-full h-screen flex flex-col items-center text-center pt-[140px]">
                <TabHeader />
                {activeTab === 'history' && <CustomDataPicker/>}
                <div className="w-full h-full overflow-y-auto mt-2 p-2">
                    {renderContent()}
                </div>
            </div>
        );
    } else {
        return (
            <div className="w-full h-screen flex flex-col items-center text-center pt-[140px] pb-10">
                <TabHeader />
                {activeTab === 'history' && <CustomDataPicker />}
                <div className="w-full h-full overflow-y-auto mt-10 p-5">
                    {renderContent()}
                </div>
            </div>
        );
    }
}
