import React, {useEffect, useState} from "react";

import CustomButton from "../../../components/CustomButton";
import {
    getAllProductsFromIdLocalAndIdMenu,
} from "../../../network/controllers/admin/ProductController";
import {GetProductResponseType} from "../../../types/GetProductResponseType";
import {CategoryType} from "../../../types/CategoryType";
import CategoryDrawerComponent from "../CategoryDrawerComponent";
import {Drawer} from "@mantine/core";
import AccordionCategoriesAdmin from "../../../components/AccordionCategoriesAdmin";
import ProductAdminTable from "./ProductAdminTable";
import placeholder from '../../../assets/placeolder-categoria.svg'
import loadingIcon from "../../../assets/loader-white.svg";
import {isMobile} from "react-device-detect";
import {getDimension} from "../../../utils/Utility";


export default function ProductAdminPage() {
    const [productList, setProductList] = useState<Map<CategoryType, GetProductResponseType[]>>(new Map());
    const [newCategory, setNewCategory] = useState<CategoryType>({
            categoryName: "",
            idLocal: JSON.parse(localStorage.getItem('selectedRestaurant') as string).id,
            image: "",
            idMenus: [],
            description: '',
        categoryNameTranslations: new Map<string, string>()
        }
    );

    const [dimension, setDimension] = useState(window.innerWidth < 1280)
    useEffect(() => {
        getDimension(1280, (res)=>{
            console.log(res);
            setDimension(res)
        })
    },[])

    const [openDrawer, setOpenDrawer] = useState({open: false, content: <div></div>, dimension: '30%'});
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        getAllProductsFromIdLocalAndIdMenu((response) => {
            
            setLoading(false)
            var tmpMap = new Map<CategoryType, GetProductResponseType[]>()
            Object.entries(response).forEach((ite) => {
                tmpMap.set(JSON.parse(ite[0]) as CategoryType, ite[1])
            })
            setProductList(tmpMap)
        })

    }, [reload]);

    if (isMobile || dimension) {
        return (
            <div className="w-full h-auto flex flex-col pt-[130px]">
                <div className={'w-full h-auto flex items-center justify-center gap-x-5'}>
                    <div className="flex flex-col items-center justify-center ">
                        <img
                            alt=""
                            src={placeholder}
                            className="w-12 h-12 rounded-lg border-4 border-lime-400 p-1"
                        />
                        <h1 className="font-normal">Approvata</h1>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <img
                            alt=""
                            src={placeholder}
                            className="w-12 h-12 rounded-lg border-4 border-red-600 p-1"
                        />
                        <h1 className="font-normal">Rifiutata</h1>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <img
                            alt=""
                            src={placeholder}
                            className="w-12 h-12 rounded-lg border-4 border-[#FFCC00] p-1"
                        />
                        <h1 className="font-normal">In Attesa</h1>
                    </div>
                </div>
                <Drawer
                    position="bottom"
                    size={'h-full'}
                    styles={{
                        content: {backgroundColor: 'black'},
                        header: {backgroundColor: 'black'}
                    }}
                    zIndex={40}
                    opened={openDrawer.open}
                    onClose={() => setOpenDrawer({...openDrawer, open: false})}
                >
                    {openDrawer.content}
                </Drawer>
                <div className="w-full flex justify-between items-center">
                    <h1 className="text-lg font-bold my-5 ">MENU: {JSON.parse(localStorage.getItem('selectedMenu') as string).name}</h1>
                    <div className="flex gap-5">

                    </div>
                    <CustomButton
                        small
                        title="Aggiungi Categoria"
                        onClick={() => {
                            setNewCategory({
                                categoryName: "",
                                idLocal: JSON.parse(localStorage.getItem('selectedRestaurant') as string).id,
                                image: "",
                                idMenus: [],
                                description: '',
                                categoryNameTranslations: new Map<string, string>()
                            });
                            setOpenDrawer({
                                open: true,
                                content: (
                                    <CategoryDrawerComponent
                                        mapSize={productList.size + 1}
                                        category={null}
                                        onCategoryCreated={() => {
                                            setReload(!reload);
                                            setOpenDrawer({...openDrawer, open: false});
                                        }}
                                    />
                                ),
                                dimension: '30%'
                            });
                        }}
                        enable={true}
                        isLoading={false}
                    />
                </div>

                {!loading && productList.size > 0 ? (
                    <AccordionCategoriesAdmin
                        key={Math.random()}
                        reload={() => setReload(!reload)}
                        addProduct={(categoryId, listSize) => {
                            setOpenDrawer({
                                open: true,
                                content: (
                                    <ProductAdminTable
                                        listSize={listSize}
                                        categoryId={categoryId}
                                        onChangeStatus={() => {
                                        }}
                                        elementSelected={() => {
                                        }}
                                        onClickItem={() => {
                                        }}
                                    />
                                ),
                                dimension: '80%'
                            });
                        }}
                        updateCategory={(category) => {
                            setOpenDrawer({
                                open: true,
                                content: (
                                    <CategoryDrawerComponent
                                        mapSize={productList.size + 1}
                                        category={category}
                                        onCategoryCreated={() => {
                                            setReload(!reload);
                                            setOpenDrawer({...openDrawer, open: false});
                                        }}
                                    />
                                ),
                                dimension: '30%'
                            });
                        }}
                        isAdmin={true}
                        data={productList}
                    />
                ) : !loading && productList.size === 0 ? <div className={'w-full h-full flex items-center justify-center font-poppins-semi-bold text-xl mt-32'}>Nessun
                    prodotto per il menù selezionato</div> : (
                    <div className="w-full flex justify-center py-10">
                        <img alt="" className="w-24 h-24" src={loadingIcon}/>
                    </div>
                )}
            </div>
        );

    } else {
        return (
            <div className={'w-full h-full  flex-col  pt-[130px] '}>
            <Drawer position={"right"}
                        size={openDrawer.dimension}
                        styles={{
                            content: {backgroundColor: 'black'},
                            header: {backgroundColor: 'black'}
                        }}
                        zIndex={40}
                        opened={openDrawer.open} onClose={() => {
                    setOpenDrawer({...openDrawer, open: false})
                }}>

                    {openDrawer.content}
                </Drawer>
                <div className={`w-full flex ${dimension? "flex-col items-center justify-center gap-y-3 mb-3":"justify-between "} `}>
                    <h1 className={'w-auto text-3xl font-poppins-bold my-5'}>{'MENU:' + JSON.parse(localStorage.getItem('selectedMenu') as string).name}</h1>

                    <div className={`w-auto flex gap-x-5  `}>
                        <div className={'flex items-center justify-center'}>
                            <img alt={''} src={placeholder}
                                 className={`w-[50px] h-[50px] rounded-lg border-[3.5px] border-lime-400 p-0.5`}/>
                            <h1 className={'w-full font-poppins-regular mx-1'}>Approvata</h1>
                        </div>
                        <div className={'flex items-center justify-center'}>
                            <img alt={''} src={placeholder}
                                 className={`w-[50px] h-[50px] rounded-lg border-[3.5px] border-red-600 p-0.5`}/>
                            <h1 className={'w-full font-poppins-regular mx-1'}>Rifiutata</h1>
                        </div>
                        <div className={'flex items-center justify-center'}>
                            <img alt={''} src={placeholder}
                                 className={`w-[50px] h-[50px] rounded-lg border-[3.5px] border-yellow p-0.5`}/>
                            <h1 className={'w-full font-poppins-regular mx-1'}>In Attesa</h1>
                        </div>


                    </div>
                    <CustomButton small={true} title={'Aggiungi Categoria'} onClick={() => {
                        setNewCategory({
                                categoryName: "",
                                idLocal: JSON.parse(localStorage.getItem('selectedRestaurant') as string).id,
                                image: "",
                                idMenus: [],
                                description: '',
                            categoryNameTranslations: new Map<string, string>()
                            }
                        )
                        setOpenDrawer({
                            open: true,
                            content: <CategoryDrawerComponent mapSize={productList.size + 1} category={null}
                                                              onCategoryCreated={() => {
                                                                  setReload(!reload)
                                                                  setOpenDrawer({...openDrawer, open: false})
                                                              }}/>,
                            dimension: '30%'
                        })
                    }} enable={true} isLoading={false}/>
                </div>

                {!loading && productList.size > 0 ? <AccordionCategoriesAdmin key={Math.random()} reload={() => {
                    setReload(!reload)
                }} addProduct={(categoryId, listSize) => {
                    setOpenDrawer({
                        open: true, content:
                            <ProductAdminTable listSize={listSize} categoryId={categoryId} onChangeStatus={() => {
                            }} elementSelected={() => {
                            }} onClickItem={() => {
                            }}/>, dimension: '80%'
                    })
                }} updateCategory={(category) => {
                    setOpenDrawer({
                        open: true,
                        content: <CategoryDrawerComponent mapSize={productList.size + 1} category={category}
                                                          onCategoryCreated={() => {
                                                              setReload(!reload)
                                                              setOpenDrawer({...openDrawer, open: false})
                                                          }}/>,
                        dimension: '30%'
                    })
                }} isAdmin={true} data={productList}/> :!loading && productList.size===0?<div className={'w-full h-full flex items-center justify-center font-poppins-semi-bold text-3xl'}>Nessun prodotto per il menù selezionato</div> :<div className={'w-full flex  justify-center py-10'}>
                    <img alt={''} className={'w-24 h-24'} src={loadingIcon}/>
                </div>}

            </div>
        )
    }
}
