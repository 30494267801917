import { LocalType } from "../../types/LocalType";
import { CategoryType } from "../../types/CategoryType";
import { useEffect, useState } from "react";
import { GetProductResponseType } from "../../types/GetProductResponseType";
import { getProductList } from "../../network/controllers/DetailController";
import { IngredientType } from "../../types/IngredientType";
import ItemProductComponentCommand from "./ItemProductComponentCommand";
import {CommandTableOrderType, Product} from "../../types/CommandTableOrderType";

export default function ProductCommandComponents(props: {
    recipe: CommandTableOrderType["order"]; // Informazioni sull'ordine
    local: LocalType;
    onBack: () => void;
    categorySelected: CategoryType;
    onProductAdd: (
        product: any,
        quantity: number,
        ingredientList: IngredientType[],
        note: string,
        turnId?: string | undefined
    ) => void;
}) {
    const [listProduct, setListProduct] = useState<GetProductResponseType[]>([]);
    const [ingredientList, setIngredientList] = useState<{ label: string; value: string }[]>([]);
    const [selectedTurn, setSelectedTurn] = useState<string | null>(null); // Turno selezionato
    const [turns, setTurns] = useState<{ value: string; label: string }[]>([]); // Lista dei turni

    useEffect(() => {
        // Caricamento dei prodotti in base al locale e alla categoria selezionata
        if (props.local.id && props.categorySelected.id) {
            getProductList(props.local.id, props.categorySelected.id, (listProductResponse) => {
                setListProduct(listProductResponse);
            });
        }
    }, [props.local.id, props.categorySelected.id]);

    useEffect(() => {
        // Prepara la lista di ingredienti e turni basati su `recipe`
        if (props.recipe?.turn) {
            const ingredients = Object.values(props.recipe.turn).flatMap((turn) =>
                turn.productsList.flatMap((product) => product.ingredients)
            );

            const formattedIngredients = ingredients.map((ingredient) => ({
                label: `${ingredient.name} €${ingredient.price}`,
                value: JSON.stringify(ingredient),
            }));
            setIngredientList(formattedIngredients);

            const formattedTurns = Object.entries(props.recipe.turn).map(([turnId, turn]) => ({
                value: turnId,
                label: turn.nameTurn,
            }));
            setTurns(formattedTurns);

            if (!selectedTurn && formattedTurns.length > 0) {
                setSelectedTurn(formattedTurns[0].value); // Imposta il primo turno come selezionato per default
            }
        }
    }, [props.recipe]);

    return (
        <div className="w-full">
            <div className="flex justify-between items-center mb-4">
                <button
                    onClick={props.onBack}
                    className=" bg-[#FFCC00] px-4 py-2 rounded-md shadow-md"
                >
                    Torna Indietro
                </button>
                <h3 className="text-lg font-bold text-white">{props.categorySelected.categoryName}</h3>
            </div>
            {listProduct.length === 0 ? (
                <p className="text-center text-white">
                    Nessun prodotto disponibile per questa categoria.
                </p>
            ) : (
                <div className="flex flex-col items-center">
                    {listProduct.map((product) => (
                        <ItemProductComponentCommand
                            key={product.id}
                            product={product}
                            recipe={props.recipe}
                            ingredientList={ingredientList}
                            onProductAdd={(quantity, ingredientList, note, turnId) => {

                                props.onProductAdd(
                                    product,
                                    quantity,
                                    ingredientList,
                                    note,
                                    turnId ?? selectedTurn ?? undefined
                                );
                            }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}
