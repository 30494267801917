import edit from '../../../assets/edit-icon.svg'
import trashIcon from '../../../assets/delete-icon.svg'
import qrIcon from '../../../assets/qr-icon.svg'
import React, {useState} from "react";
import {Input} from "@mantine/core";
import confirm from "../../../assets/confirm-icon.svg";
import closeIcon from "../../../assets/close-icon.svg";
import {TableType} from "../../../types/TableType";
import {showSuccessNotification, showWarningNotification} from "../../../utils/NotificationUtils";
import {deleteTable, getTableQr, updateTable} from "../../../network/controllers/admin/TableController";
import loadingIcon from '../../../assets/loader-white.svg'
import arrowSelection from "../../../assets/arrowSelection.svg";



export default function TablesTable(props: {
    table: TableType,
    onClickItem: (element: any) => void,
    onTableUpdate: (tableList: TableType[]) => void
}) {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [newTable, setNewTable] = useState(props.table.tableName);
    const [isLoading, setIsLoading] = useState(false);


    return (
        <div className={' flex gap-x-20 '}>
            {

                !isEditing ? <div className={'w-full flex justify-between gap-x-20 '}>
                        <div className={'w-auto flex items-center justify-center gap-x-2'}>
                            <img alt="" src={arrowSelection}/>
                            <h1 className={'w-auto font-poppins-regular m-1'}>{props.table.tableName}</h1>

                        </div>
                        <div className={'w-[100px] flex gap-x-5'}>

                        <div className={'w-5 h-5 cursor-pointer'}>
                                <img alt={''} className={'w-5 h-5'} src={isLoading ? loadingIcon : qrIcon} onClick={() => {
                                    setIsLoading(true);
                                    getTableQr(props.table.id, (response) => {
                                        setIsLoading(false);
                                        const base64Data = response.data.base64QRCode;
                                        const binaryString = atob(base64Data);
                                        const len = binaryString.length;
                                        const bytes = new Uint8Array(len);
                                        for (let i = 0; i < len; i++) {
                                            bytes[i] = binaryString.charCodeAt(i);
                                        }
                                        const blob = new Blob([bytes], {type: 'application/octet-stream'});
                                        const link = document.createElement('a');
                                        link.href = URL.createObjectURL(blob);
                                        link.download = `${props.table.tableName}-qr.png`;
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    })
                                }}/>
                            </div>

                            <div className={'w-5 h-5 cursor-pointer'}>
                                <img alt={''} className={'w-5 h-5'} src={isLoading ? loadingIcon : edit} onClick={() => {
                                    setIsEditing(true)
                                }}/>
                            </div>

                            <div className={'w-5 h-5 cursor-pointer'}>
                                <img alt={''} src={isLoading ? loadingIcon : trashIcon} onClick={() => {
                                    showWarningNotification(`Sicuro di voler eliminare  ${props.table.tableName}?`, () => {
                                        setIsLoading(true);
                                        deleteTable(props.table.id, (response) => {
                                            window.location.reload()
                                        })
                                    })
                                }}/>
                            </div>

                        </div>

                    </div> :
                    <div className={'w-full flex justify-between gap-x-20 '}>
                        <Input variant={'unstyled'} type={'text'} value={newTable}
                               className={'w-[250px] h-full bg-white text-text-button-add font-poppins-regular outline-none '}
                               placeholder={'Nome Tavolo'} onChange={(event) => {
                            setNewTable(event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1))

                        }}/>
                        <div className={'w-[100px] flex gap-x-5'}>
                            <img alt={''} className={'w-6 h-6 cursor-pointer'} src={isLoading ? loadingIcon : closeIcon}
                                 onClick={() => {
                                     setIsEditing(false)
                                 }}/>

                            <img alt={''} className={'w-6 h-6 cursor-pointer'} src={isLoading ? loadingIcon : confirm}
                                 onClick={() => {
                                     setIsEditing(true)
                                     updateTable({idTable: props.table.id, tableName: newTable}, (response) => {
                                         setIsLoading(false)
                                         showSuccessNotification('Tavolo', `${newTable} modificato con successo`, 2000)
                                         setIsEditing(false)
                                         props.onTableUpdate(response)
                                     })
                                 }}/>
                        </div>

                    </div>
            }

        </div>
    )
}
