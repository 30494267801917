import {useNavigate} from "react-router-dom";
import {isTablet} from "../utils/WindowDimensionUtils";
import logo from "../assets/logo-menus.webp";
import logoMobile from "../assets/logoMobile.svg";
import {useState, useEffect} from "react";
import {getDimension} from "../utils/Utility";
import {isMobile} from "react-device-detect";

export default function ImageLogoMenus(props: { dimension?: string, absolute?: boolean }) {
    const navigate = useNavigate();
    // Inizializza lo stato in base alla dimensione attuale della finestra
    const [dimension, setDimension] = useState(() => window.innerWidth <= 768);

    useEffect(() => {
        // Gestisce il resize
        const handleResize = () => {
            setDimension(window.innerWidth <= 768);
        };

        // Aggiungi l'event listener
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <img
            onClick={() => {
                dispatchEvent(new CustomEvent("footerVisible", { detail: { isVisible: true } }));
                navigate('/')
            }}
            className={`w-auto h-[85px] cursor-pointer ${dimension || (isMobile && !isTablet()) ? 'py-5' : 'py-4'}`}
            alt={logo}
            src={
                (dimension || (isMobile && !isTablet())
                    ? logoMobile
                    : logo) + `?timestamp=${new Date().getMilliseconds()}`
            }
        />
    )
}
