import React, { useEffect, useState } from "react";
import { Grid, Text, Select } from "@mantine/core"; // Importa Select
import { getTables } from "../network/controllers/admin/TableController";
import { getOrder, openTable } from "../network/controllers/admin/OrderController";
import tableOccupiedIcon from "../assets/table_occupied.svg";
import tableFreeIcon from "../assets/table_free.svg";
import { onMessage, subscribeTopic } from "../utils/MqttUtility";
import { LocalType } from "../types/LocalType";
import { CommandTableOrderType } from "../types/CommandTableOrderType";
import { isMobile } from "react-device-detect";
import {showFailNotification, showWarningNotification} from "../utils/NotificationUtils";

export default function TableCashDeskComponent(props: { onTableClick: (order: any) => void }) {
    const [tables, setTables] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);


    const [selectedTable, setSelectedTable] = useState<{
        idLocal: string;
        idTable: string;
        tableName: string;
    } | null>(null);

    useEffect(() => {
        reloadDataTable();
    }, []);

    useEffect(() => {
        const restaurant = JSON.parse(localStorage.getItem("selectedRestaurant") as string) as LocalType;
        const topicEnter = `/${restaurant.id}/enter-order`;
        const topicUpdate = `/${restaurant.id}/update-order`;
        const topicClose = `/${restaurant.id}/close-order`;

        subscribeTopic(topicEnter);
        subscribeTopic(topicClose);
        subscribeTopic(topicUpdate);

        onMessage(topicEnter, () => {
            reloadDataTable();
        });

        onMessage(topicUpdate, () => {
            if (selectedTable) {
                reloadDataOrder(selectedTable);
            }
        });

        onMessage(topicClose, () => {
            reloadDataTable();
        });
    }, [selectedTable]);

    function reloadDataTable() {
        setLoading(true);
        getTables((response) => {
            if (response) {
                setTables(response);
                setError(null);
            } else {
                setError("Errore nel caricamento dei tavoli");
            }
            setLoading(false);
        });
    }

    function reloadDataOrder(table: { idLocal: string; idTable: string; tableName: string }) {
        getOrder(table, (response) => {
            if (response) {
                console.log("Dettagli ordine ricevuti:", response);
                props.onTableClick(response);
            } else {
                console.log("Nessun ordine trovato per il tavolo:", table.tableName);
            }
        });
    }

    function handleOpenTable(table: {
        idLocal: string;
        idTable: string;
        tableName: string;
        numberCovered: string;
    }) {

       showWarningNotification(`Vuoi davvero occupare il tavolo ${table.tableName}`, () => {
           openTable(table, (response) => {
                if (response) {
                    console.log("Tavolo aperto con successo:", response);
                    reloadDataTable();
                } else {
                    showFailNotification("Oh no...", "Errore nell'apertura del tavolo", 3000);
                }
            });
        });
    }
    // Handler unico per selezione da <Select>
    function handleSelectTable(tableId: string) {
        // Trova il tavolo corrispondente
        const table = tables.find((t) => t.id === tableId);
        if (!table) return;

        const selected = {
            tableName: table.tableName,
            idTable: table.id,
            idLocal: table.idLocal,
            numberCovered: "",
        };

        setSelectedTable(selected);

        // Se il tavolo è occupato, carica l'ordine
        if (table.idOrder) {
            reloadDataOrder(selected);
        } else {
            // Se il tavolo è libero, aprilo
            handleOpenTable(selected);
        }
    }

    if (loading) return <p>Caricamento...</p>;
    if (error) return <p>Errore: {error}</p>;

    if (isMobile) {

        return (
            <div className="w-full h-auto items-center justify-center flex mt-5 px-4 ">
                <Select
                    placeholder="Seleziona un tavolo"

                    data={tables.map((table) => {
                        const bullet = table.idOrder ? "🔴" : "🟢";
                        return {
                            value: table.id,
                            label: `${bullet} ${table.tableName}`,
                        };
                    })}
                    onChange={(value) => {
                        if (value) handleSelectTable(value);
                    }}

                    styles={{
                        input: {
                            width: 'full',
                            fontSize: 16,
                        },
                    }}
                />
            </div>
        );
    } else {
        return (
            <div className="w-full h-full flex mt-10 ">
                <Grid gutter="sm">
                    {tables.map((table) => (
                        <Grid.Col key={table.id} span={2}>
                            <div
                                style={{
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    const selected = {
                                        tableName: table.tableName,
                                        idTable: table.id,
                                        idLocal: table.idLocal,
                                        numberCovered: "",
                                    };
                                    setSelectedTable(selected);

                                    if (table.idOrder) {
                                        reloadDataOrder(selected);
                                    } else {
                                        handleOpenTable(selected);
                                    }
                                }}
                            >
                                <img
                                    src={table.idOrder ? tableOccupiedIcon : tableFreeIcon}
                                    alt="Stato tavolo"
                                />
                                <Text style={{ marginTop: "2px" }}>{table.tableName}</Text>
                            </div>
                        </Grid.Col>
                    ))}
                </Grid>
            </div>
        );
    }
}
