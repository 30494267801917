import { GetProductResponseType } from "../../types/GetProductResponseType";
import { IngredientType } from "../../types/IngredientType";
import { useEffect, useState } from "react";
import CommandCategoryComponent from "./CommandCategoryComponent";
import { getInfoLocal } from "../../network/controllers/admin/CategoryController";
import ProductCommandComponents from "./ProductCommandComponents";
import { isMobile } from "react-device-detect";
import { CommandTableOrderType } from "../../types/CommandTableOrderType";
import loadingImg from "../../assets/loader-white.svg";

export default function CommandDrawer(props: {
    recipe: CommandTableOrderType["order"]; // Passa l'ordine con turni e prodotti
    onProductAdd: (
        product: GetProductResponseType,
        quantity: number,
        ingredientList: IngredientType[],
        note: string,
        turnId?: string
    ) => void;
}) {
    const [contentMenu, setContentMenu] = useState<React.JSX.Element | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedTurn, setSelectedTurn] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadCategoryComponent = () => {
            const categoryComponent = (
                <CommandCategoryComponent
                    categorySelected={(category) => {
                        setLoading(true);
                        const restaurantData = localStorage.getItem("selectedRestaurant");
                        if (!restaurantData) {
                            setError("Ristorante non selezionato.");
                            setLoading(false);
                            return;
                        }

                        const restaurantId = JSON.parse(restaurantData).id;

                        getInfoLocal(restaurantId, (local) => {
                            if (!local) {
                                setError("Errore durante il caricamento delle informazioni locali.");
                                setLoading(false);
                                return;
                            }
                            setLoading(false);
                            setContentMenu(
                                <ProductCommandComponents
                                    recipe={props.recipe}
                                    local={local}
                                    categorySelected={category}
                                    onProductAdd={(
                                        product,
                                        quantity,
                                        ingredientList,
                                        note,
                                        turnId
                                    ) => {
                                        props.onProductAdd(
                                            product,
                                            quantity,
                                            ingredientList,
                                            note,
                                            turnId ?? selectedTurn ?? undefined
                                        );
                                    }}
                                    onBack={() => {
                                        setContentMenu(categoryComponent);
                                    }}
                                />
                            );
                        });
                    }}
                />
            );
            setContentMenu(categoryComponent);
            setLoading(false);
        };

        loadCategoryComponent();
    }, [props.recipe, selectedTurn]);

    const LoadingComponent = () => (
        <div className="w-full h-auto flex items-center justify-center">
            <img alt="Loading" className="w-10 h-10" src={loadingImg} />
        </div>
    );

    const ErrorComponent = () => (
        <div className="w-full h-auto flex items-center justify-center text-red-500">
            <p>{error}</p>
        </div>
    );

    return (
        <div className={`w-full h-full mt-5 px-5 flex ${isMobile ? "absolute z-50" : ""}`}>
            {loading && <LoadingComponent />}
            {error && <ErrorComponent />}
            {!loading && !error && <div className="w-full">{contentMenu}</div>}
        </div>
    );
}
