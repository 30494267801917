import { Checkbox, NumberInput, Popover, PopoverDropdown } from "@mantine/core";
import { StatusEnum } from "../enums/StatusEnum";
import React, { useEffect, useState } from "react";
import Calendar from "./reservation/Calendar";
import ReservationStepper from "./reservation/Steeper";
import CustomButton from "./CustomButton";
import { checkReservation } from "../network/controllers/admin/ReservationController";
import { isMobile } from "react-device-detect";
import filterIcon from '../assets/filter-icon.svg';
import { DatePicker } from "@mantine/dates";
import { getDimension } from "../utils/Utility";

export default function HeaderReservationFilter(props: {
    reload: () => void,
    isAdmin: boolean,
    onValueChange: (value: { date: Date, status: StatusEnum }) => void
}) {
    const [value, setValue] = useState<{ date: Date, status: StatusEnum }>({
        date: new Date(),
        status: StatusEnum.PENDING
    });
    const [reservationCode, setReservationCode] = useState('');
    const [dimension, setDimension] = useState(window.innerWidth < 1280);

    useEffect(() => {
        props.onValueChange(value);
        console.log(value);
    }, [value]);

    useEffect(() => {
        getDimension(1080, (res) => {
            console.log(res);
            setDimension(res);
        });
    }, []);

    function isCurrentDate(checkDate: Date) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();
        const checkYear = checkDate.getFullYear();
        const checkMonth = checkDate.getMonth() + 1;
        const checkDay = checkDate.getDate();
        return currentYear === checkYear &&
            currentMonth === checkMonth &&
            currentDay === checkDay;
    }

    function setMidnight(date: Date): Date {
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    }

    if (isMobile) {
        return (
            <div className="w-full flex flex-col h-auto">
                <div className="w-full h-full flex flex-col items-center">
                    <h1 className="w-full font-poppins-semi-bold text-center text-3xl my-2">Prenotazioni</h1>
                    {props.isAdmin ? null : (
                        <div>
                            <Popover>
                                <Popover.Target>
                                    <img className="w-5 h-5 ml-2 cursor-pointer" alt="" src={filterIcon} />
                                </Popover.Target>
                                <Popover.Dropdown>
                                    <div className="w-full h-full flex flex-col gap-y-2">
                                        <Checkbox
                                            classNames={{ label: 'font-poppins-regular' }}
                                            color="#FFCC00"
                                            checked={StatusEnum.PENDING === value.status}
                                            label="In Attesa"
                                            onChange={() => setValue({ ...value, status: StatusEnum.PENDING })}
                                        />
                                        <Checkbox
                                            classNames={{ label: 'font-poppins-regular' }}
                                            color="green"
                                            checked={StatusEnum.ACCEPTED === value.status}
                                            label="Accettati"
                                            onChange={() => setValue({ ...value, status: StatusEnum.ACCEPTED })}
                                        />
                                        <Checkbox
                                            classNames={{ label: 'font-poppins-regular' }}
                                            color="red"
                                            checked={StatusEnum.REJECTED === value.status}
                                            label="Rifiutati"
                                            onChange={() => setValue({ ...value, status: StatusEnum.REJECTED })}
                                        />
                                        <Checkbox
                                            classNames={{ label: 'font-poppins-regular' }}
                                            color="blue"
                                            checked={StatusEnum.DONE === value.status}
                                            label="Completate"
                                            onChange={() => setValue({ ...value, status: StatusEnum.DONE })}
                                        />
                                    </div>
                                </Popover.Dropdown>
                            </Popover>
                        </div>
                    )}
                    {props.isAdmin ? (
                        <NumberInput
                            hideControls
                            allowDecimal={false}
                            classNames={{
                                label: 'w-full text-black font-poppins-bold text-center text-xs ',
                                input: 'text-xs w-full border-none bg-transparent text-black h-full font-poppins-semi-bold ',
                                wrapper: 'w-[200px] rounded-lg h-[40px] bg-yellow items-center justify-center ',
                            }}
                            value={reservationCode}
                            label="Inserisci codice prenotazione"
                            placeholder="Inserisci codice prenotazione"
                            onChange={(e) => {
                                setReservationCode(e.toString());
                                if (e.toString().length === 5) {
                                    checkReservation(e.toString(), () => {
                                        setReservationCode('');
                                        props.reload();
                                    });
                                }
                            }}
                        />
                    ) : null}
                </div>

                <div className="w-full h-full flex-col items-center justify-center mt-5">
                    <Popover>
                        <Popover.Target>
                            <div className="w-full flex items-center justify-center">
                                <div className="w-8/12 bg-yellow rounded-2xl py-2 px-10 hover:scale-105 duration-500 cursor-pointer my-2 items-center justify-center">
                                    <h1 className="w-auto text-center text-sm font-poppins-semi-bold">{`Data ${value.date.toLocaleString()}`}</h1>
                                </div>
                            </div>
                        </Popover.Target>
                        <PopoverDropdown>
                            <div className="bg-black">
                                <DatePicker
                                    className="flex content-center"
                                    classNames={{
                                        levelsGroup: 'flex w-full content-center',
                                        calendarHeader: 'text-white w-full flex-row content-center min-w-full mt-2',
                                        calendarHeaderLevel: 'customHeaderLevel0 text-xl hover:!bg-transparent !pointer-events-none',
                                        month: 'mt-4',
                                        monthRow: 'border-[12px] border-transparent',
                                        calendarHeaderControl: 'bg-trueGray-500 rounded-full mx-2 hover:!bg-yellow hover:!bg-opacity-50 hover:!rounded-full',
                                        day: 'text-white text-base font-poppins-bold customDatePicker0 hover:!bg-yellow hover:!bg-opacity-50 hover:!rounded-full',
                                        weekday: 'text-trueGray-200 uppercase text-xs font-poppins-bold',
                                    }}
                                    maxLevel="month"
                                    value={value.date}
                                    weekdayFormat="ddd"
                                    monthsListFormat="M"
                                    monthLabelFormat="MMMM"
                                    locale="it"
                                    onChange={(res) => {
                                        if (res) {
                                            const updatedDate = new Date(value.date);
                                            updatedDate.setDate(res.getDate());
                                            updatedDate.setMonth(res.getMonth());
                                            setValue({ ...value, date: updatedDate });
                                        }
                                    }}
                                    hideOutsideDates
                                    firstDayOfWeek={0}
                                />
                            </div>
                        </PopoverDropdown>
                    </Popover>
                    {props.isAdmin && (
                        <div className="w-full h-full mt-5">
                            <Popover closeOnClickOutside={false}>
                                <Popover.Target>
                                    <div className="w-full flex items-center justify-center">
                                        <CustomButton
                                            customStyles="mt-10"
                                            small
                                            title="Aggiungi Prenotazione"
                                            onClick={() => {}}
                                            enable
                                            isLoading={false}
                                        />
                                    </div>
                                </Popover.Target>
                                <PopoverDropdown>
                                    <div className="bg-black rounded-2xl w-auto flex items-center justify-center">
                                        <ReservationStepper isSmall isAdmin />
                                    </div>
                                </PopoverDropdown>
                            </Popover>
                        </div>
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div className={`w-full flex h-auto ${dimension? "flex-col items-center justify-center":""}`}>
                <h1 className="font-poppins-bold text-black text-3xl items-start p-5">Prenotazioni</h1>
                <div className={`w-full flex justify-end items-center gap-x-5  ${dimension? "flex-col items-center justify-center p-5 gap-y-3 h-auto":"h-full "}`}>
                    {props.isAdmin ? null : <div className={`w-full h-full  ${dimension?"flex flex-col items-center justify-center gap-y-2":"flex justify-end items-center gap-x-10 "}`}>
                        <Checkbox classNames={{label:'font-poppins-regular'}}
                            color={'#FFCC00'} checked={StatusEnum.PENDING === value.status} label="In Attesa"
                                  onChange={() => {
                                      setValue({...value, status: StatusEnum.PENDING})
                                  }}/>
                        <Checkbox classNames={{label:'font-poppins-regular'}}
                            color={'green'} checked={StatusEnum.ACCEPTED === value.status} label="Accettati"
                                  onChange={() => {
                                      setValue({...value, status: StatusEnum.ACCEPTED})
                                  }}/>
                        <Checkbox classNames={{label:'font-poppins-regular'}} color={'red'} checked={StatusEnum.REJECTED === value.status} label="Rifiutati"
                                  onChange={() => {
                                      setValue({...value, status: StatusEnum.REJECTED})

                                  }}/>
                        <Checkbox classNames={{label:'font-poppins-regular'}} color={'blue'} checked={StatusEnum.DONE === value.status} label="Completate"
                                  onChange={() => {
                                      setValue({...value, status: StatusEnum.DONE})
                                  }}/>
                    </div>}
                    {props.isAdmin ?
                        <NumberInput
                            style={{width:'auto'}}
                            hideControls={true}
                            allowDecimal={false}
                            classNames={{
                                input: 'w-full border-none bg-transparent text-black h-full font-poppins-bold items-center justify-center placeholder-gray-500',
                                wrapper: 'w-auto rounded-lg h-[60px] bg-yellow flex items-center justify-center  '
                            }}
                            value={reservationCode}
                            placeholder={'Inserisci codice prenotazione'}
                            onChange={(e) => {
                                setReservationCode(e.toString());
                                if (e.toString().length === 5) {
                                    checkReservation(e.toString(), (res) => {
                                        setReservationCode('');
                                        props.reload();
                                    });
                                }
                            }}
                        />
                        : null}

                    <div className={`w-auto h-full`}>
                        <Popover>
                            <Popover.Target>
                                <div
                                    className={'bg-yellow rounded-2xl py-2 px-5 hover:scale-105 duration-500 cursor-pointer'}>
                                    <h1 className={'font-poppins-semi-bold'}>Data</h1>
                                    <h1 className={'whitespace-nowrap font-poppins-semi-bold'}>
                                        {isCurrentDate(value.date)
                                            ? (props.isAdmin
                                                    ? new Date(value.date.getTime() -0 * 60 * 60 * 1000).toLocaleString()
                                                    : value.date.toLocaleString()
                                            )
                                            : setMidnight(value.date).toLocaleString()}
                                    </h1>

                                </div>
                            </Popover.Target>
                            <PopoverDropdown>
                                <div className={'bg-black'}>
                                    <Calendar value={value.date} onChange={(res) => {
                                        var date = new Date()
                                        date.setDate(res!.getDate())
                                        date.setMonth(res!.getMonth())
                                        setValue({...value, date: date})
                                    }}/>
                                </div>
                            </PopoverDropdown>
                        </Popover>

                    </div>
                    {props.isAdmin ?
                        <div className={'w-auto h-full'}>
                            <Popover closeOnClickOutside={false}>
                                <Popover.Target>
                                    <div>
                                        <CustomButton title={'Aggiungi Prenotazione'} onClick={() => {
                                        }} enable={true} isLoading={false}/>
                                    </div>
                                </Popover.Target>
                                <PopoverDropdown>
                                    <div className={'bg-black'}>
                                        <ReservationStepper  isAdmin={true}/>
                                    </div>
                                </PopoverDropdown>
                            </Popover>
                        </div> : null}
                </div>
            </div>
        )
    }
}
