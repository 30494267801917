import {ReservationResponseType} from "../types/ReservationResponseType";

export function addAlpha(color: string, opacity: number) {
    var _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}


export function getDimension(width: number, result: (res: boolean) => void) {

    const updateDimensions = () => {
        result(window.innerWidth < width)
    }
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);

}

export function getDimensionText(width: number, result: (res: boolean) => void) {

    const updateDimensions = () => {
        result(window.innerWidth < width)
    }
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);

}

export function calculatePosti(listPrenotazioni: ReservationResponseType[]) {
    var posti = 0
    listPrenotazioni.forEach(pt => {
        posti += pt.numberOfPeople
    })
    return posti
}

export function getSystemLanguage(key:string[]){
    if (typeof navigator !== 'undefined' && navigator.language) {
        return key.includes(navigator.language.split('-')[0])?navigator.language.split('-')[0]:'en'
    }
    return 'en'; // Valore predefinito se non è disponibile la lingua
};

