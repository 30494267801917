import {makeRequest} from "../RestAdapter";
import {HttpMethodsEnum} from "../HttpMethodsEnum";
import {showFailNotification} from "../../utils/NotificationUtils";
import {PersonalEnum} from "../../enums/PersonalEnum";
import {PersonalType} from "../../types/PersonalType";

export function getUserAdmin(restaurantId: string,role:PersonalEnum, onFinish: (response: PersonalType[]) => void) {
    makeRequest<PersonalType[]>(`/users/user-restaurant/all?restaurantId=${restaurantId}&role=${role}`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : [])
        } else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}


export function createTmpUser(
    role: string,
    restaurantId: string,
    idKitchen: string | null,
    onFinish: (response: any) => void
) {
    const requestBody: { role: string; restaurantId: string; idKitchen?: string } = {
        role: role,
        restaurantId: restaurantId,
    };

    // Aggiungiamo idKitchen solo se è presente
    if (idKitchen && role === PersonalEnum.KITCHEN) {
        requestBody.idKitchen = idKitchen;
    }

    makeRequest<any>('/users/user-restaurant', HttpMethodsEnum.POST, requestBody).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : {});
        } else {
            showFailNotification(
                'Errore',
                'Non è stato possibile creare l’utenza',
                3000
            );
            onFinish(null);
        }
    });
}

export function changeTmpUser(user: PersonalType, onFinish: (response: any) => void
) {
    const requestBody = {id: user.id, auth: user.auth, role: user.role, restaurantId: user.restaurantId, idKitchen: user.idKitchen, kitchenName: user.kitchenName};


    makeRequest<any>('/users/user-restaurant', HttpMethodsEnum.PUT, requestBody).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : {});
        } else {
            showFailNotification('Errore', 'Non è stato possibile aggiornare l`utenza.', 3000);
            onFinish(null);
        }
    });
}

export function deleteTMPUserById(id: string, onFinish: (success: boolean) => void) {
    makeRequest<any>(`/users/user-restaurant/${id}`, HttpMethodsEnum.DELETE).then((res) => {
            if (res.isSuccessfull()) {
                onFinish(true);
            } else {
                showFailNotification(
                    'Errore', 'Non è stato possibile eliminare l`utenza.', 3000
                );
                onFinish(false);
            }
        });
}


