import CustomTextInput from "../../components/generic/CustomTextInput";
import {NumberInput, Switch} from "@mantine/core";
import {CategoryType} from "../../types/CategoryType";
import CustomButton from "../../components/CustomButton";
import {addIngredient, updateIngredient} from "../../network/controllers/admin/IngredientsController";
import {useEffect, useState} from "react";
import {IngredientType} from "../../types/IngredientType";
import GenericMultiselect from "../../components/generic/GenericMultiselect";
import {getAllergeni} from "../../network/controllers/admin/AllergenController";
import {getAllCategories} from "../../network/controllers/admin/CategoryController";
import infoIcon from "../../assets/info-tooltip-icon.svg"
import GenericTooltip from "../../components/generic/GenericTooltip";
import {isMobile} from "react-device-detect";

export default function IngredientDrawerContent(props: {
    onIngredientChange: (ingredientList: IngredientType[]) => void,
    ingredient: IngredientType | null,
    data: any
}) {
    const [defaultCategory, setDefaultCategory] = useState<string[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [newIngredient, setNewIngredient] = useState<IngredientType>(() => {
        let selectedRestaurantId = null;
        try {
            const selectedRestaurant = localStorage.getItem('selectedRestaurant');
            if (selectedRestaurant) {
                selectedRestaurantId = JSON.parse(selectedRestaurant).id;
            }
        } catch (e) {
            console.error('Errore nel parsing di selectedRestaurant da localStorage:', e);
        }
        return props.ingredient ? props.ingredient : {
            addable: false,
            categories: [],
            idLocal: selectedRestaurantId,
            name: "",
            price: 0,
            quantity: 0,
            quantityPerServing: 0,
            allergenId: [],
            statusIngredient:"",
            position: new Map<string, number>()
        };
    });

    const [selectedAllergeni, setSelectedAllergeni] = useState<string[]>([]);
    const [allergenList, setAllergenList] = useState<{ label: any, value: string }[]>([]);
    const [categoryMap, setCategoryMap] = useState<Map<string, CategoryType>>();

    useEffect(() => {
        var tmpArr: any[] = [];
        if (props.ingredient) {
            props.ingredient.categories.forEach((category) => {
                tmpArr.push(category.id);
            });
        }
        setDefaultCategory(tmpArr);
    }, [props.ingredient]);

    useEffect(() => {
        getAllergeni((res) => {
            var tmpArr: any[] = [];
            res.map((allergen) => {
                tmpArr.push({value: allergen.id, label: allergen.name});
            });
            setAllergenList(tmpArr);
        });

        const selectedRestaurantId = newIngredient.idLocal;
        if (selectedRestaurantId) {
            getAllCategories(selectedRestaurantId, (response) => {
                var tmpMap: Map<string, CategoryType> = new Map();
                response.map((category) => {
                    tmpMap.set(category.id!, category);
                });
                setCategoryMap(tmpMap);
            });
        }
    }, [newIngredient.idLocal]);

    const handleNameChange = (value: string) => {
        const formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
        setNewIngredient({...newIngredient, name: formattedValue});
    };

    return (
        <div className={'w-full h-full  flex flex-col items-center justify-center gap-x-4'}>
            <h1 className={` ${isMobile? 'w-full h-auto flex items-center justify-center text-white text-xl py-5 ':'h-auto flex items-center justify-enter text-white text-xl p-12 font-poppins-bold'}`}>
                Aggiunta nuovo ingrediente</h1>
            <div className={'w-full h-full flex flex-col items-center justify-center'}>
                <div className={'w-full px-5'}>
                    <CustomTextInput customDimensions={'w-full h-[60px]'} label={'Nome Ingrediente :'}
                                     isBlack={true}
                                     defaultValue={newIngredient.name}
                                     onChange={(value) => handleNameChange(value)}/>
                </div>
                <div className={'w-full flex'}>
                    <div className={'w-full px-5'}>
                        <div className={'w-full mt-10'}>
                            {allergenList.length > 0 ?
                                <GenericMultiselect key={Math.random()}
                                                    searchable={true}
                                                    defaultValue={newIngredient.allergenId}
                                                    label={'Allergeni :'} data={allergenList} onSelect={(list) => {
                                    setNewIngredient({...newIngredient, allergenId: list});
                                }}/> : null}
                        </div>
                    </div>
                </div>
                <div className={'w-full flex flex-col gap-y-2 items-center justify-start'}>

                    <div className={'w-auto flex justify-between items-end'}>

                        <div className={'w-full px-5'}>
                            <NumberInput
                                allowDecimal={true}
                                hideControls={true}
                                radius="md"
                                variant={'unstyled'}
                                styles={{
                                    label: {padding: '5px', color: 'white'},
                                    input: {padding: '10px', height: 70, backgroundColor: '#F2F2F2A8'}
                                }}
                                defaultValue={props.ingredient ? newIngredient.quantity : 0}
                                label={'Disponibilità in Magazzino (kg, lt, pezzi) :'} onChange={(value) => {
                                setNewIngredient({...newIngredient, quantity: value as number});
                            }}/>
                        </div>
                        <div className={'w-full px-5'}>
                            <NumberInput
                                allowDecimal={true}
                                hideControls={true}
                                radius="md"
                                variant={'unstyled'}
                                styles={{
                                    label: {padding: '5px', color: 'white'},
                                    input: {padding: '10px', height: 70, backgroundColor: '#F2F2F2A8'}
                                }}
                                defaultValue={props.ingredient ? newIngredient.quantityPerServing : 0}
                                label={'Quantità media utilizzata per singolo piatto (kg , facoltativo) :'}
                                onChange={(value) => {
                                    setNewIngredient({...newIngredient, quantityPerServing: value as number});
                                }}/>
                        </div>
                    </div>
                    <div className={'flex flex-row w-full h-auto items-center justify-center gap-x-2 my-4'}>
                        <div>
                            <Switch checked={newIngredient.addable} styles={{label: {color: 'white'}}} color={'#FFCC00'}
                                    label={'Disponibile per aggiunta al piatto'} onChange={(value) => {
                                setNewIngredient({...newIngredient, addable: value.currentTarget.checked});
                            }}/>
                        </div>
                        <GenericTooltip
                            txt={'Questa opzione rende l`ingrediente disponibile per l`aggiunta al piatto. Seleziona il costo dell`aggiunta e le categorie per le quali vuoi renderla disponibile'}
                            innerHtml={
                                <img alt={''} src={infoIcon}/>
                            }/>
                    </div>
                </div>
                <div className={'w-full flex justify-between'}>
                    {newIngredient.addable && (
                        <div className={'w-full flex justify-between items-end gap-x-4'}>
                            <div className={'w-full bottom-2 pb-4 '}>
                                <NumberInput
                                    allowDecimal={true}
                                    hideControls={true}
                                    radius="md"
                                    variant={'unstyled'}
                                    styles={{
                                        label: {padding: '5px', color: 'white'},
                                        input: {padding: '10px', height: 45, backgroundColor: '#F2F2F2A8'}
                                    }}
                                    value={newIngredient.price}
                                    label={'Prezzo Aggiunta (€):'} onChange={(value) => {
                                    setNewIngredient({...newIngredient, price: value as number});
                                }}/>
                            </div>

                            <div className={'w-full h-auto bottom-2 pb-4'}>
                                {defaultCategory ?
                                    <GenericMultiselect
                                        label= {'Aggiunta disponibile per le categorie :'}
                                        searchable={true}
                                        defaultValue={defaultCategory.length > 0 ? defaultCategory : []}
                                        data={props.data} onSelect={(list) => {
                                        var tmpArr: CategoryType[] = [];
                                        list.forEach((item) => {
                                            if (categoryMap!.get(item)) {
                                                tmpArr.push(categoryMap!.get(item) as CategoryType);
                                            }
                                        });
                                        setNewIngredient({...newIngredient, categories: tmpArr});
                                    }}/> : null}
                            </div>
                        </div>
                    )}
                </div>
                <CustomButton
                    title={'Conferma'}
                    onClick={() => {
                        setIsLoading(true);
                        if (props.ingredient !== null) {
                            updateIngredient({
                                id: props.ingredient.id!,
                                ingredient: {...newIngredient, name: newIngredient.name}
                            }, (response) => {
                                if (response.length > 0) {
                                    props.onIngredientChange(response);
                                }
                                setIsLoading(false);
                            });
                        } else {
                            const updatedIngredient = {...newIngredient,
                                name: newIngredient.name,

                            };
                            addIngredient(updatedIngredient, (response) => {
                                if (response.length > 0) {
                                    props.onIngredientChange(response);
                                }
                                setIsLoading(false);
                            });
                        }
                    }}
                    enable={newIngredient.name.trim() !== ''}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
}
