import {Drawer, Grid} from "@mantine/core";
import CustomButton from "../components/CustomButton";
import {GoogleMap, InfoWindow, LoadScript, Marker, MarkerClusterer} from "@react-google-maps/api";
import {clusterIcons, getLocalMap} from "../network/controllers/MapContoller";
import pinMenu from "../assets/m1.webp";
import {useEffect, useRef, useState} from "react";
import {LocalType, Location, LocationMarker} from "../types/LocalType";
import {useDebounce} from "use-debounce";
import {useDisclosure} from "@mantine/hooks";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";
import '../css/InfoWindow.css'
import LocalListComponent from "../components/LocalListComponent";

var elementSelected: LocalType

export default function MapPage() {
    const [searchPosition, setSearchPosition] = useState<any>(null)
    const [value] = useDebounce(searchPosition, 500);
    const [markers, setMarkers] = useState<LocationMarker[]>([]);
    const [center, setCenter] = useState<Location>(JSON.parse(localStorage.getItem('geoData') as string) && JSON.parse(localStorage.getItem('geoData') as string).geometry?JSON.parse(localStorage.getItem('geoData') as string).geometry:{
        lat:38.142400,
        lng:15.218700,

    });
    const mapRef = useRef(null);
    const [opened, {open, close}] = useDisclosure(false);
    var navigate = useNavigate();
    const [reload, setReload] = useState(false)
    const [loadScript, setLoadScript] = useState<boolean>(false);


    useEffect(() => {
        if (searchPosition) {
            getLocalMap(searchPosition.south, searchPosition.west, searchPosition.north, searchPosition.east, (localList) => {
                var tmpMarker: LocationMarker[] = []
                localList.forEach(local => {
                    tmpMarker.push({location: local.location, restaurant: local, showTooltip: false})
                })
                setMarkers(tmpMarker)
            })
        }
    }, [value, reload]);

    const handleMouseOver = (index: number) => {
        const newMarkers = [...markers];
        newMarkers[index].showTooltip = true;
        setMarkers(newMarkers);
    }

    const handleMouseOut = (index: number) => {
        const newMarkers = [...markers];
        newMarkers[index].showTooltip = false;
        setMarkers(newMarkers);
    }

    function setInit() {
        // @ts-ignore
        if (mapRef.current.getBounds()) {
            // @ts-ignore
            setSearchPosition(JSON.parse(JSON.stringify(mapRef.current.getBounds())))
        } else {
            setTimeout(() => {
                setInit()
            }, 100)
        }
    }

    const options = {
        calculator: function (markers: any, numStyles: number) {
            return {
                text: '',
                index: Math.min(markers.length, numStyles),
                title: '',
                fontSize: "0" // Set your font size here
            };
        },
    }

    // Funzione per nascondere il pulsante di chiusura dell'InfoWindow
    const hideCloseButton = () => {
        const closeButtons = document.querySelectorAll('.gm-ui-hover-effect');
        closeButtons.forEach(button => {
            (button as HTMLElement).style.display = 'none';
        });
    };


    useEffect(() => {
        // Osservatore per monitorare i cambiamenti nel DOM e nascondere i pulsanti di chiusura
        const observer = new MutationObserver(() => {
            hideCloseButton();
        });

        // Configurazione dell'osservatore per monitorare l'apertura degli InfoWindow
        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });

        // Pulizia dell'osservatore quando il componente viene smontato
        return () => {
            observer.disconnect();
        };
    }, []);

    if (isMobile) {
        return (
            <div className="flex flex-col w-full h-auto overscroll-y-auto overflow-hidden">
                <div className="w-full h-screen">
                    <Drawer
                        position="bottom"
                        size={'350px'}
                        opened={opened}
                        onClose={close}
                        styles={{content: {backgroundColor: 'black'}}}
                        withCloseButton={false}>
                        {elementSelected && (
                            <div>
                                <div className="flex flex-col w-auto">
                                    <Grid className="w-auto h-full">
                                        <LocalListComponent isInMap={true}
                                                            reload={() => setReload(!reload)}
                                                            clickable={false}
                                                            local={elementSelected}
                                                            showTags={false}
                                        />
                                    </Grid>
                                </div>
                                <div className={'w-auto h-full flex items-center justify-center pt-6 '}>
                                    <CustomButton
                                        title={'Vai al dettaglio'}
                                        onClick={() => {
                                            localStorage.removeItem('selectedRestaurantClient');
                                            localStorage.setItem('selectedRestaurantClient', JSON.stringify(elementSelected));
                                            navigate(`/detail`);
                                        }}
                                        enable={true}
                                        isLoading={false}
                                        small={true}
                                    />
                                </div>
                            </div>
                        )}
                    </Drawer>

                    <LoadScript onLoad={() => {
                        setLoadScript(true)
                    }} googleMapsApiKey={process.env['REACT_APP_MAPS_KEY'] as string}>
                        {loadScript ? <GoogleMap
                            mapContainerClassName="w-full h-full"
                            mapContainerStyle={{height: '100%', width: '100%', padding: '10px'}}
                            zoom={15}
                            options={{
                                minZoom: 7,
                                fullscreenControl: false,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                zoomControl: false,
                                rotateControl: false,
                            }}
                            center={center}

                            onLoad={map => {
                                // @ts-ignore
                                mapRef.current = map;
                                setInit()
                                setTimeout(() => {
                                    dispatchEvent(new CustomEvent("loader", {detail: {isLoading: false}}));
                                }, 1000)


                            }}
                            onZoomChanged={() => {
                                if (mapRef.current) {
                                    // @ts-ignore
                                    setSearchPosition(JSON.parse(JSON.stringify(mapRef.current.getBounds())))
                                }
                            }}
                            onMouseUp={() => {
                                if (mapRef.current) {
                                    // @ts-ignore
                                    setSearchPosition(JSON.parse(JSON.stringify(mapRef.current.getBounds())))
                                }
                            }}
                        >
                            <MarkerClusterer styles={clusterIcons} options={options}>
                                {(clusterer) =>
                                    // @ts-ignore
                                    markers.map((local, index) => (
                                        <Marker
                                            onClick={() => {
                                                elementSelected = local.restaurant
                                                open()
                                            }}
                                            onMouseOver={() => {
                                                handleMouseOver(index)
                                            }}
                                            onMouseOut={() => {
                                                handleMouseOut(index)
                                            }}
                                            icon={pinMenu} key={index + local.restaurant.nameRestaurant}
                                            position={local.location} clusterer={clusterer}>
                                            {local.showTooltip && (
                                                <InfoWindow
                                                    options={{disableAutoPan: true}}
                                                    position={local.location} key={local.restaurant.nameRestaurant}>
                                                    <div
                                                        className={'font-poppins-semi-bold text-yellow'}>
                                                        {local.restaurant.nameRestaurant}
                                                    </div>
                                                </InfoWindow>
                                            )}
                                        </Marker>
                                    ))
                                }
                            </MarkerClusterer>
                        </GoogleMap> : null}
                    </LoadScript>
                </div>
            </div>
        )
    } else {
        return (
            <div className="flex flex-row w-full h-screen">
                <div className="w-full h-full">
                    <Drawer
                        position="bottom"
                        size={'380px'}
                        opened={opened}
                        onClose={close}
                        styles={{content: {backgroundColor: 'black', overflow: 'hidden'}}}
                        withCloseButton={false}>
                        {elementSelected && (
                            <div className="flex flex-row w-full h-full bg-black justify-center items-center">
                                <div className="flex flex-row flex-1">
                                    <div className="w-full">
                                        <Grid className="w-full h-full" style={{transform: 'scale(1.0)'}}>
                                            <LocalListComponent isInMap={true}
                                                                reload={() => setReload(!reload)}
                                                                clickable={false}
                                                                local={elementSelected}
                                                                showTags={true}
                                            />
                                        </Grid>
                                    </div>
                                    <div
                                        className=" right-10 top-3/4  absolute items-center flex flex-col justify-between">
                                        <CustomButton
                                            title={'Vai al dettaglio'}
                                            onClick={() => {
                                                localStorage.removeItem('selectedRestaurantClient');
                                                localStorage.setItem('selectedRestaurantClient', JSON.stringify(elementSelected));
                                                navigate(`/detail`);
                                            }}
                                            enable={true}
                                            isLoading={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </Drawer>

                    <LoadScript onLoad={() => {
                        setLoadScript(true)
                    }} googleMapsApiKey="AIzaSyDe8XjD2lGk6YeJEYcfaakoyoPzi-zkk3U">
                        {loadScript ? <GoogleMap
                            mapContainerClassName="w-full h-full"
                            mapContainerStyle={{height: '100%', width: '100%', padding: '10px'}}
                            zoom={15}

                            options={{
                                minZoom: 7,
                                fullscreenControl: false,
                                mapTypeControl: false,
                                mapTypeId:'roadmap',
                                scaleControl: false,
                                streetViewControl: false,
                                zoomControl: false,
                                rotateControl: false,
                                styles:[{
                                    featureType: 'poi',
                                    elementType: 'labels',
                                    stylers: [{ visibility: 'off' }]
                                }]

                            }}
                            center={center}

                            onLoad={map => {
                                // @ts-ignore
                                mapRef.current = map;
                                setInit()
                                setTimeout(() => {
                                    dispatchEvent(new CustomEvent("loader", {detail: {isLoading: false}}));
                                }, 1000)


                            }}
                            onZoomChanged={() => {
                                if (mapRef.current) {
                                    // @ts-ignore
                                    setSearchPosition(JSON.parse(JSON.stringify(mapRef.current.getBounds())))
                                }
                            }}
                            onMouseUp={() => {
                                if (mapRef.current) {
                                    // @ts-ignore
                                    setSearchPosition(JSON.parse(JSON.stringify(mapRef.current.getBounds())))
                                }
                            }}
                        >
                            <MarkerClusterer styles={clusterIcons} options={options}>
                                {(clusterer) =>
                                    // @ts-ignore
                                    markers.map((local, index) => (
                                        <Marker
                                            onClick={() => {
                                                elementSelected = local.restaurant
                                                open()
                                            }}
                                            onMouseOver={() => {
                                                handleMouseOver(index)
                                            }}
                                            onMouseOut={() => {
                                                handleMouseOut(index)
                                            }}
                                            icon={pinMenu} key={index + local.restaurant.nameRestaurant}
                                            position={local.location} clusterer={clusterer}>
                                            {local.showTooltip && (
                                                <InfoWindow
                                                    options={{disableAutoPan: true}}
                                                    position={local.location} key={local.restaurant.nameRestaurant}>
                                                    <div className={'font-poppins-semi-bold text-yellow mb-3'}>
                                                        <p>
                                                        {local.restaurant.nameRestaurant}
                                                        </p>
                                                    </div>
                                                </InfoWindow>
                                            )}
                                        </Marker>
                                    ))
                                }
                            </MarkerClusterer>
                        </GoogleMap> : null}
                    </LoadScript>
                </div>
            </div>
        )
    }
}
