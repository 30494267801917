import {PrinterShopType, PrinterType, Sector, SectorType} from "../../types/PrinterType";
import {makeRequest} from "../RestAdapter";
import {HttpMethodsEnum} from "../HttpMethodsEnum";
import {showFailNotification} from "../../utils/NotificationUtils";

export function getPrintersByUser(idUsers: string, onFinish: (response: PrinterType[]) => void) {
    makeRequest<PrinterType[]>(`/users/printers/get-printers-by-user/${idUsers}`, HttpMethodsEnum.GET)
        .then((res) => {
            if (res.isSuccessfull()) {
                onFinish(res.data?res.data:[]);
            } else {
                onFinish([])
                showFailNotification('Attenzione', 'Nessuna stampante trovata', 3000);
            }
        })
        .catch((error) => {
            console.error('Errore nella richiesta:', error);
            showFailNotification('Errore', 'Errore durante la richiesta delle stampanti', 3000);
        });
}

export function getSectorsByIdUser(id: string, onFinish: (response: SectorType[]) => void) {
    makeRequest<SectorType[]>(`/users/printers/getSector?idUser=${id}`, HttpMethodsEnum.GET)
        .then((res) => {
            if (res.data) {
                onFinish(res.data);
            } else {
                showFailNotification('Attenzione', 'Nessun settore trovato', 3000);
            }
        })
        .catch((error) => {
            console.error('Errore nella richiesta:', error);
            showFailNotification('Errore', 'Errore durante la richiesta dei settori', 3000);
        });
}


export function getAllPrintersShop(onFinish: (response: PrinterShopType[]) => void) {
    makeRequest<PrinterShopType[]>(`/users/printerShop/all`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : [])
        } else {
            onFinish([])
        }
    })
}

export function updatePrinter(imei: string, printerName: string, sector: string[], onFinish: (response: any) => void) {
    makeRequest<any>(`/users/printers/update`, HttpMethodsEnum.PUT, {
        imei,
        printerName,
        sector
    })
        .then((res) => {
            if (res.data) {
                onFinish(res.data);
            } else {
                showFailNotification('Attenzione', 'Aggiornamento stampante non riuscito', 3000);
            }
        })
        .catch((error) => {
            console.error('Errore nell`aggiornamento della stampante:', error);
            showFailNotification('Errore', 'Errore durante l`aggiornamento della stampante', 3000);
        });
}

export function getAllOrderbyUser(idUsers: string,onFinish: (response: any[]) => void) {
    makeRequest<any[]>(`/users/shop/${idUsers}`, HttpMethodsEnum.GET)
        .then((res) => {
            if (res.isSuccessfull()) {
                onFinish(res.data ? res.data : []);
            } else {
                onFinish([]);
                showFailNotification('Attenzione', 'Nessun elemento trovato', 3000);
            }
        })
        .catch((error) => {
            console.error('Errore nella richiesta:', error);
            showFailNotification('Errore', 'Errore durante il recupero dei dati', 3000);
        });
}

