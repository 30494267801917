import {useState} from "react";

import ReactApexChart from "react-apexcharts";
import {LiveDataType} from "../../types/LiveDataType";

export default function HallLiveStatPage(props: { liveData: LiveDataType }) {
    const {liveData} = props;

    const itemsPerPage = 10;
    const [tablePage, setTablePage] = useState(0);
    const [revenuePage, setRevenuePage] = useState(0);
    const [ingredientPage, setIngredientPage] = useState(0);
    const [prepTimePage, setPrepTimePage] = useState(0);

    const getPagedData = (data: any[], page: number) => {
        const startIndex = page * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data.slice(startIndex, endIndex);
    };

    const pagedTables = getPagedData(liveData.nPlatesTables || [], tablePage);
    const pagedRevenues = getPagedData(liveData.revenueForPlate || [], revenuePage);
    const pagedIngredients = getPagedData(liveData.ingredientsScore || [], ingredientPage);

    const totalTablePages = Math.ceil((liveData.nPlatesTables?.length || 0) / itemsPerPage);
    const totalRevenuePages = Math.ceil((liveData.revenueForPlate?.length || 0) / itemsPerPage);
    const totalIngredientPages = Math.ceil((liveData.ingredientsScore?.length || 0) / itemsPerPage);

    const pagedPrepTime = getPagedData(liveData.meanPreparationTimePerTurn || [], prepTimePage);
    const totalPrepTimePages = Math.ceil((liveData.meanPreparationTimePerTurn?.length || 0) / itemsPerPage);


    const [reservationPage, setReservationPage] = useState(0); // Stato della pagina corrente per le prenotazioni

    // Funzione per convertire la fascia oraria (stringa) in un oggetto `Date`
    const convertTimeBandToDate = (timeBand: string) => {
        const [hours, minutes] = timeBand.split(":").map(Number);
        const date = new Date();
        date.setHours(hours, minutes, 0, 0); // Imposta ore e minuti
        return date;
    };

    // Filtrare le fasce orarie antecedenti a 2 ore dal tempo attuale
    const now = new Date();
    const filteredReservationsByTimeSlots = (liveData.reservationsByTimeSlots || []).filter((slot) => {
        const slotTime = convertTimeBandToDate(slot.band);
        return slotTime >= new Date(now.getTime() - 2 * 60 * 60 * 1000); // Tempo corrente - 2 ore
    });

    // Dati paginati per le fasce orarie filtrate
    const totalReservationPages = Math.ceil(filteredReservationsByTimeSlots.length / itemsPerPage);
    const pagedReservations = getPagedData(filteredReservationsByTimeSlots, reservationPage);


    const handleNextTablePage = () => {
        if (tablePage < totalTablePages - 1) setTablePage(tablePage + 1);
    };
    const handlePreviousTablePage = () => {
        if (tablePage > 0) setTablePage(tablePage - 1);
    };
    const handleNextRevenuePage = () => {
        if (revenuePage < totalRevenuePages - 1) setRevenuePage(revenuePage + 1);
    };
    const handlePreviousRevenuePage = () => {
        if (revenuePage > 0) setRevenuePage(revenuePage - 1);
    };
    const handleNextIngredientPage = () => {
        if (ingredientPage < totalIngredientPages - 1) setIngredientPage(ingredientPage + 1);
    };
    const handlePreviousIngredientPage = () => {
        if (ingredientPage > 0) setIngredientPage(ingredientPage - 1);
    };
    const handleNextReservationPage = () => {
        if (reservationPage < totalReservationPages - 1) setReservationPage(reservationPage + 1);
    };

    const handlePreviousReservationPage = () => {
        if (reservationPage > 0) setReservationPage(reservationPage - 1);
    };
    const handleNextPrepTimePage = () => {
        if (prepTimePage < totalPrepTimePages - 1) setPrepTimePage(prepTimePage + 1);
    };
    const handlePreviousPrepTimePage = () => {
        if (prepTimePage > 0) setPrepTimePage(prepTimePage - 1);
    };


    return (
        <div className="w-full h-full p-4">
            {/* Prima Row */}
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4 mb-8">
                {/* Riquadro con i dati sintetici */}
                <div className="bg-black p-4 flex flex-col gap-y-5 rounded-xl shadow col-span-1 ">

                    <div className="w-full flex justify-between">
                        <p className="font-poppins-bold text-md text-white">Tavoli Occupati</p>
                        <p className="text-white font-poppins-regular">{liveData.occupiedTables}</p>
                    </div>
                    <div className="w-full flex justify-between">
                        <p className="font-poppins-bold text-white">Media Piatti per Tavolo</p>
                        <p className="font-poppins-regular text-white">
                            {liveData.meanPlatesTables.toFixed(2)}
                        </p>
                    </div>
                    <div className="w-full flex justify-between">
                        <p className="font-poppins-bold text-white">Tempo Medio di Occupazione</p>
                        <p className="font-poppins-regular text-white">{liveData.meanOccupiedTimeTables}</p>
                    </div>
                    <div className="w-full flex justify-between">
                        <p className="font-poppins-bold text-white">Totale Persone</p>
                        <p className="font-poppins-regular text-white">{liveData.totalPeoples}</p>
                    </div>
                    <div className="w-full flex justify-between">
                        <p className="font-poppins-bold text-white">Spesa Media per Tavolo</p>
                        <p className="font-poppins-regular text-white">
                            €
                            {(
                                (liveData.meanExpensePersonTable?.reduce(
                                    (sum, table) => sum + table.pricePerCapitaNoDiscount,
                                    0
                                ) || 0) / (liveData.meanExpensePersonTable?.length || 1)
                            ).toFixed(2)}
                        </p>
                    </div>
                </div>


                {/* Piatti per Tavolo */}
                <div className="bg-black p-4 rounded-xl shadow sm:col-span-1 md:col-span-2 lg:col-span-3">
                    {totalTablePages > 1 && (
                        <div className="flex justify-between mb-4">
                            <button
                                onClick={handlePreviousTablePage}
                                disabled={tablePage === 0}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8592;
                            </button>
                            <button
                                onClick={handleNextTablePage}
                                disabled={tablePage === totalTablePages - 1}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8594;
                            </button>
                        </div>
                    )}
                    <ReactApexChart
                        options={{
                            chart: {type: "bar", background: "#000", toolbar: {show: false}},
                            xaxis: {
                                categories: pagedTables.map((table) => table.tableName),
                                labels: {style: {colors: "#FFF"}},
                            },
                            yaxis: {labels: {style: {colors: "#FFF"}}},
                            colors: ["#FFCC00"],
                            title: {
                                text: "Piatti per Tavolo",
                                align: "center",
                                style: {color: "#FFF"},
                            },
                            grid: {borderColor: "#fff"},
                            tooltip: {theme: "dark"},
                            legend: {labels: {colors: "#FFF"}},
                        }}
                        series={[
                            {
                                name: "Numero di Piatti",
                                data: pagedTables.map((table) => table.numberOfItems),
                            },
                        ]}
                        type="bar"
                        height={300}
                    />
                </div>

                {/* Piatti per Turno */}
                <div className="bg-black p-4 rounded-xl shadow sm:col-span-1 md:col-span-2 lg:col-span-2">
                    <ReactApexChart
                        options={{
                            chart: {type: "pie", background: "#000", toolbar: {show: false}},
                            labels: liveData.platesForTurn ? liveData.platesForTurn.map((turn) => turn.nameTurn) : [],
                            colors: liveData.platesForTurn ? liveData.platesForTurn.map((turn) => turn.color) : [],
                            title: {
                                text: "Piatti per Turno",
                                align: "center",
                                style: {color: "#FFF"},
                            },
                            dataLabels: {enabled: false},
                            tooltip: {theme: "dark"},
                            legend: {labels: {colors: "#FFF"}},
                        }}
                        series={liveData.platesForTurn ? liveData.platesForTurn.map((turn) => turn.quantity) : []}
                        type="pie"
                        height={300}
                    />
                </div>
            </div>

            {/* Seconda Row */}
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4 mb-8">
                {/* Classifica Piatti */}
                <div
                    className="bg-black p-4 rounded-xl shadow sm:col-span-1 md:col-span-1 lg:col-span-1 h-[350px] overflow-y-auto">
                    <p className="text-white text-xl font-bold">Classifica Piatti</p>
                    <ul className="text-white">
                        {liveData.revenueForPlate ? liveData.revenueForPlate.map((plate) => (
                            <li
                                key={plate._id}
                                className="mb-2 flex justify-between items-center"
                            >
                                <span>{plate.nameProduct}</span>
                                <span
                                    className="text-right">€{Number(parseFloat(plate.totalPrice.toString()).toFixed(2))}</span>
                            </li>
                        )) : null}
                    </ul>
                </div>

                {/* Dettaglio Incasso */}
                <div className="bg-black p-4 rounded-xl shadow sm:col-span-1 md:col-span-1 lg:col-span-2">
                    {totalRevenuePages > 1 && (
                        <div className="flex justify-between mb-4">
                            <button
                                onClick={handlePreviousRevenuePage}
                                disabled={revenuePage === 0}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8592;
                            </button>
                            <button
                                onClick={handleNextRevenuePage}
                                disabled={revenuePage === totalRevenuePages - 1}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8594;
                            </button>
                        </div>
                    )}
                    <ReactApexChart
                        options={{
                            chart: {type: "bar", background: "#000", toolbar: {show: false}},
                            xaxis: {
                                categories: pagedRevenues.map((plate) => plate.nameProduct),
                                labels: {style: {colors: "#FFF"}},
                            },
                            yaxis: {labels: {style: {colors: "#FFF"}}},
                            colors: ["#FFCC00"],
                            title: {
                                text: "Dettaglio Incasso per Piatto",
                                align: "center",
                                style: {color: "#FFF"},
                            },
                            grid: {borderColor: "#fff"},
                            tooltip: {theme: "dark"},
                            legend: {labels: {colors: "#FFF"}},
                        }}
                        series={[
                            {
                                name: "Incasso",
                                data: pagedRevenues.map((plate) =>
                                    Number(parseFloat(plate.totalPrice.toString()).toFixed(2))
                                ),
                            },
                        ]}
                        type="bar"
                        height={300}
                    />
                </div>

                {/* Incasso per Turno */}
                <div className="bg-black p-4 rounded-xl shadow sm:col-span-1 md:col-span-1 lg:col-span-3">
                    <ReactApexChart
                        options={{
                            chart: {type: "bar", background: "#000", toolbar: {show: false}},
                            xaxis: {
                                categories: liveData.meanExpensePerTurn ? liveData.meanExpensePerTurn.map((turn) => turn.nameTurn) : [],
                                labels: {style: {colors: "#FFF"}},
                            },
                            yaxis: {labels: {style: {colors: "#FFF"}}},
                            title: {
                                text: "Incasso per Turno",
                                align: "center",
                                style: {color: "#FFF"},
                            },
                            grid: {borderColor: "#fff"},
                            tooltip: {theme: "dark"},
                            legend: {
                                show: true,
                                position: "bottom",
                                labels: {colors: "#FFF"},
                            },
                            colors: ["#FFCC00", "#00FFFF"],
                        }}
                        series={[
                            {
                                name: "Guadagno Medio",
                                data: liveData.meanExpensePerTurn ? liveData.meanExpensePerTurn.map((turn) =>
                                    Number(parseFloat(turn.totalPriceMean.toString()).toFixed(2))
                                ) : [],
                            },
                            {
                                name: "Guadagno Massimo",
                                data: liveData.meanExpensePerTurn ? liveData.meanExpensePerTurn.map((turn) =>
                                    Number(parseFloat(turn.totalPriceMax.toString()).toFixed(2))
                                ) : [],
                            },
                        ]}
                        type="bar"
                        height={300}
                    />
                </div>
            </div>
            {/* Terza Row */}
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4 mb-8">
                {/* Percentuale Prenotazioni */}
                <div className="bg-black p-4 rounded-xl shadow sm:col-span-1 md:col-span-2 lg:col-span-2">
                    <ReactApexChart
                        options={{
                            chart: {type: "pie", background: "#000", toolbar: {show: false}},
                            labels: ["Completate", "In Attesa"],
                            colors: ["#00FF00", "#FF0000"],
                            title: {
                                text: "Percentuale delle Prenotazioni",
                                align: "center",
                                style: {color: "#FFF"},
                            },
                            dataLabels: {enabled: true},
                            tooltip: {theme: "dark"},
                            legend: {labels: {colors: "#FFF"}},
                        }}
                        series={[
                            liveData.totalCompletedReservations,
                            liveData.totalPendingReservations,
                        ]}
                        type="pie"
                        height={300}
                    />
                </div>

                {/* Box: Numero Totale Prenotazioni e Persone Prenotate */}
                <div
                    className="bg-black p-4 flex flex-col justify-center rounded-xl shadow sm:col-span-1 md:col-span-1 lg:col-span-1">
                    <div className="w-full flex justify-between">
                        <p className="text-md font-poppins-bold text-center">Totale Prenotazioni</p>
                        <p className="text-md font-poppins-bold text-center">{liveData.totalReservations}</p>
                    </div>
                    <div className="h-[1px] bg-gray-400 my-4"></div>

                    <div className="w-full flex justify-between">
                        <p className="text-md font-poppins-bold text-center">Totale Persone Prenotate</p>
                        <p className="text-md font-poppins-bold text-center">{liveData.totalPeopleReservations}</p>
                    </div>
                </div>
                {/* Prenotazioni per Fascia Oraria con Paginazione */}
                <div className="bg-black p-4 rounded-xl shadow sm:col-span-1 md:col-span-3 lg:col-span-3">
                    {totalReservationPages > 1 && (
                        <div className="flex justify-between mb-4">
                            <button
                                onClick={handlePreviousReservationPage}
                                disabled={reservationPage === 0}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8592;
                            </button>
                            <button
                                onClick={handleNextReservationPage}
                                disabled={reservationPage === totalReservationPages - 1}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8594;
                            </button>
                        </div>
                    )}
                    <ReactApexChart
                        options={{
                            chart: {type: "bar", background: "#000", toolbar: {show: false}},
                            xaxis: {
                                categories: pagedReservations.map((slot) => slot.band), // Fasce orarie della pagina corrente
                                labels: {style: {colors: "#FFF"}},
                            },
                            yaxis: {
                                labels: {style: {colors: "#FFF"}},
                                title: {
                                    text: "Numero Prenotazioni",
                                    style: {color: "#FFF"},
                                },
                            },
                            colors: ["#FFA500", "#32CD32", "#1E90FF", "#FF6347"],
                            title: {
                                text: "Prenotazioni per Fascia Oraria",
                                align: "center",
                                style: {color: "#FFF"},
                            },
                            tooltip: {theme: "dark"},
                            legend: {
                                show: true,
                                position: "bottom",
                                labels: {colors: ["#FFF"]},
                            },
                            grid: {borderColor: "#fff"},
                        }}
                        series={[
                            {
                                name: "In Attesa",
                                data: pagedReservations.map((slot) => slot.pendingCount),
                            },
                            {
                                name: "Accettate",
                                data: pagedReservations.map((slot) => slot.acceptedCount),
                            },
                            {
                                name: "Completate",
                                data: pagedReservations.map((slot) => slot.doneCount),
                            },
                            {
                                name: "Rifiutate",
                                data: pagedReservations.map((slot) => slot.rejectedCount),
                            },
                        ]}
                        type="bar"
                        height={300}
                    />
                </div>


            </div>

            {/* Quarta Row: Ingredienti */}
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4 mb-8">
                <div className="bg-black p-4 rounded-xl shadow sm:col-span-6 lg:col-span-6">
                    {/* Controlla se i dati sono disponibili e se ci sono più pagine */}
                    {pagedIngredients?.length > 0 && totalIngredientPages > 1 && (
                        <div className="flex justify-between mb-4">
                            <button
                                onClick={handlePreviousIngredientPage}
                                disabled={ingredientPage === 0}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8592;
                            </button>
                            <button
                                onClick={handleNextIngredientPage}
                                disabled={ingredientPage === totalIngredientPages - 1}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8594;
                            </button>
                        </div>
                    )}
                    {/* Verifica se ci sono dati per gli ingredienti */}
                    {pagedIngredients?.length > 0 ? (
                        <ReactApexChart
                            options={{
                                chart: { type: "bar", background: "#000", toolbar: { show: false } },
                                xaxis: {
                                    categories: pagedIngredients.map((ingredient) => ingredient.name || "N/A"),
                                    labels: { style: { colors: "#FFF" } },
                                },
                                yaxis: { labels: { style: { colors: "#FFF" } } },
                                colors: ["#00FF00", "#FF0000"],
                                title: {
                                    text: "Ingredienti Aggiunti e Rimossi",
                                    align: "center",
                                    style: { color: "#FFF" },
                                },
                                grid: { borderColor: "#fff" },
                                tooltip: { theme: "dark" },
                                legend: { labels: { colors: "#FFF" } },
                            }}
                            series={[
                                {
                                    name: "Aggiunti",
                                    data: pagedIngredients.map((ingredient) => ingredient.addedCount || 0),
                                },
                                {
                                    name: "Rimossi",
                                    data: pagedIngredients.map((ingredient) => ingredient.removedCount || 0),
                                },
                            ]}
                            type="bar"
                            height={300}
                        />
                    ) : (
                        // Messaggio di fallback se non ci sono dati
                        <p className="text-white text-center">Nessun dato disponibile sugli ingredienti</p>
                    )}
                </div>
            </div>


            {/* Ultima Row: Piatti per Cucina */}
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4 mb-8">
                {/* Distribuzione Piatti per Cucina */}
                <div className="bg-black p-4 rounded-xl shadow sm:col-span-6 lg:col-span-2">
                    <ReactApexChart
                        options={{
                            chart: {
                                type: "pie",
                                background: "#000",
                                toolbar: {show: false},
                            },
                            labels: liveData.platesForKitchen
                                ? liveData.platesForKitchen.map((kitchen) => kitchen.nameKitchen)
                                : [],
                            colors: liveData.platesForKitchen
                                ? liveData.platesForKitchen.map((kitchen) => kitchen.color)
                                : [],
                            title: {
                                text: "Distribuzione Piatti per Cucina",
                                align: "center",
                                style: {color: "#FFF"},
                            },
                            dataLabels: {enabled: false},
                            tooltip: {theme: "dark"},
                            legend: {labels: {colors: "#FFF"}},
                        }}
                        series={liveData.platesForKitchen
                            ? liveData.platesForKitchen.map((kitchen) => kitchen.quantity)
                            : []}
                        type="pie"
                        height={300}
                    />
                </div>

                <div className="bg-black p-4 rounded-xl shadow sm:col-span-6 lg:col-span-4">
                    {totalPrepTimePages > 1 && (
                        <div className="flex justify-between mb-4">
                            <button
                                onClick={handlePreviousPrepTimePage}
                                disabled={prepTimePage === 0}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8592;
                            </button>
                            <button
                                onClick={handleNextPrepTimePage}
                                disabled={prepTimePage === totalPrepTimePages - 1}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8594;
                            </button>
                        </div>
                    )}
                    <ReactApexChart
                        options={{
                            chart: {
                                type: "bar",
                                background: "#000",
                                toolbar: {show: false},
                            },
                            xaxis: {
                                categories: pagedPrepTime.map((turn) => turn.nameTurn), //Sull'asse x metto i dati puri in modo da avere la formattazione 00:00:00
                                labels: {style: {colors: "#FFF"}},
                            },
                            yaxis: {
                                labels: {
                                    style: {colors: "#FFF"},
                                    formatter: (value) => {
                                        // Converto i valori numerici in formato "HH:mm:ss"
                                        const hours = Math.floor(value / 3600);
                                        const minutes = Math.floor((value % 3600) / 60);
                                        const seconds = Math.floor(value % 60);
                                        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
                                    },
                                },
                                title: {
                                    text: "Durata (HH:mm:ss)",
                                    style: {color: "#FFF"},
                                },
                            },
                            colors: ["#FFCC00"],
                            title: {
                                text: "Tempi Medi di Preparazione per Turno (hh:mm:ss)",
                                align: "center",
                                style: {color: "#FFF"},
                            },
                            grid: {borderColor: "#fff"},
                            tooltip: {
                                theme: "dark",
                                y: {
                                    formatter: (value) => {
                                        //Formatto il Tooltip nel formato "HH:mm:ss"
                                        const hours = Math.floor(value / 3600);
                                        const minutes = Math.floor((value % 3600) / 60);
                                        const seconds = Math.floor(value % 60);
                                        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
                                    },
                                },
                            },
                            legend: {labels: {colors: "#FFF"}},
                            dataLabels: {
                                enabled: true,
                                formatter: (_, {dataPointIndex}) =>
                                    pagedPrepTime[dataPointIndex].duration, // Mostra la durata originale sopra le colonne
                                style: {
                                    colors: ["#FFF"],
                                },
                            },
                        }}
                        series={[
                            {
                                name: "Durata",
                                data: pagedPrepTime.map((turn) => {
                                    const [hours, minutes, seconds] = turn.duration.split(":").map(Number);
                                    return hours * 3600 + minutes * 60 + seconds; // Converto in secondi il dato da valutare per la generazione della colonna
                                }),
                            },
                        ]}
                        type="bar"
                        height={300}
                    />
                </div>


            </div>

        </div>
    );
}
