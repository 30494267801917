import CustomButton from '../components/CustomButton';
import CustomTextInput from './generic/CustomTextInput';
import { useState } from 'react';
import { UpdatePswType } from '../types/UpdatePswType';
import { changeTmpPass } from "../network/controllers/LoginApiController";
import { Buffer } from "buffer";
import { showFailNotification, showSuccessNotification } from "../utils/NotificationUtils";
import { LoginResponseType } from "../types/LoginResponseType";
import { isMobile } from "react-device-detect";

export default function PasswordDrawerComponent() {
    const [updatePswData, setUpdatePswData] = useState<UpdatePswType>({
        id: "",
        newAuth: ""
    });

    const [oldPassword, setOldPassword] = useState<string>('');
    const [changePassData, setChangePassData] = useState({ pass: '', confirmPass: '' });
    const [loading, setLoading] = useState<boolean>(false);
    const userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType;

    // Funzione di validazione della password
    const validatePassword = (password: string): boolean => {
        const hasUpperCase = /[A-Z]/.test(password);  // Controllo per almeno una lettera maiuscola
        const hasMinLength = password.length >= 8;    // Esempio: lunghezza minima di 8 caratteri
        // Puoi aggiungere altre regole di validazione qui
        return hasUpperCase && hasMinLength;
    };

    const handlePasswordChange = () => {
        setLoading(true);
        const auth = Buffer.from(userData.email + ':' + Buffer.from(changePassData.confirmPass).toString('base64')).toString('base64');
        changeTmpPass({ id: userData.id, newAuth: auth }, (response) => {
            setLoading(false);
            if (response.status === 200) {
                showSuccessNotification('Password', 'Modificata con successo', 3000);
            } else {
                showFailNotification('Errore', response.data.message, 3000);
            }
        });
    };

    const isPasswordValid = changePassData.pass === changePassData.confirmPass &&
        validatePassword(changePassData.confirmPass) &&
        oldPassword !== '';

    if (isMobile) {
        return (
            <div className='w-full h-full flex flex-col justify-center items-center'>
                <div className='flex flex-col h-full w-full items-center gap-y-2 px-8'>
                    <div>
                        <h1 className='text-white font-poppins-bold text-3xl item-center mb-10'>Cambio password</h1>
                    </div>

                    <div className='flex flex-col w-full h-auto justify-center gap-y-10 mt-5'>
                        <CustomTextInput customDimensions={'h-[60px]'}
                                         label='Vecchia password'
                                         isPassword
                                         labelColor={'#FFFFFF'}
                                         defaultValue={oldPassword}
                                         onChange={(value) => setOldPassword(value)} />
                        <CustomTextInput customDimensions={'h-[60px]'}
                                         label='Nuova password'
                                         labelColor={'#FFFFFF'}
                                         isPassword
                                         defaultValue={changePassData.pass}
                                         onChange={(value) => setChangePassData({ ...changePassData, pass: value })} />
                        <div>
                            <CustomTextInput customDimensions={'h-[60px]'}
                                             label='Conferma password'
                                             isPassword
                                             labelColor={'#FFFFFF'}
                                             defaultValue={changePassData.confirmPass}
                                             onChange={(value) => setChangePassData({ ...changePassData, confirmPass: value })} />
                        </div>
                    </div>

                    <div className='mt-24'>
                        <CustomButton title='Salva'
                                      onClick={handlePasswordChange}
                                      enable={isPasswordValid}
                                      isLoading={loading} />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='w-fullh-full flex flex-col justify-center items-center'>
                <div className='flex flex-col h-full w-auto items-center gap-y-2'>
                    <div>
                        <h1 className='text-white font-poppins-bold text-3xl item-center'>Cambio password</h1>
                    </div>

                    <div className='flex flex-col w-[500px] h-auto justify-center gap-y-10 mt-5'>
                        <CustomTextInput customDimensions={'h-[60px]'}
                                         label='Vecchia password'
                                         isPassword
                                         defaultValue={oldPassword}
                                         labelColor={'#FFFFFF'}
                                         onChange={(value) => setOldPassword(value)} />
                        <CustomTextInput customDimensions={'h-[60px]'}
                                         label='Nuova password'
                                         isPassword
                                         labelColor={'#FFFFFF'}
                                         defaultValue={changePassData.pass}
                                         onChange={(value) => setChangePassData({ ...changePassData, pass: value })} />
                        <div>
                            <CustomTextInput customDimensions={'h-[60px]'}
                                             label='Conferma password'
                                             isPassword
                                             labelColor={'#FFFFFF'}
                                             defaultValue={changePassData.confirmPass}
                                             onChange={(value) => setChangePassData({ ...changePassData, confirmPass: value })} />
                        </div>
                    </div>

                    <div className='mt-24'>
                        <CustomButton small={true}
                                      title='Salva'
                                      onClick={handlePasswordChange}
                                      enable={isPasswordValid}
                                      isLoading={loading} />
                    </div>
                </div>
            </div>
        );
    }
}
