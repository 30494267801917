import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ReservationResponseType } from "../types/ReservationResponseType";
import React, { useEffect, useState } from "react";
import { Accordion, Grid } from "@mantine/core";
import { calculatePosti } from "../utils/Utility";
import ReservationCardComponentAdmin from "./reservation/ReservationCardComponentAdmin";
import ReservationCardComponentClient from "./reservation/ReservationCardComponentClient";

dayjs.extend(utc);

export default function AccordionReservation(props: {
    isAdmin: boolean,
    dataReservation: Map<string, ReservationResponseType[]>,
    reload?: () => void
}) {
    const [defaultOpen, setDefaultOpen] = useState<string | null>(null);

    useEffect(() => {
        setDefaultOpen(Array.from(props.dataReservation.keys()).sort()[0]);
    }, [props.dataReservation]);

    return (
        <div className={"min-w-full max-w-[800px] h-auto"}>
            {props.dataReservation && defaultOpen ? (
                <Accordion defaultValue={defaultOpen} className={"w-full"}>
                    {Array.from(props.dataReservation.keys()).sort().map((date) => (
                        <Accordion.Item key={date} value={date}>
                            <Accordion.Control>
                                <div className={"w-full flex flex-col"}>

                                    <h1 className={"font-poppins-semi-bold"}>
                                        {dayjs.utc(date).format("DD/MM/YYYY HH:mm")}
                                    </h1>

                                    {props.isAdmin && (
                                        <div>
                                            <h1 className={"font-poppins-semi-bold"}>
                                                {`Prenotazioni totali: ${props.dataReservation.get(date)!.length}`}
                                            </h1>
                                            <h1 className={"font-poppins-semi-bold"}>
                                                {`Posti totali: ${calculatePosti(props.dataReservation.get(date)!)}`}
                                            </h1>
                                        </div>
                                    )}
                                </div>
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Grid>
                                    {props.dataReservation.get(date)?.map((reservation) => (
                                        <Grid.Col
                                            key={reservation.id}
                                            span={{
                                                base: 12,
                                                md: props.isAdmin ? 12 : 6,
                                                lg: props.isAdmin ? 12 : 4,
                                                xl: props.isAdmin ? 12 : 3,
                                            }}
                                        >
                                            {props.isAdmin ? (
                                                <ReservationCardComponentAdmin
                                                    reservation={reservation}
                                                    client={false}
                                                    onCallFinish={() => {
                                                        if (props.reload) {
                                                            props.reload();
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                <ReservationCardComponentClient
                                                    reload={() => {
                                                        if (props.reload) {
                                                            props.reload();
                                                        }
                                                    }}
                                                    reservation={reservation}
                                                />
                                            )}
                                        </Grid.Col>
                                    ))}
                                </Grid>
                            </Accordion.Panel>
                        </Accordion.Item>
                    ))}
                </Accordion>
            ) : null}
        </div>
    );
}
