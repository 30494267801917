import RatingComponent from "../components/RatingComponent";
import iconGroupDetail from "../assets/icon-group-detail.svg";
import iconPositionDetail from "../assets/position-icon-detail.svg";
import iconPriceDetail from "../assets/icon-price-detail.svg";
import GenericImg from "../components/generic/GenericImg";
import InfoDetailPage from "./InfoDetailPage";
import iconInfoDetail from "../assets/info-detail-icon.svg";
import iconEdit from '../assets/edit-icon.svg'
import React, {useEffect, useState} from "react";
import iconPrice from "../assets/icona_euro.svg";
import iconConfirm from "../assets/icon-v.svg";
import iconDelete from "../assets/delete-icon.svg";
import {BackgroundImage, Textarea, TextInput} from "@mantine/core";
import DrawerCropComponent from "../components/generic/cropper/DrawerCropComponent";
import {LocalType} from "../types/LocalType";
import {updateDescription, updateImage, updateTYpe} from "../network/controllers/RestaurantsControllers";
import {StatusEnum} from "../enums/StatusEnum";
import confirmIcon from '../assets/confirm-icon.svg'
import {showSuccessNotification} from "../utils/NotificationUtils";
import {isMobile} from "react-device-detect";
import CustomTextInput from "../components/generic/CustomTextInput";
import iconPhone from "../assets/phoneIcon.svg";
import {getDimension, getDimensionText} from "../utils/Utility";


var originalPhoto = ''
var originalStatus: StatusEnum = StatusEnum.PENDING

export default function MyLocalPage() {

    const [local, setLocal] = useState(JSON.parse(localStorage.getItem('selectedRestaurant') as string) as LocalType)
    const [dimension, setDimension] = useState(window.innerWidth < 1280)
    useEffect(() => {
        getDimension(1280, (res)=>{
            console.log(res);
            setDimension(res)
        })
    },[])


    var [arrayIcon, setArrayIcon] = useState<string[]>([]);
    var [typeLocal, setTypeLocal] = useState<string>(local.typeLocal);
    var [descriptionLocal, setDescriptionLocal] = useState<string>(local.description);
    useEffect(() => {
        var array = []
        for (let i = 1; i <= local.priceLevel; i++) {
            array.push(iconPrice)
        }
        originalPhoto = local.photoPath
        originalStatus = local.approvalStatus
        setArrayIcon(array)
    }, []);

    useEffect(() => {
        console.log('ajxuykghjn.sicjowlhgsedkuylihk',local)
    }, [local]);


    if (isMobile) {
        return (
            <div className={"w-full h-full flex flex-col pt-[130px]"}>
                <div className={'w-full h-auto pt-2'}>
                    <div className={'h-auto w-full justify-between'}>
                        <div className={'w-full flex justify-between'}>
                            {local.approvalStatus === StatusEnum.ACCEPTED ?
                                <h1 className={'w-full text-xs font-poppins-semi-bold text-green-700 ml-5'}>Immagine Approvata,
                                    disponibile
                                    online .</h1> : null}
                            {local.approvalStatus === StatusEnum.REJECTED ?
                                <h1 className={'w-full text-xs font-poppins-semi-bold text-red-700 ml-5'}>Immagine rifiutata, non
                                    disponibile online . </h1> : null}
                            {local.approvalStatus === StatusEnum.PENDING ?
                                <h1 className={'w-full text-xs font-poppins-semi-bold text-yellow ml-5'}>Immagine in attesa di
                                    valutazione,
                                    non disponibile online .</h1> : null}
                            <div className={'flex items-center'}>
                                <DrawerCropComponent onCroppedFinish={(res) => {
                                    setLocal({...local, approvalStatus: StatusEnum.PENDING, photoPath: res})
                                }} aspect={9 / 4} contentHtml={
                                    <div
                                        className={'w-6 h-6 bg-yellow rounded-full hover:scale-125 duration-500 cursor-pointer'}>
                                        <img alt={''} className={'w-full h-full p-2'} src={iconEdit}/>
                                    </div>
                                }/>
                                {local.photoPath !== originalPhoto ? <div
                                    className={'w-6 h-6 bg-yellow rounded-full ml-2 hover:scale-125 duration-500 cursor-pointer'}
                                    onClick={() => {
                                        updateImage(local.photoPath, local.id, (res) => {
                                            localStorage.setItem('selectedRestaurant', JSON.stringify(res))
                                            window.location.reload()
                                        })
                                    }}>
                                    <img alt={''}  className={'w-full h-full p-2 '} src={iconConfirm}/>
                                </div> : null}
                                {local.photoPath !== originalPhoto ? <div
                                    className={'w-6 h-6 bg-yellow rounded-full ml-2 hover:scale-125 duration-500 cursor-pointer'}
                                    onClick={() => {
                                        setLocal({...local, approvalStatus: originalStatus, photoPath: originalPhoto})
                                    }}>
                                    <img alt={''} className={'w-full h-full p-2 '} src={iconDelete}/>
                                </div> : null}
                            </div>
                        </div>

                        <BackgroundImage radius='md' src={local.photoPath}
                                         className={'w-full bg-contain bg-no-repeat h-[250px]'}/>

                    </div>

                    <div className={'w-full h-auto flex flex-col'}>
                        <div className={'w-full h-auto flex flex-col '}>
                            <div className={'h-full w-full flex items-center justify-center '}>
                                <h1 className="w-full text-center text-3xl mb-3  px-4 font-poppins-bold overflow-hidden mt-2">{local.nameRestaurant}</h1>
                            </div>
                            <div className="w-full h-full flex flex-col  gap-y-4">
                                <div className={'w-full flex items-center justify-center mb-2'}>
                                    <RatingComponent size={'md'} value={local.rating} readonly={true}
                                                     onChangeValue={() => {
                                                     }}/>
                                    <h1 className={` ${local.openingInfo.isOpen ? 'text-green-600 border-green-600' : 'text-red-600 border-red-600'} font-poppins-bold ml-5  px-2 py-1 `}>{local.openingInfo.isOpen ? `Aperto. ${local.openingInfo.closingAt}` : `Chiuso. ${local.openingInfo.nextOpen?`Apre ${local.openingInfo.nextOpen}`:''}`}</h1>
                                </div>
                                <div className={'w-auto h-full flex flex-col '}>

                                    <div className={'w-full flex-col h-auto flex gap-y-3'}>
                                        <h1 className={'w-full font-poppins-semi-bold text-xs mb-1 '}>Inserisci il tuo tipo di attività. Separa le tipologie usando - (es. Ristorante-Pizzeria...)</h1>
                                        <div className={'h-auto w-auto flex flex-row '}>
                                            <img alt={''} className={'w-8 h-8'} src={iconGroupDetail}/>
                                            <TextInput
                                                rightSection={
                                                    <div onClick={() => {
                                                        updateTYpe(typeLocal || '', local.id, (res) => {
                                                            showSuccessNotification('Aggiornamento', 'Cambio avvenuto con successo', 3000)
                                                            localStorage.setItem('selectedRestaurant', JSON.stringify(res))
                                                        })
                                                    }}>
                                                        <img alt={''} src={confirmIcon}/>
                                                    </div>
                                                }
                                                className="text-sm ml-2 font-poppins-bold whitespace-nowrap truncate w-full"
                                                defaultValue={typeLocal}
                                                placeholder={'Ristorante-Pizzeria-Sala Ricevimenti'}
                                                onChange={(e) => {
                                                    setTypeLocal(e.currentTarget.value || '')
                                                }}
                                            />


                                        </div>
                                        <div className={'flex items-center'}>
                                            <img alt={''} className={'w-8 h-8'} src={iconPositionDetail}/>
                                            <h1 className="text-sm ml-2 font-poppins-semi-bold whitespace-normal ">{`${local.address}, ${local?.civicNumber}, ${local?.zipCode}, ${local?.city}, ${local?.state}`}</h1>
                                        </div>
                                        <div className={'flex items-center'}>
                                            <img alt={''} className={'w-8 h-8'} src={iconPhone}/>
                                            <h1 className="text-sm ml-2 font-poppins-semi-bold whitespace-nowrap text-ellipsis truncate ">{`${local.phoneNumber}`}</h1>
                                        </div>
                                        <div className={'flex items-center'}>
                                            <img alt={''} className={'w-8 h-8'} src={iconPriceDetail}/>
                                            <div className="flex gap-x-1 ml-2">
                                                {arrayIcon.map((ite) => (
                                                    <GenericImg key={Math.random()} className="w-4 h-4 invert"
                                                                src={ite}/>
                                                ))}
                                            </div>
                                        </div>

                                    </div>


                                    <div className={'w-full h-full mt-4 '}>
                                        <h1 className={'font-poppins-semi-bold text-xs'}>Inserisci la descrizione della tua attività</h1>
                                        <Textarea onKeyPress={(e) => {
                                            if (e.code === 'Enter') {
                                                e.preventDefault()
                                            }
                                        }}
                                            autosize={true} minRows={6} maxRows={6}  defaultValue={descriptionLocal}
                                                  rightSection={<div className={'w-5 h-5 cursor-pointer'} onClick={() => {
                                                      updateDescription(descriptionLocal, local.id, (res) => {
                                                          showSuccessNotification('Aggiornamento', 'Cambio avvenuto con successo', 3000)
                                                          localStorage.setItem('selectedRestaurant', JSON.stringify(res))
                                                      })}}><img alt={''} src={confirmIcon}/>
                                                  </div>} onChange={(e) => {
                                            setDescriptionLocal(e.currentTarget.value)
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={'w-full h-full'}>
                    <div className={'w-full h-auto flex items-center justify-center gap-x-6 mt-10'}>
                        <div className={`flex flex-col items-center`} onClick={() => {
                        }}>
                            <img alt={''} className={'w-20'} src={iconInfoDetail}/>
                            <h1 className={`text-md ml-2 text-amber-400 h-auto mt-2 font-poppins-bold `}>Info</h1>
                        </div>
                    </div>
                    <div className={'mt-10'}>
                        <InfoDetailPage adminpage={true} local={local}/>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={`w-full h-full flex overflow-x-hidden pt-[130px] ${dimension? "flex-col":""} `}>
                <div className={`w-[60%] h-full pt-2  ${dimension? "w-full":""} `}>
                    <div className={'h-[60%] w-full justify-between'}>
                        <div className={'w-full flex justify-between pb-3'}>
                            {local.approvalStatus === StatusEnum.ACCEPTED ?
                                <h1 className={'font-poppins-semi-bold text-green-700 ml-5'}>Immagine Approvata,
                                    disponibile
                                    online .</h1> : null}
                            {local.approvalStatus === StatusEnum.REJECTED ?
                                <h1 className={'font-poppins-semi-bold text-red-700 ml-5'}>Immagine rifiutata, non
                                    disponibile online . </h1> : null}
                            {local.approvalStatus === StatusEnum.PENDING ?
                                <h1 className={'font-poppins-semi-bold text-yellow ml-5'}>Immagine in attesa di
                                    valutazione,
                                    non disponibile online .</h1> : null}
                            <div className={'flex items-center'}>
                                <DrawerCropComponent onCroppedFinish={(res) => {
                                    setLocal({...local, approvalStatus: StatusEnum.PENDING, photoPath: res})
                                }} aspect={9 / 4} contentHtml={
                                    <div
                                        className={'w-8 h-8 bg-yellow rounded-full hover:scale-125 duration-500 cursor-pointer'}>
                                        <img alt={''} className={'w-full h-full p-2'} src={iconEdit}/>
                                    </div>
                                }/>
                                {local.photoPath !== originalPhoto ? <div
                                    className={'w-8 h-8 bg-yellow rounded-full ml-2 hover:scale-125 duration-500 cursor-pointer'}
                                    onClick={() => {
                                        updateImage(local.photoPath, local.id, (res) => {
                                            localStorage.setItem('selectedRestaurant', JSON.stringify(res))
                                            window.location.reload()
                                        })
                                    }}>
                                    <img alt={''} className={'w-full h-full p-2 '} src={iconConfirm}/>
                                </div> : null}
                                {local.photoPath !== originalPhoto ? <div
                                    className={'w-8 h-8 bg-yellow rounded-full ml-2 hover:scale-125 duration-500 cursor-pointer'}
                                    onClick={() => {
                                        setLocal({...local, approvalStatus: originalStatus, photoPath: originalPhoto})
                                    }}>
                                    <img alt={''} className={'w-full h-full p-2 '} src={iconDelete}/>
                                </div> : null}
                            </div>
                        </div>

                        <BackgroundImage radius='md' src={local.photoPath}
                                         className={'w-full bg-contain bg-no-repeat h-full'}/>

                    </div>

                    <div className={'w-full h-auto flex flex-col pt-5 '}>
                        <div className={'w-full h-auto flex flex-col '}>
                            <div className={'h-full w-auto pl-5'}>
                                <h1 className="w-full text-[3rem] font-poppins-bold whitespace-nowrap text-ellipsis overflow-hidden mt-5">{local.nameRestaurant}</h1>
                            </div>

                            <div className="w-full h-full flex flex-col pl-5 gap-y-4">
                                <div className={'flex'}>
                                    <RatingComponent size={'lg'} value={local.rating} readonly={true}
                                                     onChangeValue={() => {
                                                     }}/>
                                    <h1 className={` ${local.openingInfo.isOpen ? 'text-green-600 border-green-600' : 'text-red-600 border-red-600'} font-poppins-bold ml-5  px-2 py-1 `}>{local.openingInfo.isOpen ? `Aperto. ${local.openingInfo.closingAt}` : `Chiuso. ${local.openingInfo.nextOpen?`Apre ${local.openingInfo.nextOpen}`:''}`}</h1>
                                </div>
                                <div className={'w-auto h-full flex flex-col '}>

                                    <div className={'w-auto flex-col h-auto flex gap-y-3'}>

                                        <h1 className={'font-poppins-semi-bold text-xs '}>Inserisci il tuo tipo di
                                            attività.
                                            Separa le tipologie usando - (es. Ristorante-Pizzeria...)</h1>

                                        <div className={'h-auto w-auto flex flex-row pr-5'}>

                                            <img alt={''} className={'w-8 h-8'} src={iconGroupDetail}/>

                                            <TextInput
                                                rightSection={
                                                    <div onClick={() => {
                                                        updateTYpe(typeLocal || '', local.id, (res) => {
                                                            showSuccessNotification('Aggiornamento', 'Cambio avvenuto con successo', 3000)
                                                            localStorage.setItem('selectedRestaurant', JSON.stringify(res))
                                                        })
                                                    }}>
                                                        <img alt={''} src={confirmIcon}/>
                                                    </div>
                                                }
                                                className="text-sm ml-2 font-poppins-bold whitespace-nowrap truncate w-full"
                                                defaultValue={typeLocal}
                                                placeholder={'Ristorante-Pizzeria-Sala Ricevimenti'}
                                                onChange={(e) => {
                                                    setTypeLocal(e.currentTarget.value || '')
                                                }}
                                            />


                                        </div>
                                        <div className={'flex items-center'}>
                                            <img alt={''} className={'w-8 h-8'} src={iconPositionDetail}/>
                                            <h1 className="text-sm ml-2 font-poppins-semi-bold whitespace-nowrap text-ellipsis truncate ">{`${local.address}, ${local?.civicNumber}, ${local?.zipCode}, ${local?.city}, ${local?.state}`}</h1>
                                        </div>
                                        <div className={'flex items-center'}>
                                            <img alt={''} className={'w-8 h-8'} src={iconPhone}/>
                                            <h1 className="text-sm ml-2 font-poppins-semi-bold whitespace-nowrap text-ellipsis truncate ">{`${local.phoneNumber}`}</h1>
                                        </div>
                                        <div className={'flex items-center'}>
                                            <img alt={''} className={'w-8 h-8'} src={iconPriceDetail}/>
                                            <div className="flex gap-x-1 ml-2">
                                                {arrayIcon.map((ite) => (
                                                    <GenericImg key={Math.random()} className="w-4 h-4 invert"
                                                                src={ite}/>
                                                ))}
                                            </div>
                                        </div>

                                    </div>


                                    <div className={'w-full h-full mt-3 px-5 flex  '}>
                                        <Textarea onKeyPress={(e) => {
                                            if (e.code === 'Enter') {
                                                e.preventDefault();
                                            }
                                        }} defaultValue={descriptionLocal}
                                                  className={'w-full h-auto font-poppins-semi-bold text-justify'}
                                                  rightSection={<div onClick={() => {
                                                      updateDescription(descriptionLocal, local.id, (res) => {
                                                          showSuccessNotification('Aggiornamento', 'Cambio avvenuto con successo', 3000)
                                                          localStorage.setItem('selectedRestaurant', JSON.stringify(res))
                                                      })
                                                  }}><img src={confirmIcon}/>

                                                  </div>} onChange={(e) => {

                                            setDescriptionLocal(e.currentTarget.value)

                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={`${dimension? "w-full h-auto mt-60 ":"w-[40%] h-full "}`}>
                    <div className={'w-full h-[15%] flex items-center justify-center gap-x-6 mt-10 '}>
                        <div className={`flex flex-col items-center`} onClick={() => {
                        }}>
                            <img alt={''} className={'w-32'} src={iconInfoDetail}/>
                            <h1 className={`text-lg ml-2 text-amber-400 h-auto mt-2 font-poppins-bold `}>Info</h1>
                        </div>
                    </div>

                    <div className={'w-auto h-auto p-5'}>
                        <InfoDetailPage adminpage={true} local={local}/>
                    </div>
                </div>

            </div>
        )
    }

}
