import CustomTextInput from "../../components/generic/CustomTextInput";
import CustomButton from "../../components/CustomButton";
import {useState} from "react";
import {KitchenType} from "../../types/KitchenType";
import {createKitchen} from "../../network/controllers/admin/KitchenController";

export default function KitchenDrawerComponent(props: { kitchenList: (kitchenList: KitchenType[]) => void }) {
    const [kitchenName, setKitchenName] = useState<string>('');
    return (
        <div className={'w-full h-full py-20 flex flex-col gap-y-5 items-center justify-center '}>
            <CustomTextInput label={'Nome Cucina'} labelColor={'#FFFFFF'} defaultValue={kitchenName}
                             onChange={(value) =>
                                 setKitchenName(value)
                             }/>
            <div className={'w-full flex flex-col gap-y-2 items-center justify-start'}>
                <CustomButton title={'Conferma'} onClick={() => {
                    createKitchen({
                        idLocal: JSON.parse(localStorage.getItem('selectedRestaurant') as string).id,
                        kitchenName: kitchenName
                    }, (response) => (
                        props.kitchenList(response)))
                }} enable={true} isLoading={false}/>
            </div>
        </div>)
}
