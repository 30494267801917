import React from "react";
import placeholderCategory from "../../assets/placeolder-categoria.svg";
import { CategoryType } from "../../types/CategoryType";
import { StatusEnum } from "../../enums/StatusEnum";

export default function CategoryCommandItemDrawer({
                                                      isSelected = false, // Valore di default per isSelected
                                                      category,
                                                      onClick,
                                                  }: {
    isSelected?: boolean;
    category: CategoryType;
    onClick: (category: CategoryType) => void;
}) {
    return (
        <div
            className="w-auto h-full flex flex-col items-center cursor-pointer"
            onClick={() => onClick(category)}
        >
            <img
                alt={category.categoryName || "Categoria"}
                className={`w-[200px] h-full rounded-[30px] border-[2px] p-0.5 ${
                    isSelected ? "border-yellow-400 shadow-lg" : "border-black"
                }`}
                src={
                    category.approvalStatus === StatusEnum.ACCEPTED && category.image
                        ? category.image
                        : placeholderCategory
                }
                onError={(e) => (e.currentTarget.src = placeholderCategory)}
            />
            <div className="w-full flex justify-center items-center py-1">
                <p className="text-white font-poppins-bold truncate">{category.categoryName || "Senza Nome"}</p>
            </div>
        </div>
    );
}
