import React, {useState, useEffect} from "react";
import QrCategoryDetailComponent from "../../components/QrCategoryDetailComponent";
import ProductDetailComponent from "../../components/ProductDetailComponent";
import {isMobile} from "react-device-detect";
import {getInfoLocal} from "../../network/controllers/admin/CategoryController";
import {LocalType} from "../../types/LocalType";
import Game from "../Game";


export default function  QrWebMenuPage () {
    const [contentMenu, setContentMenu] = useState<React.ReactNode>(null);
    const [local, setLocal] = useState<LocalType>()
    const queryParameters = new URLSearchParams(window.location.search)
    const data = queryParameters.get("idLocal")
    useEffect(()=>{
        getInfoLocal(atob(data!), (local)=>{
            setLocal(local)
            const categoryComponent = (
                <QrCategoryDetailComponent local={local} categorySelected={(category) => {
                    setContentMenu(
                        <ProductDetailComponent local={local} categorySelected={category}
                                                onBack={() => setContentMenu(categoryComponent)}/>
                    );
                }}
                />
            );
            setContentMenu(categoryComponent);
        })
    }, [])


    if (isMobile) {

        return <div className={" flex flex-col w-full h-full items-center"}>
            {local?<img alt={''} className={'w-full'} src={local!.photoPath}/>:null}
            {contentMenu}

        </div>;
    } else {
        return <div className={'w-full h-full mt-5 px-5 '}>
            {local?<img alt={''} className={'w-full'} src={local.photoPath}/>:null}{contentMenu}
        </div>;
    }
};


