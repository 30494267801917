import React, { useEffect, useState } from "react";
import { Divider } from "@mantine/core";
import confirm from "../assets/confirm-icon.svg";
import closeIcon from "../assets/close-icon.svg";
import editIcon from "../assets/edit-icon.svg";
import deleteIcon from "../assets/delete-icon.svg";
import loadingIcon from "../assets/loader-white.svg";
import { isMobile } from "react-device-detect";
import { showSuccessNotification } from "../utils/NotificationUtils";
import CustomButton from "../components/CustomButton";
import {
    createTimeBand,
    deleteTimeBand,
    getTimeBandsByRestaurant,
    TimeBandType, updateTimeBand
} from "../network/controllers/TableBandController";
import {TimeInput} from "@mantine/dates";


export default function TimeSlotsAdminPage() {
    const [addTimeSlot, setAddTimeSlot] = useState(false);
    const [newTimeSlot, setNewTimeSlot] = useState<string>(""); // Orario da aggiungere
    const [timeSlotsList, setTimeSlotsList] = useState<TimeBandType[]>([]); // Lista degli orari
    const [isLoading, setIsLoading] = useState(false); // Gestione del caricamento

    const [editIndex, setEditIndex] = useState<number | null>(null); // Per tracciare quale orario è in modifica


    const restaurantId = JSON.parse(localStorage.getItem("selectedRestaurant") as string)?.id;

    // Funzione per validare l'orario
    const validateTime = (time: string) => {
        const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/; // HH:MM formato 24 ore
        return timeRegex.test(time);
    };

    // Funzione per caricare gli orari dal backend
    useEffect(() => {
        if (!restaurantId) {
            console.error("ID del ristorante non trovato nel localStorage.");
            return;
        }

        setIsLoading(true);
        getTimeBandsByRestaurant(restaurantId, (response) => {
            if (response) {
                setTimeSlotsList(response);
            }
            setIsLoading(false);
        });
    }, [restaurantId]);

    const handleAddTimeSlot = () => {
        if (!newTimeSlot || !validateTime(newTimeSlot)) {
            alert("Inserisci un orario valido (HH:MM).");
            return;
        }

        setIsLoading(true);
        const newTimeBand: TimeBandType = {
            restaurant_id: restaurantId,
            band: newTimeSlot,
        };

        createTimeBand(newTimeBand, (response) => {
            if (response) {
                setTimeSlotsList([...timeSlotsList, response]); // Aggiungi il nuovo orario
                showSuccessNotification("Orario", "Orario aggiunto con successo", 2000);
            }
            setNewTimeSlot("");
            setAddTimeSlot(false);
            setIsLoading(false);
        });
    };

    const handleDeleteTimeSlot = (index: number) => {
        const timeSlotToDelete = timeSlotsList[index];
        setIsLoading(true);
        deleteTimeBand(timeSlotToDelete.id!, (isDeleted) => {
            if (isDeleted) {
                const updatedList = [...timeSlotsList];
                updatedList.splice(index, 1); // Rimuove l'orario
                setTimeSlotsList(updatedList);
                showSuccessNotification("Orario", "Orario eliminato con successo", 2000);
            }
            setIsLoading(false);
        });
    };

    const handleEditTimeSlot = (index: number) => {
        setEditIndex(index); // Attiva la modalità modifica
        setNewTimeSlot(timeSlotsList[index].band); // Precompila l'input con l'orario corrente
    };

    const handleUpdateTimeSlot = () => {
        if (!newTimeSlot || !validateTime(newTimeSlot)) {
            alert("Inserisci un orario valido (HH:MM).");
            return;
        }

        if (editIndex !== null) {
            const timeSlotToEdit = timeSlotsList[editIndex];
            setIsLoading(true);

            const updatedTimeBand: TimeBandType = {
                ...timeSlotToEdit,
                band: newTimeSlot,
            };

            updateTimeBand(timeSlotToEdit.id!, updatedTimeBand, (response) => {
                if (response) {
                    const updatedList = [...timeSlotsList];
                    updatedList[editIndex] = response; // Aggiorna l'orario modificato
                    setTimeSlotsList(updatedList);
                    showSuccessNotification("Orario", "Orario modificato con successo", 2000);
                }
                setEditIndex(null);
                setNewTimeSlot("");
                setIsLoading(false);
            });
        }
    };

    return (
        <div className={`w-full h-full flex-col ${isMobile ? "pt-32" : "px-10 pt-44"}`}>
            {/* Header */}
            <div className={"w-full flex justify-between items-center"}>
                <h1 className={"w-auto text-3xl font-poppins-bold my-5"}>Fasce Orarie</h1>
                <CustomButton
                    small={true}
                    title={!addTimeSlot ? "Aggiungi Orario" : "Annulla"}
                    onClick={() => {
                        setAddTimeSlot(!addTimeSlot);
                        setEditIndex(null);
                        setNewTimeSlot("");
                    }}
                    enable={true}
                    isLoading={false}
                />
            </div>

            {/* Input per aggiungere o modificare un orario */}
            {(addTimeSlot || editIndex !== null) && (
                <div className={"w-full flex my-10 items-center"}>
                    <TimeInput
                        value={newTimeSlot}
                        onChange={(e) => {
                            setNewTimeSlot(e.target.value)
                        }}
                        placeholder="HH:MM"
                        className="mr-5 p-2 border border-gray-300 rounded w-40"
                    />
                    <div className={"w-[100px] flex gap-x-3"}>
                        <img
                            alt={"Conferma"}
                            className={"cursor-pointer w-6 h-6"}
                            src={isLoading ? loadingIcon : confirm}
                            onClick={editIndex !== null ? handleUpdateTimeSlot : handleAddTimeSlot}
                        />
                        <img
                            className={"cursor-pointer w-6 h-6"}
                            alt={"Annulla"}
                            src={isLoading ? loadingIcon : closeIcon}
                            onClick={() => {
                                setAddTimeSlot(false);
                                setEditIndex(null);
                                setNewTimeSlot("");
                            }}
                        />
                    </div>
                </div>
            )}

            {/* Lista degli orari disponibili */}
            {timeSlotsList.length > 0 ? (
                timeSlotsList.map((slot, index) => (
                    <div className={`py-5 bg-opacity-90 rounded-md px-2`} key={slot.id}>
                        <div className={"flex justify-between items-center"}>
                            <p className={"text-lg font-poppins-regular"}>{`Orario: ${slot.band}`}</p>
                            <div className="flex gap-x-3">
                                <img
                                    alt={"Modifica"}
                                    className={"cursor-pointer w-6 h-6"}
                                    src={editIcon}
                                    onClick={() => handleEditTimeSlot(index)}
                                />
                                <img
                                    alt={"Elimina"}
                                    className={"cursor-pointer w-6 h-6"}
                                    src={deleteIcon}
                                    onClick={() => handleDeleteTimeSlot(index)}
                                />
                            </div>
                        </div>
                        <Divider color={"yellow"} />
                    </div>
                ))
            ) : (
                <p className="text-center mt-5">Nessun orario disponibile.</p>
            )}
        </div>
    );
}
