import {CommandTableOrderType} from "../../../types/CommandTableOrderType";
import {HttpMethodsEnum} from "../../HttpMethodsEnum";
import {makeRequest} from "../../RestAdapter";

export function getOrder(
    params: { idLocal: string; idTable: string; tableName: string },
    onFinish: (response: CommandTableOrderType[] | null) => void
) {
    const {idLocal, idTable, tableName} = params;
    makeRequest<CommandTableOrderType[]>(`/orders/order?idLocal=${idLocal}&idTable=${idTable}&tableName=${encodeURIComponent(tableName)}&role=WAITER`,
        HttpMethodsEnum.GET
    )
        .then((res) => {

            if (res.isSuccessfull() && res.data) {
                onFinish(res.data);
            } else {
                onFinish(null);
            }
        })
        .catch((error) => {
            console.error("Errore durante la chiamata getOrder:", error);
            onFinish(null);
        });
}

export function updateOrder(
    orderData: CommandTableOrderType,
    onFinish: (response: CommandTableOrderType | null) => void
) {
    makeRequest<CommandTableOrderType>(`/orders/order/update`, HttpMethodsEnum.PUT, orderData)
        .then((res) => {
            if (res.isSuccessfull() && res.data) {
                onFinish(res.data);
            } else {
                onFinish(null);
            }
        })
        .catch((error) => {
            console.error("Errore durante la chiamata updateOrder:", error);
            onFinish(null);
        });
}




export function printOrder(idOrder: string, onFinish: (response: any | null) => void) {
    makeRequest<any>(`/orders/order/print-order/${idOrder}`, HttpMethodsEnum.GET)
        .then((res) => {
            if (res.isSuccessfull() && res.data) {
                onFinish(res.data);
            } else {
                onFinish(null);
            }
        })
        .catch((error) => {
            console.error("Errore durante la chiamata printOrder:", error);
            onFinish(null);
        });
}


export function closeTable(orderData: CommandTableOrderType, onFinish: (response: CommandTableOrderType | null) => void) {
    makeRequest<CommandTableOrderType>(`/orders/order/close`, HttpMethodsEnum.PUT, orderData)
        .then((res) => {
            if (res.isSuccessfull() && res.data) {
                onFinish(res.data);
            } else {
                onFinish(null);
            }
        })
        .catch((error) => {
            console.error("Errore durante la chiamata updateOrder:", error);
            onFinish(null);
        });
}



export function openTable(params: { idLocal: string; idTable: string; tableName: string, numberCovered:string}, onFinish: (response: CommandTableOrderType | null) => void) {
    const { idLocal, idTable, tableName, numberCovered} = params;
    const url = `/orders/order/openTableWeiter?idLocal=${idLocal}&idTable=${idTable}&tableName=${encodeURIComponent(tableName)}&numberCovered=${0}`;
    makeRequest<CommandTableOrderType>(url, HttpMethodsEnum.POST)
        .then((res) => {
            if (res.data) {
                console.log("Tavolo aperto con successo:", res.data);
                onFinish(res.data);
            } else {
                console.warn("Risposta vuota ricevuta da openTable.");
                onFinish(null);
            }
        })
        .catch((error) => {
            console.error("Errore durante la chiamata openTable:", error);
            onFinish(null);
        });
}


