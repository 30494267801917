import React, {useEffect, useState, useCallback} from "react";
import {Input, Tooltip} from "@mantine/core";
import CustomButton from "../../components/CustomButton";
import {showSuccessNotification} from "../../utils/NotificationUtils";
import confirm from "../../assets/confirm-icon.svg";
import closeIcon from "../../assets/close-icon.svg";
import loadingIcon from "../../assets/loader-white.svg";
import qrImage from "../../assets/qr-icon.svg";
import {
    createMenu,
    getMenu,
    getMenuQr,
    updateCoverage,
    updateMenu
} from "../../network/controllers/admin/MenuController";
import MenuAdminTable from "./tables/MenuAdminTable";
import {useNavigate} from "react-router-dom";
import {MenuType} from "../../types/CategoryType";
import {isMobile} from "react-device-detect";
import {getDetailRestaurants} from "../../network/controllers/RestaurantsControllers";

export default function MenuAdminPage() {
    const [addMenu, setAddMenu] = useState<boolean>(false);
    const [coveredAmount, setCoveredAmount] = useState<string>("0");
    const [isEditingAmount, setIsEditingAmount] = useState<boolean>(false);
    const [modifyMenu, setModifyMenu] = useState<boolean>(false);
    const [newMenu, setNewMenu] = useState("");
    const [editableMenu, setEditableMenu] = useState<MenuType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [menuList, setMenuList] = useState<any[]>([]);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const navigate = useNavigate();

    const fetchMenu = useCallback(() => {
        setIsLoading(true);
        getMenu((response) => {
            setMenuList(response);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchMenu();
    }, [fetchMenu]);

    useEffect(() => {
        const idLocal = JSON.parse(localStorage.getItem("selectedRestaurant") as string)?.id;

        if (idLocal) {
            setIsLoadingDetail(true);
            getDetailRestaurants(idLocal, (response) => {
                if (response && response.covered !== undefined && response.covered !== null) {
                    setCoveredAmount(response.covered.toFixed(2)); // Imposta il valore solo se esiste
                } else {
                    setCoveredAmount("0.00"); // Valore di fallback se `covered` è undefined
                }
                setIsLoadingDetail(false);
            });
        }
    }, []);

    const handleUpdateCoverage = useCallback(() => {
        const idLocal = JSON.parse(localStorage.getItem("selectedRestaurant") as string)?.id;
        if (!idLocal) return;

        const newPrice = parseFloat(coveredAmount);

        console.log("Dati inviati a updateCoverage:", {idLocal, newPrice});

        setIsLoading(true);
        updateCoverage({idLocal, newPrice}, () => {
            showSuccessNotification("Importo coperto", "Importo aggiornato con successo", 2000);
            setIsEditingAmount(false);
            setIsLoading(false);
        });
    }, [coveredAmount]);

    return isMobile ? (
        <div className="w-full flex flex-col pt-[130px]">
            {/* Header Mobile */}
            <div className="w-full flex items-center justify-center my-3">
                <h1 className="text-2xl font-bold">Menu</h1>

                <Tooltip
                    radius="lg"
                    classNames={{tooltip: "font-bold"}}
                    position="right-end"
                    label="Scarica qui il menù del tuo Ristorante!"
                >
                    <img
                        src={qrImage}
                        alt="QR Icon"
                        className="w-8 h-8 ml-4"
                        onClick={() => {
                            getMenuQr((response) => {
                                const base64Data = response.qrMenu;
                                const binaryString = atob(base64Data);
                                const len = binaryString.length;
                                const bytes = new Uint8Array(len);
                                for (let i = 0; i < len; i++) {
                                    bytes[i] = binaryString.charCodeAt(i);
                                }
                                const blob = new Blob([bytes], {type: "application/octet-stream"});
                                const link = document.createElement("a");
                                link.href = URL.createObjectURL(blob);
                                link.download = `${
                                    JSON.parse(localStorage.getItem("selectedRestaurant") as string)?.nameRestaurant
                                }.png`;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            });
                        }}
                    />
                </Tooltip>
            </div>

            {/* Importo Coperto */}
            <div className="w-full flex justify-center  items-center bg-black rounded-2xl p-3 my-5">
                <label className="text-sm text-white font-poppins-regular">Importo coperto</label>
                {!isEditingAmount ? (
                    <Tooltip
                        label="Clicca qui per modificare l'importo coperto"
                        position="top"
                        withArrow
                    >
                       <span
                           className="text-lg text-white font-medium cursor-pointer hover:underline"
                           onClick={() => setIsEditingAmount(true)}
                       >
    € {parseFloat(coveredAmount).toFixed(2)}
</span>
                    </Tooltip>
                ) : (
                    <div className="flex gap-2 ml-3 items-center justify-center">
                        <Input
                            type="number"
                            value={coveredAmount}
                            className="w-24 text-white"
                            onChange={(e) => setCoveredAmount(e.currentTarget.value)}
                        />
                        <img
                            src={isLoading ? loadingIcon : confirm}
                            className="w-6 h-6 cursor-pointer"
                            alt="Confirm"
                            onClick={handleUpdateCoverage}
                        />
                        <img
                            src={closeIcon}
                            className="w-6 h-6 cursor-pointer"
                            alt="Cancel"
                            onClick={() => setIsEditingAmount(false)}
                        />
                    </div>
                )}
            </div>

            {/* Nome Menu */}
            <div className="w-full flex flex-col justify-center items-center ">
                <h1 className="font-bold text-lg">NOME MENU</h1>


            <CustomButton
                small
                title={!addMenu ? "Aggiungi Menu" : "Annulla"}
                onClick={() => setAddMenu(!addMenu)}
                enable
                isLoading={isLoading}
            />
            </div>
            {/* Lista Menu */}
            {!isLoading && menuList.length > 0 ?
                <div className={`py-5 bg-opacity-90 rounded-md px-2`}>
                    <MenuAdminTable menuList={menuList} onEdit={(menu) => {
                        setEditableMenu(menu)
                        setModifyMenu(true)
                        updateMenu({
                            idMenu: menu?.id!,
                            name: menu?.name!
                        }, (response) => {
                            showSuccessNotification('Menu', `${menu?.name} modificato con successo`, 2000)
                            setTimeout(() => {
                                window.location.reload()
                            }, 2000)
                        })
                    }} onClickItem={(menu) => {
                        localStorage.setItem('selectedMenu', JSON.stringify(menu))
                        localStorage.setItem('menuId', menu.id)
                        navigate(`/admin/prodotti`)
                    }} onChangeStatus={(res) => {
                        localStorage.setItem('selectedRestaurantClient', JSON.stringify(res))
                        window.location.reload()
                    }}/>
                </div> : <div className={'w-full flex  justify-center py-10'}>
                    <img alt={''} className={'w-24 h-24'} src={loadingIcon}/>
                </div>
            }
        </div>
    ) : (
        <div className="w-full h-full flex-col pt-[130px] ">
            <div className="w-full flex items-center mb-10">
                <h1 className="text-3xl font-bold">Menu</h1>

                {/* Tooltip per scaricare QR */}
                <Tooltip
                    label="Scarica qui il menù del tuo Ristorante! Si aggiornerà automaticamente in base al menù attivo!"
                    position="right-end"
                    withArrow
                >
                    <img
                        alt=""
                        src={qrImage}
                        className="w-10 h-10 ml-4 cursor-pointer hover:scale-125 transition-transform duration-300"
                        onClick={() => {
                            getMenuQr((response) => {
                                const base64Data = response.qrMenu;
                                const binaryString = atob(base64Data);
                                const len = binaryString.length;
                                const bytes = new Uint8Array(len);
                                for (let i = 0; i < len; i++) {
                                    bytes[i] = binaryString.charCodeAt(i);
                                }
                                const blob = new Blob([bytes], {type: "application/octet-stream"});
                                const link = document.createElement("a");
                                link.href = URL.createObjectURL(blob);
                                link.download = `${
                                    JSON.parse(localStorage.getItem("selectedRestaurant") as string)?.nameRestaurant
                                }.png`;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            });
                        }}
                    />
                </Tooltip>


                <div className="w-auto h-auto flex justify-center items-center bg-black rounded-2xl p-3 ml-3 ">
                    <label className="text-sm text-white font-poppins-regular">Importo coperto</label>
                    {!isEditingAmount ? (
                        <Tooltip
                            label="Clicca qui per modificare l'importo coperto"
                            position="top"
                            withArrow
                        >
                        <span
                            className="text-lg text-white font-medium ml-3 cursor-pointer hover:underline"
                            onClick={() => setIsEditingAmount(true)}
                        >
                            € {parseFloat(coveredAmount).toFixed(2)}
                        </span>
                        </Tooltip>
                    ) : (
                        <div className="flex gap-2 ml-3 items-center justify-center">
                            <Input
                                type="number"
                                value={coveredAmount}
                                className="w-24 text-white"
                                onChange={(e) => setCoveredAmount(e.currentTarget.value)}
                            />
                            <img
                                src={isLoading ? loadingIcon : confirm}
                                className="w-6 h-6 cursor-pointer"
                                alt="Confirm"
                                onClick={handleUpdateCoverage}
                            />
                            <img
                                src={closeIcon}
                                className="w-6 h-6 cursor-pointer"
                                alt="Cancel"
                                onClick={() => setIsEditingAmount(false)}
                            />
                        </div>
                    )}
                </div>
            </div>


            {/* Aggiungi nuovo Menu */
            }
            <div className="w-full flex justify-between">
                <h1 className="w-auto font-poppins-bold my-5">NOME MENU</h1>
                <CustomButton
                    small
                    title={!addMenu ? "Aggiungi Menu" : "Annulla"}
                    onClick={() => setAddMenu(!addMenu)}
                    enable
                    isLoading={false}
                />
            </div>

            {
                addMenu && (
                    <div className="w-full flex my-10 items-center">
                        <Input
                            type="text"
                            className="w-[250px] h-full bg-white text-black font-poppins-regular outline-none"
                            placeholder="Nome menu"
                            onChange={(e) =>
                                setNewMenu(e.currentTarget.value.charAt(0).toUpperCase() + e.currentTarget.value.slice(1))
                            }
                        />
                        <div className="w-[100px] flex ml-10 gap-x-3">
                            <img
                                src={isLoading ? loadingIcon : confirm}
                                className="cursor-pointer w-6 h-6"
                                alt=""
                                onClick={() => {
                                    const idLocal = JSON.parse(localStorage.getItem("selectedRestaurant") as string)?.id;
                                    setIsLoading(true);
                                    createMenu({idLocal, name: newMenu}, () => {
                                        showSuccessNotification("Menu", "Menu creato con successo", 2000);
                                        setAddMenu(false);
                                        fetchMenu();
                                    });
                                }}
                            />
                            <img
                                src={closeIcon}
                                className="cursor-pointer w-6 h-6"
                                alt=""
                                onClick={() => setAddMenu(false)}
                            />
                        </div>
                    </div>
                )}

            {/* Tabella menu */}
            {!isLoading && menuList.length > 0 ?
                <div className={`py-5 bg-opacity-90 rounded-md px-2`}>
                    <MenuAdminTable menuList={menuList} onEdit={(menu) => {
                        updateMenu({
                            idMenu: menu?.id!,
                            name: menu?.name!
                        }, (response) => {
                            showSuccessNotification('Menu', `${editableMenu?.name} modificato con successo`, 2000)
                            setTimeout(() => {
                                window.location.reload()
                            }, 2000)
                        })
                    }} onClickItem={(menu) => {
                        localStorage.setItem('selectedMenu', JSON.stringify(menu))
                        localStorage.setItem('menuId', menu.id)
                        navigate(`/admin/prodotti`)
                    }} onChangeStatus={(res) => {
                        localStorage.setItem('selectedRestaurantClient', JSON.stringify(res))
                        window.location.reload()
                    }}/>
                </div> : <div className={'w-full flex  justify-center py-10'}>
                    <img alt={''} className={'w-24 h-24'} src={loadingIcon}/>
                </div>
            }

        </div>
    );
}
