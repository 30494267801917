import React, { useEffect, useState } from 'react';
import {isMobile} from "react-device-detect";

export default function WelcomeTmp() {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e:any) => {
            setMousePosition({
                x: (e.clientX / window.innerWidth) * 20,
                y: (e.clientY / window.innerHeight) * 20
            });
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => window.removeEventListener('mousemove', handleMouseMove);
    }, []);

    return (
        <div className={`${isMobile? "h-screen":"h-full"} w-full  flex flex-col items-center justify-center bg-[#fed302] pt-[170px] relative overflow-hidden`}>
            {/* Elementi decorativi con parallasse */}
            <div className="absolute inset-0 pointer-events-none">
                <div
                    className="absolute top-10 left-10 w-20 h-20 rounded-full bg-[#ffea80] opacity-50"
                    style={{
                        animation: 'pulse 2s infinite, float 6s ease-in-out infinite',
                        transform: `translate(${mousePosition.x}px, ${mousePosition.y}px)`
                    }}
                />

                {/* Nuovi elementi decorativi */}
                <div className="absolute top-1/4 right-1/4 w-16 h-16 rounded-full bg-[#ffea80] opacity-30"
                     style={{ animation: 'float 5s ease-in-out infinite' }} />
                <div className="absolute bottom-1/3 left-1/3 w-24 h-24 rounded-full bg-[#ffea80] opacity-20"
                     style={{ animation: 'float 7s ease-in-out infinite' }} />
            </div>

            {/* Testo principale con effetti migliorati */}
            <p className={` ${isMobile ? "text-5xl":"text-8xl"} font-poppins-bold flex flex-col text-center relative z-10`}
               style={{
                   animation: 'slideIn 1.2s ease-out',
                   transform: `translate(${mousePosition.x * 0.2}px, ${mousePosition.y * 0.2}px)`
               }}>
                <span className="transition-all duration-300 hover:scale-105 hover:-rotate-1"
                      style={{ animation: 'fadeIn 1s ease-out' }}>
                    Il futuro della ristorazione,
                </span>
                <span className="mt-4 transition-all duration-300 hover:scale-105 hover:rotate-1"
                      style={{ animation: 'bounceIn 1s ease-out 0.5s both' }}>
                    OGGI!
                </span>
            </p>

            {/* Video container migliorato */}
            <div className="w-[50%] h-[50%] flex items-center justify-center ">
                <div className="w-full transition-all duration-700 hover:scale-[1.02] relative"
                     style={{ animation: 'slideUp 1s ease-out 0.8s both' }}>
                    <div className="absolute inset-0 bg-gradient-to-t from-[#fed302] opacity-20 rounded-t-3xl z-10" />
                    <video
                        src="https://storage.googleapis.com/maisazio/video_home_maisazio/web.mov"
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="w-full h-full object-cover rounded-t-3xl "
                    />
                </div>
            </div>

            <style>
                {`
                    @keyframes pulse {
                        0% { transform: scale(1); opacity: 0.5; }
                        50% { transform: scale(1.1); opacity: 0.3; }
                        100% { transform: scale(1); opacity: 0.5; }
                    }

                    @keyframes float {
                        0% { transform: translateY(0px); }
                        50% { transform: translateY(-20px); }
                        100% { transform: translateY(0px); }
                    }

                    @keyframes fadeIn {
                        from {
                            opacity: 0;
                            transform: translateY(20px) rotate(-2deg);
                        }
                        to {
                            opacity: 1;
                            transform: translateY(0) rotate(0);
                        }
                    }

                    @keyframes slideIn {
                        from {
                            opacity: 0;
                            transform: translateX(-100px) rotate(-3deg);
                        }
                        to {
                            opacity: 1;
                            transform: translateX(0) rotate(0);
                        }
                    }

                    @keyframes bounceIn {
                        0% {
                            opacity: 0;
                            transform: scale(0.3) rotate(5deg);
                        }
                        50% {
                            opacity: 0.9;
                            transform: scale(1.1) rotate(-2deg);
                        }
                        80% {
                            opacity: 1;
                            transform: scale(0.89) rotate(1deg);
                        }
                        100% {
                            opacity: 1;
                            transform: scale(1) rotate(0);
                        }
                    }

                    @keyframes slideUp {
                        from {
                            opacity: 0;
                            transform: translateY(100px) scale(0.95);
                        }
                        to {
                            opacity: 1;
                            transform: translateY(0) scale(1);
                        }
                    }
                `}
            </style>
        </div>
    );
}
