import {useEffect, useState} from "react";
import {Switch, Table, TextInput} from "@mantine/core";
import {setActiveMenuRestaurants} from "../../../network/controllers/admin/AdminController";
import {GetRestaurantResponseType} from "../../../types/GetRestaurantResponseType";
import GenericImg from "../../../components/generic/GenericImg";
import trashIcon from "../../../assets/delete-icon.svg";
import {showFailNotification, showSuccessNotification, showWarningNotification} from "../../../utils/NotificationUtils";
import {deleteMenu, getMenuPDF} from "../../../network/controllers/admin/MenuController";
import {MenuType} from "../../../types/CategoryType";
import pdfImage from "../../../assets/icona_pdf.svg";
import loader from '../../../assets/loader-white.svg';
import editIcon from '../../../assets/edit-icon.svg';
import {isMobile} from "react-device-detect";
import confirm from "../../../assets/confirm-icon.svg";
import closeIcon from "../../../assets/close-icon.svg";

export default function MenuAdminTable(props: {
    menuList: MenuType[];
    onClickItem: (menu: any) => void;
    onEdit: (menu: MenuType) => void;
    onChangeStatus: (menu: GetRestaurantResponseType) => void;
}) {
    const idMenuActive = JSON.parse(localStorage.getItem('selectedRestaurant')!).idMenuAvalible;
    const [isLoading, setIsLoading] = useState<Map<string, boolean>>(new Map());
    const [editingMenu, setEditingMenu] = useState<string | null>(null);
    const [editedName, setEditedName] = useState<string>("");

    useEffect(() => {
        const tmpListStatus = new Map<string, boolean>();
        props.menuList.forEach((item) => {
            tmpListStatus.set(item.id, false);
        });
        setIsLoading(tmpListStatus);
    }, [props.menuList]);

    const handleConfirmEdit = (element: MenuType) => {
        const updatedMenu = { ...element, name: editedName };
        props.onEdit(updatedMenu); // Notifica la modifica al genitore
        setEditingMenu(null); // Esci dalla modalità modifica
    };

    const handleCancelEdit = () => {
        setEditingMenu(null);
    };

    const rows = props.menuList.map((element) => (
        <Table.Tr className={'w-full cursor-pointer'} key={element.id}>
            <Table.Td className={'text-center'}>
                {editingMenu === element.id ? (
                    <div className="flex items-center gap-2">
                        <TextInput
                            value={editedName}
                            onChange={(e) => setEditedName(e.currentTarget.value)}
                            autoFocus
                        />
                        <GenericImg
                            src={confirm}
                            className="w-5 h-5 cursor-pointer"
                            onCLick={() => handleConfirmEdit(element)}
                        />
                        <GenericImg
                            src={closeIcon}
                            className="w-5 h-5 cursor-pointer"
                            onCLick={handleCancelEdit}
                        />
                    </div>
                ) : (
                    <h1
                        className={'w-auto'}
                        onClick={() => props.onClickItem(element)}
                    >
                        {element.name}
                    </h1>
                )}
            </Table.Td>
            <Table.Td>
                <div className={'w-min'}>
                    <Switch
                        color={'yellow'}
                        defaultChecked={element.id === idMenuActive}
                        onChange={() => {
                            setActiveMenuRestaurants(element.id, (response) => {
                                props.onChangeStatus(response);
                            });
                        }}
                    />
                </div>
            </Table.Td>
            <Table.Td>
                <div className={'w-full flex items-end justify-end pr-5 gap-x-10'}>
                    <GenericImg
                        src={isLoading.get(element.id) ? loader : editIcon}
                        className={'w-5 h-5 flex'}
                        onCLick={() => {
                            setEditingMenu(element.id);
                            setEditedName(element.name);
                        }}
                    />
                    <GenericImg
                        src={isLoading.get(element.id) ? loader : pdfImage}
                        className={'w-5 h-5 flex'}
                        onCLick={() => {
                            if (!isLoading.get(element.id)) {
                                const tmpMap = new Map(isLoading);
                                tmpMap.set(element.id, true);
                                setIsLoading(tmpMap);
                                getMenuPDF(element.id, (response) => {
                                    const updatedMap = new Map(isLoading);
                                    updatedMap.set(element.id, false);
                                    setIsLoading(updatedMap);
                                    window.open(response.PDFMenu, "_blank", "noopener,noreferrer");
                                });
                            }
                        }}
                    />
                    <GenericImg
                        src={trashIcon}
                        className={'w-5 h-5 flex'}
                        onCLick={() => {
                            showWarningNotification(
                                `Sicuro di voler eliminare ${element.name}?`,
                                () => {
                                    deleteMenu(element.id!, (response) => {
                                        if (response.status === 200) {
                                            showSuccessNotification(
                                                "Menu",
                                                "Eliminato con successo",
                                                2000
                                            );
                                            window.location.reload();
                                        } else {
                                            showFailNotification(
                                                "Errore",
                                                "Riprova più tardi",
                                                2000
                                            );
                                        }
                                    });
                                }
                            );
                        }}
                    />
                </div>
            </Table.Td>
        </Table.Tr>
    ));

    return isMobile ? (
        <div className={'w-full h-auto mt-10 px-3'}>
            <Table.ScrollContainer minWidth={'auto'}>
                <Table striped highlightOnHover withTableBorder withRowBorders={false}>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th style={{fontSize: '12px'}} className={'text-center'}>NOME MENU</Table.Th>
                            <Table.Th style={{fontSize: '12px'}} className={'text-center'}>ATTIVO</Table.Th>
                            <Table.Th></Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Table.ScrollContainer>
        </div>
    ) : (
        <div className={'mt-10'}>
            <Table.ScrollContainer minWidth={'auto'}>
                <Table striped highlightOnHover withTableBorder withRowBorders={false}>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th className={'text-center'}>NOME MENU</Table.Th>
                            <Table.Th>ATTIVO</Table.Th>
                            <Table.Th></Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Table.ScrollContainer>
        </div>
    );
}
