import { useEffect, useState } from "react";
import { getAllCategories } from "../../network/controllers/admin/CategoryController";
import CustomButton from "../../components/CustomButton";
import { Drawer, TextInput } from "@mantine/core";
import { IngredientType } from "../../types/IngredientType";
import { getIngredients } from "../../network/controllers/admin/IngredientsController";
import IngredientsAdminTable from "./tables/IngredientsAdminTable";
import IngredientDrawerContent from "./IngredientDrawerContent";
import { ReactComponent as CustomLoaderIcon } from "../../assets/loader-white.svg"
import {isMobile} from "react-device-detect";
import {getDimension} from "../../utils/Utility";

export default function IngredientsAdminPage() {
    const [ingredientSelected, setIngredientSelected] = useState<IngredientType | null>(null);
    const [ingredientList, setIngredientList] = useState<IngredientType[]>([]);
    const [originalIngredientList, setOriginalIngredientList] = useState<IngredientType[]>([]);
    const [categoryMultiSelect, setCategoryMultiSelect] = useState<{ label: any, value: string }[]>();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true); // Stato di caricamento

    const [dimension, setDimension] = useState(window.innerWidth < 1280)
    useEffect(() => {
        getDimension(1280, (res)=>{
            console.log(res);
            setDimension(res)
        })
    },[])



    useEffect(() => {
        setLoading(true);
        getIngredients('', (response) => {
            setIngredientList(response);
            setOriginalIngredientList(response);
            setLoading(false);
        });
        getAllCategories(JSON.parse(localStorage.getItem('selectedRestaurant') as string).id, (response) => {
            var tmpArr: any[] = [];
            response.map((category) => {
                tmpArr.push({ value: category.id, label: category.categoryName });
            });
            setCategoryMultiSelect(tmpArr);
        });
    }, []);

    useEffect(() => {
        if (searchTerm === '') {
            setIngredientList(originalIngredientList);
        } else {
            var tmpIngredient: IngredientType[] = originalIngredientList.filter((i: IngredientType) => i.name.toLowerCase().includes(searchTerm.toLowerCase()));
            setIngredientList(tmpIngredient);
        }
    }, [searchTerm]);

if (isMobile || dimension) {

    return (
        <div className="w-auto h-full flex flex-col pt-[130px] ">
            <Drawer
                position="bottom"
                size="full"
                styles={{
                    content: { backgroundColor: 'black' },
                    header: { backgroundColor: 'black' }
                }}
                zIndex={40}
                opened={openDrawer}
                onClose={() => {
                    setIngredientSelected(null);
                    setOpenDrawer(false);
                }}
            >
                <IngredientDrawerContent
                    ingredient={ingredientSelected}
                    data={categoryMultiSelect}
                    onIngredientChange={(ingredientList) => {
                        window.location.reload();
                    }}
                />
            </Drawer>
            <div className="flex justify-between items-center gap-x-2">
                <div className="h-auto rounded-md w-full " onKeyDown={(e) => {}}>
                    <TextInput
                        classNames={{
                            label: 'labelStyleBlack',
                            input: 'inputStyle',
                            wrapper: 'wrapperStyle'

                        }}
                        label="Cerca ingrediente"
                        placeholder="Cerca..."
                        readOnly={false}
                        value={searchTerm}
                        onChange={(res) => {
                            setSearchTerm(res.currentTarget.value);
                        }}
                    />
                </div>
                <div className={'mt-5'}>
                    <CustomButton
                        small={true}
                        title="Aggiungi Ingrediente"
                        onClick={() => {
                            setOpenDrawer(true);
                        }}
                        enable={true}
                        isLoading={false}
                    />
                </div>

            </div>
            {loading ? (
                <div className="w-auto h-full flex justify-center items-center">
                    <CustomLoaderIcon width="120" height="120" />
                </div>
            ) : (
                <div className={'w-auto h-auto mt-8 '}>
                <IngredientsAdminTable
                    ingredientsList={ingredientList}
                    onClickItem={(ingredient) => {
                        setIngredientSelected(ingredient);
                        setOpenDrawer(true);
                    }}
                />
                </div>
            )}
        </div>
    );
}else{
    return (
        <div className={'w-full h-full flex-col pt-[130px]'}>
            <Drawer position={"right"}
                    size={'50%'}
                    styles={{
                        content: { backgroundColor: 'black' },
                        header: { backgroundColor: 'black' }
                    }}
                    zIndex={40}
                    opened={openDrawer} onClose={() => {
                setIngredientSelected(null);
                setOpenDrawer(false);
            }}>
                <IngredientDrawerContent ingredient={ingredientSelected} data={categoryMultiSelect}
                                         onIngredientChange={(ingredientList) => {
                                             window.location.reload()
                                         }} />
            </Drawer>
            <div className={'w-full flex justify-between '}>
                <div className={` rounded-md w-[300px] h-full`} onKeyDown={(e) => {
                }}>
                    <TextInput
                        classNames={{
                            label: 'labelStyleBlack',
                            input: 'inputStyle',
                            wrapper: 'wrapperStyle'
                        }}
                        label={'Cerca ingrediente'}
                        placeholder={'Cerca...'}
                        readOnly={false}
                        value={searchTerm}
                        onChange={(res) => {
                            setSearchTerm(res.currentTarget.value);
                        }}
                    />
                </div>
                <CustomButton small={true} title={'Aggiungi Ingrediente'} onClick={() => {
                    setOpenDrawer(true);
                }} enable={true} isLoading={false} />
            </div>

            {loading ? (
                <div className="w-full flex justify-center items-center h-full">
                    <CustomLoaderIcon width="120" height="120"/>
                </div>
            ) : (
                <IngredientsAdminTable ingredientsList={ingredientList} onClickItem={(ingredient) => {
                    setIngredientSelected(ingredient);
                    setOpenDrawer(true);
                }} />
            )}
        </div>
    );
}
}
