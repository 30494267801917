import {Divider, Popover, Select, Tooltip} from "@mantine/core";
import {useEffect, useState} from "react";
import placeProd from '../../assets/placeholder-dish.svg';
import {GetProductResponseType} from "../../types/GetProductResponseType";
import {getDimension, getSystemLanguage} from "../../utils/Utility";
import {StatusEnum} from "../../enums/StatusEnum";
import CustomButton from "../../components/CustomButton";
import closeIcon from '../../assets/add-icon.svg'
import {IngredientType} from "../../types/IngredientType";
import {getAllergeni} from "../../network/controllers/admin/AllergenController";
import {AllergenType} from "../../types/AllergenType";
import GenericTooltip from "../../components/generic/GenericTooltip";
import CustomTextInput from "../../components/generic/CustomTextInput";
import {isMobile} from "react-device-detect";
import {useDebounce} from "use-debounce";
import minusIcon from "../../assets/minusIcon.svg"


export default function ItemProductComponentDelivery(props: {
    ingredientList: { label: string, value: string }[],
    product: GetProductResponseType,
    onProductAdd: (quantity: number, ingredientList: IngredientType[], note: string) => void,
}) {
    const [error, setError] = useState<string | null>(null);
    const [allAllergenList, setAllAllergenList] = useState<AllergenType[]>([]);
    const [uniqueAllergens, setUniqueAllergens] = useState<Map<string, AllergenType>>();
    const [note, setNote] = useState<string>('');
    const [productAdd, setProductAdd] = useState(false);
    const [productQuantity, setProductQuantity] = useState<number>(1);
    const [addPrice, setAddPrice] = useState<number>(0);
    props.product.ingredients.forEach(ingredient => {
        ingredient.name = ingredient.ingredientNameTranslations?.get(getSystemLanguage(Array.from(ingredient.ingredientNameTranslations?.keys())))!!
    })
    props.ingredientList.forEach(ingredient => {
        //ingredient.label = ingredient.ingredientNameTranslations?.get(getSystemLanguage())!!
    })
    const [ingredients, setIngredients] = useState<IngredientType[]>(props.product.ingredients);
    const [filteredIngredientList, setFilteredIngredientList] = useState(props.ingredientList);
    const [value] = useDebounce(productAdd, 1000);

    const [dimension, setDimension] = useState(false);
    getDimension(1080, (res: boolean) => {
        setDimension(res);
    });

    useEffect(() => {
        getAllergeni((allergeniList) => {
            setAllAllergenList(allergeniList)
        })
        var tmpArr: Map<string, AllergenType> = new Map()
        props.product.allergensList.forEach((item) => {
            if (!tmpArr.has(item.id)) {
                tmpArr.set(item.id, item);
            }
        })
        setUniqueAllergens(tmpArr)

        // Filtra gli ingredienti originali dalla lista delle aggiunte
        const originalIngredientIds = props.product.ingredients.map(ing => ing.id);
        const filtered = props.ingredientList.filter(item => {
            const ingredient = JSON.parse(item.value) as IngredientType;
            return !originalIngredientIds.includes(ingredient.id);
        });
        setFilteredIngredientList(filtered);
    }, []);

    useEffect(() => {
        var price = 0;
        ingredients.forEach(item => {
            const isOriginalIngredient = props.product.ingredients.some(
                origIngr => origIngr.id === item.id
            );
            if (!isOriginalIngredient) {
                price += item.price;
            }
        });
        setAddPrice(price);
    }, [ingredients, props.product.ingredients]);

    useEffect(() => {
        if (productAdd) {
            setProductAdd(false)
        }
    }, [value])

    const handleDecrement = () => {
        if (productQuantity > 1) {
            setProductQuantity(productQuantity - 1);
            setError(null);
        }
    };

    const handleIncrement = () => {
        if (productQuantity < 99) {
            setProductQuantity(productQuantity + 1);
            setError(null);
        } else {
            setError('Massimo 99 piatti');
        }
    };

    const handleAddToCart = () => {
        if (!error && ingredients.length > 0) {

            props.onProductAdd(productQuantity, ingredients, note);
            setProductAdd(true);
            setNote('');
            setProductQuantity(1);
            setIngredients([...props.product.ingredients]);
            setAddPrice(0);

            var tmpArr: Map<string, AllergenType> = new Map()
            props.product.allergensList.forEach((item) => {
                if (!tmpArr.has(item.id)) {
                    tmpArr.set(item.id, item);
                }
            })
            setUniqueAllergens(tmpArr);
        }
    };

    const isOriginalIngredient = (ingredient: IngredientType) => {
        return props.product.ingredients.some(origIngr => origIngr.id === ingredient.id);
    };





    if (isMobile) {
        return (
            <div className={'w-full h-full flex flex-col  '}>
                <div
                    className={`flex flex-col w-full h-full pt-4 items-center justify-center' gap-x-2 `}>
                    <div className={`flex flex-col items-center justify-center w-full h-full `}>
                        <img className={'w-[200px] h-[200px] rounded-xl shadow-[3px_3px_8px_0.5px_rgba(0,0,0,0.3)] '}
                             src={props.product.approvalStatus === StatusEnum.ACCEPTED ? props.product.photoPath : placeProd}/>
                        <div className={'flex w-full h-full flex-col justify-between px-1 '}>
                            <div
                                className={'flex flex-col w-full h-full font-poppins-bold text-xl text-center mt-1 p-3 '}>
                                <div className={'w-full h-auto flex flex-col gap-x-5 '}>
                                    <div className={'w-auto p-2'}>


                                    </div>

                                    <p className={'text-white  text-center whitespace-normal'}>{props.product.nameProduct}</p>
                                    {getSystemLanguage(Array.from(props.product.productNameTranslations?.keys()!!))!=='it'?<p className={'text-white  text-center text-xs whitespace-normal mb-2'}>{props.product.productNameTranslations?.get(getSystemLanguage(Array.from(props.product.productNameTranslations?.keys())))}</p>:null}
                                  <p className={'text-white whitespace-nowrap'}> € {`${props.product.priceOriginal}  ${props.product.priceType !== 'Singolo' ? props.product.priceType : ''}`}</p>
                                    <div className={'w-full flex min-h-[40px] my-3'}>
                                        {uniqueAllergens && Array.from(uniqueAllergens).length > 0 && (
                                            <div className={'flex w-full h-auto  gap-x-2 justify-center '}>
                                                <div className={'flex gap-x-2'}>
                                                    {Array.from(uniqueAllergens).map((allergen, index) => {
                                                            return (
                                                                <Tooltip key={index} label={allergen[1].name}>
                                                                    <img alt={''} className={'w-7 h-7'}
                                                                         src={allergen[1].b64Image}/>
                                                                </Tooltip>
                                                            )
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>


                                <div className={'w-full h-auto min-h-[30px] flex item-center justify-center'}>
                                    <div className={'w-auto h-auto flex flex-col'}>
                                        {ingredients.map((ing) => (
                                            <div
                                                className={'bg-yellow w-auto h-auto  flex gap-x-1 items-center justify-center  m-1 p-1  rounded-2xl'}>
                                                <GenericTooltip txt={ing.name} innerHtml={
                                                    <p className={' text-sm text-black font-poppins-regular truncate'}>
                                                        {ing.name}
                                                    </p>
                                                }
                                                />
                                                <img alt={''} src={closeIcon} className={'w-3 h-3'} onClick={() => {
                                                    setUniqueAllergens(prevState => {
                                                        const updatedListAllergen = new Map(prevState);
                                                        ing.allergenId.forEach((it) => {
                                                            updatedListAllergen.delete(it)
                                                        })
                                                        return updatedListAllergen;
                                                    })
                                                    setIngredients(ingredients.filter(item => item.name !== ing.name));
                                                }}/>
                                            </div>
                                        ))}
                                        <Popover width={300} trapFocus position="bottom" withArrow shadow="md">
                                            <Popover.Target>
                                                <div
                                                    className={'cursor-pointer ml-2 w-6 h-6 bg-yellow mt-1.5 rounded-full flex items-center justify-center hover:scale-125 duration-500'}>
                                                    <p>+</p>
                                                </div>
                                            </Popover.Target>
                                            <Popover.Dropdown>
                                                <Select
                                                    searchable={true}
                                                    size={'md'}
                                                    value={null}
                                                    data={props.ingredientList ? props.ingredientList : []}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            var ing = JSON.parse(e) as IngredientType
                                                            var allergenList: AllergenType[] = []
                                                            ing.allergenId.forEach((allergenId) => {
                                                                allergenList.push(allAllergenList.find(item => item.id === allergenId) as AllergenType)
                                                            });

                                                            setUniqueAllergens(prevState => {
                                                                const updatedListAllergen = new Map(prevState);
                                                                allergenList.forEach((it) => {
                                                                    updatedListAllergen.set(it.id, it)
                                                                })
                                                                return updatedListAllergen;
                                                            })

                                                            setIngredients(prevState => {
                                                                if (!prevState.some(item => item.id === ing.id)) {
                                                                    return [...prevState, ing];
                                                                }
                                                                return prevState;
                                                            });
                                                        }
                                                    }}
                                                />
                                            </Popover.Dropdown>
                                        </Popover>
                                    </div>


                                </div>


                                <div className="flex gap-4 items-center justify-center pt-5">
                                    <button
                                        onClick={handleDecrement}
                                        className="w-7 h-7 rounded-full bg-[#FFCC00] flex items-center justify-center text-black font-bold hover:opacity-90 transition-opacity"
                                        disabled={productQuantity <= 1}
                                    >
                                        -
                                    </button>

                                    <div
                                        className="w-14 h-10 bg-white rounded flex items-center justify-center text-black font-semibold">
                                        {productQuantity}
                                    </div>

                                    <button
                                        onClick={handleIncrement}
                                        className="w-7 h-7 rounded-full bg-[#FFCC00] flex items-center justify-center text-black font-bold hover:opacity-90 transition-opacity"
                                        disabled={productQuantity >= 99}
                                    >
                                        +
                                    </button>

                                    {error && (
                                        <span className="text-red-500 text-sm absolute -bottom-6">
                                            {error}
                                        </span>
                                    )}
                                </div>

                                <div className={'w-full h-full flex flex-col justify-center items-center gap-y-5 mt-8'}>
                                    <CustomTextInput
                                        customDimensions={'w-full h-[50px]'}
                                        isBlack={true}
                                        placeholder={'inserisci note aggiuntive....'}
                                        defaultValue={note}
                                        onChange={(value) => setNote(value)}
                                    />
                                    <CustomButton
                                        small={true}
                                        title={productAdd ? 'Aggiunto con successo' : 'Aggiungi'}
                                        onClick={handleAddToCart}
                                        enable={!error && ingredients.length > 0}
                                        isLoading={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'flex-col mt-4'}>
                    <Divider color={'yellow'} size="sm"/>
                </div>
            </div>
        )
    } else {
        return (
            <div className={`w-full h-full `}>
                <div
                    className={`flex flex-col w-full h-full pt-4 gap-x-2`}>
                    <div
                        className={`flex  ${dimension ? 'flex-col items-center justify-center gap-y-3' : ''} w-full h-full `}>
                        <img className={'w-[200px] h-[200px] rounded-xl shadow-[3px_3px_8px_0.5px_rgba(0,0,0,0.3)] '}
                             src={props.product.approvalStatus === StatusEnum.ACCEPTED ? props.product.photoPath : placeProd}/>
                        <div className={'flex w-full h-full flex-col justify-between px-5 '}>
                            <div
                                className={`flex flex-col w-full h-full font-poppins-bold text-xl text-center mt-1 ${dimension ? "items-center justify-center" : ""}`}>

                                <p className={'text-white mb-2 text-sm font-poppins-bold'}>{props.product.nameProduct}</p>
                                {getSystemLanguage(Array.from(props.product.productNameTranslations?.keys()!!))!=='it'?<p className={'text-white  text-center text-xs whitespace-normal mb-2'}>{props.product.productNameTranslations?.get(getSystemLanguage(Array.from(props.product.productNameTranslations?.keys())))}</p>:null}
                                <div className=" w-auto h-auto gap-x-2 flex items-center  ">
                                    <button
                                        onClick={handleDecrement}
                                        className="w-7 h-7 rounded-full bg-[#FFCC00] flex items-center justify-center text-black font-bold hover:opacity-90 transition-opacity"
                                        disabled={productQuantity <= 1}
                                    >
                                        -
                                    </button>

                                    <div
                                        className="w-14 h-10 bg-white rounded flex items-center justify-center text-black font-semibold">
                                        {productQuantity}
                                    </div>

                                    <button
                                        onClick={handleIncrement}
                                        className="w-7 h-7 rounded-full bg-[#FFCC00] flex items-center justify-center text-black font-bold hover:opacity-90 transition-opacity"
                                        disabled={productQuantity >= 99}
                                    >
                                        +
                                    </button>

                                    {error && (
                                        <span className="text-red-500 text-sm absolute -bottom-6">
                                            {error}
                                        </span>
                                    )}
                                    <div className={'w-full flex justify-end'}>
                                        <p className={'text-white'}>€ {(props.product.priceOriginal + addPrice).toFixed(2)}</p>
                                    </div>
                                </div>
                                <div className={`w-full flex min-h-[40px] my-2 `}>
                                    {uniqueAllergens && Array.from(uniqueAllergens).length > 0 && (
                                        <div
                                            className={`flex w-full h-auto  gap-x-2 pl-2 ${dimension ? "justify-center" : ""}`}>
                                            <div className={'flex gap-x-2'}>
                                                {Array.from(uniqueAllergens).map((allergen, index) => {
                                                        return (
                                                            <Tooltip key={index} label={allergen[1].name}>
                                                                <img alt={''} className={'w-7 h-7'}
                                                                     src={allergen[1].b64Image}/>
                                                            </Tooltip>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div
                                    className={`w-full h-auto min-h-[30px] flex  ${dimension ? "items-center justify-center" : "justify-between"}  `}>
                                    <div
                                        className={`w-auto h-auto ${dimension ? " flex flex-col justify-center" : " grid grid-cols-3"}`}>
                                        {ingredients.map((ing) => (
                                            <div
                                                className={'bg-yellow w-auto h-auto flex gap-x-1 items-center justify-center m-1 p-1 rounded-2xl'}>
                                                <GenericTooltip txt={ing.name} innerHtml={
                                                    <p className={'text-sm text-black font-poppins-regular truncate'}>
                                                        {!isOriginalIngredient(ing) && ing.price !== 0
                                                            ? `${ing.name} + € ${ing.price.toFixed(2)}`
                                                            : ing.name
                                                        }
                                                    </p>
                                                }/>
                                                <img alt={''} src={minusIcon} className={'w-5 h-5'} onClick={() => {
                                                    setUniqueAllergens(prevState => {
                                                        const updatedListAllergen = new Map(prevState);
                                                        ing.allergenId.forEach((it) => {
                                                            updatedListAllergen.delete(it)
                                                        })
                                                        return updatedListAllergen;
                                                    })
                                                    setIngredients(ingredients.filter(item => item.name !== ing.name));
                                                }}/>
                                            </div>
                                        ))}
                                        <Popover width={300} trapFocus position="bottom" withArrow shadow="md">
                                            <Popover.Target>
                                                <div
                                                    className={'cursor-pointer ml-2 w-6 h-6 bg-yellow mt-1.5 rounded-full flex items-center justify-center hover:scale-125 duration-500'}>
                                                    <p>+</p>
                                                </div>
                                            </Popover.Target>
                                            <Popover.Dropdown>
                                                <Select
                                                    searchable={true}
                                                    size={'md'}
                                                    value={null}
                                                    data={filteredIngredientList}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            var ing = JSON.parse(e) as IngredientType
                                                            var allergenList: AllergenType[] = []
                                                            ing.allergenId.forEach((allergenId) => {
                                                                allergenList.push(allAllergenList.find(item => item.id === allergenId) as AllergenType)
                                                            });

                                                            setUniqueAllergens(prevState => {
                                                                const updatedListAllergen = new Map(prevState);
                                                                allergenList.forEach((it) => {
                                                                    updatedListAllergen.set(it.id, it)
                                                                })
                                                                return updatedListAllergen;
                                                            })

                                                            setIngredients(prevState => {
                                                                if (!prevState.some(item => item.id === ing.id)) {
                                                                    return [...prevState, ing];
                                                                }
                                                                return prevState;
                                                            });
                                                        }
                                                    }}
                                                />
                                            </Popover.Dropdown>
                                        </Popover>
                                    </div>


                                </div>

                                <div
                                    className={`w-full h-full flex items-end mt-3 ${dimension ? "flex-col items-center justify-center gap-y-2" : "justify-between"}`}>
                                    <CustomTextInput
                                        customDimensions={`w-full h-[50px] ${dimension ? "" : "pr-20"}`}
                                        placeholder={'inserisci note aggiuntive ....'}
                                        defaultValue={note}
                                        onChange={(value) => setNote(value)}
                                    />
                                    <CustomButton
                                        small={true}
                                        title={productAdd ? 'Aggiunto con successo' : 'Aggiungi'}
                                        onClick={handleAddToCart}
                                        enable={!error && ingredients.length > 0}
                                        isLoading={false}
                                        bgColor={productAdd ? '#28a745' : undefined} // Verde quando è aggiunto con successo
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'flex-col mt-4'}>
                    <Divider color={'yellow'} size="sm"/>
                </div>
            </div>
        )


    }
}
