import React, { useEffect, useState } from "react";
import { Drawer, TextInput } from "@mantine/core";
import { showFailNotification } from "../utils/NotificationUtils";
import { getAllOrderbyUser } from "../network/controllers/PrinterController";
import { ReactComponent as CustomLoaderIcon } from "../assets/loader-white.svg";
import { isMobile } from "react-device-detect";
import { getDimension } from "../utils/Utility";
import OrderTable from "./admin/tables/OrderTable";
import { OrderType } from "../types/OrderType";

export default function OrderCheckStatus() {
    const [orderList, setOrderList] = useState<OrderType[]>([]);
    const [originalOrderList, setOriginalOrderList] = useState<OrderType[]>([]);
    const [orderSelected, setOrderSelected] = useState<OrderType | null>(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [dimension, setDimension] = useState(window.innerWidth < 1280);

    useEffect(() => {
        getDimension(1280, (res) => {
            setDimension(res);
        });
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userData') as string);
        if (!user || !user.id) {
            showFailNotification('Errore', 'Utente non trovato', 3000);
            setLoading(false);
            return;
        }

        setLoading(true);
        getAllOrderbyUser(user.id, (response) => {
            setLoading(false);
            setOrderList(response);
            setOriginalOrderList(response);
        });
    }, []);

    useEffect(() => {
        if (searchTerm === '') {
            setOrderList(originalOrderList);
        } else {
            const filteredOrders = originalOrderList.filter((order) =>
                order.print.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setOrderList(filteredOrders);
        }
    }, [searchTerm]);

    const renderDrawerContent = () => (
        <div className="text-white p-4">
            {orderSelected && (
                <div>
                    <h2>{orderSelected.print.name}</h2>
                    <p>Status: {orderSelected.status}</p>
                    <p>Indirizzo: {orderSelected.spedizione.indirizzo}</p>
                </div>
            )}
        </div>
    );

    const renderStatusLegend = () => (
        <div className="w-full flex justify-between mt-5">
            <div className="w-auto flex justify-items-center flex-col">
                <span className="w-5 h-5 bg-lime-400 rounded-full border-[1px] border-white"></span>
                <p className="font-poppins-regular text-sm">SPEDITO</p>
            </div>
            <div className="w-auto flex justify-items-center flex-col">
                <span className="w-5 h-5 bg-amber-400 rounded-full border-[1px] border-white"></span>
                <p className="font-poppins-regular text-sm">IN LAVORAZIONE</p>
            </div>
            <div className="w-auto flex justify-items-center flex-col">
                <span className="w-5 h-5 bg-blue-900 rounded-full border-[1px] border-white"></span>
                <p className="font-poppins-regular text-sm">COMPLETATO</p>
            </div>
        </div>
    );

    const renderSearchInput = () => (
        <div className={`rounded-md ${isMobile ? 'w-full h-full mx-2' : 'w-[300px] h-full'}`}>
            <TextInput
                classNames={{
                    label: 'labelStyleBlack',
                    input: 'inputStyle',
                    wrapper: 'wrapperStyle'
                }}
                label="Cerca ordine"
                placeholder="Cerca..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
            />
        </div>
    );

    const content = (
        <>
            <Drawer
                position={isMobile ? "bottom" : "right"}
                size={isMobile ? "100%" : "50%"}
                styles={{
                    content: { backgroundColor: 'black' },
                    header: { backgroundColor: 'black' }
                }}
                zIndex={40}
                opened={openDrawer}
                onClose={() => {
                    setOrderSelected(null);
                    setOpenDrawer(false);
                }}
            >
                {renderDrawerContent()}
            </Drawer>

            <div className={`w-full ${isMobile ? 'h-auto flex flex-col' : 'flex justify-between'} ${dimension ? "flex-col justify-center items-center gap-y-3" : ""}`}>
                {renderSearchInput()}
                {isMobile ? renderStatusLegend() : (
                    <div className={`flex items-center text-center w-full h-auto space-x-4 ${dimension ? "justify-center" : "justify-end"}`}>
                        <div className="flex items-center">
                            <span className="w-5 h-5 bg-lime-400 rounded-full border-[1px] border-white"></span>
                            <p className="ml-2 font-poppins-regular">SPEDITO</p>
                        </div>
                        <div className="flex items-center">
                            <span className="w-5 h-5 bg-amber-400 rounded-full border-[1px] border-white"></span>
                            <p className="ml-2 font-poppins-regular">IN LAVORAZIONE</p>
                        </div>
                        <div className="flex items-center">
                            <span className="w-5 h-5 bg-blue-900 rounded-full border-[1px] border-white"></span>
                            <p className="ml-2 font-poppins-regular">COMPLETATO</p>
                        </div>
                    </div>
                )}
            </div>

            {loading ? (
                <div className="w-full flex justify-center items-center h-full">
                    <CustomLoaderIcon width="120" height="120" />
                </div>
            ) : (
                <OrderTable
                    ordersList={orderList}
                    onClickItem={(order) => {
                        setOrderSelected(order);
                        setOpenDrawer(true);
                    }}
                />
            )}
        </>
    );

    return (
        <div className={`w-full h-full flex-col ${isMobile ? 'gap-y-3' : 'px-5 relative'} pt-[130px]`}>
            {content}
        </div>
    );
}
