import React, {useEffect, useState} from "react";
import CustomButton from "../components/CustomButton";
import deleteIcon from "../assets/delete-icon.svg";
import confirmIcon from "../assets/confirm-icon.svg";
import {closeTable, printOrder, updateOrder} from "../network/controllers/admin/OrderController";
import {Drawer} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import CommandDrawer from "../components/CommandDrawer/CommandDrawer";
import {CommandTableOrderType, Ingredient, Product, Turn} from "../types/CommandTableOrderType";
import {isMobile} from "react-device-detect";
import {showFailNotification, showSuccessNotification, showWarningNotification} from "../utils/NotificationUtils";


export default function RecipeComponent(props:{
                                            order:CommandTableOrderType,
                                            onUpdateOrder:(updatedOrder:CommandTableOrderType)=>void,}){
    const [opened, {open, close}] = useDisclosure(false);

    const handlePrintOrder = () => {
        const idComanda = props.order.id;

        if (!idComanda) {
            console.error("ID comanda non disponibile.");
            return;
        }

        showWarningNotification(`Vuoi stampare il preconto del ${tableName}?`, () => {
            printOrder(idComanda, (response) => {
                if (response) {
                    showSuccessNotification("Fatto!", "Preconto stampato", 3000);
                } else {
                    showFailNotification("Oh no...", "Errore nella stampa del preconto", 3000);
                }
            });
        });
    };

    useEffect(() => {
        console.log('prodotto', )
    }, []);


    const handleCloseTable = () => {
        const orderData = props.order;

        if (!orderData) {
            console.error("Ordine non disponibile.");
            return;
        }

        showWarningNotification(`Vuoi davvero chiudere il ${tableName} ?`, () => {
            closeTable(orderData, (response) => {
                if (response) {
                    showSuccessNotification("Fatto!", "Tavolo chiuso con successo", 3000);
                    props.onUpdateOrder(response);
                } else {
                    showFailNotification("Oh no...!", "Errore nella chiusura del tavolo", 3000);
                }
            });
        });
    };


    const handleRemoveProduct = (turnId: string, product: Product) => {
        let updatedTurn = props.order.order.turn[turnId];
        if (!updatedTurn) {
            console.error(`Turno con ID ${turnId} non trovato nell'ordine.`);
            return;
        }

        var tmpr = updatedTurn.productsList.find(item => `${product.nameProduct}${formatIngrString(product.ingredients)}${product.note}`===`${item.nameProduct}${formatIngrString(item.ingredients)}${item.note}`)


        if (tmpr) {
            if (tmpr.quantity > 1) {
                tmpr.quantity = tmpr.quantity - 1;
            } else {
                updatedTurn.productsList = updatedTurn.productsList.filter(
                    it =>
                        `${product.nameProduct}${formatIngrString(product.ingredients)}${product.note}` !==
                        `${it.nameProduct}${formatIngrString(it.ingredients)}${it.note}`
                );
            }
        }

        updateOrder({...props.order, order: props.order.order}, (response) => {
            if (response) {
                console.log("Prodotto aggiunto con successo al turno:", response);
                props.onUpdateOrder(response);
            } else {
                console.error("Errore durante l'aggiornamento dell'ordine.");
            }
        });


    };

    function getIngredientsList(ingredients: Ingredient[]) {
        return ingredients
            .filter((ing) => ing.statusIngredient !== "BASE") // Filtra gli ingredienti con stato diverso da BASE
            .map((ing) => {
                if (ing.statusIngredient === "ADD") {
                    return `+${ing.name}`;
                } else if (ing.statusIngredient === "REMOVE") {
                    return `-${ing.name}`;
                }
                return ing.name;
            });
    }

    const handleProductAdd = (
        product: any,
        quantity: number,
        ingredientList: any[],
        note: string,
        turnId?: string
    ) => {
        if (!turnId) {
            console.error("Turno non definito. Impossibile aggiungere il prodotto.");
            return;
        }

        const updatedTurn = props.order.order.turn[turnId];


        if (!updatedTurn) {
            console.error(`Turno con ID ${turnId} non trovato nell'ordine.`);
            return;
        }

        var tmpr = updatedTurn.productsList.find(item => `${product.nameProduct}${formatIngrString(ingredientList)}${note}`===`${item.nameProduct}${formatIngrString(item.ingredients)}${item.note}`)


            if (tmpr){
                tmpr.quantity = tmpr.quantity + quantity;
                tmpr.price = tmpr.price * quantity;
            }else{
                var newPrice=product.priceOriginal
                ingredientList.forEach(item => {
                    if (item.statusIngredient==="ADD"){
                        newPrice+=item.price;
                    }
                })

                product.note=note
                product.quantity = quantity
                product.ingredients=ingredientList
                product.price=newPrice * quantity
                updatedTurn.productsList.push(product)
            }

        updateOrder({...props.order, order: props.order.order}, (response) => {
            if (response) {
                console.log("Prodotto aggiunto con successo al turno:", response);
                props.onUpdateOrder(response);
            } else {
                console.error("Errore durante l'aggiornamento dell'ordine.");
            }
        });
    };

    useEffect(() => {
        console.log('come sta arrivando sta purcaria',props.order)
    }, [props.order]);

    const currentOrder:CommandTableOrderType = Array.isArray(props.order) ? props.order[0] : props.order;
    const discount = currentOrder?.order?.discount || 0;

    const [newDiscount, setNewDiscount] = useState<string>("");

    useEffect(() => {
        setNewDiscount(discount.toFixed(2));
    }, [discount]);

    if (!currentOrder || !currentOrder.order) {
        return (
            <div className="w-full bg-gray-400 rounded-2xl flex justify-center items-center h-full">
                <p className="text-white text-xl">Seleziona un tavolo per visualizzare l'ordine.</p>
            </div>
        );
    }

    const {tableName, order: orderDetails} = currentOrder;
    const totalProductPrice = orderDetails.totalProductPrice || 0;
    const totalCoveredPrice = orderDetails.totalCovered || 0;
    const totalOrderNoIva = orderDetails.totalOrderNoIva || 0;
    const iva = orderDetails.iva || 0;
    const totalOrder = orderDetails.totalOrder || 0;

    const priceForPerson =
        discount > 0
            ? (totalOrder - discount) / orderDetails.numberCovered
            : totalOrder / orderDetails.numberCovered;

    function formatIngrString(ingList:any[]) {
        var recipt=""
        ingList.forEach(ing => {
            if (ing.statusIngredient==="ADD"){
                recipt+=`+${ing.name}`
            }else if (ing.statusIngredient==="REMOVE"){
                recipt+=`-${ing.name}`
            }
        })
        return recipt;
    }



    return (
        <div className={`w-full min-h-fit bg-gray-400 rounded-2xl flex ${isMobile? "mt-5":"mt-20"} flex-col justify-center items-center`}>
            <Drawer
                position="left"
                size={isMobile ? '100%' : '50%'}
                opened={opened}
                onClose={close}
                styles={{
                    content: {backgroundColor: "black"},
                    header: {backgroundColor: "black"},
                }}
            >
                <CommandDrawer
                    recipe={orderDetails}
                    onProductAdd={(product, quantity, ingredientList, note, turnId) =>{
                        handleProductAdd(product, quantity, ingredientList, note, turnId)
                    }}
                />
            </Drawer>
            <div className={`bg-white rounded-lg shadow-lg ${isMobile?"w-[95%]":"w-[80%]"} mt-5`}>
                <div className="text-center py-4 border-b">
                    <h2 className="text-yellow-500 text-4xl font-poppins-bold">{tableName}</h2>
                    <p className="text-gray-600 text-sm">
                        {new Date(orderDetails.dataOpenOrder).toLocaleString()}
                    </p>
                </div>
                <div className="p-6">
                    {Object.values(orderDetails.turn)
                        .filter((turn: Turn) => turn.productsList.length > 0)
                        .map((turn: Turn) => (
                            <div key={turn.id}>
                                <h3 className="font-bold text-lg">{turn.nameTurn}</h3>
                                {turn.productsList.map((product: Product, index: number) => (
                                    <div key={index} className="flex justify-between border-b py-2">
                                        <div className="w-auto h-auto flex pl-5">
                                            <img
                                                src={deleteIcon}
                                                alt="Delete"
                                                className="w-6 h-6 cursor-pointer"
                                                onClick={() => {handleRemoveProduct(turn.id,product)}}
                                            />
                                            <p className="ml-3">
                                                {product.nameProduct || "Prodotto senza nome"}&nbsp;&nbsp;
                                                {product.quantity || 1}x&nbsp;&nbsp;
                                                {(product.priceOriginal ?? 0).toFixed(2)}€<br/>
                                                {product.note && (
                                                    <span className="text-gray-500 text-sm italic">
                                                        {product.note}
                                                    </span>

                                                )}


                                                <div className="flex flex-col ml-3">
                                                    {getIngredientsList(product.ingredients).map((ingString, idx) => (
                                                        <div key={idx}>{ingString}</div>
                                                    ))}
                                                </div>
                                            </p>
                                        </div>
                                        <p className="font-bold">
                                        €
                                            {((product.price ?? 0) * (product.quantity ?? 1)).toFixed(2)}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        ))}
                </div>
                <div className="p-6 border-t">
                    <div className="flex justify-between mb-2">
                        <p>{orderDetails.numberOfItems} articoli</p>
                        <p className="font-bold">€{totalProductPrice.toFixed(2)}</p>
                    </div>
                    <div className="flex justify-between mb-2">
                        <p>coperti</p>
                        <p className="font-bold">€{totalCoveredPrice.toFixed(2)}</p>
                    </div>
                    <hr className="my-2"/>
                    <div className="flex justify-between mb-2 text-xl font-bold">
                        <p>Totale</p>
                        <p>€{totalOrder.toFixed(2)}</p>
                    </div>
                    <div className="flex justify-between mb-2 items-center">
                        <p>Sconto</p>
                        <div className="flex items-center gap-x-3">
                            <input
                                type="text"
                                className="w-[100px] h-8 bg-white text-text-button-add font-poppins-regular outline-none border border-gray-300 rounded-md text-center"
                                value={newDiscount}
                                onChange={(e) => setNewDiscount(e.target.value)}
                            />
                            <img
                                src={confirmIcon}
                                alt="Confirm"
                                className="cursor-pointer w-6 h-6"
                                onClick={() => {
                                    const updatedDiscount = parseFloat(newDiscount);
                                    if (!isNaN(updatedDiscount)) {
                                        const updatedOrder = {
                                            ...currentOrder,
                                            order: {...orderDetails, discount: updatedDiscount},
                                        };
                                        updateOrder(updatedOrder, (response) => {
                                            if (response) {
                                                props.onUpdateOrder(response);
                                            }
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex justify-between mb-2">
                        <p>Prezzo scontato</p>
                        <p className="font-bold">€{(totalOrder - discount).toFixed(2)}</p>
                    </div>
                    <div className="flex justify-between mb-2">
                        <p>Prezzo a persona</p>
                        <p className="font-bold">€{priceForPerson ? priceForPerson.toFixed(2) : "0.00"}</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="w-full h-auto flex p-3 gap-x-3">
                    <CustomButton title={"Modifica"} onClick={open} enable={true} isLoading={false} small={true}/>
                    <CustomButton
                        title={"Chiudi Tavolo"}
                        onClick={handleCloseTable}
                        enable={true}
                        isLoading={false}
                        small={true}
                    />
                </div>
                <div className="w-full h-auto flex items-center justify-center mb-5">
                    <CustomButton
                        title={"Stampa Preconto"}
                        onClick={handlePrintOrder}
                        enable={true}
                        isLoading={false}
                        small={true}
                    />
                </div>
            </div>
        </div>
    );
}
