import React, {useEffect, useState} from "react";
import PrinterShopCardComponent from "../components/PrinterShopCardComponent";
import {PrinterShopType} from "../types/PrinterType";
import {getAllPrintersShop} from "../network/controllers/PrinterController";
import {Drawer} from "@mantine/core";
import {makeRequest} from "../network/RestAdapter";
import {HttpMethodsEnum} from "../network/HttpMethodsEnum";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutFormShop from "../components/CheckoutFormShop";
import {isMobile} from "react-device-detect";
import PrinterShopCardComponentMobile from "../components/PrinterShpoCardComponentMobile";
import CustomButton from "../components/CustomButton";
import {useNavigate} from "react-router-dom";
import {getDimension} from "../utils/Utility";

const stripePromise = loadStripe(process.env["REACT_APP_STRIPE_PRIVATE_KEY"] as string);
export default function ShopPage() {
    var navigate = useNavigate();
    const [printerList, setPrinterList] = React.useState<PrinterShopType[]>([]);
    const [open, setOpen] = React.useState(false);
    const [selectedPrinter,setSelectedPrinter] = React.useState<PrinterShopType|null>(null)
    const [clientSecret, setClientSecret] = useState({
        clientSecret: '',
        idStripe: ''
    });

    useEffect(() => {
        getAllPrintersShop((response)=>{
            setPrinterList(response);
        })

    }, []);

    const [dimension, setDimension] = useState(window.innerWidth < 1280)
    useEffect(() => {
        getDimension(1280, (res)=>{
            console.log(res);
            setDimension(res)
        })
    },[])


    useEffect(() => {
        if (open && selectedPrinter){
            var restaurant=JSON.parse(localStorage.getItem('selectedRestaurant') as string)
            var extraDataMap:Map<string,any>=new Map()
            extraDataMap.set('nome',selectedPrinter.name)
            extraDataMap.set('descrizione',selectedPrinter.description)
            extraDataMap.set('prezzo',selectedPrinter.price)
            extraDataMap.set('tipologia acquisto',selectedPrinter.typePurchase)
            makeRequest<Map<string, string>>('/users/restaurants/client_secret', HttpMethodsEnum.POST,
                {
                    ammountCent: (selectedPrinter?.price! + (selectedPrinter?.price! * 22)/100)*100 ,
                    stripeId:restaurant.stripeId,
                    nameRestaurant:restaurant.nameRestaurant,
                    description:selectedPrinter.typePurchase==='PURCHASE'?'Acquisto':'Noleggio',
                    dataExtra:extraDataMap
                }
            ).then((res) => {
                // @ts-ignore
                setClientSecret({clientSecret: res.data.clientSecret, idStripe: res.data.idStripe});
            })

        }

    }, [open]);

if (isMobile){
    return (
        <div className={'w-full h-auto flex flex-col items-center pt-[130px] pb-10'}>
            <div className={`w-full h-full flex flex-col  items-center justify-center gap-y-3`}>
                <h1 className={'w-full h-auto text-2xl font-poppins-semi-bold flex justify-center'}> I nostri
                    prodotti </h1>
                <div className={`w-auto h-auto flex items-center justify-center mt-20`}>
                <CustomButton title={'I miei Ordini'}
                              onClick={() => navigate('/admin/order')}
                              enable={true} isLoading={false} small={true}/>
            </div>
            </div>
            <Drawer
                position={"bottom"}
                size={'100%'}
                styles={{
                    content: {backgroundColor: 'black'},
                    header: {backgroundColor: 'black'}
                }}
                zIndex={40}
                opened={open}
                onClose={() => {
                    setSelectedPrinter(null)
                    setOpen(false)
                }}
            >
                {clientSecret.clientSecret !== '' && selectedPrinter ? <Elements stripe={stripePromise} options={
                    clientSecret}>
                    <CheckoutFormShop printer={selectedPrinter} idStripe={clientSecret.idStripe}/>
                </Elements> : null}

            </Drawer>
            {printerList.length > 0 ? printerList.map((printer) => (
                <PrinterShopCardComponentMobile onBuyButtonClick={() => {
                    setSelectedPrinter(printer)
                    setOpen(true)
                }} key={printer.id} printer={printer}/>
            )) : null}
        </div>

    )
} else {
    return (
        <div className={'w-full h-full flex flex-col  gap-x-5 pt-[120px]'}>
            <div className={`${dimension? "w-full h-auto flex flex-col items-center gap-y-3":""}`}>
                <h1 className={'w-full h-auto text-4xl font-poppins-semi-bold flex justify-center'}> I nostri
                    prodotti </h1>
                <div>
                <CustomButton title={'I miei Ordini'}
                              onClick={() => navigate('/admin/order')}
                              enable={true} isLoading={false}/>
            </div>
            </div>
            <Drawer
                position={"right"}
                size={'45%'}
                styles={{
                    content: {backgroundColor: 'black'},
                    header: {backgroundColor: 'black'}
                }}
                zIndex={40}
                opened={open}
                onClose={() => {
                    setSelectedPrinter(null)
                    setOpen(false)
                }}
            >
                {clientSecret.clientSecret !== '' && selectedPrinter ? <Elements stripe={stripePromise} options={
                    clientSecret}>
                    <CheckoutFormShop printer={selectedPrinter} idStripe={clientSecret.idStripe}/>
                </Elements> : null}

            </Drawer>

            <div className={'w-full h-full my-20 flex gap-x-10 justify-start '}>
            {printerList.length > 0 ? printerList.map((printer) => (
                <PrinterShopCardComponent onBuyButtonClick={() => {
                    setSelectedPrinter(printer)
                    setOpen(true)
                }} key={printer.id} printer={printer}/>
            )) : null}
            </div>
        </div>

    )

}
}

