import {PrinterType, Sector, SectorType} from "../types/PrinterType";
import {useEffect, useState} from "react";
import {getSectorsByIdUser, updatePrinter} from "../network/controllers/PrinterController";
import {MultiSelect, TextInput} from "@mantine/core";
import CustomButton from "./CustomButton";
import {LoginResponseType} from "../types/LoginResponseType";

export default function PrinterDrawerContent(props: { printerType: PrinterType }) {
    const [selectorList, setSelectorList] = useState<{ group: string, items: {value: string, label: string }[] }[]>([]);
    const [defaultSelectors, setDefaultSelector] = useState<string[]>([]);
    const [sendData, setSendData] = useState<{
        printerName: string;
        sector: string[];  // Specificato il tipo corretto qui
        imei: string;
    }>({
        printerName: props.printerType ? props.printerType.printerName : "",
        sector: [],
        imei: props.printerType ? props.printerType.imei : ''
    });
    var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType

    const isMultiSelectDisabled = () => {
        return sendData.imei.includes('V510');
    };

    useEffect(() => {
        if (props.printerType){
            var tmpList:string[]=[]
            props.printerType.sector.map((selector: Sector) => tmpList.push(selector.id))
            setDefaultSelector(tmpList)
        }
    }, []);

    useEffect(() => {
        const selectedRestaurant = JSON.parse(localStorage.getItem("selectedRestaurant")!);
        if (selectedRestaurant && selectedRestaurant.id) {
            getSectorsByIdUser(userData.id, (res) => {
                const tmpList:{ group: string, items: {value: string, label: string }[] }[] = []

                res.forEach(item =>{
                    const tmpListSector:{value: string, label: string }[] = []
                    item.sectors.forEach(sectorItem=>{
                        tmpListSector.push({value:sectorItem.id, label: sectorItem.name})
                    })

                    tmpList.push({group:item.restaurantName, items:tmpListSector})
                })

                setSelectorList(tmpList);
            });
        }
    }, []);

    const handleUpdatePrinter = () => {
        var sectorsID:string[]=[]
        Array.from(selectorList).forEach((value)=>{
            console.log(value)
            //sectorsID.push(value.value)
        })
        updatePrinter(sendData.imei, sendData.printerName, sendData.sector, (response) => {
            window.location.reload();
        });
    };

    return (
        <div className="flex flex-col h-full w-full items-center justify-center gap-y-7 mt-52">
            <div className={'flex flex-col w-full h-full items-center justify-center gap-y-2.5'}>
                <TextInput
                    label="Nome Articolo"
                    placeholder="Cambia nome articolo"
                    className="mb-4 w-3/4"
                    value={sendData.printerName}
                    onChange={(res) => {
                        setSendData({...sendData, printerName: res.currentTarget.value});
                    }}
                    styles={{
                        label: { color: 'white' },
                        input: { height: '50px' }
                    }}
                />

                <MultiSelect
                    key={selectorList.length}
                    label="Settori"
                    placeholder={"Seleziona settori"}
                    className="w-3/4 h-auto"
                    data={selectorList}
                    defaultValue={defaultSelectors}
                    disabled={isMultiSelectDisabled()}
                    onChange={(res) => {
                        var newSectors:string[]=[]
                        res.forEach((sector)=>{
                            newSectors.push(sector)
                        })
                        setSendData({...sendData, sector: newSectors});
                    }}
                    styles={{
                        label: { color: 'white' },
                    }}
                />
            </div>

            <div className={'flex w-auto h-auto mb-15 items-center justify-center mt-20'}>
                <CustomButton
                    title={'Salva'}
                    isLoading={false}
                    enable={true}
                    onClick={handleUpdatePrinter}
                />
            </div>
        </div>
    );
}
