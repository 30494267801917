import React, {useEffect, useState} from "react";
import {getInfoLocal} from "../../network/controllers/admin/CategoryController";
import loadingImg from '../../assets/loader-white.svg'
import CategoryDeliveryComponent from "./CategoryDeliveryComponent";
import ProductDeliveryComponent from "./ProductDeliveryComponent";
import {GetProductResponseType} from "../../types/GetProductResponseType";
import {IngredientType} from "../../types/IngredientType";
import {isMobile} from "react-device-detect";

export default function MenuDrawerClient(props:{
    onProductAdd:(product:GetProductResponseType,quantity:number,ingredientList:IngredientType[],note:string)=>void,
}) {
    const [contentMenu, setContentMenu] = useState<React.JSX.Element>();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        var categoryComponent = <CategoryDeliveryComponent  categorySelected={(category) => {
            setLoading(true)
            getInfoLocal(JSON.parse(localStorage.getItem('selectedRestaurantClient')!).id, (local)=>{
                setLoading(false)
                setContentMenu(<ProductDeliveryComponent  key={Math.random()} onProductAdd={(product, quantity,ingredientList,note)=>{
                        props.onProductAdd(product, quantity,ingredientList,note);
                }} local={local} categorySelected={category} onBack={() => {
                    setContentMenu(categoryComponent)
                }}/>)
            })
        }}/>
        setContentMenu(categoryComponent)
    },[])



        return (

            <div className={`w-full h-full  mt-5 px-5 flex  ${isMobile? "absolute z-50 ":""}`}>
                {loading?
                    <div className={'w-full h-auto flex items-center justify-center'}>
                        <img alt={''} className={'w-10 h-10'} src={loadingImg}/>
                    </div> : contentMenu ? contentMenu:null}
            </div>
        )

    }
