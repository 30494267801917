import React, {useEffect, useState} from "react";
import CustomButton from "../../components/CustomButton";
import {Divider, Input} from "@mantine/core";
import confirm from "../../assets/confirm-icon.svg";
import closeIcon from "../../assets/close-icon.svg";
import {showSuccessNotification} from "../../utils/NotificationUtils";
import loadingIcon from '../../assets/loader-white.svg'
import {createKitchen, getKitchens} from "../../network/controllers/admin/KitchenController";
import {KitchenType} from "../../types/KitchenType";
import KitchenAdminTable from "./tables/KitchenAdminTable";
import {isMobile} from "react-device-detect";


export default function KitchenAdminPage() {
    const [addKitchen, setAddKitchen] = useState<boolean>(false);
    const [newKitchen, setNewKitchen] = useState('');
    const [kitchenList, setKitchenList] = useState<KitchenType[]>([]);
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        getKitchens((response) => {
            setKitchenList(response)
        })
    }, []);


    return (
        <div className={`w-full h-full  flex-col  ${isMobile ? "pt-32":"px-10 pt-44 "} `}>
            <div className={'w-full flex justify-between items-center'}>
                <h1 className={'w-auto text-3xl font-poppins-bold my-5'}>Cucine</h1>
                <CustomButton small={true} title={!addKitchen ? 'Aggiungi Cucina' : 'Annulla'} onClick={() => {
                    setAddKitchen(!addKitchen)
                }} enable={true} isLoading={false}/>
            </div>
            <h1 className={'w-auto font-poppins-bold my-5'}>NOME CUCINA</h1>
            {addKitchen ?
                <div className={'w-full flex my-10 items-center '}>
                    <Input type={'text'}
                           className={'w-[250px] h-full bg-white text-text-button-add font-poppins-regular outline-none '}
                           placeholder={'Nome Cucina'} onChange={(event) => {
                        setNewKitchen(event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1))
                    }}/>
                    <div className={'w-[100px] flex ml-10 gap-x-3'}>
                        <img alt={''} className={'cursor-pointer w-6 h-6'} src={isLoading ? loadingIcon : confirm}
                             onClick={() => {
                                 setIsLoading(true)
                                 createKitchen({
                                     idLocal: JSON.parse(localStorage.getItem('selectedRestaurant') as string).id,
                                     kitchenName: newKitchen
                                 }, (response) => {
                                     setIsLoading(false)
                                     setAddKitchen(false)
                                     showSuccessNotification('Cucina', `${newKitchen} creata con successo`, 2000)
                                     setKitchenList(response)
                                     setNewKitchen('')
                                 })
                             }}/>
                        <img  className={'cursor-pointer w-6 h-6'} alt={''} src={isLoading ? loadingIcon : closeIcon}
                             onClick={() => {
                                 setAddKitchen(false)
                             }}/>
                    </div>
                </div>

                : null
            }

            {kitchenList.length > 0 ? kitchenList.map((kitchen, index) => (
                <div className={`py-5 bg-opacity-90 rounded-md px-2`} key={index}>
                    <KitchenAdminTable kitchen={kitchen} onKitchenUpdate={(kitchenList) => {
                        setKitchenList(kitchenList)
                    }} onClickItem={() => {
                    }}/>
                    <Divider color={'yellow'}/>
                </div>)) : null}


        </div>
    )
}
