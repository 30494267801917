import mqtt, {IClientOptions} from "mqtt";

let client: mqtt.MqttClient | null = null

export function connectMQTT(clientId: string) {
    const options: IClientOptions = {
        path: '/ws', clientId: clientId, defaultProtocol: 'wss', password: 'Codgosrl1!', username: "menus",
        servers: [{
            host: process.env['REACT_APP_HOST_MQTT'] as string,
            port: Number(process.env['REACT_APP_HOST_MQTT_PORT']),
            protocol: process.env['REACT_APP_PROTOCOL_MQTT'] as 'wss'|'ws'
        }]
    };
    console.log("op", JSON.stringify(options))
    client = mqtt.connect(options);
    client.on("connect", () => {
        console.log('CONNESSO A MQTT')
    });
    client.on("disconnect", () => {
        console.log('DISCONNESSO A MQTT')
    });
}


export function subscribeTopic(topic: string) {
    if (client) {
        client.subscribe(topic, () => {
            // do something
        });
    }
}

export function onMessage(topicCheck: string, onMessage: (message: string)=>void) {
    client!.on("message", (topic: any, message: { toString: () => any; }) => {
        if (message!==""){
            if (topicCheck === topic) {
                onMessage(JSON.parse(message.toString()))
            }
        }else{
            onMessage("")
        }


    });
}
