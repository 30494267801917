import React, {useEffect, useState} from "react";
import {Drawer, FileButton} from "@mantine/core";
import Resizer from "react-image-file-resizer";
import {showFailNotification} from "../../../utils/NotificationUtils";
import {isMobile} from "react-device-detect";
import MobileCropImg from "./MobileCropImg";
import {getDimension} from "../../../utils/Utility";

var resizeNumber = 10
export default function DrawerCropComponent(prop: {
    contentHtml?: React.JSX.Element,
    file?: File,
    onCroppedFinish: (imageSrc: string, w: number, h: number) => void,
    aspect: number,
    isDeleting?: boolean,
    maxWidth?: number,
    maxHeight?: number
}) {
    const [containerDimension, setContainerDimension] = useState({x: 0, y: 0})
    const [genericImgCrop,  setGenericImgCrop] = useState<React.JSX.Element>();

    const [dimension, setDimension] = useState(false)

    getDimension(1300, (res) => {
        setDimension(res)
    })

    const [statusMenu, setStatusMenu] = useState(false)

    useEffect(() => {
        setStatusMenu(genericImgCrop !== undefined)
    }, [genericImgCrop]);

    function calculateQuality(fileSize:number): number {
        return fileSize>5000000? parseInt((100-(fileSize/5000000)*5).toString()):90
    }

    function resizeFile(getFile: File, maxWidth: number | undefined, maxHeight: number | undefined, onResizeFinish: (file: File, x: number, y: number) => void) {
        const img = new Image();
        img.src = URL.createObjectURL(getFile);
        img.onload = () => {
            var width=maxWidth!==undefined?maxWidth:img.width
            var height=maxHeight!==undefined?maxHeight:img.height
            Resizer.imageFileResizer(getFile, width,height, 'JPEG', 100, 0, (uri) => {
                // Suppongo che resizeNumber sia una variabile globale
                if (resizeNumber === 10 || ((uri as File).size > 111111111800000 && resizeNumber > 0)) {
                    console.log((uri as File).size);
                    console.log('weeeeee',calculateQuality(getFile.size));
                    console.log(resizeNumber);
                    resizeNumber = resizeNumber - 1;
                    resizeFile(uri as File, width, height, onResizeFinish);
                } else {
                    console.log('fine',(uri as File).size);
                    console.log('width', width);
                    console.log('height', height);
                    resizeNumber = 10;
                    onResizeFinish(uri as File, img.width, img.height);
                }
            }, 'file');
        };

        // Gestione degli errori nel caricamento dell'immagine
        img.onerror = (error) => {

            console.error('Errore nel caricamento dell\'immagine', error);
            dispatchEvent(new CustomEvent("loader", {detail: {isLoading: false}}));
            showFailNotification('Errore','Riprova in seguito ',3000);
        };
    }

    return (
        <div>
            <Drawer size={isMobile || dimension?'100%':`40%`}
                    styles={{
                        content: {backgroundColor: 'black'},
                        header: {backgroundColor: 'black'}
                    }} color={'black'}
                    opened={statusMenu} onClose={() => {

                setGenericImgCrop(undefined)
            }}>

                {genericImgCrop}

            </Drawer>
            <FileButton  disabled={prop.isDeleting}   onChange={(getFile) => {
                console.log("Entrato")
                if (getFile !== null && (getFile.type==="image/png" || getFile.type==="image/jpeg")) {
                    dispatchEvent(new CustomEvent("loader", {detail: {isLoading: true}}));
                    resizeFile(getFile, prop.maxWidth, prop.maxHeight, (file, x, y) => {
                        setContainerDimension({x: x, y: y});

                            setGenericImgCrop(<MobileCropImg aspect={prop.aspect} file={getFile} onCrop={(cropped)=>{
                                prop.onCroppedFinish(cropped,x,y)
                                setGenericImgCrop(undefined)
                            }}/> );
                    })
                }else{
                    dispatchEvent(new CustomEvent("loader", {detail: {isLoading: false}}));
                }
                    dispatchEvent(new CustomEvent("loader", {detail: {isLoading: false}}));
            }}>
                {(props) => <div className={' cursor-pointer'} {...props}>{prop.contentHtml}</div>}
            </FileButton>
        </div>

    )
}
