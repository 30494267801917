import {Accordion, BackgroundImage, Drawer, Popover, Select, Switch} from "@mantine/core";
import blocknote from '../assets/blockNote.svg'
import CustomButton from "../components/CustomButton";
import {DatePickerProps, TimeInput} from "@mantine/dates";
import React, {useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import MenuDrawerClient from "./deliveryDrawer/MenuDrawerClient";
import {GetProductResponseType} from "../types/GetProductResponseType";
import {addCommand} from "../network/controllers/CommandController";
import {Dish, Ingredient, PostCommand} from "../types/PostCommand";
import {LoginResponseType} from "../types/LoginResponseType";
import deleteIcon from '../assets/delete-icon.svg'
import addIcon from '../assets/add-icon.svg'
import GenericTooltip from "../components/generic/GenericTooltip";
import {GetRestaurantResponseType} from "../types/GetRestaurantResponseType";
import Calendar from "../components/reservation/Calendar";
import CustomTextInput from "../components/generic/CustomTextInput";
import GoogleAddressSearch from "../components/GoogleAddressSearch";
import {RestaurantStatusEnum} from "../enums/RestaurantStatusEnum";
import check_prenotazione from "../assets/reservation/stepper/check-prenotazione.svg";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {showWarningNotification} from "../utils/NotificationUtils";
import MenuDrawerAdmin from "./deliveryDrawer/MenuDrawerAdmin";
import {Debugger} from "node:inspector";
import {IngredientType} from "../types/IngredientType";
import {GetUserReservationAdmin} from "../types/GetUsersReservationAdmin";
import {useDebounce} from "use-debounce";
import {searchUserReservation} from "../network/controllers/admin/ReservationController";


export default function DeliveryAdminPage(props: { restaurantStatus: RestaurantStatusEnum }) {
    const [opened, {open, close}] = useDisclosure(false);
    const [messageError, setMessageError] = useState<string | undefined>(undefined)
    const [isDelivery, setIsDelivery] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const [reservationSuccess, setReservationSuccess] = useState<boolean>(false);
    const [listProduct, setListProduct] = useState<Map<string, {
        product: GetProductResponseType,
        quantity: number,
        newPrice: number,
        ingredientList:IngredientType[],
        note: string | null
    }>>();
    var userData = JSON.parse(localStorage.getItem('userDataa')!) as LoginResponseType
    const [user, setUser] = useState({
        name: userData ? userData.name : '',
        surname: userData ? userData.surname : '',
        phoneNumber: userData ? userData.phone : '',
        email: userData ? userData.email : '',
        userId: userData ? userData.id : '',
        address: null
    })
    const [dateTimeData, setDateTimeData] = useState<Date | null>(new Date());
    var navigate = useNavigate();
    const [finalPrice, setFinalPrice] = useState<number>(0)
    const [formattedIng, setFormattedIng] = useState<string[]>([])
    useEffect(() => {
        if (listProduct && Array.from((listProduct)).length > 0) {
            var count = 0
            Array.from((listProduct)).forEach((item) => {
                count += (item[1].product.priceOriginal + item[1].newPrice) * item[1].quantity
            })
            setFinalPrice(count)
        } else {
            setFinalPrice(0)
        }

    }, [listProduct]);


    //gestione rubrica

    const [valueUser] = useDebounce(user.phoneNumber, 500);
    const [usersList, setUsersList] = useState<{ label: string, value: any }[]>([]);

    useEffect(() => {
        if (user.phoneNumber.length>4) {
            searchUserReservation(user.phoneNumber, (response) => {
                var tmpArr: { label: string, value: any }[] = [];
                if (response.length > 0) {
                    response.forEach((user) => {
                        tmpArr.push({value: JSON.stringify(user), label: `${user.name}  ${user.surname}  ${user.phone}`});
                    })
                }
                setUsersList(tmpArr);
            })
        }else{
            setUsersList([])
        }
    }, [valueUser]);


    const restaurantData = JSON.parse(localStorage.getItem('selectedRestaurant') as string) as GetRestaurantResponseType
    console.log(restaurantData)
    let closedDays: number[] = []
    let openedDays: string[] = [];
    calculateOpenedDays()

    function calculateOpenedDays() {
        for (let day of Object.values(restaurantData.hours)) {
            if (day.split(':').at(1)!.toLowerCase().trim() === 'chiuso') {
                closedDays.push(italianWeekdayToNumber(day.split(':').at(0)!));
            } else {
                openedDays.push(day)
            }
        }
    }

    const getDayProps: DatePickerProps['getDayProps'] = (date) => {
        if (openedDays.length < 7) {
            for (let closedDay of closedDays) {
                if (date.getDay() === closedDay) {
                    return {disabled: true};
                }
            }
        }
        if (new Date(Date.now()).setHours(0, 0, 0, 0) > new Date(date).setHours(0, 0, 0, 0)) {
            return {disabled: true};
        }
        return {};
    };

    function italianWeekdayToNumber(weekday: string) {
        switch (weekday.toLowerCase()) {
            case 'lunedì':
                return 1;
            case 'martedì':
                return 2;
            case 'mercoledì':
                return 3;
            case 'giovedì':
                return 4;
            case 'venerdì':
                return 5;
            case 'sabato':
                return 6;
            case 'domenica':
                return 0;
            default:
                return -1;
        }
    }

    function HandleTimeChange(eventTarget: string) {
        // Controlliamo prima se il locale è aperto per il giorno selezionato
        const dayHours = dayNumberToHourInfo();
        if (dayHours.split(':').at(1)?.toLowerCase().trim() === 'chiuso') {
            setMessageError('Il locale è chiuso in questo giorno');
            return;
        }

        var actualTime = new Date()
        const hours = String(actualTime.getHours()).padStart(2, '0');
        const minutes = String(actualTime.getMinutes()).padStart(2, '0');
        var splittedDate = eventTarget.split(':')

        if (dateTimeData && dateTimeData.getTime() > actualTime.getTime()) {
            if (CheckHour(eventTarget)) {
                setMessageError('')
            } else {
                setMessageError(`Seleziona un orario compreso tra quelli elencati sopra`)
            }
        } else {
            if (parseInt(hours) < parseInt(splittedDate[0])) {
                if (CheckHour(eventTarget)) {
                    setMessageError('')
                } else {
                    setMessageError(`Seleziona un orario compreso tra quelli elencati sopra`)
                }
            } else if (parseInt(hours) === parseInt(splittedDate[0])) {
                if (parseInt(minutes) < parseInt(splittedDate[1])) {
                    if (CheckHour(eventTarget)) {
                        setMessageError('')
                    } else {
                        setMessageError(`Seleziona un orario compreso tra quelli elencati sopra`)
                    }
                } else {
                    setMessageError(`Non è possibile effettuare un ordine nel passato!`)
                }
            } else {
                setMessageError(`Non è possibile effettuare un ordine nel passato!`)
            }
        }
    }

    function hoursLabel(day: string) {
        let hours = day.slice(day.indexOf(':') + 1)
        if (hours.toLowerCase().trim() === 'chiuso') {
            return 'Chiuso';
        }
        let ranges = hours.split(',')
        const formatedRanges = ranges.map(range => {
            const [startRange, endRange] = range.split('–');
            if (endRange) {
                return `${startRange}-${endRange}`;
            } else {
                return `${startRange}`;
            }
        })
        return formatedRanges.join(' / ');
    }

    function dayNumberToHourInfo() {
        if ((dateTimeData !== null && dateTimeData.getDay() - 1) >= 0) {
            // @ts-ignore
            return restaurantData.hours[(dateTimeData.getDay() - 1).toString()]
        } else {
            // @ts-ignore
            return restaurantData.hours["6"]
        }
    }

    function combineDateTimeCustom(localDateString: string, timeString: string) {
        let localDate = new Date(localDateString);
        let year = localDate.getFullYear();
        let month = localDate.getMonth();
        let day = localDate.getDate();
        let hours = timeString.split(':')[0];
        let minutes = timeString.split(':')[1]
        // @ts-ignore
        let combinedDate = new Date(year, month, day, hours, minutes, 11, 11);
        let isoString = combinedDate.toISOString().slice(0, -2);
        isoString += '7Z';
        let result = isoString.replace(/:(\d{3})Z$/, '.$1Z')
        return result;
    }

    function CheckHour(ore: string) {
        let hours = hoursLabel(dayNumberToHourInfo()).split('/')
        let validation = false
        hours.forEach(range => {
            let inizioData = new Date(combineDateTimeCustom(dateTimeData!.toISOString(), range.split('-').at(0)!))
            let inizioDataNumber = new Date(combineDateTimeCustom(inizioData.toISOString(), range.split('-').at(0)!)).getTime()
            let endData = new Date(combineDateTimeCustom(dateTimeData!.toISOString(), range.split('-').at(1)!))
            let selectedData = new Date(combineDateTimeCustom(dateTimeData!.toISOString(), ore))
            if (selectedData.getHours() <= 5) {
                selectedData.setDate(selectedData.getDate() + 1)
            }
            if (endData.getHours() <= 5) {
                endData.setDate(endData.getDate() + 1)
            }
            let selectedDataNumber = new Date(combineDateTimeCustom(selectedData!.toISOString(), ore)).getTime()
            let endDataNumber = new Date(combineDateTimeCustom(endData.toISOString(), range.split('-').at(1)!)).getTime()

            if (selectedDataNumber >= inizioDataNumber && selectedDataNumber <= endDataNumber) {
                validation = true
            }
        })
        return validation;
    }

    function checkEnableButton(): boolean {
        // Controlla se il locale è chiuso
        const dayHours = dayNumberToHourInfo();
        if (dayHours.split(':').at(1)?.toLowerCase().trim() === 'chiuso') {
            return false;
        }

        if (isDelivery) {
            if (user.address) {
                if (userData) {
                    // @ts-ignore
                    return finalPrice !== 0 && messageError === '' && user.address!.address !== '' && user.address!.civicNumber !== '' && user.address!.city !== ''
                } else {
                    // @ts-ignore
                    return finalPrice !== 0 && messageError === '' && user.address!.address !== '' && user.address!.civicNumber !== '' && user.address!.city !== '' && user.name !== '' && user.surname !== '' && user.email !== '' && user.phoneNumber.length === 10
                }
            } else {
                return false
            }
        } else {
            if (userData) {
                return finalPrice !== 0 && messageError === ''
            } else {
                return finalPrice !== 0 && messageError === '' && user.name !== '' && user.surname !== '' && user.email !== '' && user.phoneNumber.length === 10
            }
        }
    }

    if (!reservationSuccess) {
        return (
            <div className={'w-full h-full flex flex-col pb-20 items-center justify-center '}>
                <Drawer
                    position={"left"}
                    size={"50%"}
                    styles={{
                        content: {backgroundColor: 'black'},
                        header: {backgroundColor: 'black'}
                    }}
                    closeOnClickOutside={true}
                    zIndex={50}
                    opened={opened}
                    onClose={() => {
                        close()
                    }}>
                    <MenuDrawerAdmin onProductAdd={(product, quantity, ingredientList, note) => {
                        var newRecipt = ''
                        var addPrice = 0
                        var originalIng = product.ingredients
                        originalIng.forEach((ingoriginal) => {
                            if (!ingredientList.some(item => item.id === ingoriginal.id)) {
                                newRecipt = newRecipt.concat(`-${ingoriginal.name},`);
                            }
                        });
                        ingredientList.forEach((newing) => {
                            if (!originalIng.some(ing => ing.id === newing.id)) {
                                setFormattedIng([...formattedIng, `+${newing.name},`])
                                newRecipt = newRecipt.concat(`+${newing.name},`);
                                addPrice += newing.price
                            }
                        })
                        setListProduct(prevListProduct => {
                            const updatedListProduct = new Map(prevListProduct);
                            if (updatedListProduct.has(product.id + newRecipt + ",note:" + note)) {
                                const existingProduct = updatedListProduct.get(product.id + newRecipt + ",note:" + note);
                                var updateIngredients:IngredientType[]=product.ingredients
                                newRecipt.split(",").forEach(singleIng =>{
                                    if (product.ingredients.some(item => item.name === singleIng.substring(1))) {
                                        if (singleIng.includes('+')){

                                            updateIngredients=updateIngredients.filter(ing=>ing.name!==singleIng.substring(1))
                                            var a=product.ingredients.find(it=>it.name===singleIng.substring(1))
                                            if (a){
                                                updateIngredients.push({...a,statusIngredient:"ADD"})
                                            }

                                        }else{
                                            updateIngredients=updateIngredients.filter(ing=>ing.name!==singleIng.substring(1))
                                            var a=product.ingredients.find(it=>it.name===singleIng.substring(1))
                                            if (a){
                                                updateIngredients.push({...a,statusIngredient:a.statusIngredient==="REMOVE"?"BASE":"REMOVE"})
                                            }
                                        }
                                    }else {
                                        var a = ingredientList.find(it=>it.name===singleIng.substring(1))
                                        if (a){
                                            updateIngredients.push({
                                                addable: a.addable,
                                                allergenId: a.allergenId,
                                                categories: a.categories,
                                                idLocal: a.idLocal,
                                                name: a.name,
                                                position:a.position,
                                                price: a.price,
                                                quantity: a.quantity,
                                                quantityPerServing: a.quantityPerServing,
                                                statusIngredient: "ADD"
                                            })
                                        }
                                    }
                                })
                                updatedListProduct.set(product.id + newRecipt + ",note:"+note, {
                                    product: product,
                                    ingredientList:updateIngredients,
                                    quantity: existingProduct!.quantity + quantity,
                                    newPrice: addPrice,
                                    note: existingProduct!.note
                                });
                            } else {
                                var updateIngredients:IngredientType[]=product.ingredients
                                newRecipt.split(",").forEach(singleIng =>{
                                    if (product.ingredients.some(item => item.name === singleIng.substring(1))) {
                                        if (singleIng.includes('+')){
                                            updateIngredients=updateIngredients.filter(ing=>ing.name!==singleIng.substring(1))
                                            var a=product.ingredients.find(it=>it.name===singleIng.substring(1))
                                            if (a){

                                                updateIngredients.push({...a,statusIngredient:"ADD"})
                                            }

                                        }else{
                                            updateIngredients=updateIngredients.filter(ing=>ing.name!==singleIng.substring(1))
                                            var a=product.ingredients.find(it=>it.name===singleIng.substring(1))
                                            if (a){

                                                updateIngredients.push({...a,statusIngredient:"REMOVE"})
                                            }
                                        }
                                    }else{
                                        var a = ingredientList.find(it=>it.name===singleIng.substring(1))
                                        if (a){

                                            updateIngredients.push({
                                                id:a.id,
                                                addable: a.addable,
                                                allergenId: a.allergenId,
                                                categories: a.categories,
                                                idLocal: a.idLocal,
                                                name: a.name,
                                                position:a.position,
                                                price: a.price,
                                                quantity: a.quantity,
                                                quantityPerServing: a.quantityPerServing,
                                                statusIngredient: "ADD"
                                            })
                                        }

                                    }
                                })
                                updatedListProduct.set(product.id + newRecipt + ",note:"+note, {
                                    product: product,
                                    ingredientList:updateIngredients,
                                    quantity: quantity,
                                    newPrice: addPrice,
                                    note: note
                                });
                            }
                            return updatedListProduct;
                        });


                    }}/>
                </Drawer>
                <BackgroundImage className={'w-full h-full overflow-y-auto p-20 m-5 '}
                                 src={blocknote}>
                    <p className={'w-full text-center text-black font-shadow-into-light text-4xl mt-16'}>Il mio ordine</p>
                    <div className={'w-full h-auto flex justify-around mt-5 '}>
                        <Popover
                            position={"bottom"}
                            withArrow>
                            <Popover.Target>
                                <div
                                    className={`hover:scale-110 duration-700 pointer-events-auto cursor-pointer bg-yellow rounded-xl w-[150px] h-[3rem] flex flex-col justify-center items-center px-1 mt-12`}
                                    onClick={() => {
                                    }}>
                                    <p className={`font-poppins-bold text-[14px] w-full text-center`}>{dateTimeData ? dateTimeData.toLocaleDateString() : new Date().toLocaleDateString()}</p>
                                </div>
                            </Popover.Target>
                            <Popover.Dropdown className={'bg-black'}>
                                <Calendar onChange={(value) => setDateTimeData(value!)} value={dateTimeData}
                                          dayProps={getDayProps}/>
                            </Popover.Dropdown>
                        </Popover>

                        <TimeInput
                            className={' mt-4 mx-4 cursor-pointer'}
                            classNames={{
                                input: 'my-4 cursor-pointer',
                                label: 'text-black font-poppins-regular text-sm'
                            }}
                            label={`Scegli  un orario : ${hoursLabel(dayNumberToHourInfo())}`} error={messageError}
                            defaultValue={dateTimeData ? dateTimeData.toTimeString() : new Date().toTimeString()}
                            onChange={(event) => {
                                if (event.target.value && event.target.value.split(":").length === 2 && event.target.value.split(":")[1].length === 2) {
                                    HandleTimeChange(event.target.value)
                                    if (messageError === '' || messageError === undefined) {
                                        setDateTimeData(prevState => {
                                            var date = new Date(prevState!)
                                            var value = event.target.value.split(":").map(Number);
                                            date.setHours(value[0] + 1);
                                            date.setMinutes(value[1]);
                                            date.setSeconds(0);
                                            date.setMilliseconds(0);
                                            date.setFullYear(prevState?.getFullYear() as number, prevState?.getMonth(), prevState?.getDate())
                                            return date
                                        });
                                    }
                                }
                            }}/>
                        {props.restaurantStatus === RestaurantStatusEnum.DELIVERY ?
                            <div className={'w-auto flex items-center justify-center mt-10 '}>
                                <Switch checked={isDelivery} onChange={(value) => {
                                    setIsDelivery(value.currentTarget.checked)
                                }} color={'yellow'} size={'xl'} classNames={{label: 'font-poppins-bold'}}
                                        onLabel={'Domicilio'}
                                        offLabel={'Asporto'}/>
                            </div> : <p className={'w-auto text-center mt-16 text-black font-poppins-bold'}>Asporto</p>}
                    </div>
                    <div className={'w-full h-[500px] flex flex-col overflow-y-auto'}>
                        {userData && !isDelivery ? null : <Accordion defaultValue="pippo">
                            <Accordion.Item  value={'pippo'}>
                                <Accordion.Control >
                                    <p className={'font-poppins-regular'}>{
                                        // @ts-ignore
                                        user.address ? user.address!.formatted_address : 'Inserisci dati aggiuntivi'
                                    }</p>
                                </Accordion.Control>
                                <Accordion.Panel className={'h-full overflow-y-auto'} color={'white'}>
                                    {!userData ?
                                        <div className={'w-full h-min flex flex-col gap-2 bg-white p-10 rounded-xl'}>


                                            <div className={'w-full h-[50px] flex mb-10 '}>
                                                <Select
                                                    searchable={true}
                                                    label={'Numero di telefono'}
                                                    placeholder={'Inserisci il numero di telefono del cliente'}
                                                    variant={'unstyled'}
                                                    defaultValue={user.phoneNumber}
                                                    data={usersList}
                                                    classNames={{
                                                        root: 'w-full mt-2 ml-1',
                                                        wrapper: 'w-full h-full',
                                                        input: 'w-full h-full bg-[#F2F2F2A8] rounded-lg',
                                                        label: 'text-black font-poppins-regular',
                                                    }}
                                                    onInput={(v) => {
                                                        setUser({...user, phoneNumber: v.currentTarget.value})
                                                    }}
                                                    onChange={(value) => {
                                                        if (value) {
                                                            var resValue = JSON.parse(value) as GetUserReservationAdmin
                                                            setUser({
                                                                ...user,
                                                                name: resValue.name,
                                                                surname: resValue.surname,
                                                                phoneNumber: resValue.phone,
                                                                email: resValue.email
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className={'flex gap-2 flex-row'}>

                                                <CustomTextInput customDimensions={'h-[47px] w-full'}
                                                                 defaultValue={user.name} isBlack={true}
                                                                 placeholder={'Inserisci il nome'}
                                                                 label={'Nome'}
                                                                 onChange={(name) => {
                                                                     setUser({...user, name: name})
                                                                 }}/>
                                                <CustomTextInput customDimensions={'w-full h-[47px]'}
                                                                 defaultValue={user.surname}
                                                                 label={'Cognome'} isBlack={true}
                                                                 onChange={(surname) => {
                                                                     setUser({...user, surname: surname})
                                                                 }} placeholder={'Inserisci il Cognome'}/>
                                            </div>


                                            <div className={'w-full flex h-[47px] mt-5'}>
                                                <CustomTextInput label={'Email'} isBlack={true}
                                                                 customDimensions={'w-full h-[50px]'}
                                                                 defaultValue={user.email}
                                                                 onChange={(email) => {
                                                                     setUser({...user, email: email})
                                                                 }} placeholder={'Email'}/>
                                            </div>
                                            {isDelivery ? <div className={'w-full mt-8'}>
                                                    <GoogleAddressSearch isBlack={true}
                                                                         suggestionDimensions={'h-auto w-full rounded-2xl'}
                                                                         inputDimensions={`w-full h-[60px] `}
                                                                         setNameTextInput={'formatted_address'}
                                                                         width={'100px'}
                                                                         label={'Indirizzo'}
                                                                         onPlaceSelect={(v) => {
                                                                             setUser({...user, address: v})
                                                                         }}/>
                                                </div>
                                                : null}
                                        </div> : isDelivery ?
                                            <div
                                                className={'w-full h-min  flex flex-col gap-2 bg-white p-10 rounded-xl'}>
                                                <GoogleAddressSearch isBlack={true}
                                                                     suggestionDimensions={'h-auto w-full  rounded-2xl'}
                                                                     inputDimensions={`w-full h-[60px]`}
                                                                     setNameTextInput={'formatted_address'}
                                                                     width={'100px'}
                                                                     label={'Indirizzo'}
                                                                     onPlaceSelect={(v) => {
                                                                         setUser({
                                                                             ...user,
                                                                             address: v
                                                                         })
                                                                     }}/>
                                            </div> : null
                                    }
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>}
                        <div className={'w-full flex mt-5 items-center justify-center'}>
                            <GenericTooltip txt={'Clicca qui per creare il tuo ordine!'} innerHtml={
                                <img alt={''} src={addIcon} className={'w-7 h-7 hover:scale-125 duration-500'}
                                     onClick={() => {
                                         open()
                                     }}/>
                            }/>
                        </div>
                        {listProduct && Array.from((listProduct)).length > 0 ? Array.from((listProduct)).map((item) => (
                            <div className={'w-full flex flex-col px-5'}>
                                <div
                                    className={'w-full flex whitespace-nowrap  overflow-x-auto justify-between items-center   mt-5'}>
                                <p className={'w-full whitespace-nowrap lg:text-2xl sm:text-md md:text-xl font-shadow-into-light '}>{`${item[1].quantity}X  ${item[1].product.nameProduct}`}</p>
                                    <p className={'w-full whitespace-nowrap lg:text-2xl sm:text-md md:text-xl text-center font-shadow-into-light'}>
                                        {`€  ${(item[1].product.priceOriginal + item[1].newPrice) * item[1].quantity}`}
                                    </p>
                                    <img alt={''} className={'w-5 h-5'} src={deleteIcon} onClick={() => {
                                        setListProduct(prevListProduct => {
                                            const updatedListProduct = new Map(prevListProduct);
                                            updatedListProduct.delete(item[0]);
                                            return updatedListProduct;
                                        })
                                    }}/>
                                </div>
                                {item[0].substring(36).split(',').map((value) => (
                                    <p className={' text-xl whitespace-nowrap font-shadow-into-light'}>{value.includes("note")?null:value}</p>
                                ))}
                                <p className={' text-xl whitespace-nowrap font-shadow-into-light'}>{` ${item[1].note}`}</p>

                            </div>

                        )) : null}
                    </div>

                    <p className={'w-full text-center text-4xl font-shadow-into-light pb-10 '}>{`Totale:  € ${finalPrice}`}</p>

                </BackgroundImage>
                <CustomButton title={'Conferma'} onClick={() => {

                    showWarningNotification('Sicuro di confermare questo ordine?', () => {

                        setLoading(true)
                        var dishes: Dish[] = []
                        var allergensId: string[] = []
                        var ingrList:Ingredient[] =[]
                        Array.from((listProduct!)).map((item) => {
                            item[1].product.allergensList.map((allergen)=>{
                                allergensId.push(allergen.id)
                            })
                            item[1].product.ingredients.map((ing)=>{
                                ingrList.push({
                                    addable: ing.addable,
                                    categories: ing.categories,
                                    id: ing.id?ing.id:"",
                                    idLocal: ing.idLocal,
                                    name: ing.name,
                                    price: ing.price,
                                    statusIngredient: ing.statusIngredient
                                })
                            })
                            dishes.push({
                                allergens: allergensId,
                                available: item[1].product.isAvailable,
                                description: item[1].product.description,
                                id: item[1].product.id,
                                idKitchen: item[1].product.idKitchen,
                                idLocal: item[1].product.idLocal,
                                ingredients: ingrList,
                                nameKitchen: item[1].product.nameKitchen,
                                nameProduct: item[1].product.nameProduct,
                                note: item[1].note?item[1].note:"",
                                price: item[1].product.priceOriginal+item[1].newPrice,
                                priceOriginal:item[1].product.priceOriginal,
                                quantity: item[1].quantity,
                                subNameProduct: item[1].product.subNameProduct
                            })

                        });
                        var date = dateTimeData ? new Date(dateTimeData) : new Date()
                        if (dateTimeData && dateTimeData?.getUTCHours() === 22) {
                            date.setDate(date.getDate() + 1)
                        }

                        var request: PostCommand = {
                            // @ts-ignore
                            address: user.address ? user.address!.formatted_address : '',
                            id:"",
                            delivery: dateTimeData ? date.toISOString() : new Date().toISOString(),
                            dishes: dishes,
                            email: user.email,
                            idLocal: JSON.parse(localStorage.getItem('selectedRestaurant')!).id,
                            idUser: userData ? userData.id : undefined,
                            name: user.name,
                            phoneNumber: user.phoneNumber,
                            surname: user.surname,
                            type: props.restaurantStatus === RestaurantStatusEnum.DELIVERY ? isDelivery ? 'DELIVERY' : "ASPORTO" : 'ASPORTO',
                        }
                        addCommand(request, (res) => {
                            setLoading(false)
                            if (res !== null) {
                                setReservationSuccess(true)
                                setTimeout(()=>{
                                    window.location.reload()
                                }, 2000)
                            } else {
                                setReservationSuccess(false)
                            }
                        })
                    })
                }} enable={checkEnableButton()} isLoading={loading}/>
            </div>
        )

    } else {
        return (
            <div className={'w-full h-full flex flex-col pb-20 items-center justify-center'}>
                <BackgroundImage className={'w-full h-screen p-20 m-5'}
                                 src={blocknote}>
                    <div
                        className={'gap-y-10 flex max-w-full flex-col text-white items-center h-full justify-center p-20'}>
                        <div className={'flex justify-center items-center flex-col content-center'}>
                            <img className={'max-w-[50px] mb-2'} src={check_prenotazione} alt={''}></img>
                            <div className={'text-container'}>
                                <p className={'text-base text-center text-black font-poppins-regular'}>Ordine creato
                                    con successo!</p>
                                <p className={'text-base text-center text-black font-poppins-regular'}></p>
                            </div>
                        </div>

                    </div>
                </BackgroundImage>
            </div>
        )
    }

}
