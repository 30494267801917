
import placeholderCategory from '../../assets/placeolder-categoria.svg'
import {CategoryType} from "../../types/CategoryType";
import {StatusEnum} from "../../enums/StatusEnum";
import {getSystemLanguage} from "../../utils/Utility";

export default function CategoryDeliveryItem(props: {
    isSelected?: boolean,
    category: CategoryType,
    onClick: (category: CategoryType) => void
}) {
    return (
        <div className={'w-auto h-full flex flex-col items-center cursor-pointer'} onClick={() => {
            props.onClick(props.category)
        }}>
            <img alt={''}
                 className={`w-[200px] h-[100%] rounded-[30px] border-[2px] p-0.5 ${props.isSelected ? 'border-black' : 'border-black'}`}
                 src={props.category.approvalStatus !== StatusEnum.ACCEPTED ? placeholderCategory : props.category.image}/>
            <div className={'w-full flex flex-col justify-center items-center py-1'}>
                <p className={'text-white font-poppins-bold whitespace-nowrap truncate'}>{props.category.categoryName}</p>
                {getSystemLanguage(Array.from(props.category.categoryNameTranslations.keys()))!== 'it'?<p className={'text-white text-xs font-poppins-bold whitespace-nowrap truncate'}>{props.category.categoryNameTranslations.get(getSystemLanguage(Array.from(props.category.categoryNameTranslations.keys())))}</p>:null}
            </div>
        </div>
    )
}
