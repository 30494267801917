import { makeRequest } from "../RestAdapter";
import { HttpMethodsEnum } from "../HttpMethodsEnum";

export interface TimeBandType {
    id?: string;
    restaurant_id: string;
    band: string;
}

export function getTBand(restaurantId: string, onFinish: (timeBands: any[] | null) => void) {
    makeRequest<any>(`/restaurants/time-band/${restaurantId}`, HttpMethodsEnum.GET).then((res) => {
        if (res && res.status === 200) {
            onFinish(res.data);
        } else {
            onFinish(null);
        }
    })
}

export function updateTimeBand(id: string, data: TimeBandType, onFinish: (updatedBand: TimeBandType | null) => void) {
    makeRequest<TimeBandType>(`/restaurants/time-bands/${id}`, HttpMethodsEnum.PUT, data).then((res) => {
        if (res && res.status === 200) {
            onFinish(res.data);
        } else {
            onFinish(null);
        }
    });
}

export function createTimeBand(data: TimeBandType, onFinish: (createdBand: TimeBandType | null) => void) {
    makeRequest<TimeBandType>(`/restaurants/time-bands`, HttpMethodsEnum.POST, data).then((res) => {
        if (res && res.data) {
            onFinish(res.data);
        } else {
            onFinish(null);
        }
    });
}

export function deleteTimeBand(id: string, onFinish: (isDeleted: boolean) => void) {
    makeRequest<null>(`/restaurants/time-bands/${id}`, HttpMethodsEnum.DELETE).then((res) => {
        if (res && res.status === 204) {
            onFinish(true);
        } else {
            onFinish(false);
        }
    });
}

export function getTimeBand(id: string, onFinish: (timeBand: TimeBandType | null) => void) {
    makeRequest<TimeBandType>(`/restaurants/time-bands/${id}`, HttpMethodsEnum.GET).then((res) => {
        if (res && res.status === 200) {
            onFinish(res.data);
        } else {
            onFinish(null);
        }
    });
}

export function getTimeBandsByRestaurant(restaurantId: string, onFinish: (timeBands: TimeBandType[] | null) => void) {
    makeRequest<TimeBandType[]>(`/restaurants/time-bands/restaurant/${restaurantId}`, HttpMethodsEnum.GET).then(
        (res) => {
            if (res && res.status === 200) {
                onFinish(res.data);
            } else {
                onFinish(null);
            }
        }
    );
}
