import React, { useEffect, useState } from "react";
import { Select } from "@mantine/core";
import { isMobile, isTablet } from "react-device-detect";
import editIcon from "../assets/edit-icon.svg";
import deleteIcon from "../assets/delete-icon.svg";
import { getDimension } from "../utils/Utility";
import { getLicenceFromLicenseId } from "../network/controllers/admin/AdminController";
import {
    changeTmpUser,
    createTmpUser,
    deleteTMPUserById,
    getUserAdmin,
} from "../network/controllers/PersonalController";
import { PersonalEnum } from "../enums/PersonalEnum";
import { PersonalType } from "../types/PersonalType";
import { showWarningNotification } from "../utils/NotificationUtils";
import CustomButton from "../components/CustomButton";
import CustomTextInput from "../components/generic/CustomTextInput";
import { getKitchens } from "../network/controllers/admin/KitchenController";

export interface KitchenType {
    id: string;
    idLocal: string;
    kitchenName: string;
}

export default function PersonalAdminPage() {
    const [listUser, setListUser] = useState<PersonalType[]>([]);
    const [selectedRole, setSelectedRole] = useState<PersonalEnum>();
    const [dimension, setDimension] = useState(window.innerWidth < 1280);
    const [loadingRoles, setLoadingRoles] = useState<string | null>(null);
    const [editingUser, setEditingUser] = useState<PersonalType | null>(null);
    const [newPassword, setNewPassword] = useState<string>("");
    const [licenseType, setLicenseType] = useState<string | null>(null);
    const [kitchens, setKitchens] = useState<{ value: string; label: string }[]>([]);
    const [selectedKitchen, setSelectedKitchen] = useState<string | null>(null);
    const [kitchenPopoverOpened, setKitchenPopoverOpened] = useState(false);

    const roles = Object.values(PersonalEnum);
    const isResponsive = isMobile || isTablet || dimension;

    useEffect(() => {
        getDimension(1280, setDimension);
    }, []);

    useEffect(() => {
        getLicenceFromLicenseId((response) => {
            setLicenseType(response.namePackage);
        });
    }, []);

    useEffect(() => {
        if (selectedRole) {
            const restaurantId = JSON.parse(localStorage.getItem("selectedRestaurant")!).id;
            getUserAdmin(restaurantId, selectedRole, setListUser);
        }
    }, [selectedRole]);

    const handleSavePassword = () => {
        if (!editingUser || !newPassword) return;

        const decodedAuth = atob(editingUser.auth);
        const [username] = decodedAuth.split(":");
        const updatedAuth = `${username}:${btoa(newPassword)}`;

        const updatedUser: PersonalType = {
            id: editingUser.id,
            auth: btoa(updatedAuth),
            role: editingUser.role,
            restaurantId: editingUser.restaurantId,
        };

        if (editingUser.role === PersonalEnum.KITCHEN) {
            updatedUser.idKitchen = editingUser.idKitchen;
            updatedUser.kitchenName = editingUser.kitchenName;
        }

        changeTmpUser(updatedUser, (response) => {
            if (response) {
                setListUser((prevList) =>
                    prevList.map((item) =>
                        item.id === editingUser.id ? { ...item, auth: updatedUser.auth } : item
                    )
                );
                setEditingUser(null);
                setNewPassword("");
            }
        });
    };

    const handleGenerateKitchenUser = () => {
        if (!selectedKitchen) return;

        const restaurantId = JSON.parse(localStorage.getItem("selectedRestaurant")!).id;

        setLoadingRoles(PersonalEnum.KITCHEN);
        createTmpUser(PersonalEnum.KITCHEN, restaurantId, selectedKitchen, (response) => {
            if (response) {
                getUserAdmin(restaurantId, selectedRole!, setListUser); // Reload della lista
            }
            setLoadingRoles(null);
            setKitchenPopoverOpened(false);
            setSelectedKitchen(null);
        });
    };

    const fetchKitchens = () => {
        getKitchens((response) => {
            setKitchens(response.map((kitchen) => ({ value: kitchen.id, label: kitchen.kitchenName })));
        });
    };

    useEffect(() => {
        if (selectedRole === PersonalEnum.KITCHEN) {
            fetchKitchens();
        }
    }, [selectedRole]);

    const handleDeleteUser = (user: PersonalType) => {
        showWarningNotification("Sei sicuro di voler eliminare questo utente?", () => {
            deleteTMPUserById(user.id, (success) => {
                if (success) {
                    setListUser((prevList) => prevList.filter((item) => item.id !== user.id));
                }
            });
        });
    };

    const handleFilterClick = (role: PersonalEnum) => {
        setSelectedRole(role === selectedRole ? undefined : role);
    };

    if (isMobile) {
    return (
        <div className={`w-full h-full pt-[130px]`}>
            <p className="w-full h-auto flex text-2xl items-center justify-center font-poppins-bold">Il mio personale</p>
            <div className="w-full flex items-center justify-center gap-x-6 p-4">
                {roles.map((role) => (
                    <CustomButton
                        key={role}
                        title={role}
                        enable={true}
                        isLoading={false}
                        small={true}
                        bgColor={selectedRole === role ? "#FFCC00" : "#9a9393"}
                        customStyles={`${
                            selectedRole === role ? "text-white bg-opacity-100" : "hover: text-black bg-opacity-70"
                        }`}
                        onClick={() => handleFilterClick(role)}
                    />
                ))}
            </div>
            <div className="w-full flex justify-center items-center p-4 bg-white relative">
                <CustomButton
                    title={`Genera ${selectedRole || ""}`}
                    enable={!!selectedRole}
                    isLoading={loadingRoles === selectedRole}
                    small={true}
                    onClick={() => {
                        if (selectedRole === PersonalEnum.KITCHEN) {
                            setKitchenPopoverOpened(!kitchenPopoverOpened);
                        } else if (selectedRole) {
                            const restaurantId = JSON.parse(localStorage.getItem("selectedRestaurant")!).id;

                            setLoadingRoles(selectedRole);
                            createTmpUser(selectedRole, restaurantId, null, (response) => {
                                if (response) {
                                    getUserAdmin(restaurantId, selectedRole, setListUser); // Ricarica la lista utenti
                                }
                                setLoadingRoles(null);
                            });
                        }
                    }}
                    customStyles="bg-yellow text-white px-6 py-2 rounded"
                />
                {kitchenPopoverOpened && (
                    <div className="absolute top-full mt-2 w-auto rounded-lg shadow-lg p-4 z-50">
                        {selectedRole === PersonalEnum.KITCHEN && (
                            <div className="w-fit h-auto flex  bg-white flex-col items-center justify-center gap-2">
                                <Select
                                    placeholder="Seleziona una cucina"
                                    data={kitchens}
                                    value={selectedKitchen}
                                    onChange={setSelectedKitchen}
                                    className="bg-white rounded-lg shadow-sm"
                                />
                                <CustomButton
                                    title="Conferma"
                                    enable={!!selectedKitchen}
                                    isLoading={loadingRoles === PersonalEnum.KITCHEN}
                                    small={true}
                                    onClick={handleGenerateKitchenUser}
                                    customStyles="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <table className="w-full h-auto ">
                <thead>
                <tr className="max-w-fit ">
                    <th className="text-left p-2 font-poppins-semi-bold">Nome Utenza</th>
                    <th className="text-left p-2 font-poppins-semi-bold">Password</th>
                    {selectedRole === PersonalEnum.KITCHEN && (
                        <th className="text-left p-2  font-poppins-semi-bold">ID Cucina</th>
                    )}
                    <th className="text-center p-2 font-poppins-semi-bold">Azione</th>
                </tr>
                </thead>
                <tbody>
                {selectedRole && listUser.length === 0 ? (
                    <tr>
                        <td
                            colSpan={selectedRole === PersonalEnum.KITCHEN ? 4 : 3}
                            className={`p-2 text-center font-poppins-semi-bold`}
                        >
                            Nessuna utenza trovata
                        </td>
                    </tr>
                ) : (
                    listUser.map((personal, index) => {
                        const decodedAuth = atob(personal.auth);
                        const [username, passwordEncoded] = decodedAuth.split(":");
                        const password = atob(passwordEncoded);

                        return (
                            <tr
                                key={personal.id}
                                className={`${
                                    index % 2 === 0 ? "bg-white" : "bg-gray-200"
                                } hover:bg-gray-100 transition-colors duration-150`}
                            >
                                <td className="p-2">{username}</td>
                                <td className="p-2">
                                    {editingUser?.id === personal.id ? (
                                        <CustomTextInput
                                            defaultValue=""

                                            placeholder="Inserisci nuova password"
                                            onChange={(value: string) => setNewPassword(value)}
                                        />
                                    ) : (
                                        password
                                    )}
                                </td>
                                {selectedRole === PersonalEnum.KITCHEN && (
                                    <td className="p-2">
                                        {personal.kitchenName || "Nessuna cucina associata"}
                                    </td>
                                )}
                                <td className="p-2 text-right flex items-center justify-center gap-4">
                                    {editingUser?.id === personal.id ? (
                                        <CustomButton
                                            title="Salva"
                                            enable={!!newPassword}
                                            isLoading={false}
                                            small={true}
                                            onClick={handleSavePassword}
                                        />
                                    ) : (
                                        <button
                                            className="flex items-center justify-center w-8 h-8 hover:scale-110 transition-transform duration-200"
                                            onClick={() => setEditingUser(personal)}
                                        >
                                            <img src={editIcon} alt="Edit" className="w-6 h-6" />
                                        </button>
                                    )}
                                    <button
                                        className="flex items-center justify-center w-8 h-8 hover:scale-110 transition-transform duration-200"
                                        onClick={() => handleDeleteUser(personal)}
                                    >
                                        <img src={deleteIcon} alt="Delete" className="w-6 h-6" />
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                )}
                </tbody>
            </table>
        </div>
    );
}else{
        return (
            <div className={`w-full h-full ${isResponsive ? "flex flex-col" : ""} pt-[130px]`}>
                <p className="w-full h-auto flex text-4xl font-poppins-bold">Il mio personale</p>
                <div className="w-full flex items-center justify-center gap-x-6 p-4">
                    {roles.map((role) => (
                        <CustomButton
                            key={role}
                            title={role}
                            enable={true}
                            isLoading={false}
                            small={true}
                            bgColor={selectedRole === role ? "#FFCC00" : "#9a9393"}
                            customStyles={`${
                                selectedRole === role ? "text-white bg-opacity-100" : "hover: text-black bg-opacity-70"
                            }`}
                            onClick={() => handleFilterClick(role)}
                        />
                    ))}
                </div>
                <div className="w-full flex justify-end p-4 bg-white relative">
                    <CustomButton
                        title={`Genera ${selectedRole || ""}`}
                        enable={!!selectedRole}
                        isLoading={loadingRoles === selectedRole}
                        small={true}
                        onClick={() => {
                            if (selectedRole === PersonalEnum.KITCHEN) {
                                setKitchenPopoverOpened(!kitchenPopoverOpened);
                            } else if (selectedRole) {
                                const restaurantId = JSON.parse(localStorage.getItem("selectedRestaurant")!).id;

                                setLoadingRoles(selectedRole);
                                createTmpUser(selectedRole, restaurantId, null, (response) => {
                                    if (response) {
                                        getUserAdmin(restaurantId, selectedRole, setListUser); // Ricarica la lista utenti
                                    }
                                    setLoadingRoles(null);
                                });
                            }
                        }}
                        customStyles="bg-yellow text-white px-6 py-2 rounded"
                    />
                    {kitchenPopoverOpened && (
                        <div className="absolute top-full mt-2 right-0 w-auto rounded-lg bg-white shadow-lg p-4 z-50">
                            {selectedRole === PersonalEnum.KITCHEN && (
                                <div className="w-fit h-auto flex flex-col items-center justify-center gap-2">
                                    <Select
                                        placeholder="Seleziona una cucina"
                                        data={kitchens}
                                        value={selectedKitchen}
                                        onChange={setSelectedKitchen}
                                        className="bg-white rounded-lg shadow-sm"
                                    />
                                    <CustomButton
                                        title="Conferma"
                                        enable={!!selectedKitchen}
                                        isLoading={loadingRoles === PersonalEnum.KITCHEN}
                                        small={true}
                                        onClick={handleGenerateKitchenUser}
                                        customStyles="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <table className="w-full">
                    <thead>
                    <tr className="bg-gray-100">
                        <th className="text-left p-4 font-poppins-semi-bold">Nome Utenza</th>
                        <th className="text-left p-4 font-poppins-semi-bold">Password</th>
                        {selectedRole === PersonalEnum.KITCHEN && (
                            <th className="text-left p-4 font-poppins-semi-bold">ID Cucina</th>
                        )}
                        <th className="text-center p-4 font-poppins-semi-bold">Azione</th>
                    </tr>
                    </thead>
                    <tbody>
                    {selectedRole && listUser.length === 0 ? (
                        <tr>
                            <td
                                colSpan={selectedRole === PersonalEnum.KITCHEN ? 4 : 3}
                                className={`p-4 text-center ${
                                    isResponsive ? "text-2xl" : "text-3xl"
                                } font-poppins-semi-bold ${isResponsive ? "pt-24" : ""}`}
                            >
                                Nessuna utenza trovata
                            </td>
                        </tr>
                    ) : (
                        listUser.map((personal, index) => {
                            const decodedAuth = atob(personal.auth);
                            const [username, passwordEncoded] = decodedAuth.split(":");
                            const password = atob(passwordEncoded);

                            return (
                                <tr
                                    key={personal.id}
                                    className={`${
                                        index % 2 === 0 ? "bg-white" : "bg-gray-200"
                                    } hover:bg-gray-100 transition-colors duration-150`}
                                >
                                    <td className="p-4">{username}</td>
                                    <td className="p-4">
                                        {editingUser?.id === personal.id ? (
                                            <CustomTextInput
                                                defaultValue=""
                                                label="Nuova password"
                                                placeholder="Inserisci nuova password"
                                                onChange={(value: string) => setNewPassword(value)}
                                            />
                                        ) : (
                                            password
                                        )}
                                    </td>
                                    {selectedRole === PersonalEnum.KITCHEN && (
                                        <td className="p-4">
                                            {personal.kitchenName || "Nessuna cucina associata"}
                                        </td>
                                    )}
                                    <td className="p-4 text-right flex items-center justify-center gap-4">
                                        {editingUser?.id === personal.id ? (
                                            <CustomButton
                                                title="Salva"
                                                enable={!!newPassword}
                                                isLoading={false}
                                                small={true}
                                                onClick={handleSavePassword}
                                            />
                                        ) : (
                                            <button
                                                className="flex items-center justify-center w-8 h-8 hover:scale-110 transition-transform duration-200"
                                                onClick={() => setEditingUser(personal)}
                                            >
                                                <img src={editIcon} alt="Edit" className="w-6 h-6" />
                                            </button>
                                        )}
                                        <button
                                            className="flex items-center justify-center w-8 h-8 hover:scale-110 transition-transform duration-200"
                                            onClick={() => handleDeleteUser(personal)}
                                        >
                                            <img src={deleteIcon} alt="Delete" className="w-6 h-6" />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                    </tbody>
                </table>
            </div>
        );

    }}
