import { useState } from "react";
import { LiveDataType } from "../../types/LiveDataType";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs"; // Libreria per gestire date

export default function DeliveryLiveStatPage(props: { liveStats: LiveDataType }) {
    const { liveStats = {} as LiveDataType } = props; // Default per `liveStats`

    const itemsPerPage = 10; // Numero di valori visibili per pagina (default)
    const ingredientItemsPerPage = 5; // Numero di valori visibili per il grafico ingredienti
    const [currentPage, setCurrentPage] = useState(0);
    const [ingredientPage, setIngredientPage] = useState(0);

    // Funzione per dividere i dati in pagine
    const getPagedData = (data: any[], page: number, perPage: number) => {
        const startIndex = page * perPage;
        const endIndex = startIndex + perPage;
        return data.slice(startIndex, endIndex);
    };

    const pagedIngredients = getPagedData(liveStats.ingredientsScoreAsportoDelivery || [], ingredientPage, ingredientItemsPerPage);

    // Ottieni l'orario attuale
    const now = new Date();

    // Funzione per convertire la fascia oraria (stringa) in un oggetto `Date`
    const convertTimeBandToDate = (timeBand: string) => {
        const [hours, minutes] = timeBand.split(":").map(Number);
        const date = new Date();
        date.setHours(hours, minutes, 0, 0); // Imposta ore e minuti
        return date;
    };

    // Filtra le fasce orarie per includere solo quelle future o attuali
    const filteredComandaOrdersByTimeSlot = liveStats.comandaOrdersByTimeSlot?.filter((slot) => {
        const slotTime = convertTimeBandToDate(slot.time_band);
        return slotTime >= now; // Includi solo fasce orarie uguali o future
    }) || [];

    // Paginazione per `comandaOrdersByTimeSlot`
    const pagedComandaCategories = getPagedData(
        filteredComandaOrdersByTimeSlot.map((slot) => slot.time_band),
        currentPage,
        itemsPerPage
    );

    const pagedComandaAsportoData = getPagedData(
        filteredComandaOrdersByTimeSlot.map((slot) => slot.asportoCount),
        currentPage,
        itemsPerPage
    );

    const pagedComandaDeliveryData = getPagedData(
        filteredComandaOrdersByTimeSlot.map((slot) => slot.deliveryCount),
        currentPage,
        itemsPerPage
    );

    // Calcolo pagine totali
    const totalPages = Math.ceil((liveStats.comandaOrdersByTimeSlot?.length || 0) / itemsPerPage);
    const totalIngredientPages = Math.ceil((liveStats.ingredientsScoreAsportoDelivery?.length || 0) / ingredientItemsPerPage);

    // Opzioni per il grafico di `comandaOrdersByTimeSlot`
    const comandaOrdersByTimeSlotOptions: ApexCharts.ApexOptions = {
        chart: {
            type: "bar",
            background: "#000",
            toolbar: { show: false },
        },
        xaxis: {
            categories: pagedComandaCategories,
            labels: { style: { colors: "#FFF" }, rotate: -45 },
        },
        yaxis: {
            labels: { style: { colors: "#FFF" } },
        },
        title: {
            text: "Comande per Fascia Oraria (Asporto e Domicilio)",
            align: "center",
            style: { color: "#FFF" },
        },
        grid: { borderColor: "#fff" },
        tooltip: { theme: "dark" },
        legend: {
            show: true,
            position: "bottom",
            labels: { colors: ["#FFCC00", "#00BFFF"] },
        },
        colors: ["#FFCC00", "#00BFFF"], // Giallo e Blu
    };

    const comandaOrdersByTimeSlotSeries = [
        {
            name: "Asporto",
            data: pagedComandaAsportoData,
        },
        {
            name: "Domicilio",
            data: pagedComandaDeliveryData,
        },
    ];

    // Opzioni per il grafico degli ingredienti
    const ingredientsOptions: ApexCharts.ApexOptions = {
        chart: {
            type: "bar",
            background: "#000",
            toolbar: { show: false },
        },
        xaxis: {
            categories: pagedIngredients.map((ingredient) => ingredient.name),
            labels: { style: { colors: "#FFF" } },
        },
        yaxis: {
            labels: { style: { colors: "#FFF" } },
        },
        title: {
            text: "Ingredienti Aggiunti e Rimossi",
            align: "center",
            style: { color: "#FFF" },
        },
        grid: { borderColor: "#fff" },
        tooltip: { theme: "dark" },
        legend: {
            show: true,
            position: "bottom",
            labels: { colors: "#FFF" },
        },
        colors: ["#FF00FF", "#0000FF", "#FF9800", "#00FFFF"], // Magenta, Blu, Orange, Cyan
    };

    const ingredientsSeries = [
        {
            name: "Aggiunti Asporto",
            data: pagedIngredients.map((ingredient) => ingredient.addedCountAsporto),
        },
        {
            name: "Rimossi Asporto",
            data: pagedIngredients.map((ingredient) => ingredient.removedCountAsporto),
        },
        {
            name: "Aggiunti Domicilio",
            data: pagedIngredients.map((ingredient) => ingredient.addedCountDelivery),
        },
        {
            name: "Rimossi Domicilio",
            data: pagedIngredients.map((ingredient) => ingredient.removedCountDelivery),
        },
    ];

    // Gestione eventi di navigazione
    const handleNextPage = () => {
        if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    };

    const handleNextIngredientPage = () => {
        if (ingredientPage < totalIngredientPages - 1) setIngredientPage(ingredientPage + 1);
    };

    const handlePreviousIngredientPage = () => {
        if (ingredientPage > 0) setIngredientPage(ingredientPage - 1);
    };

    return (
        <div className="w-full h-full p-4">
            {/* Prima Row */}
            <div className="grid sm:grid-cols-1 lg:grid-cols-6 gap-4 mb-8">
                {/* Box Asporto/Domicilio */}
                <div className="bg-black p-4 rounded-xl shadow sm:col-span-6 lg:col-span-2">
                    <div className="flex flex-col divide-y divide-gray-400">
                        {/* Asporto */}
                        <div className="pb-4">
                            <p className="text-yellow font-poppins-bold">Asporto</p>
                            <div className="w-full flex justify-between">
                                <p className="text-yellow font-poppins-regular">Totale comande:</p>
                                <p className="text-yellow font-poppins-regular">{liveStats.nAsporto}</p>
                            </div>
                            <div className="w-full flex justify-between">
                                <p className="text-yellow font-poppins-regular">N° piatti per comanda:</p>
                                <p className="text-yellow font-poppins-regular">{liveStats.meanPlatesForAsporto}</p>
                            </div>
                            <div className="w-full flex justify-between">
                                <p className="text-yellow font-poppins-regular">Spesa media comanda:</p>
                                <p className="text-yellow font-poppins-regular">{liveStats.meanExpensePerAsporto}</p>
                            </div>
                        </div>

                        {/* Domicilio */}
                        <div className="pt-4">
                            <p className="text-[#00BFFF] font-poppins-bold">Domicilio</p>
                            <div className="w-full flex justify-between">
                                <p className="text-[#00BFFF] font-poppins-regular">Totale comande:</p>
                                <p className="text-[#00BFFF] font-poppins-regular">{liveStats.nDelivery}</p>
                            </div>
                            <div className="w-full flex justify-between">
                                <p className="text-[#00BFFF] font-poppins-regular">N° piatti per
                                    comanda: </p>
                                <p className="text-[#00BFFF] font-poppins-regular">{liveStats.meanPlatesForDelivery}</p>
                            </div>
                            <div className="w-full flex justify-between">
                                <p className="text-[#00BFFF] font-poppins-regular">Spesa media comanda: </p>
                                <p className="text-[#00BFFF] font-poppins-regular">{liveStats.meanExpensePerDelivery}</p>
                            </div>

                        </div>
                    </div>
                </div>
                {/* Grafico Barre con Paginazione */}
                <div className="bg-black p-4 rounded-xl shadow sm:col-span-6 lg:col-span-4">
                    {totalPages > 1 && (
                        <div className="flex justify-between mb-4">
                            <button
                                onClick={handlePreviousPage}
                                disabled={currentPage === 0}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8592;
                            </button>
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages - 1}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8594;
                            </button>
                        </div>
                    )}
                    <ReactApexChart
                        options={comandaOrdersByTimeSlotOptions}
                        series={comandaOrdersByTimeSlotSeries}
                        type="bar"
                        height={300}
                    />
                </div>
            </div>

            {/* Seconda Row */}
            <div className="grid sm:grid-cols-1 lg:grid-cols-6 gap-4 mb-8">
                {/* Grafico a Torta */}
                <div className="bg-black p-4 rounded-xl shadow sm:col-span-3 lg:col-span-2">
                    <ReactApexChart
                        options={{
                            chart: { type: "pie", background: "#000", toolbar: { show: false } },
                            labels: ["Asporto", "Domicilio"],
                            colors: ["#FFCC00", "#00BFFF"],
                            title: {
                                text: "Rapporto Asporto/Domicilio",
                                align: "center",
                                style: { color: "#FFF" },
                            },
                            dataLabels: { enabled: true },
                            tooltip: { theme: "dark" },
                            legend: { labels: { colors: "#FFF" } },
                        }}
                        series={[liveStats.nAsporto || 0, liveStats.nDelivery || 0]}
                        type="pie"
                        height={300}
                    />
                </div>

                {/* Grafico Ingredienti con Paginazione */}
                <div className="bg-black p-4 rounded-xl shadow sm:col-span-3 lg:col-span-4">
                    {totalIngredientPages > 1 && (
                        <div className="flex justify-between mb-4">
                            <button
                                onClick={handlePreviousIngredientPage}
                                disabled={ingredientPage === 0}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8592;
                            </button>
                            <button
                                onClick={handleNextIngredientPage}
                                disabled={ingredientPage === totalIngredientPages - 1}
                                className="text-white text-2xl disabled:opacity-50"
                            >
                                &#8594;
                            </button>
                        </div>
                    )}
                    <ReactApexChart
                        options={ingredientsOptions}
                        series={ingredientsSeries}
                        type="bar"
                        height={300}
                    />
                </div>
            </div>
        </div>
    );
}
