import {makeRequest} from "../../RestAdapter";
import {HttpMethodsEnum} from "../../HttpMethodsEnum";
import {IngredientType} from "../../../types/IngredientType";
import {showFailNotification, showSuccessNotification} from "../../../utils/NotificationUtils";
import {getSystemLanguage} from "../../../utils/Utility";



export function getIngredients(idCategory:string,onFinish:(response: IngredientType[]) => void) {
    makeRequest<IngredientType[]>(`/restaurants/ingredients/search?idLocal=${JSON.parse(localStorage.getItem('selectedRestaurant') as string).id}&idCategory=${idCategory}`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()){
            onFinish(res.data?res.data:[])
        }else{
            onFinish([])
        }
    })
}

//restituisce solo gli ingredienti che sono disponibili per l'aggiunta dentro la categoria selezionata
export function getAvailableIngredients(idCategory:string,onFinish:(response: IngredientType[]) => void) {
    makeRequest<IngredientType[]>(`/restaurants/ingredients/isAddable/${JSON.parse(localStorage.getItem('selectedRestaurantClient') as string).id}/${idCategory}`, HttpMethodsEnum.GET).then((res) => {
        res.data?.forEach((item)=>{
            item.ingredientNameTranslations = new Map(Object.entries(item.ingredientNameTranslations!!))
            item.name = item.ingredientNameTranslations.get(getSystemLanguage(Array.from(item.ingredientNameTranslations.keys())))!!
        })
        if (res.isSuccessfull()){
            onFinish(res.data?res.data:[])
        }else{
            onFinish([])
        }
    })
}
export function getAvailableIngredientsAdmin(idCategory:string,onFinish:(response: IngredientType[]) => void) {
    makeRequest<IngredientType[]>(`/restaurants/ingredients/isAddable/${JSON.parse(localStorage.getItem('selectedRestaurant') as string).id}/${idCategory}`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()){
            onFinish(res.data?res.data:[])
        }else{
            onFinish([])
        }
    })
}

export function addIngredient(request:IngredientType,onFinish:(response: IngredientType[]) => void) {
    makeRequest<IngredientType[]>(`/restaurants/ingredients`, HttpMethodsEnum.POST,request).then((res) => {
        if (res.isSuccessfull()){
            onFinish(res.data?res.data:[])
            showSuccessNotification('Ingrediente',`${request.name} creato con successo!`,3000)
        }else{
            if (res.status===420){
                showFailNotification('Attenzione','Prodotto Già esistente',3000)
            }else{
                showFailNotification('Ingredienti','Errore ! controlla i campi inseriti e riprova',3000)
            }
            onFinish([])


        }
    })
}


export function updateIngredient(request:{id:string,ingredient:IngredientType},onFinish:(response: IngredientType[]) => void) {
    makeRequest<IngredientType[]>(`/restaurants/ingredients/update/${request.id}`, HttpMethodsEnum.PUT,request.ingredient).then((res) => {
        if (res.isSuccessfull()){
            onFinish(res.data?res.data:[])
            showSuccessNotification('Ingrediente',`${request.ingredient.name} modificato con successo!`,3000)
        }else{
            if (res.status===420){
                showFailNotification('Attenzione','Prodotto Già esistente',3000)
            }else{
                showFailNotification('Ingredienti','Errore controlla i campi inseriti e riprova',3000)
            }
            onFinish([])
        }
    })
}

export function deleteIngredient(ingredientId:string,onFinish:(response: any) => void) {
    makeRequest<any>(`/restaurants/ingredients/${ingredientId}`, HttpMethodsEnum.DELETE).then((res) => {
            onFinish(res)
    })
}


