import React, {useEffect, useState} from "react";
import {AddRestaurantRequest, License} from "../../../types/AddRestaurantRequest";
import {Divider, Group, Stepper} from "@mantine/core";
import SearchClientDataForm from "./SearchClientDataForm";
import CustomButton from "../../../components/CustomButton";
import RestaurantDataForm from "./RestaurantDataForm";
import SubscritionPlanPage from "../../SubscritionPlanPage";
import {stripeRegistration} from "../../../network/controllers/RestaurantsControllers";
import {LoginResponseType} from "../../../types/LoginResponseType";
import {checkUser} from "../../../network/controllers/seller/GenericSellerController";
import {notificationSuccess} from "../../../utils/NotificationUtils";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";
import LocalListComponent from "../../../components/LocalListComponent";
import CheckoutformRenew from "../../../components/CheckoutformRenew";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {getLicenceFromLicenseId} from "../../../network/controllers/admin/AdminController";
import {makeRequest} from "../../../network/RestAdapter";
import {HttpMethodsEnum} from "../../../network/HttpMethodsEnum";
import {LicenseType} from "../../../types/LicenseType";
import {formatStringDuration} from "../../../utils/StringUtils";
import LicenseRecapComponent from "../../../components/LicenseRecapComponent";
import {PackageType} from "../../../types/PackageType";
import CustomTextInput from "../../../components/generic/CustomTextInput";
import check from "../../../assets/couponCheck.svg"
import deleteIcon from "../../../assets/deleteCoupon.svg"
import {getDimension} from "../../../utils/Utility";

export interface OpeningHours {
    openNow: boolean
    periods: null
    weekdayText: string[]
}

interface ClientSecretResponse {
    clientSecret: string;
    idStripe: string;
}

const stripePromise = loadStripe(process.env["REACT_APP_STRIPE_PRIVATE_KEY"] as string);

export default function AddRestaurantSellerPage(props: { isRenew: boolean }) {
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 4 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    const [nextBtnStates, setNextBtnState] = useState({isEnable: false, isLoading: false})
    var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType
    const [loading, setLoading] = useState(false)
    const [stripeId, setStripeId] = useState<string|null>()
    const navigate = useNavigate()
    const [dataStripe, setDataStripe] = useState({
        clientSecret: '',
        idStripe: ''
    });
    const [checKCouponj, setChecKCouponj] = useState(false)
    const [newLicense, setNewLicense] = useState<License>()
    const [newPack, setNewPack] = useState<PackageType>()
    const [coupon, setCoupon] = useState('');
    const [dataToSend, setDataToSend] = useState<AddRestaurantRequest>({
        addressComponents: [],
        clientUserDto: {
            address: "aaaa",
            cf: "",
            city: "b",
            civicNumber: "1",
            email: "",
            idSocial: "",
            name: 'dd',
            nameSocial: "",
            phone: "dddddddddd",
            social: false,
            state: "italia",
            surname: "dd",
            zipCode: "11111"
        },
        companyName: "",
        stripeId:'',
        contactEmail: "",
        description: "",
        foto: "",
        geometry: undefined,
        idClient: "",
        idLicenseType: "",
        inShowcase: false,
        kitchenVisible: false,
        legalForm: "",
        nameRestaurant: "",
        openingHours: undefined,
        phoneNumber: "",
        placeId: "",
        priceLevel: 0,
        rating: 0,
        review: [],
        subscriptionActive: false,
        tags: [],
        vatNumber: "",
        webSite: "",
        seller: 'gicod',
        idPackage: '',
    })

    const [dimension, setDimension] = useState(window.innerWidth < 1280)

    useEffect(() => {
        getDimension(980, (res)=>{
            console.log(res);
            setDimension(res)
        })
    },[])

    useEffect(() => {
        if (userData) {
            checkUser(userData.email, (user) => {
                setDataToSend({
                    ...dataToSend, clientUserDto: {
                        ...dataToSend.clientUserDto,
                        address: user.data.address,
                        cf: user.data.cf,
                        city: user.data.city,
                        civicNumber: user.data.civicNumber,
                        email: user.data.email,
                        name: user.data.name,
                        phone: user.data.phone,
                        state: user.data.state,
                        surname: user.data.surname,
                        zipCode: user.data.zipCode,
                    }
                })
            })
        }
    }, []);

    useEffect(() => {
        if (checKCouponj){
            setStripeId(null)
            createStripeSecret((newLicense!.price-calculateDiscount(newLicense!.price, 20)), ()=>{})
        }else {
            if (newLicense) {
                setStripeId(null)
                createStripeSecret(newLicense!.price)
            }
        }
    }, [checKCouponj]);

    window.onpopstate = () => {
        navigate("/welcome");
    }

    function adIva(price:number):number{
        return price + (price *0.22)
    }

    function calculateIva(price:number):number{
        return (price *0.22)
    }

    function calculateDiscount(price:number, discount:number):number{
        return price*(discount/100)
    }

    function createStripeSecret(pice:number, onSuccess?:()=>void){
        var obj = {
            ammountCent: (pice! + (pice * 22)/100)*100 ,
            dataExtra: {},
            description: newLicense?.description == null ? '' : newLicense?.description,
            stripeId: stripeId?stripeId:JSON.parse(localStorage.getItem('selectedRestaurant')!).stripeId,
            nameRestaurant: dataToSend.nameRestaurant
        }

        makeRequest<ClientSecretResponse>('/users/restaurants/client_secret', HttpMethodsEnum.POST, obj).then((res) => {
            setStripeId(JSON.parse(localStorage.getItem('selectedRestaurant')!).stripeId)
            setDataStripe({
                clientSecret: res.data!.clientSecret,
                idStripe: res.data!.idStripe
            });
            setStripeId(res.data!.idStripe)

            if (onSuccess) {
                onSuccess()
            }
        })
    }

    return (
        <div className='w-full h-auto flex flex-col items-center justify-center'>
            <div className={'w-full flex flex-col gap-y-6'}>
                <Stepper color={'yellow'} orientation={isMobile ? 'vertical' : 'horizontal'}
                         className={`mt-10 ${isMobile ? 'px-1 ' : 'px-10'}`} active={active}>
                    <Stepper.Step description="Selezione Pacchetti">
                        <SubscritionPlanPage isRenew={props.isRenew} allowNestStep={() => {
                            nextStep()
                        }} onLicenseSelect={(pack, license, allowNextStep) => {
                            if (allowNextStep) {
                                console.log('license', license)
                                setNewLicense(license[0])
                                setNewPack(pack)
                                setDataToSend({...dataToSend, idLicenseType: license[0].id, idPackage: pack!.id})
                            }
                            setNextBtnState({...nextBtnStates, isEnable: allowNextStep})
                        }} isRestaurantAdding={true}/>
                    </Stepper.Step>
                    <Stepper.Step description="Dati Locale">
                        {props.isRenew ?
                            <div className={'w-full h-auto'}>
                                <LicenseRecapComponent license={newLicense!!} imagePack={newPack?.image!!}
                                                       namePack={newPack?.name!!}/>
                            </div>
                            :
                            <RestaurantDataForm data={dataToSend} allowNext={(allowed, localData) => {
                                if (allowed) {
                                    var tmpArray: OpeningHours = {
                                        openNow: false,
                                        periods: null,
                                        weekdayText: []
                                    }
                                    if (localData?.hours) {
                                        Array.from(Object.entries(localData.hours!)).map((item: [string, any]) => {
                                            tmpArray.weekdayText.push(item[1])
                                        })
                                    }
                                    setDataToSend({
                                        ...dataToSend,
                                        companyName: localData?.companyName!,
                                        nameRestaurant: localData?.nameRestaurant!,
                                        legalForm: localData?.legalForm!,
                                        contactEmail: localData?.contactEmail!,
                                        vatNumber: localData?.vatNumber!,
                                        phoneNumber: localData?.phoneNumber!,
                                        webSite: localData?.webSite!,
                                        foto: localData?.foto!,
                                        inShowcase: localData?.isInShowcase!,
                                        kitchenVisible: localData?.kitchenVisible!,
                                        description: localData?.description!,
                                        review: localData?.review,
                                        rating: localData?.rating,
                                        priceLevel: localData?.priceLevel,
                                        placeId: localData?.place_id,
                                        openingHours: localData?.hours ? tmpArray : localData?.openingHours,
                                        geometry: localData?.geometry,
                                        addressComponents: localData?.addressComponent,
                                    })
                                }
                                setNextBtnState({...nextBtnStates, isEnable: allowed})
                            }}/>
                        }
                    </Stepper.Step>
                    {userData ? null :
                        <Stepper.Step description="Dati Cliente">
                            <SearchClientDataForm readonly={true} allowNext={(allowed, client) => {
                                if (allowed) {
                                    setDataToSend({...dataToSend, clientUserDto: client})
                                }
                                setNextBtnState({...nextBtnStates, isEnable: allowed})
                            }} data={dataToSend}/>
                        </Stepper.Step>
                    }
                    <Stepper.Step description="Pagamento">
                        {newLicense?.price === 0 ?
                            <div className={'flex flex-col w-full h-full justify-center items-center'}>
                                <div className={'w-full h-full flex flex-col items-center pt-44'}>
                                    <div className="flex flex-col gap-y-4">
                                        <p className={'w-full h-auto text-4xl font-poppins-bold text-center'}>
                                            {'Benvenuto su MENUS ' + dataToSend.nameRestaurant + '!'}
                                        </p>
                                        <p className={'w-full h-auto text-2xl font-poppins-bold text-center pb-32'}>
                                            Il nostro viaggio inizia qui...
                                        </p>
                                    </div>
                                </div>
                                <div className={'w-full h-full flex items-center justify-center'}>
                                    <CustomButton
                                        title={"Attiva periodo di prova"}
                                        onClick={() => {
                                            makeRequest<ClientSecretResponse>(
                                                `/users/restaurants/enableFreeTrial/${stripeId ? stripeId : JSON.parse(localStorage.getItem('selectedRestaurant')!).stripeId}`,
                                                HttpMethodsEnum.GET
                                            ).then((res) => {
                                                if (res.isSuccessfull()) {
                                                    if (userData && userData.role.includes('ADMIN')) {
                                                        navigate('/admin')
                                                    } else {
                                                        navigate('/welcome')
                                                    }
                                                    window.location.reload()
                                                }
                                            })
                                        }}
                                        enable={true}
                                        isLoading={false}
                                    />
                                </div>
                            </div>
                            :
                            stripeId ?
                                <Elements stripe={stripePromise} options={dataStripe}>
                                    <div
                                        className={`${isMobile || dimension ? 'w-full h-full flex flex-col justify-center items-center' : 'w-full h-full flex justify-center items-center my-5 gap-x-5'}`}>
                                        <div
                                            className={`${isMobile || dimension ? 'w-full h-full border-2 rounded-xl items-center justify-center gap-y-5 p-5 border-black shadow-2xl' : 'w-[30%] h-full flex flex-col border-2 rounded-xl gap-y-2 p-5 border-black shadow-2xl'}`}>
                                            <h1 className="font-poppins-bold text-2xl text-center">
                                                RIEPILOGO PAGAMENTO
                                            </h1>
                                            <h2 className={`${isMobile ? 'font-poppins-semi-bold text-lg text-center' : 'font-poppins-semi-bold text-xl whitespace-normal'}`}>
                                                il tuo nuovo piano di abbonamento comprende
                                            </h2>
                                            <h1 className={`${isMobile ? 'text-center font-poppins-bold text-xl whitespace-break-spaces' : 'font-poppins-bold whitespace-normal text-xl'}`}>
                                                {'durata abbonamento - ' + (newLicense?.duration ? formatStringDuration(newLicense.duration) : 'N/A ')}
                                            </h1>

                                            <div className="flex w-full justify-between items-center mt-10">
                                                <h1 className="font-poppins-bold text-xl">{newLicense?.name}</h1>
                                                <h1 className="font-poppins-semi-bold text-2xl">
                                                    {'€' + (newLicense?.price ? newLicense.price.toFixed(2) : 'N/A')}
                                                </h1>
                                            </div>

                                           {/* <div className="w-full h-auto flex justify-between items-center">
                                                <p className="font-poppins-bold text-xl">Codice Sconto</p>
                                                {checKCouponj ?
                                                    <div className={'flex justify-center items-center gap-x-2'}>
                                                        <p className="font-poppins-semi-bold text-xl">{coupon}</p>
                                                        <img
                                                            className={'w-5 h-5 pr-1 hover:scale-125 duration-500 cursor-pointer'}
                                                            src={deleteIcon}
                                                            onClick={() => {
                                                                setCoupon('')
                                                                setChecKCouponj(false)
                                                            }}
                                                        />
                                                    </div>
                                                    :
                                                    <div
                                                        className={'w-auto h-auto flex border-2 items-center justify-center border-black rounded-xl'}>
                                                        <CustomTextInput
                                                            readOnly={false}
                                                            placeholder={'Inserisci il tuo Coupon'}
                                                            defaultValue={coupon}
                                                            onChange={(value) => setCoupon(value)}
                                                        />
                                                        <img
                                                            className={'w-7 h-7 pr-1 hover:scale-125 duration-500 cursor-pointer'}
                                                            src={check}
                                                            onClick={() => {
                                                                setChecKCouponj(!checKCouponj)
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>*/}

                                            {checKCouponj ?
                                                <div>
                                                    <div className="flex w-full justify-between items-center">
                                                        <h1 className="font-poppins-bold text-xl">Sconto 20%</h1>
                                                        <h1 className="font-poppins-semi-bold text-2xl mb-3">
                                                            {'€' + calculateDiscount(newLicense!.price, 20).toFixed(2)}
                                                        </h1>
                                                    </div>

                                                    <div className="flex w-full justify-between items-center">
                                                        <h1 className="font-poppins-bold text-xl">Prezzo {newLicense?.name} Scontato</h1>
                                                        <h1 className="font-poppins-semi-bold text-2xl mb-3">
                                                            {`€ ${(newLicense!.price - calculateDiscount(newLicense!.price, 20)).toFixed(2)}`}
                                                        </h1>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }

                                            <div className="flex w-full justify-between items-center">
                                                <h1 className="font-poppins-bold text-xl">IVA (22%)</h1>
                                                <h1 className="font-poppins-semi-bold text-2xl mb-3">
                                                    {`€ ${newLicense ? checKCouponj ? calculateIva((newLicense!.price - calculateDiscount(newLicense!.price, 20))).toFixed(2) : calculateIva(newLicense!.price).toFixed(2) : ""}`}
                                                </h1>
                                            </div>

                                            <Divider size="md" color={'dark'}/>

                                            <div className="flex w-full justify-between items-center mt-3">
                                                <h1 className="font-poppins-bold text-xl">Totale</h1>
                                                <h1 className="font-poppins-semi-bold text-2xl">
                                                    {`€ ${newLicense ? checKCouponj ? adIva((newLicense!.price - calculateDiscount(newLicense!.price, 20))).toFixed(2) : adIva(newLicense!.price).toFixed(2) : ""}`}
                                                </h1>
                                            </div>
                                        </div>

                                        <div
                                            className={`${isMobile || dimension ? 'w-full h-full shadow-lg p-2 rounded-xl border-2 border-black mt-3' : 'w-[80%] h-full flex rounded-xl shadow-2xl'}`}>
                                            <CheckoutformRenew
                                                idLicense={newLicense?.id!!}
                                                idPackage={dataToSend.idPackage}
                                                idStripe={stripeId ? stripeId : JSON.parse(localStorage.getItem('selectedRestaurant')!).stripeId}
                                            />
                                        </div>
                                    </div>
                                </Elements>
                                :
                                null
                        }
                    </Stepper.Step>
                </Stepper>



                    {active !== 0 ?
                        <Group className={`${isMobile ? '' : 'px-20 pt-20'} mb-8`} justify="center"
                               mt="xl">
                            <CustomButton
                                title={"Indietro"}
                                onClick={() => {
                                    if (active === 0) {
                                        navigate('/welcome')
                                    } else {
                                        prevStep()
                                    }
                                }}
                                enable={true}
                                isLoading={false}
                            />


                            {((userData && active !== 2) || (!userData && active !== 3)) && (
                                <CustomButton
                                    title={"Avanti"}
                                    onClick={() => {
                                        if (userData ? active === 1 : active === 2) {
                                            setLoading(true)
                                            if (props.isRenew) {
                                                setLoading(false)
                                                createStripeSecret(newLicense!.price)
                                                nextStep()
                                            } else {
                                                stripeRegistration(dataToSend, (response) => {
                                                    setLoading(false)
                                                    setStripeId(response.data.stripeId)

                                                    const obj = {
                                                        ammountCent: (newLicense?.price! + (newLicense?.price! * 22) / 100) * 100,
                                                        dataExtra: {},
                                                        description: newLicense?.description ?? '',
                                                        stripeId: response.data.stripeId,
                                                        nameRestaurant: dataToSend.nameRestaurant
                                                    }

                                                    if (newLicense?.price !== 0) {
                                                        makeRequest<ClientSecretResponse>('/users/restaurants/client_secret', HttpMethodsEnum.POST, obj)
                                                            .then((res) => {
                                                                setDataStripe({
                                                                    clientSecret: res.data!.clientSecret,
                                                                    idStripe: res.data!.idStripe
                                                                });
                                                                sessionStorage.setItem('registrationData',
                                                                    JSON.stringify({
                                                                        ...dataToSend,
                                                                        stripeId: res.data!.idStripe
                                                                    })
                                                                )
                                                                nextStep()
                                                            })
                                                    } else {
                                                        nextStep()
                                                    }
                                                })
                                            }
                                        } else {
                                            nextStep()
                                        }
                                    }}
                                    enable={nextBtnStates.isEnable}
                                    isLoading={loading}
                                />
                            )}

                        </Group>
                        : null
                    }
                </div>
                </div>

            );
            }
