import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { Divider, Input } from "@mantine/core";
import {createTable, getTables, updateTablesPosition} from "../../network/controllers/admin/TableController";
import { TableType } from "../../types/TableType";
import TablesTable from "./tables/TablesTable";
import confirm from "../../assets/confirm-icon.svg";
import closeIcon from "../../assets/close-icon.svg";
import { showSuccessNotification } from "../../utils/NotificationUtils";
import loadingIcon from '../../assets/loader-white.svg';
import { LoginResponseType } from "../../types/LoginResponseType";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ReactDragListView from "react-drag-listview";
import { ReactComponent as CustomLoaderIcon } from "../../assets/loader-white.svg"


export default function TableAdminPage() {
    const [addTable, setAddTable] = useState(false);
    const [newTable, setNewTable] = useState('');
    const [tableList, setTableList] = useState<TableType[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType;
    const navigate = useNavigate();

    useEffect(() => {
        if (userData && userData.role.includes('ADMIN')) {
            setIsLoading(true);
            getTables((response) => {
                setIsLoading(false)
                setTableList(response);
            });
        } else {
            navigate('/welcome');
        }
    }, []);

    const onDragEnd = (fromIndex: number, toIndex: number) => {
        if (fromIndex !== toIndex) {
            const updatedList = [...tableList];
            const [removedItem] = updatedList.splice(fromIndex, 1);
            updatedList.splice(toIndex, 0, removedItem);
            const reorderedList = updatedList.map((table, index) => ({
                ...table,
                position: index + 1,
            }));
            const backendData = reorderedList.map((table) => ({
                id: table.id,
                position: table.position,
            }));
            updateTablesPosition(backendData,(response)=>{
               setTableList(reorderedList)
            })
        }
    };


    return (
        <div className={`w-full h-full flex-col ${isMobile ? "pt-32" : "px-10 pt-44"}`}>
            <div className="w-full flex justify-between items-center">
                <h1 className="w-auto text-3xl font-poppins-bold my-5">Tavoli</h1>
                <CustomButton
                    small={true}
                    title={!addTable ? 'Aggiungi Tavolo' : 'Annulla'}
                    onClick={() => setAddTable(!addTable)}
                    enable={true}
                    isLoading={false}
                />
            </div>
            <h1 className="w-auto font-poppins-bold my-5">NOME TAVOLO</h1>
            {addTable && (
                <div className="w-full flex my-10 items-center">
                    <Input
                        type="text"
                        className="w-[250px] h-full bg-white text-text-button-add font-poppins-regular outline-none"
                        placeholder="Nome Tavolo"
                        onChange={(event) =>
                            setNewTable(event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1))
                        }
                    />
                    <div className="w-[100px] flex ml-10 gap-x-3">
                        <img
                            className="cursor-pointer w-6 h-6"
                            alt=""
                            src={isLoading ? loadingIcon : confirm}
                            onClick={() => {
                                setIsLoading(true);
                                createTable(
                                    {
                                        idLocal: JSON.parse(localStorage.getItem('selectedRestaurant') as string).id,
                                        tableName: newTable
                                    },
                                    (response) => {
                                        setIsLoading(false);
                                        setAddTable(false);
                                        showSuccessNotification('Tavolo', `${newTable} creato con successo`, 2000);
                                        setTableList(response);
                                        setNewTable('');
                                    }
                                );
                            }}
                        />
                        <img
                            className="cursor-pointer w-6 h-6"
                            alt=""
                            src={isLoading ? loadingIcon : closeIcon}
                            onClick={() => setAddTable(false)}
                        />
                    </div>
                </div>
            )}

            {tableList.length > 0 ? (
                <ReactDragListView
                    onDragEnd={onDragEnd}
                    lineClassName="test"
                    nodeSelector=".drag-item"
                >
                    {tableList.map((table, index) => (
                        <div className="py-5 bg-opacity-90 rounded-md px-2 drag-item" key={index}>
                            <TablesTable
                                table={table}
                                onTableUpdate={(tableList) => setTableList(tableList)}
                                onClickItem={() => {}}
                            />
                            <Divider color="yellow" />
                        </div>
                    ))}
                </ReactDragListView>
            ): isLoading ?
                (<div className="w-full flex justify-center items-center h-full">
                    <CustomLoaderIcon width="120" height="120"/>
                </div>)
                : null}
        </div>
    );
}
