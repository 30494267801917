import {Menu, rem} from '@mantine/core';
import {
    IconCirclePlus,
    IconEdit,
    IconTrash,
    IconArrowsLeftRight
} from '@tabler/icons-react';
import {CategoryType} from "../types/CategoryType";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";

export default function MenuCategory(props: {listSize:number, category?: CategoryType, onCategorySelect: (type: string) => void , onProductAdd:()=>void}) {
    var navigate = useNavigate()

    if (isMobile) {
        return (
        <Menu shadow="md" width={200} position={'bottom-end'} transitionProps={{duration: 150}}>
            <Menu.Target>
                <div className={'w-auto rounded-xl bg-yellow p-2 my-8' }>
                    <h1 className={'font-semibold text-md whitespace-nowrap text-center'}>{`Gestisci ${props.category?.categoryName}`}</h1>
                </div>
            </Menu.Target>
            <Menu.Dropdown className={'mx-5'}>
                <Menu.Label>Categoria</Menu.Label>
                <Menu.Item onClick={() => {
                    props.onCategorySelect('update')
                }} leftSection={<IconEdit style={{width: rem('full'), height: rem(20)}}/>}>
                    {`Modifica categoria ${props.category?.categoryName}`}
                </Menu.Item>
                {/*<Menu.Item style={{color: 'red'}} onClick={() => {
                    props.onCategorySelect('remove')
                }} leftSection={<IconEdit style={{color:'#f30404',width: rem('full'), height: rem(4)}}/>}>
                    {`Rimuovi categoria ${props.category?.categoryName} dal menù`}
                </Menu.Item>*/}
                <Menu.Item style={{color: 'red'}} onClick={() => {
                    props.onCategorySelect('delete')
                }} leftSection={<IconTrash style={{color:'#f30404',width: rem('full'), height: rem(20)}}/>}>
                    {`Elimina categoria ${props.category?.categoryName}`}
                </Menu.Item>

                <Menu.Divider/>

                <Menu.Label>{`Aggiungi prodotti a  ${props.category?.categoryName}`}</Menu.Label>
                <Menu.Item onClick={()=>{
                    props.onProductAdd()
                }} leftSection={<IconArrowsLeftRight style={{width: rem('full'), height: rem(20)}}/>}>
                    Da esistente
                </Menu.Item>
                <Menu.Item onClick={() => {
                    navigate('/admin/prodotti/aggiunta', {state: {product: null,category:props.category,listSize:props.listSize}})
                }} leftSection={<IconCirclePlus style={{width: rem('full'), height: rem(20)}}/>}>
                    Crea Nuovo
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}else{
        return (
            <Menu  shadow="md" width={200} position={'bottom-end'} transitionProps={{duration: 150}}>
                <Menu.Target>
                    <div className={'w-auto rounded-xl bg-yellow p-2'}>
                        <h1 className={'font-semibold text-md whitespace-nowrap'}>{`Gestisci ${props.category?.categoryName}`}</h1>
                    </div>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Label>Categoria</Menu.Label>
                    <Menu.Item onClick={() => {
                        props.onCategorySelect('update')
                    }} leftSection={<IconEdit style={{width: rem(14), height: rem(14)}}/>}>
                        {`Modifica categoria ${props.category?.categoryName}`}
                    </Menu.Item>
                   {/* <Menu.Item style={{color: 'red'}} onClick={() => {
                        props.onCategorySelect('remove')
                    }} leftSection={<IconEdit style={{color:'#f30404',width: rem(14), height: rem(14)}}/>}>
                        {`Rimuovi categoria ${props.category?.categoryName} dal menù`}
                    </Menu.Item>*/}
                    <Menu.Item style={{color: 'red'}} onClick={() => {
                        props.onCategorySelect('delete')
                    }} leftSection={<IconTrash style={{color:'#f30404',width: rem(14), height: rem(14)}}/>}>
                        {`Elimina categoria ${props.category?.categoryName}`}
                    </Menu.Item>

                    <Menu.Divider/>

                    <Menu.Label>{`Aggiungi prodotti a  ${props.category?.categoryName}`}</Menu.Label>
                    <Menu.Item onClick={()=>{
                        props.onProductAdd()
                    }} leftSection={<IconArrowsLeftRight style={{width: rem(14), height: rem(14)}}/>}>
                        Da esistente
                    </Menu.Item>
                    <Menu.Item onClick={() => {
                        navigate('/admin/prodotti/aggiunta', {state: {product: null,category:props.category,listSize:props.listSize}})
                    }} leftSection={<IconCirclePlus style={{width: rem(14), height: rem(14)}}/>}>
                        Crea Nuovo
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        )

    }}
