import {makeRequest} from "../../RestAdapter";
import {HttpMethodsEnum} from "../../HttpMethodsEnum";
import {CategoryType, MenuType} from "../../../types/CategoryType";
import {LocalType} from "../../../types/LocalType";
import {showFailNotification, showSuccessNotification} from "../../../utils/NotificationUtils";



export function getCategories(idRestaurant:string,onFinish: (response: CategoryType[]) => void, idMenu?: string) {

    makeRequest<CategoryType[]>(`/restaurants/categories/local/${idRestaurant}`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()) {
            res.data?.forEach((item)=>{
                item.categoryNameTranslations = new Map(Object.entries(item.categoryNameTranslations))
            })
            onFinish(res.data?res.data:[])
        }else{
            onFinish([])
        }
    })
}

export function getCategoriesQr(idRestaurant:string,onFinish: (response: CategoryType[]) => void) {
    makeRequest<CategoryType[]>(`/restaurants/categories/localQr/${idRestaurant}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data !== null) {
            onFinish(res.data)
        }
    })
}

export function getInfoLocal(idLocal:string,onFinish: (response: LocalType) => void) {
   makeRequest<LocalType>(`/users/user/info-local/${idLocal}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data !== null) {
            onFinish(res.data)
        }
    })
}


//usato in fase di aggiunta ingrediente per selezionare le categorie su cui è possibile l'aggiunta
export function getAllCategories(idRestaurant:string,onFinish: (response: CategoryType[]) => void) {
   makeRequest<CategoryType[]>(
         `/restaurants/categories/getAllCategory?idLocal=${idRestaurant}`
        , HttpMethodsEnum.GET).then((res) => {
        if (res.data !== null) {
            onFinish(res.data)
        }
    })
}

export function addCategory(request: CategoryType,categoryPosition:number, onFinish: (response: CategoryType[]) => void) {
    var tmpReq = request
    if (!tmpReq.image.includes('timestamp=')){
        tmpReq.image = tmpReq.image.split(',')[1]
    }

    var tmpMap=tmpReq.positionCategory!
    tmpMap.set(JSON.parse(localStorage.getItem('selectedMenu') as string).id,categoryPosition)
    //tmpReq.positionCategory=Object.entries(tmpMap)
    debugger
    makeRequest<CategoryType[]>(`/restaurants/categories`, HttpMethodsEnum.POST, tmpReq).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data?res.data:[])
        }else{
            onFinish([])
            showFailNotification('Errore','Riprova più tardi',2000)
        }
    })
}

export function updateCategory(request: {
    id: string,
    categoryName: string,
    image: string,
    idMenus: string[],
    description:string
    positionCategory:Map<string,number>|any,

},categoryPosition:number, onFinish: (response: CategoryType[]) => void) {
    var mappa=new Map<string, number>();
    Array.from(Object.entries(request.positionCategory)).forEach((k)=>{
        // @ts-ignore
        mappa.set(k[0],k[1])
    })
    var newItem= {...request,positionCategory:mappa}
    if (!newItem.image.includes('timestamp=')){
        newItem.image = newItem.image.split(',')[1]
    }
    if (newItem.positionCategory.get(JSON.parse(localStorage.getItem('selectedMenu') as string).id)===undefined){
        var tmpMap=newItem.positionCategory!
        tmpMap.set(JSON.parse(localStorage.getItem('selectedMenu') as string).id,categoryPosition)
        newItem={...newItem,positionCategory: tmpMap}
    }
    var itemSend={...newItem,positionCategory:Object.fromEntries(newItem.positionCategory)}
    makeRequest<CategoryType[]>(`/restaurants/categories/update`, HttpMethodsEnum.PUT, itemSend).then((res) => {
        if (res.isSuccessfull()) {
            showSuccessNotification('Categoria','Modificata con successo',2000)
            onFinish(res.data?res.data:[])
        }else{
            onFinish([])
            showFailNotification('Errore','Riprova più tardi',2000)
        }
    })
}

export function deleteCategory(categoryId: string, onFinish: (response: any) => void) {
    makeRequest<any>(`/restaurants/categories/${categoryId}/${JSON.parse(localStorage.getItem('selectedMenu') as string).id}`, HttpMethodsEnum.DELETE).then((res) => {
        onFinish(res)
    })
}

export function removeCategoryFromMenu(menuID:string,categoryId: string, onFinish: (response: any) => void) {
    makeRequest<any>(`/restaurants/categories/removeMenu/${categoryId}/${menuID}`, HttpMethodsEnum.PUT).then((res) => {
        onFinish(res)
    })
}


