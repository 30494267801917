import {makeRequest} from "../../RestAdapter";
import {HttpMethodsEnum} from "../../HttpMethodsEnum";
import {TableType} from "../../../types/TableType";

export function getTables(onFinish:(response: TableType[]) => void) {
    makeRequest<TableType[]>(`/restaurants/tables/local/${JSON.parse(localStorage.getItem('selectedRestaurant') as string).id}`, HttpMethodsEnum.GET).then((res) => {
        console.log('Risposta dalla API getTables:', res);
        if (res.data !== null) {
            onFinish(res.data);
        }
    });
}

export function createTable(request:{idLocal:string,tableName:string},onFinish:(response: TableType[]) => void) {
    makeRequest<TableType[]>(`/restaurants/tables`, HttpMethodsEnum.POST,request).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }
    })
}

export function updateTable(request:{idTable:string,tableName:string},onFinish:(response: TableType[]) => void) {
    makeRequest<TableType[]>(`/restaurants/tables/update`, HttpMethodsEnum.PUT,request).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }

    })
}
export function updateTablesPosition(request:{position:number,id:string}[],onFinish:(response: TableType[]) => void) {
    makeRequest<TableType[]>(`/restaurants/tables/sort/${JSON.parse(localStorage.getItem('selectedRestaurant') as string).id}`, HttpMethodsEnum.PUT,request).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }

    })
}

export function deleteTable(request:string,onFinish:(response: any) => void) {
    makeRequest<any>(`/restaurants/tables/${request}`, HttpMethodsEnum.DELETE).then((res) => {
        if (res.status===200){
            onFinish(res)
        }

    })
}
export function getTableQr(request:string,onFinish:(response: any) => void) {
    makeRequest<any>(`/restaurants/tables/qr-code/${request}`, HttpMethodsEnum.GET).then((res) => {
        if (res.status===200){
            onFinish(res)
        }

    })
}
