import {useEffect, useState} from "react";
import {Group, Stepper} from "@mantine/core";
import CustomButton from "../../../components/CustomButton";
import StepProductInfo from "./StepProductInfo";
import {UpdateProductType} from "../../../types/ProductType";
import StepAddIngredient from "./StepAddIngredient";
import {addProduct, updateProduct} from "../../../network/controllers/admin/ProductController";
import {useLocation, useNavigate} from "react-router-dom";
import {GetProductResponseType} from "../../../types/GetProductResponseType";
import {AllergenType} from "../../../types/AllergenType";
import StepRecapProduct from "./StepRecapProduct";
import {CategoryType} from "../../../types/CategoryType";
import {isMobile} from "react-device-detect";
import {getDimension} from "../../../utils/Utility";

export default function ProductAdminStepperPage() {
    var location = useLocation();
    const [active, setActive] = useState(0);
    const nextStep = () => {
        setActive((current) => (current < 3 ? current + 1 : current))
        setEnableNextStep(false)
    };
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current))
    const [enableNextStep, setEnableNextStep] = useState(false);
    const [locationData, setLocationData] = useState(location.state.product as GetProductResponseType);
    const [isBack, setIsBack] = useState(false);
    const [loading, setLoading] = useState(false);

    const [dimension, setDimension] = useState(window.innerWidth < 1280)
    useEffect(() => {
        getDimension(1280, (res) => {
            console.log(res);
            setDimension(res)
        })
    }, [])

    function retrieveIdFromAllergens(allergens: AllergenType[]): string[] {
        var allergensID: string[] = []
        allergens.forEach((element: AllergenType) => {
            allergensID.push(element.id)
        })
        return allergensID
    }

    useEffect(() => {
        setIsBack(active === 1)
    }, [prevStep]);

    var tmpMap = new Map<string, number>()
    if (location.state.category) {
        var tmpCategory: CategoryType = {
            ...location.state.category,
            insertDate: new Date(location.state.category.insertDate),
            lastUpdateDate: new Date(location.state.category.lastUpdateDate)
        }
        tmpMap.set(`${JSON.parse(localStorage.getItem('selectedMenu') as string).id}--${location.state.category.id}`, location.state.listSize + 1)
    }


    const navigate = useNavigate();
    const [newProduct, setNewProduct] = useState<any>(locationData ? {//getProductType
            id: locationData.id,
            description: locationData.description,
            categories: locationData.nameCategories,
            idKitchen: locationData.idKitchen,
            idLocal: locationData.idLocal,
            ingredients: locationData.ingredients,
            nameProduct: locationData.nameProduct,
            priceOriginal: locationData.priceOriginal,
            subNmeProduct: locationData.subNameProduct,
            photoPath: locationData.photoPath,
            priceType: locationData.priceType,
            allergens: retrieveIdFromAllergens(locationData.allergensList as unknown as AllergenType[]),
            idMenus: locationData.menusList!,
            position: locationData.position,
        } : { //productType
            description: '',
            categories: location.state.category ? [tmpCategory!] : [],
            idKitchen: '',
            idLocal: JSON.parse(localStorage.getItem('selectedRestaurant') as string).id,
            ingredients: [],
            nameProduct: '',
            priceOriginal: 0,
            subNameProduct: '',
            photoPath: '',
            allergens: [],
            priceType: '',
            idMenus: [localStorage.getItem('menuId') as string],
            position: tmpMap
        }
    )


    if (isMobile || dimension) {
        return (
            <div className={'w-auto h-auto pt-[120px]'}>
                <Stepper color={'#FFCC00'} styles={{
                    stepCompletedIcon: {color: 'black'},
                    separator: {
                        background: 'none',
                        border: 'none'
                    },
                    step: {
                        flexDirection: 'row',
                        alignItems: 'start',
                        width: '100%'
                    },
                    stepBody: {
                        alignItems: 'start',
                        textAlign: 'start'
                    },
                    stepDescription: {
                        color: 'black',
                        marginTop: '16px',
                        fontFamily: 'semi-bold',
                        fontSize: '12px',
                        textAlign: 'start'
                    }
                }} active={active}>
                    <Stepper.Step description="INSERISCI LE INFORMAZIONI DEL PRODOTTO">
                        <StepProductInfo isMenuSelect={location.state.isMenuAddable} product={newProduct}
                                         onDataSet={(product) => {
                                             if (product !== null) {
                                                 setNewProduct(product)
                                                 setEnableNextStep(true)
                                             } else {
                                                 setEnableNextStep(false)
                                             }
                                         }}/>
                    </Stepper.Step>
                    <Stepper.Step description="INSERISCI GLI INGREDIENTI DEL PRODOTTO">
                        <StepAddIngredient isBack={isBack} categoryId={location.state.category.id} product={newProduct}
                                           onIngredientSelected={(ingredients) => {
                                               if (ingredients.length > 0) {
                                                   var tmpAllergenId: string[] = []
                                                   ingredients.forEach((ingredient) => {
                                                       ingredient.allergenId.forEach((id) => {
                                                           tmpAllergenId.push(id)
                                                       })
                                                   })
                                                   setNewProduct({
                                                       ...newProduct,
                                                       ingredients: ingredients,
                                                       allergens: tmpAllergenId
                                                   })
                                               }
                                               setEnableNextStep(true)
                                           }} onAllergenSelect={(allergenList) => {
                        }} onDescriptionChange={(description) => {
                            setNewProduct({...newProduct, description: description})
                        }}
                        />
                    </Stepper.Step>
                    <Stepper.Step description="RIEPILOGO COMPLETO DEL PRODOTTO">
                        <StepRecapProduct product={newProduct}/>
                    </Stepper.Step>
                </Stepper>
                <Group className={'px-10 pb-12 items-center justify-center'} mt="20px">
                    <CustomButton isLoading={false} enable={true} title={'Indietro'} onClick={() => {
                        if (active === 0) {
                            navigate(-1)
                        } else {
                            prevStep()
                        }
                    }
                    }/>
                    <CustomButton isLoading={loading} enable={enableNextStep || active === 2}
                                  title={active === 2 ? 'Salva' : 'Avanti'} onClick={() => {
                        if (active === 2) {
                            setLoading(true)
                            if (locationData) {
                                var updateRequest: UpdateProductType = {
                                    position: locationData ? locationData.position : newProduct.position,
                                    allergens: newProduct.allergens,
                                    available: locationData.isAvailable,
                                    description: newProduct.description,
                                    categories: newProduct.categories,
                                    idKitchen: newProduct.idKitchen,
                                    idMenu: newProduct.idMenus! as string[],
                                    ingredients: newProduct.ingredients,
                                    nameProduct: newProduct.nameProduct,
                                    photo: newProduct.photoPath,
                                    priceType: newProduct.priceType,
                                    priceOriginal: newProduct.priceOriginal,
                                    subNameProduct: newProduct.subNameProduct
                                }
                                updateProduct(locationData.id, updateRequest, () => {
                                    setLoading(false)
                                    navigate(-1)
                                })
                            } else {
                                addProduct(newProduct, (response) => {
                                    setLoading(false)
                                    navigate(-1)
                                })
                            }
                        } else {
                            nextStep()
                        }
                    }}/>
                </Group>
                <div>


                </div>
            </div>
        )
    } else {
        return (
            <div className={'w-full h-auto pt-[120px] overflow-hidden'}>
                <Stepper color={'#FFCC00'} styles={{
                    stepCompletedIcon: {color: 'black'},
                    stepDescription: {color: 'black', marginTop: '10px', fontFamily: 'semi-bold'}
                }} active={active}>
                    <Stepper.Step description="INSERISCI LE INFORMAZIONI DEL PRODOTTO">
                        <StepProductInfo isMenuSelect={location.state.isMenuAddable} product={newProduct}
                                         onDataSet={(product) => {
                                             if (product !== null) {
                                                 setNewProduct(product)
                                                 setEnableNextStep(true)
                                             } else {
                                                 setEnableNextStep(false)
                                             }
                                         }}/>
                    </Stepper.Step>
                    <Stepper.Step description="INSERISCI GLI INGREDIENTI DEL PRODOTTO">
                        <StepAddIngredient isBack={isBack} categoryId={location.state.category.id} product={newProduct}
                                           onIngredientSelected={(ingredients) => {
                                               if (ingredients.length > 0) {
                                                   var tmpAllergenId: string[] = []
                                                   ingredients.forEach((ingredient) => {
                                                       ingredient.allergenId.forEach((id) => {
                                                           tmpAllergenId.push(id)
                                                       })
                                                   })
                                                   setNewProduct({
                                                       ...newProduct,
                                                       ingredients: ingredients,
                                                       allergens: tmpAllergenId
                                                   })
                                               }
                                               setEnableNextStep(true)
                                           }} onAllergenSelect={(allergenList) => {
                        }} onDescriptionChange={(description) => {
                            setNewProduct({...newProduct, description: description})
                        }}
                        />
                    </Stepper.Step>
                    <Stepper.Step description="RECAP DEL PRODOTTO">
                        <StepRecapProduct product={newProduct}/>
                    </Stepper.Step>
                </Stepper>
                <Group className={'px-20 '} justify="space-between" >
                    <CustomButton isLoading={false} enable={true} title={'Indietro'} onClick={() => {
                        if (active === 0) {
                            navigate(-1)
                        } else {
                            prevStep()
                        }
                    }
                    }/>
                    <CustomButton isLoading={loading} enable={enableNextStep || active === 2}
                                  title={active === 2 ? 'Salva' : 'Avanti'} onClick={() => {
                        if (active === 2) {
                            setLoading(true)
                            if (locationData) {
                                var updateRequest: UpdateProductType = {
                                    position: locationData ? locationData.position : newProduct.position,
                                    allergens: newProduct.allergens,
                                    available: locationData.isAvailable,
                                    description: newProduct.description,
                                    categories: newProduct.categories,
                                    idKitchen: newProduct.idKitchen,
                                    idMenu: newProduct.idMenus! as string[],
                                    ingredients: newProduct.ingredients,
                                    nameProduct: newProduct.nameProduct,
                                    photo: newProduct.photoPath,
                                    priceType: newProduct.priceType === '' ? 'Singolo' : newProduct.priceType,
                                    priceOriginal: newProduct.priceOriginal,
                                    subNameProduct: newProduct.subNameProduct
                                }
                                updateProduct(locationData.id, updateRequest, () => {
                                    setLoading(false)
                                    navigate(-1)
                                })
                            } else {
                                addProduct({
                                    ...newProduct,
                                    priceType: newProduct.priceType === '' ? 'Singolo' : newProduct.priceType
                                }, (response) => {
                                    setLoading(false)
                                    navigate(-1)
                                })
                            }
                        } else {
                            nextStep()
                        }
                    }}/>
                </Group>
                <div>


                </div>
            </div>
        )
    }
}
