import React, {useEffect, useState} from "react";
import {CategoryType} from "../types/CategoryType";
import arrow from '../assets/arrow-yellow.svg'
import {getProductList} from "../network/controllers/DetailController";
import ItemProductComponent from "./ItemProductComponent";
import QrItemComponent from "./QrItemComponent";
import {GetProductResponseType} from "../types/GetProductResponseType";
import {isMobile} from "react-device-detect";
import {LocalType} from "../types/LocalType";
import {BackgroundImage, Textarea} from "@mantine/core";
import {getSystemLanguage} from "../utils/Utility";

export default function ProductDetailComponent(props: {
    local: LocalType,
    onBack: () => void,
    categorySelected: CategoryType
}) {

    const [listProduct, setListProduct] = useState<GetProductResponseType[]>([]);

    useEffect(() => {
        getProductList(props.local.id, props.categorySelected.id!, (listProductResponse) => {
            setListProduct(listProductResponse)
        })
    }, []);


    if (isMobile) {
        return (
            <div className="flex flex-col w-[110%] h-auto ">
                <div className={`w-full h-14 bg-black p-2 ${props.categorySelected.description !== '' ? 'rounded-t-xl' : 'rounded-xl'} `}>
                    <div className="flex flex-row h-full w-full items-center relative">
                        <img
                            alt=""
                            className="h-full p-3 items-start justify-start ml-2"
                            src={arrow}
                            onClick={() => {
                                props.onBack()
                            }}
                        />

                        <h1 className="font-bold text-amber-400 text-center text-lg absolute left-1/2 transform -translate-x-1/2 ">
                            {props.categorySelected.categoryName}
                            <br/>
                            {getSystemLanguage(Array.from(props.categorySelected.categoryNameTranslations.keys()))!=='it'?<span className="font-poppins-bold text-xs text-center break-words">{props.categorySelected.categoryNameTranslations.get(getSystemLanguage(Array.from(props.categorySelected.categoryNameTranslations.keys())))}</span>:null}
                        </h1>
                        {getSystemLanguage(Array.from(props.categorySelected.categoryNameTranslations.keys()))!=='it'?<p className="font-poppins-bold text-xs text-center break-words">{props.categorySelected.categoryNameTranslations.get(getSystemLanguage(Array.from(props.categorySelected.categoryNameTranslations.keys())))}</p>:null}
                    </div>
                </div>
                {props.categorySelected.description && (
                    <div className="w-full h-[170px] rounded-b-2xl">
                        <BackgroundImage
                            className="w-full h-full no-repeat rounded-b-2xl"
                            src={props.categorySelected.image}
                        >
                            <div
                                className="w-full h-full flex flex-row bg-gradient-to-b  from-black from-40% to-transparent to-100% rounded-b-2xl whitespace-normal">
                                <div className={'w-full flex  items-center  justify-center mt-2 '}>
                                    <Textarea
                                        classNames={{
                                            input: 'text-amber-400 font-poppins-regular text-md text-center',
                                            root: 'w-full px-1',
                                            label: 'text-white font-poppins-semi-bold',
                                            wrapper: 'rounded-xl px-2'
                                        }}
                                        defaultValue={props.categorySelected.description}
                                        autosize
                                        maxRows={5}
                                        minRows={5}
                                        variant={'unstyled'}
                                        readOnly={true}
                                    />

                                </div>
                            </div>
                        </BackgroundImage>
                    </div>
                )}
                <div className={'w-full h-full overflow-y-auto'}>
                    <div className="flex flex-col w-full max-w-[100%] gap-y-7 px-4 pb-2 pt-5">
                        {listProduct.length > 0 ? listProduct.map((product) => (
                            <QrItemComponent
                                key={`mobile-product-${product.id}`}
                                product={product}
                            />
                        )) : null}
                    </div>
                </div>

            </div>
        );
    } else {
        return (
            <div className={'w-full'}>
                <div
                    className={`w-full h-14 bg-black p-2 ${props.categorySelected.description !== '' ? 'rounded-t-xl' : 'rounded-xl'} `}>
                    <div className={'flex items-center justify-center h-full'}>
                        <img alt={''} className={'h-full p-3'} src={arrow} onClick={() => {
                            props.onBack()
                        }}/>
                        <h1 className={' w-full font-bold text-amber-400 text-center text-lg'}>{props.categorySelected.categoryName}</h1>
                    </div>
                </div>
                {props.categorySelected.description && (
                    <div className="w-full h-[170px] rounded-b-2xl">
                        <BackgroundImage
                            className="w-full h-full  no-repeat rounded-b-2xl"
                            src={props.categorySelected.image}
                        >
                            <div
                                className="w-full h-full flex flex-row bg-gradient-to-b  from-black from-40% to-transparent to-100% rounded-b-2xl">
                                <div className={'w-full flex items-center justify-center'}>
                                    <Textarea
                                        classNames={{
                                            input: 'text-amber-400 font-poppins-regular text-md text-center ',
                                            root: 'w-full ',
                                            label: 'text-white font-poppins-semi-bold',
                                            wrapper: 'rounded-xl'
                                        }}
                                        defaultValue={props.categorySelected.description}
                                        autosize
                                        maxRows={5}
                                        minRows={5}
                                        variant={'unstyled'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                        </BackgroundImage>
                    </div>
                )}

                <div className={'w-full h-full overflow-y-auto'}>
                    <div className={'w-full max-w-[100%] flex flex-col gap-y-7 px-4 py-2'}>
                        {listProduct.length > 0 ? listProduct.map((product) => (
                            <ItemProductComponent key={`desktop-product-${product.id}`} product={product}/>)) : null}
                    </div>
                </div>

            </div>
        );
    }
}
