import React, { useState } from "react";
import TableCashDeskComponent from "../components/TableCashDeskComponent";
import RecipeComponent from "../components/RecipeComponent";
import { CommandTableOrderType } from "../types/CommandTableOrderType";
import {isMobile} from "react-device-detect";

export default function CashDeskPage() {
    const [selectedOrder, setSelectedOrder] = useState<CommandTableOrderType | null>(null);

    const handleTableClick = (orderData: CommandTableOrderType[] | null) => {
        if (orderData) {
            console.log("Tavolo selezionato con ordine:", orderData[0]);
            setSelectedOrder(orderData[0]);
        } // Debug


    };

    const handleUpdateOrder = (updatedOrder: CommandTableOrderType) => {
        console.log("Ordine aggiornato:", updatedOrder); // Debug

        setSelectedOrder(updatedOrder);
    };


    if (isMobile) {
    return (
        <div className="w-full h-full pt-[130px] flex flex-col ">
            <div className="w-full h-full flex flex-col">
                <p className="text-4xl text-poppins-bold">Preconto</p>
                <TableCashDeskComponent onTableClick={handleTableClick} />
            </div>
            <div className="w-full h-full flex">
                {selectedOrder?<RecipeComponent order={selectedOrder} onUpdateOrder={handleUpdateOrder}/>:null}
            </div>
        </div>
    )
}else{
    return (
        <div className="w-full h-full pt-[130px] flex ">
            <div className="w-[65%] h-full flex flex-col">
                <p className="text-4xl text-poppins-bold">Preconto</p>
                <TableCashDeskComponent onTableClick={handleTableClick} />
            </div>
            <div className="w-[35%] h-full flex">
                {selectedOrder?<RecipeComponent order={selectedOrder} onUpdateOrder={handleUpdateOrder}/>:null}
            </div>
        </div>
    );
}
}
