import {isMobile} from "react-device-detect";

export default function SettingsAdminCardComponent(props: {
    title: string,
    image: string,
    text: string,
    arrow: string,
    onClick: () => void,
}) {
    if (isMobile){
    return (
        <div className={'flex w-full h-[220px] p-8 bg-black rounded-3xl mb-5'} onClick={() => {
            props.onClick()
        }
        }>
            <div className={'w-full flex   '}>
                <div className={'flex flex-col justify-around'}>
                    <div className={'w-auto h-auto text-white text-2xl font-poppins-bold'}>
                        {props.title}
                    </div>
                    <div className={'w-auto h-auto text-white text-xs font-poppins-regular'}>
                        {props.text}
                    </div>
                    <div className={'w-full h-auto flex justify-center items-center'}>
                        <img src={props.arrow}/>
                    </div>
                </div>
                <div className={'w-auto h-full flex justify-end items-center pl-5 '}>
                    <img className={'w-auto max-h-[180px]'} src={props.image}/>
                </div>
            </div>
        </div>
    );
}else{
        return (
            <div className={'flex w-[300px] h-[250px] bg-black rounded-3xl cursor-pointer'} onClick={() => {
                props.onClick()
            }
            }>
                <div className={'w-full flex p-5  '}>
                    <div className={'flex flex-col justify-around'}>
                        <div className={'w-auto h-auto text-white text-2xl font-poppins-bold'}>
                            {props.title}
                        </div>
                        <div className={'w-auto h-auto text-white text-xs font-poppins-regular'}>
                            {props.text}
                        </div>
                        <div className={'w-full h-auto flex justify-center items-center'}>
                            <img src={props.arrow}/>
                        </div>
                    </div>
                    <div className={'w-auto flex justify-end items-center'}>
                        <img className={'w-full h-auto'} src={props.image}/>
                    </div>
                </div>
            </div>
        );
    }}
