import {LocalType} from "../../types/LocalType";
import {makeRequest} from "../RestAdapter";
import {HttpMethodsEnum} from "../HttpMethodsEnum";
import {showFailNotification} from "../../utils/NotificationUtils";
import {TagType} from "../../types/TagType";
import {AddRestaurantRequest} from "../../types/AddRestaurantRequest";
import {RestaurantStatusEnum} from "../../enums/RestaurantStatusEnum";


export function getPlaceIds(onFinish: (response: string[]) => void) {
    makeRequest<string[]>(`/users/restaurants/getAllPlaceId`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : [])
        } else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}

export function getDetailRestaurants(idLocal: string, onFinish: (response: LocalType) => void) {
    makeRequest<LocalType>(`/users/restaurants/detail/${idLocal}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data) {
            onFinish(res.data)
        } else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}


export function updateTag(tagList: TagType[], idLocal: string, onFinish: (response: LocalType | null) => void) {
    makeRequest<LocalType>(`/users/restaurants/updateTag`, HttpMethodsEnum.PUT, {
        idLocal: idLocal,
        listTag: tagList
    }).then((res) => {
        if (res.status === 200) {
            onFinish(res.data ? res.data : null)
        } else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}

export function updateTYpe(type: string, idLocal: string, onFinish: (response: LocalType | null) => void) {
    makeRequest<LocalType>(`/users/restaurants/updateTypeLocal`, HttpMethodsEnum.PUT, {
        idLocal: idLocal,
        typeLocal: type
    }).then((res) => {
        if (res.status === 200) {
            onFinish(res.data ? res.data : null)
        } else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}

export function updateDescription(descriptionLocal: string, idLocal: string, onFinish: (response: LocalType | null) => void) {
    makeRequest<LocalType>(`/users/restaurants/updateDescription`, HttpMethodsEnum.PUT, {
        idLocal: idLocal,
        descriptionLocal: descriptionLocal
    }).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : null)
        } else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}

export function updateReservation(isReservation: boolean, idLocal: string, onFinish: (response: LocalType | null) => void) {
    makeRequest<LocalType>(`/users/restaurants/updateReservation`, HttpMethodsEnum.PUT, {
        idLocal: idLocal,
        isReservation: isReservation
    }).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : null)
        } else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}

export function updateImage(imageLocal: string, idLocal: string, onFinish: (response: LocalType | null) => void) {
    makeRequest<LocalType>(`/users/restaurants/updateImage`, HttpMethodsEnum.PUT, {
        idLocal: idLocal,
        imageLocal: imageLocal.split(',')[1]
    }).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : null)
        } else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}

export function addFavourite(restaurantId: string, onFinish: (response: LocalType | null) => void) {
    makeRequest<LocalType>(`/users/favorites-restaurants/${restaurantId}`, HttpMethodsEnum.POST).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : null)
        } else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }
    })
}

export function getFavourites(onFinish: (response: LocalType[] | null) => void) {
    makeRequest<LocalType[]>(`/users/favorites-restaurants/user`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : null)
        } else {
            dispatchEvent(new CustomEvent("loader", {detail: {isLoading: false}}));
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}

export function checkIfFavourite(restaurantId: string, onFinish: (isFavourite: boolean) => void) {
    getFavourites((favourites) => {
        if (favourites) {
            const isFavourite = favourites.some(favourite => favourite.id === restaurantId);
            onFinish(isFavourite);
        } else {
            onFinish(false);
        }
    });
}

export function restaurantRegistration(request: AddRestaurantRequest, onFinish: (response: any) => void) {

    var tmpReq = request
    try {
        //tmpReq.foto = tmpReq.foto.split(',')[1]
    } catch (e) {

    }
    makeRequest<any>(`/users/user/restaurants`, HttpMethodsEnum.POST, tmpReq).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res)
        } else {
            showFailNotification('Errore', 'riprova più tardi', 3000)
            onFinish(null)
        }
    })
}
export function stripeRegistration(request: AddRestaurantRequest, onFinish: (response: any) => void) {
    var tmpReq = request
    try {
        tmpReq.foto = tmpReq.foto.split(',')[1]
    } catch (e) {

    }
    makeRequest<any>(`/users/user/registrationStripe`, HttpMethodsEnum.POST, tmpReq).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res)
        } else {
            showFailNotification('Errore', 'riprova più tardi', 3000)
            onFinish(null)
        }
    })
}


export function updateDeliveryStatus(id: string, status: RestaurantStatusEnum, onFinish: (response: RestaurantStatusEnum) => void) {
    makeRequest<any>(`/users/restaurants/update-delivery-status/${id}?status=${status}`, HttpMethodsEnum.PUT).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data);
        } else {
            onFinish(res.data);
            showFailNotification('Errore', 'Riprova più tardi', 2000);
        }
    })
}

export function renewRestaurantSubscription(idLocal: string, idLicense:string,idPack:string, onFinish: (response: LocalType|null) => void) {

    makeRequest<LocalType>(`/users/restaurants/updateLicense/${idLocal}/${idLicense}/${idPack}`, HttpMethodsEnum.PUT).then((res) => {
        if (res.isSuccessfull()) {

            onFinish(res.data);
        } else {

            showFailNotification('Errore', 'Riprova più tardi', 2000);
        }
    })
}


    export function syncDataGoogle(idLocal: string, onFinish: (response: any) => void) {
        makeRequest<any>(`/users/restaurants/syncDataGoogle/${idLocal}`, HttpMethodsEnum.GET).then((res) => {
            if (res.isSuccessfull()) {
                onFinish(res.data ? res.data : {});
            } else {
                showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000);
                onFinish(null);
            }
        });
    }




