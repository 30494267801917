import {makeRequest} from "../../RestAdapter";
import {HttpMethodsEnum} from "../../HttpMethodsEnum";
import {TableType} from "../../../types/TableType";
import {KitchenType} from "../../../types/KitchenType";
import {TurnType} from "../../../types/TurnType";


export function getTurns(onFinish:(response:TurnType[]) => void) {
    makeRequest<TurnType[]>(`/restaurants/turns/local/${JSON.parse(localStorage.getItem('selectedRestaurant') as string).id}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }
    })
}

export function createTurn(request:{idLocal:string,nameTurn:string},onFinish:(response: TurnType[]) => void) {
    makeRequest<TurnType[]>(`/restaurants/turns`, HttpMethodsEnum.POST,request).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }
    })
}

export function updateTurn(request:{id:string,nameTurn:string},onFinish:(response: TurnType[]) => void) {
    makeRequest<TurnType[]>(`/restaurants/turns/update`, HttpMethodsEnum.PUT,request).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }

    })
}



export function updateTurnsPosition(request:{position:number,id:string}[],onFinish:(response: TurnType[]) => void) {
    makeRequest<TurnType[]>(`/restaurants/turns/sort/${JSON.parse(localStorage.getItem('selectedRestaurant') as string).id}`, HttpMethodsEnum.PUT,request).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }

    })
}

export function deleteTurn(request:string,onFinish:(response: any) => void) {
    makeRequest<any>(`/restaurants/turns/${request}`, HttpMethodsEnum.DELETE).then((res) => {
        if (res.status===200){
            onFinish(res)
        }

    })
}
