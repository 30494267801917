import React, {useState, useEffect} from "react";
import {Badge, Grid, BackgroundImage, List} from '@mantine/core';
import {useNavigate} from "react-router-dom";
import {LocalType} from "../types/LocalType";
import {StatusEnum} from "../enums/StatusEnum";
import RatingComponent from "./RatingComponent";
import GenericImg from "./generic/GenericImg";
import placeholder from '../assets/placeolder-ristorante.svg';
import iconPrice from "../assets/icona_euro.svg";
import iconFavouriteFull from "../assets/icn-heart-yellow-full.svg";
import iconFavourite from "../assets/icon-heart-yellow.svg";
import {isMobile} from "react-device-detect";
import {showWarningNotification} from "../utils/NotificationUtils";
import {addFavourite, checkIfFavourite} from "../network/controllers/RestaurantsControllers";
import {LoginResponseType} from "../types/LoginResponseType";
import {TagType} from "../types/TagType";

export default function LocalListComponent(props: {
    isInMap?: boolean,
    local: LocalType,
    clickable?: boolean,
    favouriteClickable?: boolean,
    reload?: () => void,
    showTags?: boolean
}) {
    const navigate = useNavigate();
    const [isFavourite, setIsFavourite] = useState(false);
    const [local, setLocal] = useState<LocalType>(props.local);
    const priceIcons = Array.from({ length: local.priceLevel }, () => iconPrice);
    const userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType;
    const [tags, setTags] = useState<TagType[]>([]);
    var mapsURI = 'https://www.google.com/maps/search/?api=1&query=' + `${local.address}, ${local.civicNumber}, ${local.city}`;
    useEffect(() => {
        const listSize = local.tags.length;
        if (listSize !== 8) {
            for (let i = 0; i < 8 - listSize; i++) {
                local.tags.push({ tag:'', id: 'vuoto' });
            }
        }
        setTags(local.tags);
    }, [local.id, userData]);

    const handleClick = () => {
        if (props.clickable) {
            localStorage.setItem('selectedRestaurantClient', JSON.stringify(local));
            navigate(`/detail`);
        }
    };

    const handleFavouriteToggle = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (userData) {
            addFavourite(local.id, () => {
                setLocal({...local,isFavourite: !local.isFavourite});
                if (props.reload) props.reload();
            });
        } else {
            showWarningNotification('Attenzione: Per aggiungere un prodotto ai preferiti effettua la login. Vuoi Procedere?', () => {
                navigate('/login');
            });
        }
    };

    return (
        <div className={`w-full h-full rounded-xl shadow-xl shadow-gray-500 ${props.clickable?'cursor-pointer':''}`}>
            <BackgroundImage
                className="w-full h-full bg-no-repeat bg-right rounded-xl"
                src={local.approvalStatus !== StatusEnum.ACCEPTED ? placeholder : local.photoPath}
                onClick={handleClick}>
                <div className="flex flex-row bg-gradient-to-r from-black from-30% to-transparent to-95% rounded-xl">
                    <div className="flex flex-col p-4 w-full h-full ml-2">
                        <div className="flex flex-col h-full w-full justify-between">
                            <div className="flex flex-row h-full w-full gap-x-2.5">
                                <img key={Math.random()} alt={''} className="w-[30px] h-auto"
                                     src={local.isFavourite ? iconFavouriteFull + `?timestamp=${new Date().getMilliseconds()}` : iconFavourite + `?timestamp=${new Date().getMilliseconds()}`}
                                     onClick={handleFavouriteToggle}
                                />
                                <h1 className="w-auto truncate text-white  text-xl font-poppins-bold pr-2">
                                    {local.nameRestaurant || "\u00A0"}
                                </h1>
                            </div>
                            <div className={`flex flex-col mt-2 ${isMobile ? 'gap-y-2' : ''}`}>
                                    <a className={'text-white text-sm truncate '} onClick={() => {
                                        if (props.isInMap) {
                                            window.open(mapsURI, '_blank')
                                        }
                                    }} href="#"
                                       id="address-link"> {`${local.address?local.address:''} ` || "\u00A0"}</a>

                                <h1 className={`${props.isInMap ? 'text-md underline' : 'text-sm'} text-white mt-1 md:max-w-max font-poppins-semi-bold`}>
                                    <a className={'text-white text-sm truncate '} onClick={() => {
                                        if (props.isInMap) {
                                            window.open(`tel:${local.phoneNumber}`, '_blank')
                                        }
                                    }}>{local.phoneNumber || "©"}</a>
                                </h1>

                        </div>
                        <h1 className="text-sm font-poppins-bold text-white mt-2 mb-1">{local.typeLocal || "\u00A0"}</h1>
                            <div
                                className={`${isMobile ? 'flex-col' : 'flex'} items-center justify-start py-1  gap-x-2.5`}>
                                <RatingComponent
                                    value={local.rating}
                                    readonly={true}
                                    onChangeValue={() => {
                                    }}
                                />

                                <h1 className={` ${props.local.openingInfo.isOpen ? 'text-green-600 border-green-600' : 'text-red-600 border-red-600'} font-poppins-bold  ml-5  px-2 py-1 `}>{props.local.openingInfo.isOpen ? `Aperto. ${props.local.openingInfo.closingAt}` : `Chiuso. ${props.local.openingInfo.nextOpen?`Apre ${props.local.openingInfo.nextOpen}`:''}`}</h1>

                            </div>
                            <div className="flex w-full h-4 mt-1">
                                {priceIcons.length > 0
                                    ? priceIcons.map((icon, index) => (
                                        <GenericImg key={`price-${index}`} className="w-4 h-full" src={icon}/>                                ))
                                : "\u00A0"
                                }
                            </div>
                            {!isMobile && props.showTags ? (
                                    <div className={'w-full flex flex-col gap-y-0.5 mt-2'}>
                                        {tags.map((item,index) => (
                                        <div key={`tag-${item.id}-${index}`} className="w-full flex items-center gap-x-0.5">
                                            {item.id !== 'vuoto' ? (
                                                <span className="rounded-full bg-white w-1 h-1 block mr-1"></span>
                                            ) : null}
                                            <h1 className="ml-0.5 text-[13px] whitespace-nowrap font-poppins-regular text-white">
                                                {item.tag || "\u00A0"}
                                            </h1>
                                        </div>
                                        ))}
                                    </div>

                            ) : null}
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        </div>
    );
}
