import {Grid} from "@mantine/core";
import CategoryComponent from "./CategoryComponent";
import {useEffect, useState} from "react";
import {CategoryType} from "../types/CategoryType";
import {getCategories} from "../network/controllers/admin/CategoryController";
import {isMobile} from "react-device-detect";

export default function CategoryDetailComponent(props: { categorySelected: (category: CategoryType) => void }) {
    const [categoryList, setCategoryList] = useState<CategoryType[]>([])

    useEffect(() => {
        getCategories(JSON.parse(localStorage.getItem('selectedRestaurantClient') as string).id, (list) => {
            setCategoryList(list)
        })
    }, [])





    if (isMobile){
    return (
        <div className="w-full h-full  ">
            <div className="w-full h-14 bg-black p-2 rounded-xl">
                <div className="flex items-center justify-center h-full">
                    <p className="font-bold text-amber-400 text-lg ml-2">
                        {categoryList.length > 0 ? 'Seleziona Categoria' : 'Nessuna Categoria'}
                    </p>
                </div>
            </div>
            <div className="w-full h-full py-5  flex items-center justify-center">
                <Grid >
                    {categoryList.length > 0
                        ? categoryList.map((item) => (
                            <Grid.Col key={item.id} span={isMobile ? 4 : {base: 12, xs: 6, md: 4, xl: 3}}>
                                <CategoryComponent
                                    category={item}
                                    onClick={(sel) => {
                                        props.categorySelected(sel);
                                    }}
                                />
                            </Grid.Col>
                        ))
                        : null}
                </Grid>
            </div>
        </div>
    );
}else{
        return (
            <div className="w-full h-full">
                <div className="w-full h-14 bg-black p-2 rounded-xl">
                    <div className="flex items-center justify-center h-full">
                        <p className="font-bold text-amber-400 text-lg ml-2">
                            {categoryList.length > 0 ? 'Seleziona Categoria' : 'Nessuna Categoria'}
                        </p>
                    </div>
                </div>
                <div className="w-full h-full flex p-2 px-8 overflow-x-hidden overflow-y-auto  items-center justify-center">
                    <Grid>
                        {categoryList.length > 0
                            ? categoryList.map((item) => (
                                <Grid.Col key={item.id} span={isMobile ? 6 : {base: 12, xs: 12, md:6, xl: 6}}>
                                    <CategoryComponent
                                        category={item}
                                        onClick={(sel) => {
                                            props.categorySelected(sel);
                                        }}
                                    />
                                </Grid.Col>
                            ))
                            : null}
                    </Grid>
                </div>
            </div>)
    }}
