import { Divider, Checkbox, Tooltip } from "@mantine/core";
import { useEffect, useState } from "react";
import placeProd from "../../assets/placeholder-dish.svg";
import { GetProductResponseType } from "../../types/GetProductResponseType";
import { IngredientType } from "../../types/IngredientType";
import CustomButton from "../../components/CustomButton";
import GenericTooltip from "../../components/generic/GenericTooltip";
import CustomTextInput from "../../components/generic/CustomTextInput";
import { CommandTableOrderType } from "../../types/CommandTableOrderType";
import minusIcon from "../../assets/minusIcon.svg";
import {isMobile} from "react-device-detect";

export default function ItemProductComponentCommand(props: {
    ingredientList: { label: string; value: string }[];
    product: GetProductResponseType;
    recipe: CommandTableOrderType["order"];
    onProductAdd: (quantity: number, ingredientList: IngredientType[], note: string, turnId: string) => void;
}) {
    const [error, setError] = useState<string | null>(null);
    const [note, setNote] = useState<string>("");
    const [productAdd, setProductAdd] = useState(false);
    const [productQuantity, setProductQuantity] = useState<number>(1);
    const [addPrice, setAddPrice] = useState<number>(0);
    const originalIng=props.product.ingredients
    const [ingredients, setIngredients] = useState<IngredientType[]>(props.product.ingredients);
    const [availableIngredients, setAvailableIngredients] = useState<IngredientType[]>([]);
    const [selectedTurn, setSelectedTurn] = useState<string | null>(null);

    useEffect(() => {
        const parsedIngredients = props.ingredientList.map((item) =>
            JSON.parse(item.value) as IngredientType
        );
        const originalIngredientIds = props.product.ingredients.map((ing) => ing.id);

        const uniqueIngredients = Array.from(
            new Map(
                parsedIngredients
                    .filter((item) => item.addable && !originalIngredientIds.includes(item.id))
                    .map((item) => [item.id, item])
            ).values()
        );

        setAvailableIngredients(uniqueIngredients);

        const defaultTurn = Object.keys(props.recipe.turn || {})[0] || null;
        setSelectedTurn(defaultTurn);
    }, [props.ingredientList, props.product.ingredients, props.recipe]);

    useEffect(() => {
        const additionalPrice = ingredients.reduce((acc, item) => {
            const isOriginal = props.product.ingredients.some((orig) => orig.id === item.id);
            return isOriginal ? acc : acc + item.price;
        }, 0);
        setAddPrice(additionalPrice);
    }, [ingredients, props.product.ingredients]);

    const toggleIngredient = (ingredient: IngredientType) => {
        const isIncluded = ingredients.some((ing) => ing.id === ingredient.id);

        if (isIncluded) {
            // Rimuovi l'ingrediente
            setIngredients((prev) => prev.filter((ing) => ing.id !== ingredient.id));
            setAvailableIngredients((prev) => [...prev, ingredient]);
        } else {
            // Aggiungi l'ingrediente
            setIngredients((prev) => [...prev, ingredient]);
            setAvailableIngredients((prev) => prev.filter((ing) => ing.id !== ingredient.id));
        }
    };

    const handleAddToCart = () => {
        if (!selectedTurn) {
            setError("Seleziona un turno.");
            return;
        }
        const updatedIngredients = ingredients.map((ingredient) => {
            const isOriginal = originalIng.some((orig) => orig.id === ingredient.id);
            return {
                ...ingredient,
                statusIngredient: isOriginal ? "BASE" : "ADD",
            };
        });
        const removedIngredients = originalIng
            .filter((orig) => !ingredients.some((ing) => ing.id === orig.id))
            .map((removed) => ({
                ...removed,
                statusIngredient: "REMOVE",
            }));
        const finalIngredients = [...updatedIngredients, ...removedIngredients];

        props.onProductAdd(productQuantity, finalIngredients, note, selectedTurn);
        setProductAdd(true);
    };


    useEffect(() => {
        console.log('tutta la roba',props.product)
    }, [props.product]);

    return (
        <div className="w-full  h-full flex items-center justify-center ">
            <div className="flex flex-col w-full h-full pt-4 gap-x-2">
                <div className={`flex flex-col  w-full h-full gap-y-3 ${isMobile? "items-center justify-center ":""}`}>
                    <p className={'text-white text-2xl text-center'}>{props.product.nameProduct}</p>
                    <img
                        className="w-[20%] h-[20%] rounded-xl flex items-center justify-center shadow-lg"
                        src={props.product.photoPath || placeProd}
                        alt={props.product.nameProduct}
                    />
                    <div className="flex flex-col justify-between px-5">
                        {/* Selezione Turno */}
                        <div>
                            <p className="text-white mb-2">Seleziona Turno:</p>
                            <select
                                value={selectedTurn || ""}
                                onChange={(e) => setSelectedTurn(e.target.value)}
                                className="w-full px-2 py-2 rounded-md bg-white text-black"
                            >

                                {Object.entries(props.recipe.turn || {}).map(([turnId, turn]) => (
                                    <option key={turnId} value={turnId}>
                                        {turn.nameTurn}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/* Quantità e Prezzo */}
                        <div className="flex items-center gap-x-2 mt-5">
                            <button
                                onClick={() => setProductQuantity((prev) => Math.max(1, prev - 1))}
                                className="w-7 h-7 rounded-full bg-[#FFCC00] flex items-center justify-center text-black font-bold"
                            >
                                -
                            </button>
                            <div
                                className="w-14 h-10 bg-white rounded flex items-center justify-center text-black font-semibold">
                                {productQuantity}
                            </div>
                            <button
                                onClick={() => setProductQuantity((prev) => Math.min(99, prev + 1))}
                                className="w-7 h-7 rounded-full bg-[#FFCC00] flex items-center justify-center text-black font-bold"
                            >
                                +
                            </button>
                            <p className="text-white">€ {(props.product.priceOriginal + addPrice).toFixed(2)}</p>
                        </div>
                        <p className="text-white my-2">Ingredienti:</p>
                        {/* Lista Ingredienti */}
                        <div className={`max-w-fit h-auto flex  ${isMobile? "w-full flex-col gap-y-2":"max-w-fit flex-wrap gap-y-2"} mt-2`}>
                            {ingredients.map((ingredient) => (
                                <div
                                    key={ingredient.id}
                                    className={`flex max-w-fit max-h-min  mr-2 items-center p-1 rounded-xl bg-[#FFCC00] ${isMobile ? "flex-col":""}`}
                                >
                                    <GenericTooltip
                                        txt={ingredient.name}
                                        innerHtml={
                                            <p className="text-sm text-black font-poppins-regular truncate">
                                                {ingredient.name}
                                                {!props.product.ingredients.some((orig) => orig.id === ingredient.id) &&
                                                ingredient.price
                                                    ? ` (+€${ingredient.price.toFixed(2)})`
                                                    : ""}
                                            </p>
                                        }
                                    />
                                    <img
                                        alt=""
                                        src={minusIcon}
                                        className="mr-1 w-5 h-5 cursor-pointer"
                                        onClick={() => toggleIngredient(ingredient)}
                                    />
                                </div>
                            ))}
                        </div>

                        <p className="text-white my-2">Aggiungi Ingredienti:</p>
                        {/* Checkbox Group per ingredienti aggiungibili */}
                        <div className="mt-2">
                            {availableIngredients.map((ingredient) => (
                                <Checkbox
                                    className="text-white my-2 gap-y-2"
                                    key={ingredient.id}
                                    label={`${ingredient.name} (+€${ingredient.price.toFixed(2)})`}
                                    checked={ingredients.some((ing) => ing.id === ingredient.id)}
                                    onChange={() => toggleIngredient(ingredient)}
                                />
                            ))}
                        </div>
                        {/* Note e Bottone */}
                        <div className={`flex flex-col gap-y-2 mt-3 ${isMobile? "items-center justify-center w-full h-auto":""}`}>
                            <CustomTextInput
                                customDimensions="w-full h-[50px]"
                                placeholder="Inserisci note aggiuntive..."
                                defaultValue={note}
                                onChange={setNote}
                            />
                            <CustomButton
                                small
                                title={productAdd ? "Aggiunto con successo" : "Aggiungi"}
                                onClick={handleAddToCart}
                                enable={!!ingredients.length && !!selectedTurn}
                                isLoading={false}
                            />
                        </div>
                    </div>
                </div>
                <Divider color="yellow" size="sm" className="mt-4"/>
            </div>
        </div>
    );
}
