import { useEffect, useState } from "react";
import { makeRequest } from "../../network/RestAdapter";
import { HttpMethodsEnum } from "../../network/HttpMethodsEnum";
import { Tabs } from "@mantine/core";
import HallLiveStatPage from "./HallLiveStatPage";
import DeliveryLiveStatPage from "./DeliveryLiveStatPage";
import { LiveDataType } from "../../types/LiveDataType";
import { subscribeTopic, onMessage } from "../../utils/MqttUtility";

export default function LiveStats() {
    const [statsData, setStatsData] = useState<LiveDataType>();

    // Funzione per caricare i dati
    const loadData = () => {
        const selectedRestaurant = localStorage.getItem("selectedRestaurant");
        if (selectedRestaurant) {
            const restaurantId = JSON.parse(selectedRestaurant).id;
            makeRequest<LiveDataType>(
                `/analytics/live-stats/${restaurantId}`,
                HttpMethodsEnum.GET
            ).then((res) => {
                if (res.isSuccessfull() && res.data) {
                    setStatsData(res.data);
                }
            });
        }
    };

    useEffect(() => {
        // Esegui il caricamento iniziale dei dati
        loadData();

        const selectedRestaurant = localStorage.getItem("selectedRestaurant");
        if (selectedRestaurant) {
            const restaurantId = JSON.parse(selectedRestaurant).id;

            const topicEnter = `/${restaurantId}/enter-order`;
            const topicUpdate = `/${restaurantId}/update-order`;
            const topicClose = `/${restaurantId}/close-order`;

            //Iscrizione ai topic
            subscribeTopic(topicEnter);
            subscribeTopic(topicUpdate);
            subscribeTopic(topicClose);

            // Ascolto dei messaggi su ciascun topic e in caso di messaggio ricarico chiamando il metodo loadData()
            onMessage(topicEnter, () => {
                console.log("Ricevuto messaggio su topic enter-order");
                loadData();
            });

            onMessage(topicUpdate, () => {
                console.log("Ricevuto messaggio su topic update-order");
                loadData(); // Ricarica i dati
            });

            onMessage(topicClose, () => {
                console.log("Ricevuto messaggio su topic close-order");
                loadData(); // Ricarica i dati
            });
        }
    }, []);

    return (
        <div className="pt-[110px] text-white min-h-screen m-[-20px]">
            <h1 className="text-3xl font-bold mb-6 flex items-center">
                <span className="text-black text-2xl mr-2">● Servizio Live</span>
            </h1>

            <Tabs
                color="yellow"
                variant="pills"
                defaultValue="sala"
                classNames={{
                    root: "w-full flex flex-col items-center justify-center",
                    tab: "text-black font-poppins-semi-bold text-lg",
                    panel: "w-full h-full mt-5",
                }}
            >
                <Tabs.List>
                    <Tabs.Tab value="sala">Sala</Tabs.Tab>
                    <Tabs.Tab value="asporto">Asporto/Domicilio</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="sala" pt="xs">
                    {statsData ? <HallLiveStatPage liveData={statsData} /> : null}
                </Tabs.Panel>

                <Tabs.Panel value="asporto" pt="xs">
                    {statsData ? <DeliveryLiveStatPage liveStats={statsData} /> : null}
                </Tabs.Panel>
            </Tabs>
        </div>
    );
}
