import {CategoryType} from "../types/CategoryType";
import {StatusEnum} from "../enums/StatusEnum";
import placeholderCategory from '../assets/placeolder-categoria.svg'
import {isMobile} from "react-device-detect";
import {getSystemLanguage} from "../utils/Utility";

export default function CategoryComponent(props: {
    isSelected?: boolean,
    category: CategoryType,
    onClick: (category: CategoryType) => void
}) {
    return (
        <div className={'w-auto h-full flex flex-col cursor-pointer  items-center justify-center'} onClick={() => {
            props.onClick(props.category)
        }}>
            <img alt={''}
                 className={`w-[200px] h-[100%] rounded-xl shadow-lg shadow-black`}
                 src={props.category.approvalStatus !== StatusEnum.ACCEPTED ? placeholderCategory : props.category.image}/>
            <div className={'w-min flex flex-col justify-center items-center py-1 '}>
                <h1 className={'font-poppins-bold whitespace-nowrap truncate pt-3'}>{props.category.categoryName}</h1>
                {getSystemLanguage(Array.from(props.category.categoryNameTranslations.keys())) !== 'it'?<h1 className={'font-poppins-bold text-xs whitespace-nowrap truncate'}>{props.category.categoryNameTranslations.get(getSystemLanguage(Array.from(props.category.categoryNameTranslations.keys())))}</h1>:null}
            </div>
        </div>
    )
}
