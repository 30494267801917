import {Accordion, Center} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {CategoryType} from "../types/CategoryType";
import {GetProductResponseType} from "../types/GetProductResponseType";
import {showWarningNotification} from "../utils/NotificationUtils";
import ProductCategoryTable from "../pages/admin/product/ProductCategoryTable";
import MenuCategory from "./MenuCategory";
import {deleteCategory, removeCategoryFromMenu, updateCategory} from "../network/controllers/admin/CategoryController";
import ReactDragListView from 'react-drag-listview';
import {StatusEnum} from "../enums/StatusEnum";
import {useNavigate} from "react-router-dom";
import arrowSelection from '../../src/assets/arrowSelection.svg'
import {isMobile} from "react-device-detect";
import {getDimension} from "../utils/Utility";

export default function AccordionCategoriesAdmin(props: {
    isAdmin: boolean,
    data: Map<CategoryType, GetProductResponseType[]>,
    reload?: () => void
    updateCategory: (category: CategoryType) => void
    addProduct: (categoryId: string, listSize: number) => void
}) {
    const [items, setItems] = useState(Array.from(props.data.keys()).sort());

    const [dimension, setDimension] = useState(window.innerWidth < 1280)
    useEffect(() => {
        getDimension(1280, (res)=>{
            console.log(res);
            setDimension(res)
        })
    },[])
    const navigate = useNavigate();
    const onDragEnd = (fromIndex: any, toIndex: any) => {
        const updatedItems = [...items];
        const item = updatedItems.splice(fromIndex, 1)[0];

        updatedItems.splice(toIndex, 0, item);
        updatedItems.forEach((item, index) => {
            item.positionCategory[JSON.parse(localStorage.getItem('selectedMenu') as string).id] = index + 1
            var menus: string[] = []
            item.idMenus.forEach((cat) => {
                if (typeof cat === "string") {
                    menus.push(cat)
                } else {
                    menus.push(cat.id)
                }
            })
            setItems(updatedItems)
            updateCategory({
                id: item.id!,
                categoryName: item.categoryName,
                image: item.image,
                idMenus: menus,
                description: item.description,
                positionCategory: item.positionCategory
            }, 0, () => {
            })
        })

    };

    function retrieveStatus(category: CategoryType): string {
        if (category.approvalStatus === StatusEnum.ACCEPTED) {
            return 'border-lime-400'
        } else if (category.approvalStatus === StatusEnum.REJECTED) {
            return 'border-red-600'
        } else if (category.approvalStatus === StatusEnum.PENDING) {
            return 'border-yellow'
        } else {
            return ''
        }
    }

    if (isMobile) {
        return (
            <div className="w-auto">
                {props.data ? (
                    <ReactDragListView
                        lineClassName={'test'}
                        nodeSelector=".accordion-item"
                        onDragEnd={onDragEnd}>
                        <Accordion className="w-full h-auto">
                            {items.map((date, index) => (
                                <Accordion.Item key={date.id} value={date.categoryName} className="accordion-item">
                                    <Center>
                                        <Accordion.Control>
                                            <div className="w-autoh-auto flex justify-between items-center">
                                                <div className="flex justify-start items-center l-0 gap-x-2">
                                                    <div>
                                                        <img alt="" src={arrowSelection}/>
                                                    </div>
                                                    <img
                                                        alt=""
                                                        src={date.image}
                                                        className={`w-[50px] h-[50px] rounded-lg border-[3.5px] t ${retrieveStatus(date)} p-0.5 `}
                                                    />
                                                    <h1 className="w-auto h-auto font-poppins-semi-bold text-sm ">{date.categoryName}</h1>
                                                </div>
                                                {/*{date.description && (
                                                    <h1 className="w-auto font-poppins-regular max-h-24 overflow-y-auto whitespace-normal">
                                                        {'Descrizione: ' + date.description}
                                                    </h1>
                                                )}*/}
                                                <h1 className="font-poppins-regular text-sm justify-endunderline">Visualizza</h1>
                                            </div>
                                        </Accordion.Control>
                                    </Center>


                                    <Accordion.Panel className="">
                                        {props.data && props.data.get(date) !== undefined ? (
                                            <div>
                                                <div className="w-full flex flex-col justify-between ">
                                                    <h1 className="font-poppins-regular text-sm text-center whitespace-normal underline">
                                                        Prodotti disponibili per la categoria {date.categoryName}
                                                    </h1>
                                                    <MenuCategory listSize={props.data.get(date)!.length}
                                                                  onProductAdd={() => {
                                                                      props.addProduct(date.id!, props.data.get(date)!.length)
                                                                  }}
                                                                  onCategorySelect={(type) => {
                                                                      if (type === 'delete') {
                                                                          showWarningNotification(
                                                                              `Categorie Sicuro di voler eliminare la categoria ${date.categoryName}?`,
                                                                              () => {
                                                                                  deleteCategory(date.id!, (response) => {
                                                                                      if (props.reload) props.reload();
                                                                                  });
                                                                              }
                                                                          );
                                                                      } else if (type === 'update') {
                                                                          props.updateCategory(date);
                                                                      } else if (type === 'remove') {
                                                                          showWarningNotification(
                                                                              `Categorie Sicuro di voler rimuovere la categoria ${date.categoryName} dal menù ${JSON.parse(localStorage.getItem('selectedMenu')!).name}?`,
                                                                              () => {
                                                                                  removeCategoryFromMenu(
                                                                                      JSON.parse(localStorage.getItem('selectedMenu')!).id,
                                                                                      date.id!,
                                                                                      (response) => {
                                                                                          if (props.reload) props.reload();
                                                                                      }
                                                                                  );
                                                                              }
                                                                          );
                                                                      }
                                                                  }}
                                                                  category={date}
                                                    />
                                                </div>
                                                <ProductCategoryTable
                                                    category={date}
                                                    productList={props.data.get(date)!}
                                                    onClickItem={(product) => {
                                                        navigate('/admin/prodotti/aggiunta', {
                                                            state: {
                                                                product: product,
                                                                category: date
                                                            }
                                                        })
                                                    }}
                                                    onReload={() => {
                                                        if (props.reload) props.reload();
                                                    }
                                                    }
                                                />
                                            </div>
                                        ) : null}
                                    </Accordion.Panel>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </ReactDragListView>
                ) : null}
            </div>
        );
    } else {
        return (
            <div className="w-full">
                {props.data ? (
                    <ReactDragListView
                        lineClassName={'test'}
                        nodeSelector=".accordion-item"
                        handleSelector=".pippo"
                        onDragEnd={onDragEnd}>
                        <Accordion className="w-full h-[180px]">
                            {items.map((date, index) => (
                                <Accordion.Item key={date.id} value={date.categoryName} className="accordion-item">
                                    <Center>
                                        <Accordion.Control>
                                            <div className="w-full flex items-center justify-between gap-x-2">
                                                <div className="flex items-center gap-x-5">
                                                    <div className="pippo">
                                                        <img alt="" src={arrowSelection}/>
                                                    </div>
                                                    <img
                                                        alt=""
                                                        src={date.image}
                                                        className={`w-[100px] h-[100px] rounded-lg border-[3.5px] ${retrieveStatus(date)} p-0.5`}
                                                    />
                                                    <h1 className="font-poppins-semi-bold text-2xl">{date.categoryName}</h1>
                                                </div>
                                                {date.description && (
                                                    <h1 className="w-auto ml-32 font-poppins-regular max-h-24 overflow-y-auto whitespace-normal">
                                                        {'Descrizione: ' + date.description}
                                                    </h1>
                                                )}
                                                <h1 className="font-poppins-regular text-md pr-2 underline">Visualizza</h1>
                                            </div>
                                        </Accordion.Control>
                                    </Center>


                                    <Accordion.Panel className="px-5">
                                        {props.data && props.data.get(date) !== undefined ? (
                                            <div>
                                                <div className={`w-full flex items-end justify-between`}>
                                                    <h1 className="font-poppins-regular text-md pr-2 underline">
                                                        Prodotti disponibili per la categoria {date.categoryName}
                                                    </h1>
                                                    <MenuCategory listSize={props.data.get(date)!.length}
                                                                  onProductAdd={() => {
                                                                      props.addProduct(date.id!, props.data.get(date)!.length)
                                                                  }}
                                                                  onCategorySelect={(type) => {
                                                                      if (type === 'delete') {
                                                                          showWarningNotification(
                                                                              `Categorie Sicuro di voler eliminare la categoria ${date.categoryName}?`,
                                                                              () => {
                                                                                  deleteCategory(date.id!, (response) => {
                                                                                      if (props.reload) props.reload();
                                                                                  });
                                                                              }
                                                                          );
                                                                      } else if (type === 'update') {
                                                                          props.updateCategory(date);
                                                                      } else if (type === 'remove') {
                                                                          showWarningNotification(
                                                                              `Categorie Sicuro di voler rimuovere la categoria ${date.categoryName} dal menù ${JSON.parse(localStorage.getItem('selectedMenu')!).name}?`,
                                                                              () => {
                                                                                  removeCategoryFromMenu(
                                                                                      JSON.parse(localStorage.getItem('selectedMenu')!).id,
                                                                                      date.id!,
                                                                                      (response) => {
                                                                                          if (props.reload) props.reload();
                                                                                      }
                                                                                  );
                                                                              }
                                                                          );
                                                                      }
                                                                  }}
                                                                  category={date}
                                                    />
                                                </div>
                                                <ProductCategoryTable
                                                    category={date}
                                                    productList={props.data.get(date)!}
                                                    onClickItem={(product) => {
                                                        navigate('/admin/prodotti/aggiunta', {
                                                            state: {
                                                                product: product,
                                                                category: date
                                                            }
                                                        })
                                                    }}
                                                    onReload={() => {
                                                        if (props.reload) props.reload();
                                                    }
                                                    }
                                                />
                                            </div>
                                        ) : null}
                                    </Accordion.Panel>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </ReactDragListView>
                ) : null}
            </div>
        );

    }
}
