import {LocalType} from "../../types/LocalType";
import {CategoryType} from "../../types/CategoryType";
import {useEffect, useState} from "react";
import {GetProductResponseType} from "../../types/GetProductResponseType";
import {getProductList} from "../../network/controllers/DetailController";
import {isMobile} from "react-device-detect";
import arrow from '../../assets/arrow-yellow.svg'
import {BackgroundImage, Textarea} from "@mantine/core";
import DeliveryItemComponent from "./DeliveryItemComponent";
import ItemProductComponentDelivery from "./ItemProductComponentDelivery";
import {
    getAvailableIngredients,
} from "../../network/controllers/admin/IngredientsController";
import {IngredientType} from "../../types/IngredientType";
import {getSystemLanguage} from "../../utils/Utility";

export default function ProductDeliveryComponent(props: {
    local: LocalType,
    onBack: () => void,
    categorySelected: CategoryType,
    onProductAdd: (product: GetProductResponseType, quantity: number, ingredientList: IngredientType[], note: string, cartItemId: string) => void
}) {
    const [listProduct, setListProduct] = useState<GetProductResponseType[]>([]);
    const [categoryIngredientsList, setCategoryIngredientsList] = useState<{label:string,value:string}[]>([]);

    useEffect(() => {
        getProductList(props.local.id, props.categorySelected.id!, (listProductResponse) => {
            setListProduct(listProductResponse)
        })
        getAvailableIngredients(props.categorySelected.id!,(response)=>{
            var list:{label:string,value:string}[]=[]
            debugger
            if (response.length>0){
                response.forEach((ing)=>{
                    list.push({label:`${ing.ingredientNameTranslations?.get(getSystemLanguage(Array.from(ing.ingredientNameTranslations?.keys())))} €${ing.price}`,value:JSON.stringify(ing)})
                })
            }
            setCategoryIngredientsList(list)
        })
    }, []);

    const handleProductAdd = (
        product: GetProductResponseType,
        quantity: number,
        ingredientList: IngredientType[],
        note: string
    ) => {
        // Creiamo un identificatore univoco che include prodotto + ingredienti + note
        const cartItemId = `${product.id}-${ingredientList.map(i => i.id).sort().join('-')}-${note}`;

        // Passiamo tutti i dati incluso il cartItemId al componente genitore
        props.onProductAdd(product, quantity, ingredientList, note, cartItemId);
    };

    return (
        <div className={'w-full'}>
            <div className={`w-full h-14 bg-black p-2 ${props.categorySelected.description !== '' ? 'rounded-t-xl' : 'rounded-xl'} `}>
                <div className={'flex items-center justify-center h-full'}>
                    <img alt={''} className={'h-full p-3'} src={arrow} onClick={props.onBack}/>

                    <p className={'w-full font-bold text-amber-400 text-center text-lg'}>{props.categorySelected.categoryName}
                        {getSystemLanguage(Array.from(props.categorySelected.categoryNameTranslations.keys()))!== 'it'?<p className={' text-xs font-poppins-bold whitespace-nowrap text-amber-400 truncate'}>{props.categorySelected.categoryNameTranslations.get(getSystemLanguage(Array.from(props.categorySelected.categoryNameTranslations.keys())))}</p>:null}
                    </p>


                </div>
            </div>
            {props.categorySelected.description && (
                <div className="w-full h-[100px] rounded-b-2xl">
                    <BackgroundImage
                        className="w-full h-full no-repeat rounded-b-2xl"
                        src={props.categorySelected.image}>
                        <div className="w-full h-full flex flex-row bg-gradient-to-b from-black from-40% to-transparent to-100% rounded-b-2xl">
                            <div className={'w-full flex items-center justify-center'}>
                                <Textarea
                                    classNames={{
                                        input: 'text-amber-400 font-poppins-regular text-md text-center',
                                        root: 'w-full',
                                        label: 'text-white font-poppins-semi-bold',
                                        wrapper: 'rounded-xl'
                                    }}
                                    defaultValue={props.categorySelected.description}
                                    autosize
                                    maxRows={5}
                                    minRows={5}
                                    variant={'unstyled'}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </BackgroundImage>
                </div>
            )}

            <div className={'w-full h-full overflow-y-auto'}>
                <div className={'w-full max-w-[100%] flex flex-col gap-y-7 px-4 pt-5'}>
                    {listProduct.length > 0 ? listProduct.map((product) => (
                        <ItemProductComponentDelivery
                            key={product.id}
                            ingredientList={categoryIngredientsList}
                            onProductAdd={(quantity, ingredientList, note) => {
                                handleProductAdd(product, quantity, ingredientList, note);
                            }}
                            product={product}
                        />
                    )) : null}
                </div>
            </div>
        </div>
    );
}
