import ChatWindow from "./ChatWindow";
import chefBotImage from "../../assets/chef.svg";
import React, {useState} from "react";
import {isMobile} from "react-device-detect";

export default function ChatBotComponent() {

    const [chatOpen, setChatOpen] = useState(false); // Stato della chat
    const [smallSize, setSmallSize] = useState(false); // Stato delle dimensioni

    const handleChatOpen = () => {
        setChatOpen(true);
        setSmallSize(true);
    };

    const handleChatClose = () => {
        setChatOpen(false);
        setSmallSize(false);
    };

    if (isMobile) {
        return (
            <div>
                {chatOpen && (
                    <div className="absolute bottom-4 right-4 w-auto h-auto z-50"
                         style={{backgroundColor: 'rgba(255, 255, 255, 0.8)'}}>
                        <ChatWindow closeChat={handleChatClose}/>
                    </div>
                )}

                <div className="fixed bottom-7 right-4 z-50">
                    <div
                        className={`shadow-2xl shadow-black relative w-16 h-16 bg-amber-400 rounded-full flex items-center justify-center cursor-pointer transition-transform duration-300 ${smallSize ? 'scale-[.7]' : ''}`}
                        onClick={chatOpen ? handleChatClose : handleChatOpen}
                    >
                        <img
                            src={chefBotImage}
                            alt="ChefBot"
                            className="w-12 h-12 items-center justify-center"
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                {chatOpen && (
                    <div className="absolute bottom-2 right-4 w-auto h-auto z-50"
                         style={{backgroundColor: 'rgba(255, 255, 255, 0.8)'}}>
                        <ChatWindow closeChat={handleChatClose}/>
                    </div>
                )}

                <div className="fixed bottom-10 right-10 z-50">
                    <div
                        className={`shadow-2xl shadow-black relative w-20 h-20 bg-amber-400 rounded-full 
                        flex items-center justify-center cursor-pointer transition-transform duration-300 ${smallSize ? 'scale-[.8]' : ''}`}
                        onClick={chatOpen ? handleChatClose : handleChatOpen}
                    >
                        <img
                            src={chefBotImage}
                            alt="ChefBot"
                            className="w-16 h-16"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
