import {makeRequest} from "../../RestAdapter";
import {HttpMethodsEnum} from "../../HttpMethodsEnum";
import {MenuType} from "../../../types/CategoryType";
import {PriceType} from "../../../types/PriceType";


export function getMenu(onFinish:(response:MenuType[]) => void) {
    makeRequest<MenuType[]>(`/restaurants/menu?idLocal=${JSON.parse(localStorage.getItem('selectedRestaurant') as string).id}`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()){
            onFinish(res.data?res.data:[])
        }
    })
}

export function getPriceType(onFinish:(response:PriceType[]) => void) {
    makeRequest<PriceType[]>(`/restaurants/products/getPriceType`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()){
            onFinish(res.data?res.data:[])
        }
    })
}


export function getMenuQr(onFinish:(response:any) => void) {
    makeRequest<any>(`/restaurants/menu/generate-qr?idLocal=${JSON.parse(localStorage.getItem('selectedRestaurant') as string).id}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }
    })
}
export function getMenuPDF(idMenu:string,onFinish:(response:any) => void) {
    makeRequest<any>(`/restaurants/menu/generate-pdf?idMenu=${idMenu}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }
    })
}

export function deleteMenu(idMenu:string,onFinish:(response:any) => void) {
    makeRequest<any>(`/restaurants/menu?idMenu=${idMenu}`, HttpMethodsEnum.DELETE).then((res) => {
        if (res.isSuccessfull()){
            onFinish(res)
        }
    })
}



export function createMenu(request:{idLocal:string,name:string},onFinish:(response: any[]) => void) {
    makeRequest<any[]>(`/restaurants/menu?name=${request.name}&idLocal=${request.idLocal}`, HttpMethodsEnum.POST).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }
    })
}

export function updateMenu(request:{idMenu:string,name:string},onFinish:(response: any[]) => void) {
    makeRequest<any[]>(`/restaurants/menu/update-name`, HttpMethodsEnum.PUT,request).then((res) => {
        if (res.isSuccessfull()) onFinish(res.data?res.data:[])

    })
}
export function updateCoverage(request: { idLocal: string; newPrice: number }, onFinish: (response: any[]) => void) {
    makeRequest<any[]>(`/restaurants/menu/update-covered/${request.idLocal}/${request.newPrice}`, HttpMethodsEnum.PUT).then((res) => {
        if (res.isSuccessfull()) {onFinish(res.data ? res.data : []);}
    });
}


