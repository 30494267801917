import {ProductType} from "../../../types/ProductType";
import iphoneImg from '../../../assets/iphone-img.svg'
import ItemProductComponent from "../../../components/ItemProductComponent";
import {getAllergeni} from "../../../network/controllers/admin/AllergenController";
import {useEffect, useState} from "react";
import {AllergenType} from "../../../types/AllergenType";
import {GetProductResponseType} from "../../../types/GetProductResponseType";
import {StatusEnum} from "../../../enums/StatusEnum";
import {isMobile} from "react-device-detect";
import {getDimension} from "../../../utils/Utility";


export default function StepRecapProduct(props: {
    product: ProductType,
}) {
    const [product, setProduct] = useState<GetProductResponseType>({
        allergensList: [],
        approvalStatus: "",
        description: props.product.description,
        id: "",
        idKitchen: props.product.idKitchen,
        idLocal: props.product.idLocal,
        ingredients: props.product.ingredients,
        isAvailable: false,
        menusList: [],
        nameCategories: [],
        nameKitchen: "",
        nameProduct: props.product.nameProduct,
        photoPath: props.product.photoPath,
        priceOriginal: props.product.priceOriginal,
        subNameProduct: "",
        priceType:props.product.priceType==='Singolo'?'':props.product.priceType
    });
    useEffect(() => {
        getAllergeni((allergeniList) => {
            var tmpArr: AllergenType[] = []
            allergeniList.forEach((item) => {
                props.product.allergens.forEach((allergen) => {
                    if (item.id === allergen) {
                        if (!tmpArr.includes(item)) {
                            tmpArr.push(item);
                        }
                    }
                })
            })
            setProduct({...product, allergensList: tmpArr});
        })
    }, []);

    const [dimension, setDimension] = useState(window.innerWidth < 1280)
    useEffect(() => {
        getDimension(1280, (res)=>{
            console.log(res);
            setDimension(res)
        })
    },[])


if (isMobile || dimension) {

    return (
        <div>
            <div className={'w-full h-full flex flex-col items-center justify-center'}>
                <div className={'w-full h-full flex  flex-col items-center justify-center  gap-x-2'}>
                    <h1 className={'text-2xl font-poppins-semi-bold'}>{`Stai Creando`}</h1>
                    <h1 className={'text-2xl font-poppins-bold'}>{` ${product.nameProduct}`}</h1>
                </div>

                <div className={`w-full flex flex-col gap-x-10 mt-5 items-center justify-center `}>
                    <div className={`w-auto h-full flex `}>
                        <div className={'w-[264px] h-[536px]'} style={{
                            backgroundImage: `url(${iphoneImg})`,
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <div className={'w-full h-full items-end justify-end flex flex-col p-7'}>
                                <img alt={''} className={'w-full h-[180px] rounded-t-lg'} src={product.photoPath}/>
                                <div
                                    className={'w-full bg-white rounded-b-lg  flex flex-col p-2 gap-y-1 items-center justify-center'}>
                                    <div className={'w-full flex justify-between'}>
                                        <h1 className={'text-[10px] font-poppins-semi-bold text-start'}>{product.nameProduct}</h1>
                                        <h1 className={'text-[10px] font-poppins-semi-bold text-start'}>€ {product.priceOriginal}</h1>

                                    </div>
                                    <div className={'w-full flex flex-col items-start gap-y-1'}>
                                        <h1 className={'text-[8px] max-h-[40px]  overflow-y-auto font-poppins-regular text-start'}>{product.description}</h1>
                                        <div className={'flex gap gap-x-2'}>

                                            {product.allergensList.map((item) => (
                                                <img alt={''} className={'w-4'} src={item.b64Image}/>
                                            ))}
                                        </div>
                                    </div>

                                    <div
                                        className={'w-full bg-yellow  p-1 rounded-2xl items-center justify-center mt-1'}>
                                        <h1 className={'w-full text-[8px] text-center text-black  overflow-y-auto font-poppins-semi-bold '}>Aggiungi
                                            all'ordine</h1>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'w-auto h-auto mt-10'}>
                        <ItemProductComponent product={{...product, approvalStatus:StatusEnum.ACCEPTED}}/>
                    </div>


                </div>
            </div>
        </div>

    )
}else{
    return (
        <div>
            <div className={'w-full h-full flex flex-col items-center justify-center p-20'}>
                <div className={'w-full h-full flex gap-x-2'}>
                    <h1 className={'text-2xl font-poppins-semi-bold'}>{`Stai Creando`}</h1>
                    <h1 className={'text-2xl font-poppins-bold'}>{` ${product.nameProduct}`}</h1>
                </div>

                <div className={`w-full flex gap-x-10 mt-5 items-center justify-center `}>
                    <div className={`w-auto h-full flex `}>
                        <div className={'w-[264px] h-[536px]'} style={{
                            backgroundImage: `url(${iphoneImg})`,
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <div className={'w-full h-full items-end justify-end flex flex-col p-7'}>
                                <img alt={''} className={'w-full h-[180px] rounded-t-lg'} src={product.photoPath}/>
                                <div
                                    className={'w-full bg-white rounded-b-lg  flex flex-col p-2 gap-y-1 items-center justify-center'}>
                                    <div className={'w-full flex justify-between'}>
                                        <h1 className={'text-[10px] font-poppins-semi-bold text-start'}>{product.nameProduct}</h1>
                                        <h1 className={'text-[10px]  font-poppins-semi-bold text-start'}>€ {product.priceOriginal}</h1>

                                    </div>
                                    <div className={'w-full flex flex-col items-start gap-y-1'}>
                                        <h1 className={'text-[8px] max-h-[40px]  overflow-y-auto font-poppins-regular text-start'}>{product.description}</h1>
                                        <div className={'flex flex-wrap gap gap-x-2 '}>

                                            {product.allergensList.map((item) => (
                                                <img alt={''} className={'w-4'} src={item.b64Image}/>
                                            ))}
                                        </div>
                                    </div>

                                    <div
                                        className={'w-full bg-yellow  p-1 rounded-2xl items-center justify-center mt-1'}>
                                        <h1 className={'w-full text-[8px] text-center text-black  overflow-y-auto font-poppins-semi-bold '}>Aggiungi
                                            all'ordine</h1>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'w-1/2 h-min'}>
                        <ItemProductComponent product={{...product, approvalStatus:StatusEnum.ACCEPTED}}/>
                    </div>


                </div>
            </div>
        </div>

    )
}}
