import SettingsAdminCardComponent from "../../components/SettingsAdminCardComponent";
import settingsAccountIcon from '../../assets/settings-account-icon.svg';
import iconSettingCardArrow from '../../assets/icon-setting-card-arrow.svg';
import iconSettingKey from '../../assets/icon-setting-key.svg';
import {Drawer} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router-dom";
import AccountDrawerComponent from "../../components/AccountDrawerComponent";
import PasswordDrawerComponent from "../../components/PasswordDrawerComponent";
import {getDimension} from "../../utils/Utility";

export default function SettingClientPage() {
    const [openDreawer, setOpenDreawer] = useState({open: false, dimension: '30%'});
    const [contenentDrawer, setContenentDrawer] = useState(<div></div>);
    const [dimension, setDimension] = useState(window.innerWidth < 1280)
    useEffect(() => {
        getDimension(1280, (res)=>{
            console.log(res);
            setDimension(res)
        })
    },[])


    var navigator = useNavigate();

    function retrieveStatus(status: string): React.JSX.Element {
        if (status === 'ACTIVE') {
            return <div className={'w-5 h-5 bg-lime-400 rounded-full ml-2 border-[1px] border-white'}></div>
        } else if (status === 'SUSPENDED') {
            return <div className={'w-5 h-5 bg-yellow rounded-full ml-2 border-[1px] border-white'}></div>
        } else {
            return <div className={'w-5 h-5 bg-red-600 rounded-full ml-2 border-[1px] border-white'}></div>
        }
    }

    if (isMobile) {

        return (
            <div className={'w-full h-full flex flex-col items-center pt-[130px] bg-white pb-8'}>
                <Drawer styles={{content: {backgroundColor: 'black'}, header: {backgroundColor: 'black'}}}
                        position={'bottom'} size={'full'} opened={openDreawer.open} onClose={() => {
                    setOpenDreawer({...openDreawer, open: false});
                }}>
                    {contenentDrawer}
                </Drawer>

                <SettingsAdminCardComponent title={'Account'} text={'Clicca qui per gestire i tuoi dati personali'}
                                            image={settingsAccountIcon} arrow={iconSettingCardArrow} onClick={() => {
                    setOpenDreawer({...openDreawer, open: true});
                    setContenentDrawer(<AccountDrawerComponent/>)
                }}/>


                <SettingsAdminCardComponent title={'Cambio Password'} text={'Imposta la tua nuova password'}
                                            image={iconSettingKey} arrow={iconSettingCardArrow} onClick={() => {
                    setOpenDreawer({...openDreawer, open: true});
                    setContenentDrawer(<PasswordDrawerComponent/>)
                }}/>

            </div>
        );
    } else {
        return (

            <div className={` ${dimension? "w-auto h-full flex items-center justify-center  gap-y-3 mb-20  flex-col pt-[150px]":"w-full h-[calc(100vh)]  flex gap-x-5  justify-start pl-10 pt-[130px]"} `}>

                <Drawer styles={{content: {backgroundColor: 'black'}, header: {backgroundColor: 'black'}}}
                        position={'right'} size={openDreawer.dimension} opened={openDreawer.open} onClose={() => {
                    setOpenDreawer({...openDreawer, open: false});
                }}>
                    {contenentDrawer}
                </Drawer>

                <SettingsAdminCardComponent title={'Account'} text={'Clicca qui per gestire i tuoi dati personali'}
                                            image={settingsAccountIcon} arrow={iconSettingCardArrow} onClick={() => {
                    setOpenDreawer({...openDreawer, open: true});
                    setContenentDrawer(<AccountDrawerComponent/>)
                }}/>

                <SettingsAdminCardComponent title={'Cambio Password'} text={'Imposta la tua nuova password'}
                                            image={iconSettingKey} arrow={iconSettingCardArrow} onClick={() => {
                    setOpenDreawer({...openDreawer, open: true});
                    setContenentDrawer(<PasswordDrawerComponent/>)
                }}/>

            </div>

        );
    }
}
