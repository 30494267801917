import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";
import {Notifications} from "@mantine/notifications";
import {MantineProvider} from "@mantine/core";
import './index.css';
import './App.css';
import './index.css';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import "@mantine/carousel/styles.css";
import HomeFormPage from "./pages/HomeFormPage";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <MantineProvider>
        <Notifications/>
        <Router>
            <Suspense fallback={<h1>LOADING... INSERT YOUR LOADER FOR CALL LAZY</h1>}>
                <App />
            </Suspense>
        </Router>
    </MantineProvider>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

