import CustomButton from "../CustomButton";
import {deleteReservation, sendResponseAdmin} from "../../network/controllers/admin/ReservationController";
import {ReservationResponseType} from "../../types/ReservationResponseType";
import {StatusEnum} from "../../enums/StatusEnum";
import {useState} from "react";
import {Textarea} from "@mantine/core";
import {showFailNotification, showSuccessNotification, showWarningNotification} from "../../utils/NotificationUtils";
import Barcode from 'react-barcode';

export default function ReservationCardComponentAdmin(props: {
    client: boolean,
    reservation: ReservationResponseType,
    onCallFinish: () => void
}) {

    const [statusButton, setStatusButton] = useState<boolean>(false);
    const [noteAdmin, setNoteAdmin] = useState('');
    const [reservationCode, setReservationCode] = useState('');
    var colorStatus = 'bg-black'
    if (props.reservation.reservationStatus === StatusEnum.ACCEPTED) {
        colorStatus = 'bg-green-600'
    }
    if (props.reservation.reservationStatus === StatusEnum.PENDING) {
        colorStatus = 'bg-yellow'
    }
    if (props.reservation.reservationStatus === StatusEnum.REJECTED) {
        colorStatus = 'bg-red-600'
    }
    if (props.reservation.reservationStatus === StatusEnum.DONE) {
        colorStatus = 'bg-blue-600'
    }

    function sendResponseReservation(id: string, reservationStatus: StatusEnum, reservationAdminNote: string) {
        sendResponseAdmin({
            id: id,
            reservationStatus: reservationStatus,
            reservationAdminNote: reservationAdminNote
        }, () => {
            setStatusButton(false)
            if (reservationStatus === StatusEnum.ACCEPTED) {
                showSuccessNotification("Accettata", "", 4000)
            } else {
                showFailNotification("Rifiutata", "", 4000)
            }

            props.onCallFinish()
        })
    }

    var data = new Date(props.reservation.reservationDateTime)
    return (
        <div className={'flex  h-auto items-center justify-center'}>
            <div className='flex flex-col w-full h-auto bg-black rounded-2xl p-4 '>
                <div className='flex flex-col w-full h-auto items-start   gap-y-2'>
                    <h1 className=' w-full h-full flex text-yellow text-lg font-poppins-bold items-center justify-center'>
                        {props.reservation.userName.toUpperCase() + ' ' + props.reservation.userSurname.toUpperCase() + ' POSTI ' + props.reservation.numberOfPeople + ' ORE ' + new Date(props.reservation.reservationDateTime).toLocaleString().split(',')[1].slice(0, -3)}
                    </h1>
                    <h1 className="text-white text-sm font-poppins-bold">
                        DATA PRENOTAZIONE:{" "}
                        {(() => {
                            const dateObj = new Date(props.reservation.reservationDateTime);
                            dateObj.setHours(dateObj.getHours() - 1);
                            return dateObj.toLocaleString();
                        })()}
                    </h1>
                    <h1 className='text-white text-sm font-poppins-bold'>RICHIESTA
                        PRENOTAZIONE: {new Date(props.reservation.insertDate).toLocaleString()}</h1>

                    <h1 className='text-white text-sm font-poppins-bold'>PERSONE: {props.reservation.numberOfPeople}</h1>
                    <h1 className='text-white text-sm font-poppins-bold'>NOME: {props.reservation.userName}</h1>
                    <h1 className='text-white text-sm font-poppins-bold'>COGNOME: {props.reservation.userSurname}</h1>
                    <h1 className='text-white text-sm font-poppins-bold'>EMAIL: {props.reservation.reservationEmail}</h1>
                    <h1 className='text-white text-sm font-poppins-bold'>N.
                        TELEFONO: {props.reservation.reservationPhone}</h1>
                    <label className='text-white text-sm font-poppins-bold'
                           htmlFor="noteInput">NOTE: {props.reservation.reservationInfo}</label>

                    <div className={'flex items-center'}>
                        <h1 className='text-white text-sm font-poppins-bold'>STATO: </h1>
                        <div className={`w-4 h-4 ml-3 rounded-full ${colorStatus}`}></div>
                    </div>

                    {props.reservation.reservationStatus === StatusEnum.PENDING ?
                        <Textarea
                            label={'RISPOSTA:'}
                            value={noteAdmin}
                            onChange={(e) => setNoteAdmin(e.target.value)}
                            classNames={{label: 'text-white text-sm font-poppins-bold'}}
                            className='w-full text-white text-sm font-poppins-bold h-16 max-h-16'
                        /> : props.reservation.reservationStatus === StatusEnum.ACCEPTED ?
                            <div className={'w-full flex flex-col items-center gap-y-3 justify-center'}>
                                <h1 className='text-white text-xs font-poppins-bold'>CODICE PRENOTAZIONE:</h1>
                                {props.reservation.reservationCode && (
                                    <div className="w-full flex justify-center mb-2 bg-white p-2">
                                        <Barcode
                                            value={props.reservation.reservationCode.toString()}
                                            width={1.5}
                                            height={50}
                                            displayValue={false}
                                            background="#FFFFFF"
                                        />
                                    </div>
                                )}
                                <h1 className='text-white text-xl font-poppins-bold'>
                                    {props.reservation.reservationCode}
                                </h1>
                                <CustomButton
                                    small={true}
                                    bgColor={'#DE0000'}
                                    title={'Annulla prenotazione'}
                                    onClick={() => {
                                        showWarningNotification(
                                            `Vuoi eliminare la prenotazione di ${props.reservation.userName} ${props.reservation.userSurname} per la data ${data.toLocaleDateString()} alle ${data.getHours()}:${data.getMinutes()}?`,
                                            () => {
                                                deleteReservation(props.reservation.id, () => {
                                                    props.onCallFinish()
                                                })
                                            }
                                        )
                                    }}
                                    enable={true}
                                    isLoading={false}
                                />
                            </div> : null}
                    <div className='flex items-center w-full justify-center'>
                        {props.reservation.reservationStatus === StatusEnum.PENDING && (
                            <>
                                <div className={'flex w-full flex-col justify-center items-center'}>
                                    <div className={'flex w-full'}>
                                        <CustomButton bgColor={'#069C42'} title='ACCETTA' onClick={() => {
                                            showWarningNotification('Sicuro di voler accettare questo ordine?', () => {
                                                setStatusButton(true)
                                                sendResponseReservation(props.reservation.id, StatusEnum.ACCEPTED, noteAdmin)
                                            })
                                        }} enable={true} isLoading={statusButton}
                                                      customStyles={'w-full h-[50px] m-3 bg-green'}/>
                                        <CustomButton bgColor={'#DE0000'} title='RIFIUTA' onClick={() => {
                                            showWarningNotification('Sicuro di voler eliminare questo ordine?', () => {
                                                setStatusButton(true)
                                                sendResponseReservation(props.reservation.id, StatusEnum.REJECTED, noteAdmin)
                                            })
                                        }} enable={true} isLoading={statusButton}
                                                      customStyles={'w-full h-[50px] m-3 bg-red'}/>
                                    </div>
                                    <CustomButton small={true} bgColor={'#DE0000'} title={'Annulla prenotazione'}
                                                  onClick={() => {
                                                      showWarningNotification(`Vuoi eliminare la prenotazione presso ${props.reservation.nameRestaurants} per la data ${data.toLocaleDateString()} alle ${data.getHours()}:${data.getMinutes()}?`, () => {
                                                          deleteReservation(props.reservation.id, () => {
                                                              props.onCallFinish()
                                                          })
                                                      })
                                                  }} enable={true} isLoading={false}/>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
