import React, {useEffect, useState} from 'react';
import './App.css';
import './index.css';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import "@mantine/carousel/styles.css";
import logoMenus from './assets/logo-menus.webp'
import RouteLogin from "./routing/RouteLogin";
import {
    FailNotification,
    notificationFail,
    notificationSuccess,
    notificationWarning,
    SuccessNotification,
    WarningNotification
} from "./utils/NotificationUtils";
import {Dialog, Group, LoadingOverlay, Modal} from "@mantine/core";
import QrWebMenuPage from "./pages/admin/QrWebMenuPage";
import {LoginResponseType} from "./types/LoginResponseType";
import {getUserInfo, loginPreprod} from "./network/controllers/LoginApiController";
import {useDisclosure, useFavicon} from "@mantine/hooks";
import AddRestaurantSellerPage from "./pages/seller/add-restaurant/AddRestaurantSellerPage";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import logoSazio from "../src/assets/logo-mai-sazio.svg"
import Footer from "./components/generic/Footer";
import ImageLogoMenus from "./components/ImageLogoMenus";
import GoogleAddressSearch from './components/GoogleAddressSearch';
import ChatBotComponent from "./components/chatbot/ChatBotComponent";
import closeIcon from "./assets/close-icon.svg"
import CustomTextInput from "./components/generic/CustomTextInput";
import CustomButton from "./components/CustomButton";
import {isMobile} from "react-device-detect";

function App() {
    const firebaseConfig = {
        apiKey: "AIzaSyCFlHoToWeWY7e52EEebSQ6KiNmeuoBeeE",
        authDomain: "maisazio-web.firebaseapp.com",
        projectId: "maisazio-web",
        storageBucket: "maisazio-web.appspot.com",
        messagingSenderId: "420308238498",
        appId: "1:420308238498:web:15fcf03470a1b44a3f57fb",
        measurementId: "G-D835RSWXMF"
    };
    const [isPreprod,setIsPreprod] = useState<string|null>(sessionStorage.getItem("isPreprod"));
    useEffect(() => {
        if (window.location.host.includes('maisazio.gicod')){
            if (sessionStorage.getItem('isPreprod')===null){
                sessionStorage.setItem('isPreprod','true');
                setIsPreprod('true')
            }
        }
    }, []);


    useEffect(() => {
        if (!localStorage.getItem('geoData') || !JSON.parse(localStorage.getItem('geoData') as string).geometry){
            //default barcellona pozzo di gotto
            localStorage.setItem('geoData', JSON.stringify({"geometry":{"lat":38.1471908,"lng":15.2129391},"radius":1}));
            localStorage.setItem('formatted_address', '98051 Barcellona Pozzo di Gotto ME, Italia');
        }
    }, []);

    async function generateHMAC(message:string):Promise<string> {
        const encoder = new TextEncoder();
        const keyData = encoder.encode('authCodgosrl');
        const messageData = encoder.encode(message);

        // Importa la chiave
        const cryptoKey = await window.crypto.subtle.importKey(
            'raw',
            keyData,
            { name: 'HMAC', hash: { name: 'SHA-256' } },
            false,
            ['sign']
        );

        // Firma il messaggio con la chiave usando HMAC-SHA256
        const signature = await window.crypto.subtle.sign('HMAC', cryptoKey, messageData);
        // Converti il risultato in una stringa esadecimale leggibile
        const hashArray = Array.from(new Uint8Array(signature));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
    }

    const [isFooterVisible, setIsFooterVisible] = useState(!window.location.pathname.includes('admin') &&  !window.location.pathname.includes('registrati'));


    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    useFavicon(logoSazio);

    const [page, setPage] = useState(<div></div>);
    const [show, setShow] = useState("undefined");
    const [loading, setLoading] = useState(false);
    window.addEventListener('loader', (e) => {
        // @ts-ignore
        setLoading(e.detail.isLoading)
    });


    window.addEventListener('footerVisible', (e) => {
        // @ts-ignore
        setIsFooterVisible(e.detail.isVisible)
    });

    const userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType;

    useEffect(() => {
        if (userData) {
            getUserInfo(userData.email, (response) => {
                localStorage.setItem('userData', JSON.stringify(response));
                dispatchEvent(new CustomEvent("userInfo"));
            });
        }
    }, []);

    document.addEventListener("showSuccessNotification", function () {
        setShow('success');
    });
    document.addEventListener("showFailNotification", function () {
        setShow('fail');
    });
    document.addEventListener("showWarningNotification", function () {
        setShow('warning');
    });
    document.addEventListener('hideNotification', function () {
        setShow('undefined');
    });
    const [opened, { toggle, close }] = useDisclosure(false);
    const [preProdData, setPreProdData] = useState({
        email:'',
        password:'',
    })
    return (
        <div className={'w-screen h-screen '}>
            <div className={'w-full h-full'}>
                <Dialog classNames={{
                    root:'bg-transparent shadow-none'
                }} color={'#FFCC00'} position={{ top: 75, right:0 }} opened={!localStorage.getItem('closedGeodata')} onClose={()=>{
                    localStorage.setItem('closedGeodata','true')
                    window.location.reload()
                }} size="xl" radius="md">
                    <Group align="flex-end">
                        <div className={'w-full h-auto bg-black flex flex-col py-10 rounded-xl '}>
                            <div className={'w-full h-full flex  justify-between '}>
                                <div className={`w-full max-h-[60px] pl-10 pr-5 mt-[-10px]  flex justify-between`}>
                                    <ImageLogoMenus/>
                                    <div className={'cursor-pointer'} onClick={() => {
                                        localStorage.setItem('closedGeodata', 'true')
                                        window.location.reload()
                                    }}>
                                        <img className={'invert hover:invert-0'} src={closeIcon}/>
                                    </div>
                                </div>

                            </div>
                            <div className={`w-full px-10  flex flex-col justify-center items-center  `}>
                                <div className={'w-full flex flex-col mt-6'}>
                                    <h1 className={`text-white my-1  font-poppins-bold text-md `}><strong>Trova
                                        il ristorante più vicino a te</strong></h1>
                                    <h1 className={`text-white text-sm my-1 font-poppins-semi-bold mb-2`}>Inserisci il
                                        tuo indirizzo o la tua città</h1>
                                </div>
                                <div className={'w-full h-full flex '}>
                                    <GoogleAddressSearch defaultOpen={false}
                                                         inputDimensions={'w-full h-[60px]'}
                                                         suggestionDimensions={'w-full '}
                                                         setNameTextInput={'formatted_address'} width={'100px'}
                                                         onPlaceSelect={(v) => {
                                                             localStorage.setItem('closedGeodata','true')
                                                             localStorage.setItem('geoData', JSON.stringify({
                                                                 geometry: v.geometry.location,
                                                                 radius: 5
                                                             }))

                                                             localStorage.setItem('formatted_address', JSON.stringify(v.formatted_address))
                                                             window.location.reload()
                                                         }}/>
                                </div>

                            </div>
                        </div>
                    </Group>
                </Dialog>
                <LoadingOverlay
                    visible={loading}
                    zIndex={1000}
                    overlayProps={{radius: 'sm', blur: 2}}
                    loaderProps={{color: 'yellow', type: 'bars', size: 'xl'}}
                />
                {isPreprod==='true'?
                    <div className={'w-full h-screen flex flex-col items-center justify-between py-8 bg-black'}>
                        <img className={'w-56'} src={logoMenus} alt="Logo" />
                        <div className={'text-center text-white px-6'}>
                            <h1 className={'text-3xl font-bold mb-4'}>Bentornato!</h1>
                            <p className={'text-md text-gray-300'}>Accedi per esplorare la versione pre-produzione del nostro sito.</p>
                        </div>
                        <div className={`${isMobile?'w-full':'w-[35%]'} px-6 flex flex-col items-center justify-center gap-y-6`}>
                            <CustomTextInput
                                label={'Email'}
                                defaultValue={preProdData.email}
                                onChange={(email) => setPreProdData({ ...preProdData, email })}
                            />
                            <CustomTextInput
                                isPassword={true}
                                label={'Password'}
                                defaultValue={preProdData.password}
                                onChange={(pass) => setPreProdData({ ...preProdData, password: pass })}
                            />
                        </div>
                        <div className={'w-full px-6 flex items-center justify-center mt-6'}>
                            <CustomButton
                                small={true}
                                title={'Accedi'}
                                onClick={async () => {
                                    const hmacResult = await generateHMAC(`${preProdData.email}:${preProdData.password}`);
                                    loginPreprod(hmacResult,(response)=>{
                                        sessionStorage.setItem('isPreprod','false')
                                        setIsPreprod('false')
                                    })
                                }}
                                enable={true}
                                isLoading={false}
                            />
                        </div>
                    </div>:window.location.pathname.includes('qrMenu') ?
                    <QrWebMenuPage/> : window.location.pathname.includes('payment') ?
                        <RouteLogin/> : window.location.pathname.includes('registrati') ?
                            <AddRestaurantSellerPage isRenew={false}/> :
                            <RouteLogin/>}

                {sessionStorage.getItem('isPreprod')!=='true' && !window.location.pathname.includes('admin') && !window.location.pathname.includes('login') && !window.location.pathname.includes('registration') && !window.location.pathname.includes('rinnova') && !window.location.pathname.includes('restaurant/registrati') ?
                    <ChatBotComponent key={Math.random()}/> : null}
                {isFooterVisible && !window.location.pathname.includes('qr') && !window.location.pathname.includes('rinnova') && !window.location.pathname.includes('registr') && !window.location.pathname.includes('restaurant/registrati') ? (
                    <div className={navigator.userAgent.match(/iPhone|iPad|iPod/i) ? 'ios-footer' : ''}>
                        <Footer key={Math.random()} />
                    </div>
                ) : null}
            </div>
            {show === 'warning' && notificationWarning(WarningNotification.detail.title, WarningNotification.detail.warningYes)}
            {show === 'success' && notificationSuccess(SuccessNotification.detail.title, SuccessNotification.detail.text, SuccessNotification.detail.time)}
            {show === 'fail' && notificationFail(FailNotification.detail.title, FailNotification.detail.text, FailNotification.detail.time)}

        </div>
    );
}

export default App;
