import { Grid } from "@mantine/core";
import { isMobile } from "react-device-detect";
import { CategoryType } from "../../types/CategoryType";
import { useEffect, useState } from "react";
import { getCategories } from "../../network/controllers/admin/CategoryController";
import CategoryCommandItemDrawer from "./CategoryCommandItemDrawer";

export default function CommandCategoryComponent(props: { categorySelected: (category: CategoryType) => void }) {
    const [categoryList, setCategoryList] = useState<CategoryType[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadCategories = async () => {
            try {
                const restaurant = localStorage.getItem("selectedRestaurant");
                console.log("Valore di localStorage:", restaurant); // Debug

                if (!restaurant) {
                    setError("Ristorante non selezionato. Impossibile caricare le categorie.");
                    return;
                }

                const parsedRestaurant = JSON.parse(restaurant);
                if (!parsedRestaurant.id) {
                    setError("ID ristorante mancante nei dati.");
                    return;
                }

                const restaurantId = parsedRestaurant.id;
                console.log("ID Ristorante:", restaurantId); // Debug

                // Chiamata alla funzione getCategories
                getCategories(restaurantId, (list) => {
                    if (!list || list.length === 0) {
                        setError("Nessuna categoria disponibile.");
                    } else {
                        console.log("Categorie ricevute:", list); // Debug
                        setCategoryList(list);
                    }
                });
            } catch (err) {
                console.error("Errore durante il parsing di selectedRestaurant:", err);
                setError("Errore nella configurazione del ristorante selezionato.");
            }
        };

        loadCategories();
    }, []);

    return (
        <div className="w-full h-auto">
            <div className="w-full h-14 bg-black p-2 rounded-xl">
                <div className="flex items-center justify-center h-full">
                    <p className="font-bold text-amber-400 text-lg">
                        {categoryList.length > 0 ? "Seleziona Categoria" : error || "Nessuna Categoria"}
                    </p>
                </div>
            </div>
            <div className="w-full max-w-[100%] h-full flex flex-col px-4 pt-8 overflow-x-hidden">
                <Grid gutter="md">
                    {categoryList.length > 0
                        ? categoryList.map((item) => (
                            <Grid.Col
                                key={item.id}
                                span={isMobile ? 6 : { base: 12, xs: 6, md: 4, xl: 3 }}
                            >
                                <CategoryCommandItemDrawer
                                    category={item}
                                    onClick={(sel) => props.categorySelected(sel)}
                                />
                            </Grid.Col>
                        ))
                        : null}
                </Grid>
            </div>
        </div>
    );
}
