import React, {useRef, useState} from 'react';
import {Coordinates, Cropper, CropperRef} from "react-advanced-cropper";
import 'react-advanced-cropper/dist/style.css';
import CustomButton from "../../CustomButton";

export default function MobileCropImg(props: {
    file: File,
    onCrop: (cropped: string) => void,
    aspect: number
}) {
    const [image] = useState(URL.createObjectURL(props.file));
    const cropperRef = useRef<CropperRef>(null);

    const handleCrop = () => {
        if (cropperRef.current) {
            const canvas = cropperRef.current.getCanvas();
            if (canvas) {
                const dataUrl = canvas.toDataURL('image/jpeg', 0.7);
                props.onCrop(dataUrl);
            }
        }
    };

    return (
        <div className={'w-full h-auto'}>
            <div className={'w-full h-[600px]'}>
                <Cropper
                    ref={cropperRef}
                    src={image}
                    className={'cropper'}
                    defaultSize={{
                        width: 100,
                        height: 100 / props.aspect
                    }}
                    stencilProps={{
                        aspectRatio: props.aspect,
                        grid: true
                    }}
                />
            </div>

            <div className={'w-full items-center justify-center flex mt-4'}>
                <CustomButton
                    enable={true}
                    isLoading={false}
                    onClick={handleCrop}
                    title={'Fine'}
                />
            </div>
        </div>
    );
}
