import {Drawer, Grid, Group, Popover, PopoverDropdown, PopoverTarget, Radio} from "@mantine/core";
import {isMobile, isTablet} from "react-device-detect";
import React, {useEffect, useState} from "react";
import {ComandaOrder} from "../types/PostCommand";
import {getClientCommad} from "../network/controllers/CommandController";
import CommandAdminCardComponent from "../components/CommanAdminCardComponent";
import CustomTextInput from "../components/generic/CustomTextInput";
import {RestaurantStatusEnum} from "../enums/RestaurantStatusEnum";
import closeIcon from "../assets/close-icon.svg"
import DeliveryAdminPageMobile from "./DeliveryAdminPageMobile";
import {getDimension} from "../utils/Utility";
import {onMessage, subscribeTopic} from "../utils/MqttUtility";
import DeliveryAdminPage from "./DeliveryAdminPage";

export default function CommandAdminPage(props: { order?: ComandaOrder }) {
    const [isLoading, setIsLoading] = useState(true);
    const [popOverOrdiniOpened, setPopOverOrdiniOpened] = useState(false);
    const [dimension, setDimension] = useState(window.innerWidth < 1280)
    const [orders, setOrders] = useState<ComandaOrder[]>([]);
    const [filteredOrders, setFilteredOrders] = useState<ComandaOrder[]>([]);
    const [reload, setReload] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<string>('all');
    const [selectedStatus, setSelectedStatus] = useState<string>('all');


    useEffect(() => {
        var topic = `/pos/${JSON.parse(localStorage.getItem('selectedRestaurant')!).id}/reload`
        subscribeTopic(topic)
        onMessage(topic, () => {

            getClientCommad(JSON.parse(localStorage.getItem('selectedRestaurant')!).id, (response) => {
                if (response.length > 0) {
                    setOrders(response);
                    // Applichiamo i filtri esistenti ai nuovi dati
                    let filtered = response;
                    if (selectedType !== 'all') {
                        filtered = filtered.filter(order => order.type.toLowerCase() === selectedType.toLowerCase());
                    }
                    if (selectedStatus !== 'all') {
                        filtered = filtered.filter(order => order.status.toLowerCase() === selectedStatus.toLowerCase());
                    }
                    setFilteredOrders(filtered);
                }
            });
        });
        getDimension(1280, (res) => {
            setDimension(res)
        })
    }, [selectedType, selectedStatus])


    useEffect(() => {
        setIsLoading(true);
        dispatchEvent(new CustomEvent("loader", {detail: {isLoading: true}}));
        getClientCommad(JSON.parse(localStorage.getItem('selectedRestaurant')!).id, (response) => {
            dispatchEvent(new CustomEvent("loader", {detail: {isLoading: false}}));
            if (response.length > 0) {
                setOrders(response);
                setFilteredOrders(response);
            }
            setIsLoading(false);
        });
    }, []);


    useEffect(() => {
        filterOrders();
    }, [selectedType, selectedStatus, orders]);

    const handleTypeChange = (value: string) => {
        setSelectedType(value);
    };

    const handleStatusChange = (value: string) => {
        setSelectedStatus(value);
    };

    const handleClosePopover = () => {
        setPopOverOrdiniOpened(false);
    };

    const filterOrders = () => {
        let filtered = orders;

        if (selectedType !== 'all') {
            filtered = filtered.filter(order => order.type.toLowerCase() === selectedType.toLowerCase());
        }

        if (selectedStatus !== 'all') {
            filtered = filtered.filter(order => order.status.toLowerCase() === selectedStatus.toLowerCase());
        }

        setFilteredOrders(filtered);
    };

    const searchIntoOrders = (searchTerm: string) => {
        let filtered = orders;

        if (searchTerm !== '') {
            if (selectedType === 'DELIVERY') {
                filtered = filtered.filter(order =>
                    order.name.toLowerCase().includes(searchTerm) ||
                    order.surname.toLowerCase().includes(searchTerm) ||
                    order.address.toLowerCase().includes(searchTerm.toLowerCase())
                );
            } else {
                filtered = filtered.filter(order =>
                    order.name.toLowerCase().includes(searchTerm) ||
                    order.surname.toLowerCase().includes(searchTerm)
                );
            }
        }

        setFilteredOrders(filtered);
    };

    const [openDrawer, setOpenDrawer] = useState({open: false, content: <div></div>, dimension: '50%'});


    if (isMobile || isTablet) {
        return (
            <div
                className="w-full h-full flex flex-col items-center justify-center overflow-y-auto overflow-x-hidden pt-[300px]">
                <Drawer position={"right"}
                        size={openDrawer.dimension}
                        styles={{
                            content: {backgroundColor: 'black'},
                            header: {backgroundColor: 'black'}
                        }}
                        zIndex={40}
                        opened={openDrawer.open} onClose={() => {
                    setOpenDrawer({...openDrawer, open: false, dimension: "100%"})
                }}>
                    <DeliveryAdminPage restaurantStatus={RestaurantStatusEnum.DELIVERY}/>
                </Drawer>
                <div className="w-full h-auto flex flex-col justify-between items-center">
                    <p className="font-poppins-bold text-2xl mb-3">
                        Ordini Ricevuti
                    </p>
                    <div
                        className="w-auto max-h-min flex flex-col items-center bg-black rounded-2xl justify-center  mt-[8px] p-3">
                        <Radio.Group
                            name="Ordini"
                            label={<span
                                style={{color: '#FFCC00', fontSize: '16px'}}>Seleziona il tipo di ordine:</span>}
                            value={selectedType}
                            onChange={handleTypeChange}>
                            <CustomTextInput onChange={(value) => {
                                searchIntoOrders(value);
                            }} isBlack={true} customDimensions={'w-full mt-5'} placeholder={'cerca tra gli ordini...'}/>

                            <Group mt="sm">
                                <Radio value="all" label={<span style={{color: 'white', fontSize: '12px'}}>Tutti</span>}
                                       mt="md" color="#FFCC00"/>
                                <Radio
                                    className={`${JSON.parse(localStorage.getItem('selectedRestaurant')!).isDelivery === 'ASPORTO' || JSON.parse(localStorage.getItem('selectedRestaurant')!).isDelivery === 'DELIVERY' ? 'visible' : 'invisible'}`}
                                    value="ASPORTO"
                                    label={<span style={{color: 'white', fontSize: '12px'}}>Asporto</span>} mt="md"
                                    color="#FFCC00"/>
                                <Radio
                                    className={`${JSON.parse(localStorage.getItem('selectedRestaurant')!).isDelivery === 'DELIVERY' ? 'visible' : 'invisible'}`}
                                    value="DELIVERY"
                                    label={<span style={{color: 'white', fontSize: '12px'}}>Domicilio</span>} mt="md"
                                    color="#FFCC00"/>
                            </Group>
                        </Radio.Group>
                        <Radio.Group
                            name="Status"
                            value={selectedStatus}
                            onChange={handleStatusChange}>
                            <Group mt="md">
                                <Radio value="all" label={<span style={{color: 'white', fontSize: '12px'}}>Tutti</span>}
                                       mt="md" color="#FFCC00"/>
                                <Radio value="INATTESA"
                                       label={<span style={{color: 'white', fontSize: '12px'}}>In Attesa</span>} mt="md"
                                       color="#FFCC00"/>
                                <Radio value="COMPLETA"
                                       label={<span style={{color: 'white', fontSize: '12px'}}>Completi</span>} mt="md"
                                       color="#FFCC00"/>
                            </Group>
                        </Radio.Group>
                        <div
                            className={'w-full bg-yellow mt-8 flex items-center justify-center rounded-xl hover:scale-105 duration-500 cursor-pointer'}
                            onClick={() => {
                                setOpenDrawer({...openDrawer, open: true})
                            }}>

                            <p className={'font-poppins-semi-bold text-sm py-2'}>
                                {popOverOrdiniOpened ? 'Chiudi' : 'Crea Ordine'}
                            </p>

                        </div>

                    </div>
                </div>
                <div className="w-full h-full mt-10">
                    {filteredOrders.length === 0 && !isLoading ? (
                        <div className="w-full h-full flex items-center justify-center">
                            <p className="font-poppins-bold text-black text-center text-3xl">Non hai nessun ordine da
                                visualizzare</p>
                        </div>
                    ) : (
                        <Grid gutter="33">
                            {filteredOrders.map((item) => (
                                <Grid.Col className="w-auto" key={item.id}>
                                    <div className="w-full">
                                        <CommandAdminCardComponent order={item}/>
                                    </div>
                                </Grid.Col>
                            ))}
                        </Grid>
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div className="w-full h-full flex flex-col">
                <Drawer position={"right"}
                        size={openDrawer.dimension}
                        styles={{
                            content: {backgroundColor: 'black'},
                            header: {backgroundColor: 'black'}
                        }}
                        zIndex={40}
                        opened={openDrawer.open} onClose={() => {
                    setOpenDrawer({...openDrawer, open: false})
                }}>

                    <DeliveryAdminPage restaurantStatus={RestaurantStatusEnum.DELIVERY}/>
                </Drawer>
                <div className={`w-full h-auto flex justify-between items-center ${dimension ? "flex-col pt-32" : ""}`}>
                    <p className="font-poppins-bold text-3xl">
                        Ordini Ricevuti
                    </p>
                    <div
                        className={`flex flex-col items-center bg-black rounded-2xl justify-center p-3 ${dimension ? "mt-10" : "mt-[130px]"}`}>
                        <Radio.Group
                            name="Ordini"
                            label={<span
                                style={{color: '#FFCC00', fontSize: '14px'}}>Seleziona il tipo di ordine:</span>}
                            value={selectedType}
                            onChange={handleTypeChange}>
                            <CustomTextInput onChange={(value) => {
                                searchIntoOrders(value)
                            }} isBlack={true} customDimensions={'w-full mt-5'} placeholder={'cerca tra gli ordini...'}/>
                            {JSON.parse(localStorage.getItem('selectedRestaurant')!).isDelivery !== 'NESSUNO' ?
                                <Group mt="xs">
                                    <Radio value="all"
                                           label={<span style={{color: 'white', fontSize: '14px'}}>Tutti</span>}
                                           mt="sm" color="#FFCC00"/>
                                    <Radio
                                        className={`${JSON.parse(localStorage.getItem('selectedRestaurant')!).isDelivery === 'ASPORTO' || JSON.parse(localStorage.getItem('selectedRestaurant')!).isDelivery === 'DELIVERY' ? 'visible' : 'invisible'}`}
                                        value="ASPORTO"
                                        label={<span style={{color: 'white', fontSize: '14px'}}>Asporto</span>}
                                        mt="sm"
                                        color="#FFCC00"/>
                                    <Radio
                                        className={`${JSON.parse(localStorage.getItem('selectedRestaurant')!).isDelivery === 'DELIVERY' ? 'visible' : 'invisible'}`}
                                        value="DELIVERY"
                                        label={<span style={{color: 'white', fontSize: '14px'}}>Domicilio</span>}
                                        mt="sm"
                                        color="#FFCC00"/>
                                </Group> : null}
                        </Radio.Group>
                        <Radio.Group
                            name="Status"
                            value={selectedStatus}
                            onChange={handleStatusChange}>
                            <Group mt="xs">
                                <Radio value="all" label={<span style={{color: 'white', fontSize: '14px'}}>Tutti</span>}
                                       mt="sm" color="#FFCC00"/>
                                <Radio value="INATTESA"
                                       label={<span style={{color: 'white', fontSize: '14px'}}>In Attesa</span>}
                                       mt="sm"
                                       color="#FFCC00"/>
                                <Radio value="COMPLETA"
                                       label={<span style={{color: 'white', fontSize: '14px'}}>Completi</span>}
                                       mt="sm"
                                       color="#FFCC00"/>
                            </Group>
                        </Radio.Group>
                        <div
                            className={'w-full bg-yellow mt-8 flex items-center justify-center rounded-xl hover:scale-105 duration-500 cursor-pointer'}
                            onClick={() => {
                                setOpenDrawer({...openDrawer, open: true})
                            }}>

                            <p className={'font-poppins-semi-bold text-sm py-2'}>
                                {popOverOrdiniOpened ? 'Chiudi' : 'Crea Ordine'}
                            </p>

                        </div>
                    </div>
                </div>
                <div className="w-full h-full mt-10">
                    {filteredOrders.length === 0 && !isLoading ? (
                        <div className="w-full h-full flex items-center justify-center">
                            <p className="font-poppins-bold text-black text-3xl">Non hai nessun ordine da
                                visualizzare</p>
                        </div>
                    ) : (
                        <Grid gutter="12">
                            {filteredOrders.map((item) => (
                                <Grid.Col className="w-auto" span={isMobile ? 12 : {base: 12, md: 6, xl: 4}}
                                          key={item.id}>
                                    <div className="w-full">
                                        <CommandAdminCardComponent order={item}/>
                                    </div>
                                </Grid.Col>
                            ))}
                        </Grid>
                    )}
                </div>
            </div>
        );
    }
}
