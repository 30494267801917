import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ReservationResponseType } from "../../types/ReservationResponseType";
import { getReservationRestaurants } from "../../network/controllers/admin/ReservationController";
import { StatusEnum } from "../../enums/StatusEnum";
import AccordionReservation from "../../components/AccordionReservation";
import HeaderReservationFilter from "../../components/HeaderReservationFilter";
import { addAlpha } from "../../utils/Utility";
import { subscribeTopic, onMessage } from "../../utils/MqttUtility";
import { LocalType } from "../../types/LocalType";
import { isMobile } from "react-device-detect";

dayjs.extend(utc);

export default function ReservationAdminPage() {
    const local = JSON.parse(localStorage.getItem("selectedRestaurant") as string) as LocalType;
    const [reload, setReload] = useState<boolean>(false);
    const [dataReservationAccepted, setDataReservationAccepted] = useState<Map<string, ReservationResponseType[]>>(new Map());
    const [dataReservationRejected, setDataReservationRejected] = useState<Map<string, ReservationResponseType[]>>(new Map());
    const [dataReservationDone, setDataReservationDone] = useState<Map<string, ReservationResponseType[]>>(new Map());
    const [dataReservationPending, setDataReservationPending] = useState<Map<string, ReservationResponseType[]>>(new Map());

    const [dimension, setDimension] = useState(window.innerWidth < 1280);
    useEffect(() => {
        const handleResize = () => setDimension(window.innerWidth < 1280);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const [value, setValue] = useState<{ date: Date; status: StatusEnum }>({
        date: new Date(),
        status: StatusEnum.PENDING,
    });

    useEffect(() => {
        const adjustedDate = dayjs(value.date).utcOffset(2, true); // Imposta il fuso orario per l'Italia (CET/CEST)

        getReservationRestaurants(StatusEnum.ACCEPTED, adjustedDate.toDate(), (res) => {
            const tmp = new Map<string, ReservationResponseType[]>();
            Object.entries(res).forEach(([key, value]) => tmp.set(key, value));
            setDataReservationAccepted(tmp);
        });

        getReservationRestaurants(StatusEnum.REJECTED, adjustedDate.toDate(), (res) => {
            const tmp = new Map<string, ReservationResponseType[]>();
            Object.entries(res).forEach(([key, value]) => tmp.set(key, value));
            setDataReservationRejected(tmp);
        });

        getReservationRestaurants(StatusEnum.DONE, adjustedDate.toDate(), (res) => {
            const tmp = new Map<string, ReservationResponseType[]>();
            Object.entries(res).forEach(([key, value]) => tmp.set(key, value));
            setDataReservationDone(tmp);
        });

        getReservationRestaurants(StatusEnum.PENDING, adjustedDate.toDate(), (res) => {
            const tmp = new Map<string, ReservationResponseType[]>();
            Object.entries(res).forEach(([key, value]) => tmp.set(key, value));
            setDataReservationPending(tmp);
        });
    }, [value, reload]);

    useEffect(() => {
        const topic = "/reservation/" + local.nameRestaurant.replace(/ /g, "-").toLowerCase();
        subscribeTopic(topic);
        onMessage(topic, () => {
            setReload(!reload);
        });
    }, [local.nameRestaurant, reload]);

    if (isMobile) {
        return (
            <div className={"flex w-full h-auto flex-col items-center justify-center pt-[130px]"}>
                <div className={"w-full h-auto"}>
                    <HeaderReservationFilter
                        reload={() => setReload(!reload)}
                        isAdmin={true}
                        onValueChange={(value) => setValue(value)}
                    />
                </div>
                <div className={"flex flex-col w-full h-full gap-y-10 justify-around mt-20"}>
                    {renderAccordion("In Attesa", dataReservationPending, "amber-400", "#FFBF00")}
                    {renderAccordion("Accettati", dataReservationAccepted, "green-700", "#00A86B")}
                    {renderAccordion("Completati", dataReservationDone, "blue-700", "#002db6")}
                    {renderAccordion("Rifiutati", dataReservationRejected, "red-700", "#DE0000")}
                </div>
            </div>
        );
    } else {
        return (
            <div className={`flex w-full h-full flex-col pt-[130px]`}>
                <div className={"w-full h-auto"}>
                    <HeaderReservationFilter
                        reload={() => setReload(!reload)}
                        isAdmin={true}
                        onValueChange={(value) => setValue(value)}
                    />
                </div>

                <div className={`flex w-full h-full ${dimension ? "flex-col" : ""}`}>
                    {renderAccordion("In Attesa", dataReservationPending, "amber-400", "#FFBF00", dimension)}
                    {dimension ? null : <div className="w-[5px] h-full bg-gray-400 opacity-50" />}
                    {renderAccordion("Accettati", dataReservationAccepted, "green-700", "#00A86B", dimension)}
                    {dimension ? null : <div className="w-[5px] h-full bg-gray-400 opacity-50" />}
                    {renderAccordion("Completati", dataReservationDone, "blue-700", "#002db6", dimension)}
                    {dimension ? null : <div className="w-[5px] h-full bg-gray-400 opacity-50" />}
                    {renderAccordion("Rifiutati", dataReservationRejected, "red-700", "#DE0000", dimension)}
                </div>
            </div>
        );
    }

    function renderAccordion(
        title: string,
        data: Map<string, ReservationResponseType[]>,
        textColor: string,
        bgColor: string,
        isColumn?: boolean
    ) {
        return (
            <div className={`flex ${isColumn ? "w-full h-auto flex-col p-2" : "w-full h-auto flex-col p-4"}`}>
                <div className={"w-full flex justify-center"}>
                    <h1
                        className={`font-poppins-semi-bold text-lg text-${textColor} w-full text-center rounded-2xl`}
                        style={{ backgroundColor: addAlpha(bgColor, 0.1) }}
                    >
                        {title}
                    </h1>
                </div>
                <AccordionReservation isAdmin={true} dataReservation={data} reload={() => setReload(!reload)} />
            </div>
        );
    }
}
