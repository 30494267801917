import edit from '../../../assets/edit-icon.svg'
import trashIcon from '../../../assets/delete-icon.svg'
import React, {useState} from "react";
import {Input} from "@mantine/core";
import confirm from "../../../assets/confirm-icon.svg";
import closeIcon from "../../../assets/close-icon.svg";
import {showSuccessNotification, showWarningNotification} from "../../../utils/NotificationUtils";
import loadingIcon from '../../../assets/loader-white.svg'
import {TurnType} from "../../../types/TurnType";
import {deleteTurn, updateTurn} from "../../../network/controllers/admin/TurnController";
import arrowSelection from "../../../assets/arrowSelection.svg";


export default function TurnAdminTable(props: {
    turn: TurnType,
    onClickItem: (element: any) => void,
    onTurnUpdate: (turnList: TurnType[]) => void
}) {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [newTurn, setNewTurn] = useState(props.turn.nameTurn);
    const [isLoading, setIsLoading] = useState(false);


    return (
        <div className={' flex gap-x-20'}>
            {
                !isEditing ? <div className={'w-full flex justify-between gap-x-20 '}>
                        <div className={'w-auto flex items-center justify-center gap-x-2'}>
                            <img alt="" src={arrowSelection}/>
                            <h1 className={'w-auto font-poppins-regular m-1'}>{props.turn.nameTurn}</h1>
                        </div>
                        <div className={'w-[100px] flex gap-x-5'}>
                            <div className={'w-5 h-5 cursor-pointer'}>
                                <img alt={''} className={'w-5 h-5'} src={isLoading ? loadingIcon : edit} onClick={() => {
                                    setIsEditing(true)
                                }}/>
                            </div>

                            <div className={'w-5 h-5 cursor-pointer'}>
                                <img alt={''} src={isLoading ? loadingIcon : trashIcon} onClick={() => {
                                    showWarningNotification(`Sicuro di voler eliminare  ${props.turn.nameTurn}?`, () => {
                                        setIsLoading(true);
                                        deleteTurn(props.turn.id, (response) => {
                                            window.location.reload()
                                        })
                                    })
                                }}/>
                            </div>

                        </div>

                    </div> :
                    <div className={'w-full flex justify-between gap-x-20 '}>
                        <Input variant={'unstyled'} type={'text'} value={newTurn}
                               className={'w-[250px] h-full bg-white text-text-button-add font-poppins-regular outline-none '}
                               placeholder={'Nome Turno'} onChange={(event) => {
                            setNewTurn(event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1))

                        }}/>
                        <div className={'w-[100px] flex gap-x-5'}>
                            <img alt={''} className={'w-6 h-6 cursor-pointer'} src={isLoading ? loadingIcon : closeIcon}
                                 onClick={() => {
                                     setIsEditing(false)
                                 }}/>

                            <img alt={''} className={'w-6 h-6 cursor-pointer'} src={isLoading ? loadingIcon : confirm}
                                 onClick={() => {
                                     setIsEditing(true)
                                     updateTurn({id: props.turn.id, nameTurn: newTurn}, (response) => {
                                         setIsLoading(false)
                                         showSuccessNotification('Turno', `${newTurn} modificato con successo`, 2000)
                                         setIsEditing(false)
                                         props.onTurnUpdate(response)
                                     })
                                 }}/>
                        </div>

                    </div>
            }

        </div>
    )
}
